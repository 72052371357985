import React from "react";
import { v4 as uuidv4 } from "uuid";

import { DataTable } from "../../../library/table/Index";
import EmployeeCertificationUpdateAction from "../actions/EmployeeCertificationUpdateAction";
import EmployeeCertificationDeleteAction from "../actions/EmployeeCertificationDeleteAction";
import { multiLineTextFormatter } from "../../../helpers/Formatter";

function urlFormatter(cell, row) {
  return (
    <a href={cell}>
      {cell ? <i className="fa fa-link" aria-hidden="true"></i> : <></>}
    </a>
  );
}

let dataTableColumnsWithoutActions = [
  {
    text: "Title",
    dataField: "title",
    sort: true,
  },
  {
    text: "Description",
    dataField: "description",
    sort: true,
    formatter: multiLineTextFormatter,
  },
  {
    text: "Link",
    dataField: "link",
    sort: true,
    formatter: urlFormatter,
  },
];
let dataTableColumnsWithActions = [
  {
    text: "Title",
    dataField: "title",
    sort: true,
  },
  {
    text: "Description",
    dataField: "description",
    sort: true,
    formatter: multiLineTextFormatter,
  },
  {
    text: "Link",
    dataField: "link",
    sort: true,
    formatter: urlFormatter,
  },
  {
    text: "Actions",
    dataField: "actions",
    sort: false,
  },
];

const ActionGroup = (props) => {
  return (
    <div className="d-inline-block action-col">
      <EmployeeCertificationUpdateAction
        key={uuidv4()}
        profile={props.profile}
        employeeCertificationRow={props.employeeCertificationRow}
        onUpdateCallback={props.callbacks.onEmployeeCertificationUpdate}
      />
      <EmployeeCertificationDeleteAction
        key={uuidv4()}
        profile={props.profile}
        employeeCertificationRow={props.employeeCertificationRow}
        onDeleteCallback={props.callbacks.onEmployeeCertificationDelete}
      />
    </div>
  );
};

const buildTableRows = (profile, hasActionGroup, callbacks) => {
  let tempRows = [];
  if (profile.employeeCertification !== null) {
    profile.employeeCertification.forEach((employeeCertificationRow) => {
      let tempRow = {
        title: employeeCertificationRow.title,
        description: employeeCertificationRow.description,
        link: employeeCertificationRow.link,
      };
      if (hasActionGroup) {
        tempRow["actions"] = (
          <ActionGroup
            profile={profile}
            callbacks={callbacks}
            employeeCertificationRow={employeeCertificationRow}
          />
        );
      }
      tempRows.push(tempRow);
    });
  }
  return tempRows;
};

const CertificationTable = (props) => {
  let callbacks = {
    onEmployeeCertificationDelete: props.onDelete,
    onEmployeeCertificationUpdate: props.onUpdate,
  };
  let rows = buildTableRows(props.profile, props.hasActionGroup, callbacks);
  return (
    <DataTable
      rows={rows}
      columns={
        props.hasActionGroup
          ? dataTableColumnsWithActions
          : dataTableColumnsWithoutActions
      }
      excludeSearch={true}
      excludePagination={true}
    />
  );
};

const EmployeeCertificationView = (props) => {
  return (
    <div>
      <CertificationTable {...props} />
    </div>
  );
};
export default EmployeeCertificationView;
