import React from "react";
import {MultiSelect} from "react-multi-select-component";
import TeamApi from "../../../apis/endpoints/TeamApi";
import { defaultErrorResponseParser } from "../../../helpers/ErrorParser";
import styles from "./styles/TeamMembers.module.css";
import PropTypes from "prop-types";

export default class TeamMemberDropdown extends React.Component {

    overrideStrings = {
        "allItemsAreSelected": "All members are selected.",
        "clearSearch": "Clear Search",
        "noOptions": "No options",
        "search": "Search",
        "selectAll": "Select All",
        "selectSomeItems": "Select team members..."
    }

    static propTypes = {
        onTeamMemberSelect: PropTypes.func.isRequired,
    };

    state = {
        isLoading: false,
        options: [],
        selected: []
    };

    componentDidMount() {
        this.props.onRef(this)
    }

    componentWillUnmount() {
        this.props.onRef(undefined)
    }

    loadTeamMembersData = (selectedTeamIds) => {
        TeamApi.getMembersByTeamIds(selectedTeamIds)
            .then((res) => {
                let options = [];
                options = options.concat(
                    res.data.flatMap((t) => {
                        return { value: t.id, label: t.name };
                    })
                );
                this.setState({
                    options: options
                });
            })
            .catch((err) => {
                defaultErrorResponseParser(err, "Failed to load Teammembers information");
            });
    };

    getSelectedTeamMemberIds = () => {
        var selectedTeamMemberIds = '';
        this.state.selected.forEach(function (element) {
            selectedTeamMemberIds += element.value + ',';
        });
        return selectedTeamMemberIds.replace(/,\s*$/, "");
    }

    setSelected = (selectedList) => {
        this.props.onTeamMemberSelect(selectedList);
        this.setState({ selected: selectedList });
    }

    clearSelected = () => {
        this.setState({ selected: [] });
        this.setState({ options: [] });
    }

    render() {
        return (
            <div className="form-group">
                <label className="form-label">Team Members</label>
                <MultiSelect
                    className={styles["rmsc"]}
                    hasSelectAll={false}
                    overrideStrings={this.overrideStrings}
                    options={this.state.options}
                    value={this.state.selected}
                    onChange={this.setSelected}
                    labelledBy="Select.."
                />
            </div>
        );
    }
}
