import React from "react";
import PropTypes from "prop-types";
import { FormModal } from "../../../library/modal/Index";
import { CefaloButton } from "../../../library/button/Index";
import { SkillDevelopmentRequestDto } from "../../../models/SkillDevelopmentFund";

function RequestRefundActionComponent({ request, onRefund }) {
  const { id: requestId } = request;
  const [show, setShow] = React.useState(false);
  const toggleShow = React.useCallback(() => setShow(state => !state), [setShow]);
  const onSubmit = React.useCallback((e) => {
    toggleShow();
    e.preventDefault();
    e.stopPropagation();
    onRefund(requestId)
  }, [requestId, onRefund, toggleShow]);

  return (
    <div className="d-inline-block">
      <FormModal
        formName="Refund Request"
        onSubmit={onSubmit}
        show={show}
        toggle={toggleShow}
      >
        <div key={FormModal.ControlContainer}>
          <p>Are you sure to refund this request?</p>
        </div>
        <div key={FormModal.ActionContainer}>
          <CefaloButton
            size="sm"
            variant="danger"
            onClick={toggleShow}
            type="button"
          >
            No
          </CefaloButton>
          <CefaloButton size="sm" variant="success" type="submit">
            Yes
          </CefaloButton>
        </div>
      </FormModal>
      <CefaloButton
        className="btn-font-12"
        size="sm"
        variant="primary"
        onClick={toggleShow}
      >
        Refund
      </CefaloButton>
    </div>
  );
}

RequestRefundActionComponent.propTypes = {
  request: PropTypes.instanceOf(SkillDevelopmentRequestDto).isRequired,
  onRefund: PropTypes.func.isRequired,
};

export const RequestRefundAction = RequestRefundActionComponent;