// const BASE_URL = "https://localhost:44380";

// Enable this before deploying to test server
//const BASE_URL = "https://test-hrportal.cefalolab.com";

// Enable this before deploying to prod server
const BASE_URL = "https://hrportal.cefalolab.com";

export const config = {
  REACT_APP_GOOGLE_CLIENT_ID:
    "17849993230-4hgid40cv298seg554vc9kofv3aqoi8s.apps.googleusercontent.com",
  API_BASE_URL: `${BASE_URL}/api`,
  PROFILE_IMAGE_BASE_URL: `${BASE_URL}/api/avatars`,
};
// server live : '17849993230-4hgid40cv298seg554vc9kofv3aqoi8s.apps.googleusercontent.com'
// local  :"452994492151-ful720m9utvbuv8rhjeng5d83cpil6v2.apps.googleusercontent.com"