import { AuthenticatedAxios } from "../settings/Axios";
const BaseEndPoint = "";

export default {
  uploadTemplate: (formData) => {

    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    let endpoint = `${BaseEndPoint}/File`;
    return AuthenticatedAxios.post(endpoint, formData, config);
  },

  getLeaveReport: (formData) => {

    let config = {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      responseType: 'blob'
    };

    let endpoint = `${BaseEndPoint}/Teams/GenerateReport`;
    return AuthenticatedAxios.post(endpoint, formData, config);
  },
};
