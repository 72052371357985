import React, { useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import { Col } from "react-bootstrap";
import { Form } from "../../library/form/Index";
import { config } from "../../config.json";
import { Loading, toaster } from "../../shared-components/notification";
import Layout from "../../shared-components/layout/Layout";
import styles from "./styles/Profile.module.css";
import ProfileApi from "../../apis/endpoints/ProfileApi";
import TeamHistoryApi from "../../apis/endpoints/TeamHistoryApi";
import UserManager from "../../navigation/UserManager";
import UserProfileContactInfoView from "./views/UserProfileContactInfoView";
import UserProfilePersonalInfoView from "./views/UserProfilePersonalInfoView";
import UserProfileEducationInfoView from "./views/UserProfileEducationInfoView";
import UserProfileInfoView from "./views/UserProfileInfoView";
import UserProfileAttendanceStatView from "./views/UserProfileAttendanceStatView";
import UserProfileLeaveStatView from "./views/UserProfileLeaveStatView";
import UserProfileTeamHistoryView from "../team-history/views/UserProfileTeamHistoryView";
import EmployeeCertificationView from "../employee-certification/views/EmployeeCertificationView";
import UserApi from "../../apis/endpoints/UserApi";
import TrainingHistoryView from "../training-history/views/TrainingHistoryView";
import UserProfileAttendanceReportView from "./views/UserProfileAttendanceReportView";
import SettingsApi from "../../apis/endpoints/SettingsApi";
import AttendanceApi from "../../apis/endpoints/AttendanceApi";
import { DateOnlyString } from "../../helpers/Builders";
import { getDateByAddingDays } from "../../helpers/DateUtility";

const UserProfileViewContainer = (props) => {
  const [profile, setProfile] = useState(null);
  const [entry, setEntry] = useState(null);
  const [imageSrc, setImageSrc] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [shouldRender, setShouldRender] = useState(false);
  const [teamHistory, setTeamHistory] = useState(null);
  const [trainingHistory, setTrainingHistory] = useState(null);
  const [adminEmail, setAdminEmail] = useState("hr@cefalo.com");
  const [attendanceReports, setAttendanceReports] = useState(null);

  const bindPageData = useCallback(() => {
    loadPublicSettings();
    loadUserProfile(props.match.params.id);
    loadTrainingHistory(props.match.params.id);

    if (UserManager.getUser().isAdminOrHr()) {
      loadTeamHistoryData(props.match.params.id);
      loadUserAttendanceReports(props.match.params.id);
      loadUserTodaysEntry(props.match.params.id);
    }
  }, [props.match.params.id]);

  useEffect(() => {
    bindPageData();
  }, [bindPageData]);

  const loadUserTodaysEntry = (userId) => {
    AttendanceApi.getUserTodaysEntry(userId)
      .then((res) => {
        setEntry(res.data);
      })
      .catch((err) => {
        toaster.error("Failed to load Attendance Reports");
      });
  };

  const loadUserProfile = (userId) => {
    ProfileApi.get(userId)
      .then((res) => {
        setImageSrc(
          `${config.PROFILE_IMAGE_BASE_URL}/${res.data.photoFileName}`
        );
        setProfile(res.data);
        setShouldRender(true);
        setIsLoading(false);
      })
      .catch(() => {
        toaster.error("Profile info not found! Please contact with admin");
        setShouldRender(false);
        setIsLoading(false);
        setProfile(null);
      });
  };

  const loadUserAttendanceReports = (userId) => {
    let fromDate = DateOnlyString(getDateByAddingDays(-30));
    let toDate = DateOnlyString(new Date());
    AttendanceApi.getAllByUser(userId, fromDate, toDate)
      .then((res) => {
        let attendances = res.data;
        setAttendanceReports(attendances);
      })
      .catch((err) => {
        toaster.error("Failed to load Attendance Reports");
      });
  };

  const loadTeamHistoryData = (userId) => {
    TeamHistoryApi.getByUserId(userId)
      .then((res) => {
        let allTeamHistory = res.data;
        let teamHistory = [];

        allTeamHistory.forEach((element) => {
          teamHistory.push(element);
        });
        setTeamHistory(teamHistory);
      })
      .catch((err) => {
        toaster.error("Failed to load Team History information");
      });
  };

  const loadTrainingHistory = (userId) => {
    UserApi.getUserTrainingsByUserId(userId)
      .then((res) => {
        setTrainingHistory(res.data);
      })
      .catch((err) => {
        toaster.error("Failed to load user training history");
      });
  };

  const loadPublicSettings = () => {
    SettingsApi.getPublicSettings()
      .then((res) => {
        let publicSettings = res.data;
        setAdminEmail(publicSettings.adminEmail);
      })
      .catch((err) => {
        toaster.error("Failed to load Public Settings");
      });
  };

  return (
    <div>
      <Layout>
        <Helmet>
          <title>View Profile - Cefalo</title>
        </Helmet>
        <Loading show={isLoading} />
        {shouldRender && (
          <div className="p-4">
            <UserProfileInfoView
              profileInfo={profile}
              imageSrc={imageSrc}
              adminEmail={adminEmail}
              entry={entry}
            ></UserProfileInfoView>
            <Form.Row>
              <Col sm={9}>
                <Form.Row
                  className={
                    profile?.educationInfos?.length > 0 ? "mt-4 mb-5" : "mt-4"
                  }
                >
                  {profile?.personalInfo !== undefined && (
                    <>
                      <Col>
                        <UserProfilePersonalInfoView
                          personalInfo={profile?.personalInfo}
                        ></UserProfilePersonalInfoView>
                      </Col>
                      <div className="offset-sm-1"></div>
                    </>
                  )}
                  {profile?.educationInfos !== undefined && (
                    <>
                      <Col>
                        <UserProfileEducationInfoView
                          educationInfos={profile?.educationInfos}
                        ></UserProfileEducationInfoView>
                      </Col>
                    </>
                  )}
                </Form.Row>
                <Form.Row>
                  <Col sm={12}>
                    {profile?.contactInfo !== undefined && (
                      <div>
                        <UserProfileContactInfoView
                          contactInfo={profile?.contactInfo}
                        ></UserProfileContactInfoView>
                      </div>
                    )}
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col sm={12} className={styles.profileTitleHeader}>
                    <div className="mt-5">
                      Training History
                      <hr className="mt-0" />
                      <TrainingHistoryView
                        trainingHistory={trainingHistory}
                        hasActionGroup={false}
                      ></TrainingHistoryView>
                    </div>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col sm={12} className={styles.profileTitleHeader}>
                    {profile?.employeeCertification !== undefined && (
                      <div className="mt-5">
                        Courses and Certifications
                        <hr className="mt-0" />
                        <EmployeeCertificationView
                          profile={profile}
                        ></EmployeeCertificationView>
                      </div>
                    )}
                  </Col>
                </Form.Row>
              </Col>
              <Col sm={3} className="pl-3">
                {UserManager.getUser().isAdminOrHr() && (
                  <div className="mt-4">
                    <a href={"/Report/Index/" + profile?.userId}>
                      <b className={styles.attendanceReportHeader}>
                        Attendance Report
                      </b>
                    </a>
                    <hr className="mt-1" />
                    <UserProfileAttendanceReportView
                      attendances={attendanceReports}
                    />
                  </div>
                )}
                {profile?.userAttendanceStatDto !== undefined && (
                  <UserProfileAttendanceStatView
                    userId={profile?.userId}
                    attendanceStat={profile?.userAttendanceStatDto}
                  ></UserProfileAttendanceStatView>
                )}
                {profile?.userLeaveStatDto !== undefined && (
                  <UserProfileLeaveStatView
                    userId={profile?.userId}
                    leaveStat={profile?.userLeaveStatDto}
                  ></UserProfileLeaveStatView>
                )}
              </Col>
              <Col sm={12} className={styles.profileTitleHeader}>
                {UserManager.getUser().isAdminOrHr() && (
                  <div className="mt-5">
                    Team Allocations
                    <hr className="mt-0" />
                    <UserProfileTeamHistoryView
                      teamHistory={teamHistory}
                      profile={profile}
                      hasActionGroup={false}
                    />
                  </div>
                )}
              </Col>
            </Form.Row>
          </div>
        )}
      </Layout>
    </div>
  );
};

export default UserProfileViewContainer;
