import React from "react";
import PropTypes from "prop-types";
import UserApi from "../../../apis/endpoints/UserApi";
import UserUpsertForm from "../forms/UserUpsertForm";
import { Loading, toaster } from "../../../shared-components/notification";
import { defaultErrorResponseParser } from "../../../helpers/ErrorParser";
import { CefaloButton } from "../../../library/button";

class UserCreateAction extends React.Component {
  static propTypes = {
    onCreateCallback: PropTypes.func.isRequired,
  };

  state = {
    show: false,
    isLoading: false,
  };

  toggle = (e) => () => {
    this.setState({ show: !this.state.show });
  };

  onCreateAction = (data) => {
    this.setState({ isLoading: true });
    UserApi.create(data)
      .then((res) => {
        this.setState({
          isLoading: false,
          show: false,
        });
        toaster.success("User Saved Successfulluy");
        this.props.onCreateCallback(res.data);
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        defaultErrorResponseParser(err, "Failed To Save User");
      });
  };

  render() {
    return (
      <div className="d-inline-block">
        <Loading show={this.state.isLoading} />
        <CefaloButton size="sm" variant="primary" onClick={this.toggle()}>
          Add New User
        </CefaloButton>
        <UserUpsertForm
          teams={this.props.teams}
          userRoles={this.props.userRoles}
          onSubmit={this.onCreateAction}
          show={this.state.show}
          onToggle={this.toggle}
        />
      </div>
    );
  }
}

export default UserCreateAction;
