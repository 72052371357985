import React from "react";
import UserManager from "../../navigation/UserManager";
import Layout from "../../shared-components/layout/Layout";
import { Helmet } from "react-helmet";
import { Form, Input } from "../../library/form";
import { toaster, Loading } from "../../shared-components/notification";
import TeamLeaveReportApi from "../../apis/endpoints/TeamLeaveReportApi";
import { defaultErrorResponseParser } from "../../helpers/ErrorParser";
import { CefaloButton } from "../../library/button";
import TeamMemberDropdown from "../dropdowns/team-members/team-members.dropdown";
import TeamDropdown from "../dropdowns/team/teams.dropdown";

export default class TeamLeaveReportContainer extends React.Component {
  state = {
    shouldRender: false,
    temporaryFileName: null,
    selectedTeamIds: null,
    isLoading: false,
    remarks: '',
    isReady: false
  };

  componentDidMount() {
    let user = UserManager.getUser();
    if (user.isAdminOrHr()) {
      this.setState({ shouldRender: true });
    } else {
      window.location.replace(`/Report/Index/${user.id}`);
    }
  }

  onTeamSelect = (selectedTeams) => {
    if (selectedTeams.length > 0) {
      var selectedTeamIds = this.getSelectedTeamIds(selectedTeams);
      this.childTeamMember.loadTeamMembersData(selectedTeamIds);
    }
    else
      this.childTeamMember.clearSelected();

    this.IsPageDataValidate();
  };

  onTeamMemberSelect = (selectedTeamMembers) => {
    if (!Array.isArray(selectedTeamMembers) || selectedTeamMembers.length === 0) {
      this.childTeamMember.clearSelected();
    }
    
    this.IsPageDataValidate();
  };

  getSelectedTeamIds = (selectedTeams) => {
    var teamIds = '';
    selectedTeams.forEach(function (element) {
      teamIds += element.value + ',';
    });
    return teamIds.replace(/,\s*$/, "");
  }

  handleChange = (e) => {
    this.setState({ remarks: e.target.value });
  };

  handleTemplateInput = (e) => {
    if (e.target.files.length === 0) {
      toaster.error("Select a file first and then continue");
      return;
    }

    if (e.target.files.length > 1) {
      toaster.error("Multiple files are not allowed");
      return;
    }

    let file = e.target.files[0];
    if (file === null) {
      return;
    }

    let extension = file.name.split(".").pop().toLowerCase();
    if (extension !== "xlsx") {
      toaster.error("Only excel file in .xslx format is allowed");
      return;
    }

    this.uploadTemporaryFile(file);
  };

  uploadTemporaryFile = (file) => {

    let formData = new FormData();
    formData.append("formFile", file);
    formData.append("fileType", "LeaveReportTemplate");

    TeamLeaveReportApi.uploadTemplate(formData)
      .then((res) => {
        this.setState({ temporaryFileName: res.data });
        toaster.success("File uploaded successfully");
        this.IsPageDataValidate();
      })
      .catch((err) => {
        defaultErrorResponseParser(err, "Failed to upload template");
      });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (!this.IsValidateFileUpload()) {
      toaster.error("Leave report template must be uploaded");
      this.IsPageDataValidate();
      return;
    } else if (!this.IsValidateTeamDropdown()) {
      toaster.error("Team is not selected");
      this.IsPageDataValidate();
      return;
    }
    else if (!this.IsValidateTeamMemberDropdown()) {
      toaster.error("Team member is not selected");
      this.IsPageDataValidate();
      return;
    }

    let formData = this.PrepareSubmitValues();

    this.SubmitLeaveReportGeneration(formData);
  };

  IsValidateFileUpload() {
    if (this.state.temporaryFileName)
      return true;

    return false;
  }

  IsValidateTeamDropdown() {
    if (this.childTeam && this.childTeam.getSelectedTeamIds())
      return true;

    return false;
  }

  IsValidateTeamMemberDropdown() {
    if (this.childTeamMember && this.childTeamMember.getSelectedTeamMemberIds())
      return true;

    return false;
  }

  PrepareSubmitValues() {

    this.setState({ isLoading: true });

    let formData = new FormData();
    formData.append("selectedTeamMemberIds", this.childTeamMember.getSelectedTeamMemberIds());
    formData.append("temporaryFileName", this.state.temporaryFileName);
    formData.append("remarks", this.state.remarks);

    return formData;
  }

  SubmitLeaveReportGeneration(formData) {
    TeamLeaveReportApi.getLeaveReport(formData)
      .then((res) => {
        this.ResetValues();
        this.DownloadReportFile(res);
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        defaultErrorResponseParser(err, "Failed To Generate Team Leave Report");
      });
  }

  DownloadReportFile(res) {
    let fileName = res.headers["x-attachmentname"];
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("Download", fileName);
    link.click();
  }

  ResetValues() {
    this.setState({ isLoading: false });
    this.setState({ temporaryFileName: null });
    this.setState({ remarks: '' });
    this.childTeamMember.clearSelected();
  }

  IsPageDataValidate() {

    var isValidFileUploaded = this.IsValidateFileUpload();
    var isTeamDropdownValid = this.IsValidateTeamDropdown();
    var isTeamMemberDropdownValid = this.IsValidateTeamMemberDropdown();

    this.setState({ isReady: isValidFileUploaded && isTeamDropdownValid && isTeamMemberDropdownValid });
  }

  render() {
    if (!this.state.shouldRender) {
      return <></>;
    } else {
      return (
        <Layout>
          <Helmet>
            <title>Leave Report - Cefalo</title>
          </Helmet>
          <Loading show={this.state.isLoading} />
          <div className="p-3">
            <div className="container-fluid">
              <div className="row">
                <h3>Monthly Leave Days Report</h3>
              </div>
              <hr></hr>
              <div className="row">
                <div className="col-sm-4">
                  <TeamDropdown onTeamSelect={this.onTeamSelect} onRef={ref => (this.childTeam = ref)}></TeamDropdown>
                  <TeamMemberDropdown onTeamMemberSelect={this.onTeamMemberSelect} onRef={ref => (this.childTeamMember = ref)}></TeamMemberDropdown>
                </div>
                <div className="col-sm-4">
                  <Input label="Remarks"
                    as="textarea"
                    style={{ height: "100px" }}
                    name="remarks"
                    placeholder="Remarks..."
                    value={this.state.remarks}
                    onChange={this.handleChange}
                  /></div>
              </div>
              <div className="row">
                <div className="col-sm-4">
                  <form encType="multipart/form-data" onSubmit={this.handleSubmit}>
                    <Form.Group className="mb-3">
                      <Form.Label>Previous Month's Report</Form.Label>
                      <Form.Control
                        onChange={this.handleTemplateInput}
                        type="file"
                        size="sm"
                        accept=".xlsx"
                      />
                    </Form.Group>
                    <CefaloButton
                      variant="primary"
                      className="ml-0"
                      size="sm"
                      disabled={!this.state.isReady}
                      type="submit">
                      Export Leave Report
                </CefaloButton>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      );
    }
  }
}