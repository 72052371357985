import React from "react";
import { DataTable } from "../../../library/table/Index";
import { v4 as uuidv4 } from "uuid";
import UserTeamHistoryUpdateAction from "../actions/UserTeamHistoryUpdateAction";
import UserTeamHistoryDeleteAction from "../actions/UserTeamHistoryDeleteAction";
import { DateOnlyStringMMDDYY } from "../../../helpers/Builders";

const billingDesignation = {
  "": "None",
  0: "Trainee",
  1: "Junior",
  2: "Senior",
  3: "Lead",
  4: "Coach"
}

const billableTo = {
  "": "None",
  0: "Customer",
  1: "Cefalo",
}

let dataTableColumnsWithoutActions = [
  {
    text: "Team Name",
    dataField: "teamName",
    sort: true,
  },
  {
    text: "Joining Date",
    dataField: "joiningDate",
    sort: true,
  },
  {
    text: "Leaving Date",
    dataField: "leavingDate",
    sort: true,
  },
  {
    text: "Billing Designation",
    dataField: "billingDesignation",
    sort: true,
  },
  {
    text: "Allocation Percentage",
    dataField: "allocationPercentage",
    sort: true,
  },
  {
    text: "BillableTo",
    dataField: "billableTo",
    sort: true,
  },
];
let dataTableColumnsWithActions = [
  {
    text: "Team Name",
    dataField: "teamName",
    sort: true,
  },
  {
    text: "Joining Date",
    dataField: "joiningDate",
    sort: true,
  },
  {
    text: "Leaving Date",
    dataField: "leavingDate",
    sort: true,
  },
  {
    text: "Billing Designation",
    dataField: "billingDesignation",
    sort: true,
  },
  {
    text: "Allocation Percentage",
    dataField: "allocationPercentage",
    sort: true,
  },
  {
    text: "BillableTo",
    dataField: "billableTo",
    sort: true,
  },
  {
    text: "Actions",
    dataField: "actions",
    sort: false,
  },
];
const ActionGroup = (props) => {
  return (
    <div className="d-inline-block action-col">
      <UserTeamHistoryUpdateAction
        teamNames={props.teamNames}
        profile={props.profile}
        teamHistoryRow={props.teamHistoryRow}
        onUpdateCallback={props.callbacks.onTeamHistoryUpdate}
        key={uuidv4()}
      />
      <UserTeamHistoryDeleteAction
        teamHistoryRow={props.teamHistoryRow}
        onDeleteCallback={props.callbacks.onTeamHistoryDelete}
        key={uuidv4()}
      />
    </div>
  );
};
const buildTableRows = (
  teamNames,
  profile,
  teamHistory,
  hasActionGroup,
  callbacks
) => {
  let tempRows = [];
  if (teamHistory !== null) {
    teamHistory.forEach((teamHistoryRow) => {
      let tempRow = {
        teamName: teamHistoryRow.teamName,
        billingDesignation: billingDesignation[teamHistoryRow.billingDesignation],
        billableTo: billableTo[teamHistoryRow.billableTo],
        allocationPercentage: teamHistoryRow.allocationPercentage, 
        joiningDate:
          teamHistoryRow.fromDate === undefined
            ? ""
            : DateOnlyStringMMDDYY(teamHistoryRow.fromDate),
        leavingDate:
          teamHistoryRow.toDate === undefined
            ? ""
            : DateOnlyStringMMDDYY(teamHistoryRow.toDate),
      };
      if (hasActionGroup) {
        tempRow["actions"] = (
          <ActionGroup
            teamNames={teamNames}
            profile={profile}
            teamHistoryRow={teamHistoryRow}
            callbacks={callbacks}
          />
        );
      }
      tempRows.push(tempRow);
    });
  }
  return tempRows;
};
const TeamHistoryTable = (props) => {
  let callbacks = {
    onTeamHistoryDelete: props.onDelete,
    onTeamHistoryUpdate: props.onUpdate,
  };
  let rows = buildTableRows(
    props.teamNames,
    props.profile,
    props.teamHistory,
    props.hasActionGroup,
    callbacks
  );
  return (
    <DataTable
      rows={rows}
      columns={
        props.hasActionGroup
          ? dataTableColumnsWithActions
          : dataTableColumnsWithoutActions
      }
      excludeSearch={true}
      excludePagination={true}
    />
  );
};
const UserProfileTeamHistoryView = (props) => {
  return (
    <div>
      <TeamHistoryTable {...props} />
    </div>
  );
};
export default UserProfileTeamHistoryView;
