import React from "react";
import { GoogleLogin } from "react-google-login";
import Row from "react-bootstrap/Row";
import Alert from "react-bootstrap/Alert";
import PropTypes from "prop-types";
import { Loading } from "../../shared-components/notification";
import LoginCefaloHeader from "../../shared-components/login/loginCefaloHeader";
import { config } from "../../config.json";
import { CefaloButton } from "../../library/button";

export default function GoogleLoginView({
  onSuccess,
  onFailure,
  onRequest,
  submitError,
  googleAuthError,
  loading,
}) {
  return (
    <div className="loginHeaderContainer">
      <LoginCefaloHeader></LoginCefaloHeader>
      <Loading show={loading} />
      <Row className="justify-content-md-center">
        <GoogleLogin
          clientId={config.REACT_APP_GOOGLE_CLIENT_ID}
          render={(renderProps) => (
            <CefaloButton
              variant="success"
              bootstrap4={true}
              className="loginButton"
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
            >
              Sign in
            </CefaloButton>
          )}
          onSuccess={onSuccess}
          onFailure={onFailure}
          onRequest={onRequest}
        />
      </Row>
      <Row className="justify-content-md-center pt-2">
        <p style={{ fontSize: "21px" }}>
          with <span style={{ fontWeight: "bold" }}>Cefalo Email</span> Account
        </p>
      </Row>
      {submitError && (
        <Row className="justify-content-md-center">
          <Alert variant={"danger"}>User is not recognized</Alert>
        </Row>
      )}
      {googleAuthError && (
        <Row className="justify-content-md-center">
          <Alert variant={"danger"}>
            Unexpected error occurred during google authentication
          </Alert>
        </Row>
      )}
    </div>
  );
}

GoogleLoginView.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired,
};
