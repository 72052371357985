import React from "react";
import PropTypes from "prop-types";
import { Loading } from "../notification";

const NavAndFooterHeight = 134;

const DefaultMessage = () => {
  const customStyle = {
    paddingTop: "100px",
    paddingBottom: "100px",
    textAlign: "center",
  };
  return (
    <div style={customStyle}>
      <h5>No document found</h5>
    </div>
  );
};

const CefaloIFrame = (props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [windowInnerHeight, setWindowInnerHeight] = React.useState(
    window.innerHeight
  );
  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const handleWindowResize = (e) => {
    if (e.target.innerHeight !== windowInnerHeight) {
      setWindowInnerHeight(e.target.innerHeight);
    }
  };

  if (props.url && typeof props.url === "string") {
    if (
      !(props.url.startsWith("http://") || props.url.startsWith("https://"))
    ) {
      return <DefaultMessage />;
    }
  } else {
    return <DefaultMessage />;
  }
  const iframeStyle = {
    width: "100%",
    maxWidht: "100%",
    height: `${windowInnerHeight - NavAndFooterHeight}px`,
    display: "flex",
    justifyContent: "center",
  };

  return (
    <div className="position-relative">
      <Loading show={isLoading} />
      <iframe
        src={props.url}
        onLoadStart={(e) => {
          setIsLoading(true);
        }}
        onLoad={(e) => {
          setIsLoading(false);
        }}
        title={props.title}
        frameBorder="no"
        style={iframeStyle}
      />
    </div>
  );
};

CefaloIFrame.prototype = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default CefaloIFrame;
