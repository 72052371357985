import axios from "axios";
import { config } from "../../config.json";
import { WithInterceptors } from "./AxiosInterceptors";

export const AuthenticatedAxios = WithInterceptors(
  axios.create({
    baseURL: config.API_BASE_URL,
    headers: { Authorization: `Bearer ${localStorage.getItem("__C_Token")}` },
  })
);

export const Axios = WithInterceptors(
  axios.create({ baseURL: config.API_BASE_URL })
);

export const jsonContentConfig = {
  headers: {
    "Content-Type": "application/json",
  },
};
