import React from "react";
import { Table } from "react-bootstrap";

export default function CustomTable({ tableData, striped = false }) {
  const { columns = [], rows = [] } = tableData;
  return (
    <Table
      responsive
      bordered
      striped={striped}
      size="sm"
      style={{ marginBottom: 0 }}
    >
      <thead>
        <tr>
          {columns.map((col, index) => (
            <th key={index}>{col.label}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, index) => (
          <tr key={index}>
            {columns.map((col, colIndex) => (
              <td key={colIndex}>{row[col.field]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
