import React from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import TrainingUpdateAction from "../actions/TrainingUpdateAction";
import TrainingDeleteAction from "../actions/TrainingDeleteAction";

const ActionsGroupView = (props) => {
  return (
    <div className="d-inline-block action-col">
      <TrainingUpdateAction
        key={uuidv4()}
        training={props.training}
        activeUsers={props.activeUsers}
        onUpdateCallback={props.updateAction}
      />
      <TrainingDeleteAction
        key={uuidv4()}
        training={props.training}
        onDeleteCallback={props.deleteAction}
      />
    </div>
  );
};
ActionsGroupView.prototype = {
  training: PropTypes.object,
  deleteAction: PropTypes.func.isRequired,
  updateAction: PropTypes.func.isRequired,
};

export default ActionsGroupView;
