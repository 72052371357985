import React, { useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { FormModal } from "../../../library/modal";
import { Form, Input, Select, DateRange } from "../../../library/form";
import { Col } from "../../../library/grid-layout";
import { RemoteWorkApplicationDetailDto } from "../../../models/RemoteWorkApplication";
import { DateOnlyString } from "../../../helpers/Builders";
import { CefaloButton } from "../../../library/button";
import { RemoteWorkTypesValueDictionary } from "../Consts";
import CefaloDatePicker from "../../../library/form/DatePicker";
import UserManager from "../../../navigation/UserManager";

const ApplicantPreview = ({ userName, teamName }) => {
  return (
    <div>
      <Form.Row>
        <Col>
          <Input label="Applicant" readOnly value={userName} />
        </Col>
        <Col>
          <Input label="Team" readOnly value={teamName} />
        </Col>
      </Form.Row>
    </div>
  );
};

const ApplicationUpdateFormModal = ({
  fromDate,
  toDate,
  totalWorkingDays,
  show,
  onToggle,
  onUpdate,
  periodSelectCallBack,
  application = new RemoteWorkApplicationDetailDto(),
  remoteWorkApplicationTypes,
}) => {
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      description: application.description,
      remoteWorkApplicationType: application.type,
    },
  });
  const [remoteWorkDate, setRemoteWorkDate] = useState(application.fromDate);
  const [alternativeOfficeDate, setAlternativeOfficeDate] = useState(
    application.alternativeOfficeDate !== undefined
      ? application.alternativeOfficeDate
      : new Date()
  );

  const [isExchangeOfficeDay, setIsExchangeOfficeDay] = useState(
    application.type === RemoteWorkTypesValueDictionary.ExchangeOfficeDay
  );

  const handleRemoteWorkDate = (date) => {
    setRemoteWorkDate(date);
  };
  const handleAlternativeOfficeDate = (date) => {
    setAlternativeOfficeDate(date);
  };

  const updateRemoteWorkType = (selectedOption) => {
    const value =
      parseInt(selectedOption.target.value) ===
      RemoteWorkTypesValueDictionary.ExchangeOfficeDay;

    setIsExchangeOfficeDay(value);
  };

  const isWorkFromHome =
    application.type === RemoteWorkTypesValueDictionary.WorkFromHome;

  React.useEffect(() => {
    const componentDidUpdate = () => {
      reset({
        description: application.description,
      });
    };
    componentDidUpdate();
  }, [application, reset]);

  const onFormSubmit = (data) => {
    let applicationData = { ...data };

    if (isExchangeOfficeDay) {
      applicationData["fromDate"] = DateOnlyString(remoteWorkDate);
      applicationData["toDate"] = DateOnlyString(remoteWorkDate);
      applicationData["alternativeOfficeDate"] = DateOnlyString(
        alternativeOfficeDate
      );
    } else {
      applicationData["fromDate"] = DateOnlyString(fromDate);
      applicationData["toDate"] = DateOnlyString(toDate);
    }

    applicationData["Type"] = Number.parseInt(
      data["remoteWorkApplicationType"]
    );
    applicationData["totalNumberOfWorkingDays"] = Number.parseFloat(
      data["totalNumberOfWorkingDays"]
    );

    onUpdate(applicationData);
  };

  let remoteWorkApplicationTypeOptions = remoteWorkApplicationTypes.map((i) => {
    return { label: i.displayName, value: i.value };
  });

  return (
    <FormModal
      onSubmit={handleSubmit(onFormSubmit)}
      show={show}
      toggle={onToggle()}
      formName="Edit Remote Work Application"
    >
      <div key={FormModal.ControlContainer}>
        <ApplicantPreview
          userName={application.applicant.name}
          teamName={application.applicant.teamNames.join(", ")}
        />
        {!isExchangeOfficeDay && (
          <Form.Row>
            <DateRange
              fromDate={fromDate}
              toDate={toDate}
              onChange={({ fromDate, toDate }) =>
                periodSelectCallBack(fromDate, toDate, isWorkFromHome)
              }
            />
          </Form.Row>
        )}
        {isExchangeOfficeDay && (
          <Form.Row>
            <Col>
              <Form.Group>
                <Form.Label>Remote Work Date</Form.Label>
                <CefaloDatePicker
                  date={remoteWorkDate}
                  onChange={(d) => handleRemoteWorkDate(d)}
                  minDate={
                    !UserManager.getUser().isAdminOrHr() ? new Date() : ""
                  }
                />
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Alternative Office Date</Form.Label>
                <CefaloDatePicker
                  date={alternativeOfficeDate}
                  onChange={(d) => handleAlternativeOfficeDate(d)}
                  minDate={
                    !UserManager.getUser().isAdminOrHr() ? new Date() : ""
                  }
                />
              </Form.Group>
            </Col>
          </Form.Row>
        )}
        <Form.Row>
          <Col>
            <Input
              label="Total Working Days"
              type="number"
              name="totalNumberOfWorkingDays"
              disabled
              value={isExchangeOfficeDay ? 1 : totalWorkingDays}
              ref={register}
            />
          </Col>
          <Col>
            <Select
              ref={register}
              onChange={(option) => {
                updateRemoteWorkType(option);
              }}
              name="remoteWorkApplicationType"
              options={remoteWorkApplicationTypeOptions}
              defaultValue={application.type}
              label="Remote Work Type"
            />
          </Col>
        </Form.Row>

        <Input
          label="Description"
          as="textarea"
          placeholder="Remote Work Description..."
          name="description"
          ref={register}
          containerClassName="mt-2"
        />
      </div>
      <div key={FormModal.ActionContainer}>
        <CefaloButton size="sm" variant="light" onClick={onToggle()}>
          Close
        </CefaloButton>
        <CefaloButton variant="primary" size="sm" type="submit">
          Save
        </CefaloButton>
      </div>
    </FormModal>
  );
};

ApplicationUpdateFormModal.prototype = {
  fromDate: PropTypes.objectOf(Date).isRequired,
  toDate: PropTypes.objectOf(Date).isRequired,
  totalWorkingDays: PropTypes.number.isRequired,
  show: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  periodSelectCallBack: PropTypes.func.isRequired,
  application: PropTypes.objectOf(RemoteWorkApplicationDetailDto),
  remoteWorkApplicationTypes: PropTypes.arrayOf(Object).isRequired,
};

export default ApplicationUpdateFormModal;
