import React from "react";
import Layout from "../../shared-components/layout";
import { Helmet } from "react-helmet";
import CefaloIFrame from "../../shared-components/iframe/CefaloIFrame";
import SettingsApi from "../../apis/endpoints/SettingsApi";
import { defaultErrorResponseParser } from "../../helpers/ErrorParser";
import { Loading } from "../../shared-components/notification";

export default class HrPolicyContainer extends React.Component {
  state = {
    isLoading: false,
    hrPolicyDocUrl: null,
  };

  componentDidMount() {
    this.loadPolicySettings();
  }

  loadPolicySettings = () => {
    this.setState({ isLoading: true });
    SettingsApi.getPublicSettings()
      .then((res) => {
        this.setState({
          isLoading: false,
          hrPolicyDocUrl: res.data["hrPolicyDocsUrl"],
        });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        defaultErrorResponseParser(
          err,
          "Failed To Load HR Policy Doc Reference"
        );
      });
  };

  render() {
    return (
      <Layout>
        <Helmet>
          <title>HR Policy- Cefalo</title>
        </Helmet>
        <Loading show={this.state.isLoading} />
        {!this.state.isLoading ? (
          <CefaloIFrame
            title="Cefalo HR Policy"
            url={this.state.hrPolicyDocUrl}
          />
        ) : (
          <></>
        )}
      </Layout>
    );
  }
}
