import { AuthenticatedAxios } from "../settings/Axios";
const BaseEndPoint = "Users";
let config = {
  headers: {
    "Content-Type": "application/json",
  },
};

export default {
  all: () => {
    return AuthenticatedAxios.get(`${BaseEndPoint}/Current`, null, null);
  },

  allCefaloUsers: () => {
    return AuthenticatedAxios.get(BaseEndPoint, null, null);
  },

  update: (user) => {
    let endpoint = `${BaseEndPoint}/${user.id}`;
    let body = JSON.stringify(user);

    return AuthenticatedAxios.put(endpoint, body, config);
  },

  create: (user) => {
    let body = JSON.stringify(user);
    return AuthenticatedAxios.post(BaseEndPoint, body, config);
  },

  delete: (id) => {
    let endpoint = `${BaseEndPoint}/${id}`;
    return AuthenticatedAxios.delete(endpoint, null, {});
  },

  get: (id) => {
    let endpoint = `${BaseEndPoint}/${id}`;
    return AuthenticatedAxios.get(endpoint);
  },

  getDetails: (id) => {
    let endpoint = `${BaseEndPoint}/${id}/Details`;
    return AuthenticatedAxios.get(endpoint);
  },

  getTeamsByUserId: (id) => {
    let endpoint = `${BaseEndPoint}/${id}/Teams`;
    return AuthenticatedAxios.get(endpoint);
  },

  getActiveUsersProfiles: () => {
    let endpoint = `${BaseEndPoint}/Active/Profiles`;
    return AuthenticatedAxios.get(endpoint);
  },
  
  getActiveUsersShortProfiles: () => {
    let endpoint = `${BaseEndPoint}/Active/Profiles/Short`;
    return AuthenticatedAxios.get(endpoint);
  },

  allUserRoles: () => {
    return AuthenticatedAxios.get(`${BaseEndPoint}/Roles`);
  },

  getTeamMembers: (userId) => {
    return AuthenticatedAxios.get(`${BaseEndPoint}/${userId}/TeamMembers`);
  },

  getAttendanceStatistics: (userId) => {
    let endpoint = `${BaseEndPoint}/AttendanceStatistics/${userId}`;
    return AuthenticatedAxios.get(endpoint);
  },

  getLeaveStatistics: (userId) => {
    let endpoint = `${BaseEndPoint}/LeaveStatistics/${userId}`;
    return AuthenticatedAxios.get(endpoint);
  },
  getUserTrainingsByUserId: (userId) => {
    let endpoint = `${BaseEndPoint}/${userId}/Trainings`;
    return AuthenticatedAxios.get(endpoint);
  },
};
