import React, { useState, useEffect, useCallback } from "react";
import ProfileAvatar from "../../static/images/profile_picture_none.png";
import styles from "./styles/MemberView.module.css";
import { v4 as uuid4 } from "uuid";
import { Card } from "react-bootstrap";
import { FiMail } from "react-icons/fi";
import CefaloIcon from "../../library/Icons/Icon";
import { FaPhoneAlt } from "react-icons/fa";
import { config } from "../../config.json";
import UserManager from "../../navigation/UserManager";
import EllipsisWithTooltip from "../../library/ellipsis-with-tooltip";
import { UserRoleInString } from "../settings-officeTiming/settings/Helper";

const MemberCard = ({ profile, user }) => {
  const handleImageFetchError = (event) => {
    event.target.onerror = null;
    event.target.src = ProfileAvatar;
  };

  let imageSrc = `${config.PROFILE_IMAGE_BASE_URL}/${profile.photoFileName}`;

  return (
    <Card className={`z-depth-0 ${styles.memberCard} h-100`}>
      {user.isAdminOrHr() ? (
        <a href={"/profile/view/" + profile.userId}>
          <Card.Img
            alt="profile picture"
            src={imageSrc}
            onError={handleImageFetchError}
            className="img-fluid"
          />
        </a>
      ) : (
        <Card.Img
          alt="profile picture"
          src={imageSrc}
          onError={handleImageFetchError}
          className="img-fluid"
        />
      )}

      <Card.Body className={styles.memberCardBody}>
        <a href={"/profile/view/" + profile.userId}>
          <div className={styles["memberName"]}>
            <EllipsisWithTooltip placement="bottom">
              {profile.name}
            </EllipsisWithTooltip>
          </div>
        </a>
        {profile.designation != null ? (
          <EllipsisWithTooltip placement="bottom">
            {profile.designation}{" "}
            {profile?.role === UserRoleInString.team ? (
              <i className="fa mr-1">&#xf005;</i>
            ) : (
              ""
            )}
          </EllipsisWithTooltip>
        ) : null}
        <div className={styles.memberTeam}>
          <EllipsisWithTooltip placement="bottom">
            {profile.teamNames?.join(", ")}
          </EllipsisWithTooltip>
        </div>
        
        {!!profile.slackDisplayName && (
          <p>
            <CefaloIcon />
            <span className={styles.memberContacts}>
              {profile.slackDisplayName}
            </span>
          </p>
        )}
        {profile.email != null ? (
          <EllipsisWithTooltip placement="bottom">
            <CefaloIcon Icon={FiMail} />
            <a href={`mailto:${profile.email}`}> {profile.email}</a>
          </EllipsisWithTooltip>
        ) : null}
        {profile.mobileNumber != null ? (
          <p>
            <EllipsisWithTooltip placement="bottom">
              <CefaloIcon Icon={FaPhoneAlt} />
              <span className={styles.memberContacts}>
                <a href={`tel:${profile.mobileNumber}`}>
                  {profile.mobileNumber}
                </a>
              </span>
            </EllipsisWithTooltip>
          </p>
        ) : null}
      </Card.Body>
    </Card>
  );
};

const MembersGridCol = ({ profile, user }) => {
  return (
    <div className={`${styles.memberGridCol}`}>
      <MemberCard profile={profile} user={user} />
    </div>
  );
};

const AllMemberView = (props) => {
  const [membersGrid, setMembersGrid] = useState([]);

  const bindPageData = useCallback(() => {
    let user = UserManager.getUser();
    const grid = [];
    props.profiles.forEach((profile) => {
      grid.push(<MembersGridCol key={uuid4()} profile={profile} user={user} />);
    });

    setMembersGrid(grid);
  }, [props.profiles]);

  useEffect(() => {
    bindPageData();
  }, [bindPageData]);

  return (
    <div className={styles.membersContainer}>
      <div className={styles.membersGridContainer}>{membersGrid}</div>
    </div>
  );
};

export default AllMemberView;
