import React from "react";
import { Form } from "../../../library/form/Index";
import { Col } from "react-bootstrap";
import styles from "../styles/Profile.module.css";

export default class UserProfileContactInfoView extends React.Component {
  render() {
    return (
      <Form.Row>
        <Col sm="12">
          <Form.Row>
            <Col sm="12" className={styles.profileTitleHeader}>
              Contact Information
              <hr className="mt-0" />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <Form.Row className="mb-2">
                <Col sm="4">
                  <span>PERSONAL EMAIL</span>
                </Col>
                <Col sm="8">
                  <span>
                    {this.props.contactInfo?.personalEmail != null ?
                      <a href={`mailto:${this.props.contactInfo.personalEmail}`}> {this.props.contactInfo.personalEmail}</a> : 'N/A'
                    }
                  </span>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col className="text-muted mb-2">
                  <br></br>
                  <span>ADDRESS</span>
                </Col>
              </Form.Row>
              <Form.Row className="mb-2">
                <Col sm="4">
                  <span>PRESENT</span>
                </Col>
                <Col sm="8">
                  <span>{this.props.contactInfo?.presentAddress}</span>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col sm="4">
                  <span>PERMANENT</span>
                </Col>
                <Col sm="8">
                  <span>{this.props.contactInfo?.permanentAddress}</span>
                </Col>
              </Form.Row>
            </Col>
            <Col className="offset-sm-1">
              <Form.Row className="text-muted mb-2">
                <Col>
                  <span>SECONDARY CONTACT</span>
                </Col>
              </Form.Row>
              <Form.Row className="mb-2">
                <Col sm="3">
                  <span>NAME</span>
                </Col>
                <Col sm="9">
                  <span>{this.props.contactInfo?.secondaryContactName}</span>
                </Col>
              </Form.Row>
              <Form.Row className="mb-2">
                <Col sm="3">
                  <span>PHONE</span>
                </Col>
                <Col sm="9">
                  <span>
                    <a href={`tel:${this.props.contactInfo?.secondaryContactPhoneNo}`}>
                      {this.props.contactInfo?.secondaryContactPhoneNo}
                    </a>
                  </span>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col sm="3">
                  <span>RELATION</span>
                </Col>
                <Col sm="9">
                  <span>{this.props.contactInfo?.secondaryContactRelation}</span>
                </Col>
              </Form.Row>
            </Col>
          </Form.Row>
        </Col>
      </Form.Row>
    );
  }
}