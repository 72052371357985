import React from "react";
import { ProfileSettingsTableCol } from "../Const";
import { CheckBox } from "../../../../library/form";
import CustomTable from "../../../../shared-components/table/CustomTable";
import { v4 as uuid4 } from "uuid";

export default function ProfileSettingsView({
  settings,
  setProfileViewerState,
}) {

  const getCheckBoxesView = (recipents = {}, section = "") => {
    return (       
      <>
        <CheckBox
            key={uuid4()}
            name="team"
            label="Team"
            checked={!!recipents.team}
            onChange={(event) => setProfileViewerState(event, section)}
            />
            <CheckBox
            key={uuid4()}
            name="user"
            label="All Users"
            checked={!!recipents.user}
            onChange={(event) => setProfileViewerState(event, section)}
            />
      </>
    );
  };

  const tableData = {
    columns: ProfileSettingsTableCol,
    rows: [
      {
        profileEducationInfoViewers: getCheckBoxesView(
          settings.profileEducationInfoViewers,
          "Education section"
        ),
        profilePersonalInfoViewers: getCheckBoxesView(
            settings.profilePersonalInfoViewers,
            "PersonalInfo section"
        ),
        profileContactInfoViewers: getCheckBoxesView(
           settings.profileContactInfoViewers,
           "ContactInfo section"
        ),
        profileAttendanceInfoViewers: getCheckBoxesView(
           settings.profileAttendanceInfoViewers,
           "Attendance section"
        ),
        profileLeaveStatisticsInfoViewers: getCheckBoxesView(
            settings.profileLeaveStatisticsInfoViewers,
            "LeaveStatistics section"
         ),
      },
    ],
  };

  return <CustomTable tableData={tableData} striped={true} />;
}
