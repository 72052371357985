import React from "react";
import Layout from "../../shared-components/layout/Layout";
import UserManager from "../../navigation/UserManager";
import { Modal } from "react-bootstrap";
import { Loading, toaster } from "../../shared-components/notification";
import LeaveBalanceApi from "../../apis/endpoints/LeaveBalanceApi";
import { defaultErrorResponseParser } from "../../helpers/ErrorParser";
import { CefaloButton } from "../../library/button";
import { Select } from "../../library/form";
import { Helmet } from "react-helmet";

export default class EscalateCarryForwardContainer extends React.Component {
  state = {
    isLoading: false,
    shouldRender: false,
    isModalOpen: false,
    year: new Date().getFullYear(),
  };

  componentDidMount() {
    let user = UserManager.getUser();
    if (user.isAdminOrHr()) {
      this.setState({ shouldRender: true });
    } else {
      window.location.replace(`/Report/Index/${user.id}`);
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.toggle();
  };

  handleYearSelect = (e) => {
    this.setState({ year: e.target.value });
  };

  toggle = () => {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  };

  handleCancel = () => {
    this.setState({ isLoading: false });
    this.toggle();
  };

  requestAPI = () => {
    this.setState({ isLoading: true });
    this.toggle();
    LeaveBalanceApi.adjustCarryForwardData(this.state.year)
      .then((res) => {
        this.setState({ isLoading: false });
        toaster.success("Successfully escalated carry forward data.");
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        defaultErrorResponseParser(err, "Failed to adjust carry forward data");
      });
  };

  render() {
    if (!this.state.shouldRender) {
      return <></>;
    }
    let thisYear = new Date().getFullYear();
    let options = [
      { value: thisYear, label: `${thisYear}` },
      { value: thisYear + 1, label: `${thisYear + 1}` },
    ];
    return (
      <Layout>
        <Helmet>
          <title>Automatically Escalate Carry Forward Data- Cefalo</title>
        </Helmet>
        <Loading show={this.state.isLoading} />
        <div className="p-3">
          <h2>Automatically Escalate Carry Forward Data</h2>
          <form onSubmit={this.handleSubmit}>
            <div className="d-inline-block">
              <Select
                onChange={this.handleYearSelect}
                label="Year"
                name="year"
                className="form-inline"
                options={options}
              />
            </div>
            <div className="d-block">
              <CefaloButton type="submit" size="sm" variant="primary">
                Escalate Carry Forward Data
              </CefaloButton>
            </div>
          </form>
          <Modal show={this.state.isModalOpen} centered onHide={(e) => {}}>
            <Modal.Header>
              <small>
                Are you sure to automatically generate carry forward data?
              </small>
            </Modal.Header>
            <Modal.Footer>
              <CefaloButton
                size="sm"
                variant="danger"
                onClick={this.handleCancel}
              >
                No
              </CefaloButton>
              <CefaloButton
                size="sm"
                variant="success"
                onClick={this.requestAPI}
              >
                Yes
              </CefaloButton>
            </Modal.Footer>
          </Modal>
        </div>
      </Layout>
    );
  }
}
