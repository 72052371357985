import React from "react";
import PropTypes from "prop-types";
import RowActions from "../../../shared-components/leave-application/actions/RowActions";
import SortUtility from "../../../helpers/SortUtility";
import { LeaveApplicationDetailDto } from "../../../models/LeaveApplication";
import { DataTable } from "../../../library/table/Index";
import { getTableFormatDate } from "../../../helpers/DateUtility";

let dataTableColumns = [
  {
    text: "Application Date",
    dataField: "created",
    sort: true,
    sortFunc: SortUtility.dateSorter,
  },
  {
    text: "From Date",
    dataField: "fromDate",
    sort: true,
    sortFunc: SortUtility.dateSorter,
  },
  {
    text: "To Date",
    dataField: "toDate",
    sort: true,
    sortFunc: SortUtility.dateSorter,
  },
  {
    text: "Leave Type",
    dataField: "leaveType",
    sort: true,
  },
  {
    text: "Working Days",
    dataField: "totalNumberOfWorkingDays",
    sort: true,
  },
  {
    text: "Leave Status",
    dataField: "status",
    sort: true,
  },
  {
    text: "Action",
    dataField: "actions",
    sort: false,
  },
];

let sortedOrder = [
  {
    dataField: "fromDate",
    order: 'desc',
  },
  {
    dataField: "toDate",
    order: 'desc',
  },
]

const ApplicationTable = ({
  applications,
  actionsCallbacks,
  userId,
  leaveSettings,
  leaveTypes,
  leaveStatuses,
  selectedUserId,
  isTeamManagerVisualizingOtherMembers
}) => {
  let rows = [];
  applications.forEach((item) => {
    if (item instanceof LeaveApplicationDetailDto) {
      let tempRow = {};
      tempRow["created"] = getTableFormatDate(item.created);
      tempRow["fromDate"] = getTableFormatDate(item.fromDate);
      tempRow["toDate"] = getTableFormatDate(item.toDate);
      tempRow["leaveType"] = item.leaveTypeDisplayName;
      tempRow["totalNumberOfWorkingDays"] = item.totalNumberOfWorkingDays;
      tempRow["status"] = item.leaveStatusDisplayName;
      tempRow["actions"] = (
        <RowActions
          leaveTypes={leaveTypes}
          leaveStatuses={leaveStatuses}
          leaveSettings={leaveSettings}
          callbacks={actionsCallbacks}
          application={item}
          userId={userId}
          includeCancel={true}
        />
      );

      rows.push(tempRow);
    }
  });
  let permittedDataColumns = dataTableColumns
  if (isTeamManagerVisualizingOtherMembers(selectedUserId)) {
    permittedDataColumns = dataTableColumns.slice(0, -1)
  }

  return (
    <DataTable
      defaultSorted={sortedOrder}
      rowMutedDateColumnName="toDate"
      initialPageSize={10}
      rows={rows}
      columns={permittedDataColumns}
    />
  );
};
ApplicationTable.prototype = {
  applications: PropTypes.arrayOf(LeaveApplicationDetailDto).isRequired,
  actionsCallbacks: PropTypes.arrayOf(Function).isRequired,
  leaveStatuses: PropTypes.arrayOf(Object).isRequired,
  leaveTypes: PropTypes.arrayOf(Object).isRequired,
  leaveSettings: PropTypes.object.isRequired,
};

export default ApplicationTable;
