import React from "react";
import PropTypes from "prop-types";
import TrainingCreateAction from "../actions/TrainingCreateAction";
import TrainingTableView from "./TrainingTableView";
import { Loading } from "../../../shared-components/notification/Notification";
import { TrainingDto } from "../../../models/TrainingDto";

const TrainingManageView = (props) => {
  return (
    <div className="p-2">
      <Loading show={props.isLoading} />
      <div>
        <div className="mt-3 mb-3 d-inline-block">
          <TrainingCreateAction
            activeUsers={props.activeUsers}
            onCreateCallback={props.onCreate}
          />
        </div>
        <TrainingTableView {...props} />
      </div>
    </div>
  );
};

TrainingManageView.prototype = {
  trainings: PropTypes.arrayOf(TrainingDto),
  isLoading: PropTypes.bool.isRequired,
  onCreate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default TrainingManageView;
