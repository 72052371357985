import React from "react";
import PropTypes from "prop-types";
import { Loading } from "../../../shared-components/notification";
import ApplicationActionModal from "../../../shared-components/modals/ApplicationActionModal";
import { ExtraWorkTypesOptions } from "../Const";
import {
  DateRange,
  Select,
  CheckBox,
  Form,
  Input,
} from "../../../library/form";
import { Col } from "../../../library/grid-layout";
import {
  showSuccessMessages,
  showErrorMessages,
} from "../../../shared-components/response-message/ResponseMessage";
import { getDaysFromDateRange } from "../../../helpers/DateUtility";
import { DateOnlyString } from "../../../helpers/Builders";
import { CefaloButton } from "../../../library/button";

export default class ExtraWorkForm extends React.Component {
  state = {
    id: 0,
    fromDate: new Date(),
    toDate: new Date(),
    totalHours: 8,
    adjustmentType: 0,
    paid: false,
    description: "",
    isLoading: false,
    userId: 0,
    errors: { adjustmentType: { message: null } },
    notBillableToCustomer: false,
    extraWorkTeamId: 0,
    extraWorkTeamName: null,
  };
  componentMounted = false;
  componentDidMount = () => (this.componentMounted = true);
  componentWillUnmount = () => (this.componentMounted = false);

  componentWillReceiveProps = (nextProps) => {
    const { application } = nextProps;
    if (!!application === true) {
      application.fromDate = new Date(application.fromDate);
      application.toDate = new Date(application.toDate);
      this.setState({ ...application });
    }
  };

  setDate = ({ fromDate, toDate }) => {
    let days = getDaysFromDateRange(toDate, fromDate);
    this.setState({ fromDate, toDate, totalHours: days * 8 });
  };

  onChange = (event) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value });
  };

  setTeam = (event) => {
    var index = event.nativeEvent.target.selectedIndex;
    this.setState({
      extraWorkTeamId: event.currentTarget.value,
      extraWorkTeamName: event.nativeEvent.target[index].text,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    if (this.state.adjustmentType === 0) {
      this.setState({
        errors: { adjustmentType: { message: "Select Adjust With" } },
      });
      return;
    }

    if (this.state.extraWorkTeamName === null) {
      this.setState({
        extraWorkTeamName: this.props.application !== undefined ? this.props.application?.extraWorkTeamName :  this.props.userTeams[0]?.name,
      });
    }

    this.setState({ isLoading: true });
    const { fromDate, toDate, extraWorkTeamName } = this.state;
    const { successMessage } = this.props;
    this.props
      .submit({
        ...this.state,
        fromDate: DateOnlyString(fromDate),
        toDate: DateOnlyString(toDate),
        extraWorkTeamName: extraWorkTeamName,
      })
      .then((res) => {
        this.setState({ notBillableToCustomer: false });
        showSuccessMessages(successMessage);
        if (this.componentMounted) {
          this.setState({ isLoading: false });
        }
      })
      .catch((err) => {
        this.setState({ notBillableToCustomer: false });
        this.setState({ isLoading: false });
        showErrorMessages(err.response.data);
      });
  };

  render() {
    const {
      fromDate,
      toDate,
      totalHours,
      adjustmentType,
      isLoading,
      paid,
      description,
      notBillableToCustomer,
    } = this.state;

    const { action, header, teamNames, userTeams, application } = this.props;
    const adjustWithOptions = [
      { label: "Select One...", value: 0 },
      ...ExtraWorkTypesOptions.slice(1),
    ];

    const defaultExtraWorkTeam =
      application !== undefined
        ? application.extraWorkTeamId
        : userTeams !== null
        ? userTeams[0]?.id
        : teamNames[0]?.value;

    return (
      <div>
        <Loading show={isLoading} />
        <ApplicationActionModal
          isOpen={this.props.show}
          onToggle={this.props.onToggle}
          header={header}
          footer={this.getFooter()}
        >
          <Form>
            {this.props.children}
            <Form.Row>
              <DateRange
                fromDate={fromDate}
                toDate={toDate}
                onChange={this.setDate}
              />
            </Form.Row>
            <Form.Row>
              <Col sm>
                <Input
                  label="Total Working Hours"
                  type="number"
                  step={0.5}
                  name="totalHours"
                  value={totalHours}
                  onChange={this.onChange}
                />
              </Col>

              <Col sm>
                <Select
                  label="Adjust With"
                  name="adjustmentType"
                  options={adjustWithOptions}
                  value={adjustmentType}
                  errors={this.state.errors}
                  onChange={(e) =>
                    this.setState({
                      notBillableToCustomer: false,
                      adjustmentType: parseInt(e.currentTarget.value),
                      errors: { adjustmentType: { message: null } },
                    })
                  }
                />
              </Col>
            </Form.Row>
            {adjustmentType === 1 && action === "UPDATE" && (
              <Form.Row>
                <Col sm>
                  <CheckBox
                    name="paid"
                    id={99}
                    label="Paid"
                    checked={paid}
                    onChange={() => this.setState({ paid: !paid })}
                  />
                </Col>
              </Form.Row>
            )}
            {adjustmentType === 1 && (
              <Form.Row>
                <Col sm>
                  <CheckBox
                    id="not-billable-to-customer"
                    label="Not billable to customer"
                    style={{zIndex: 0}}
                    name="notBillableToCustomer"
                    checked={notBillableToCustomer}
                    onChange={(e) => {
                      this.setState({
                        [e.currentTarget.name]:
                          !this.state.notBillableToCustomer,
                      });
                    }}
                  />
                </Col>
              </Form.Row>
            )}

            <Form.Row>
              <Col xs={6}>
                <Select
                  label="Team for Extra Working Hours"
                  options={teamNames}
                  name="extraWorkTeamName"
                  onChange={this.setTeam}
                  defaultValue={defaultExtraWorkTeam}
                />
              </Col>
            </Form.Row>

            <Input
              label="Description"
              as="textarea"
              name="description"
              value={description}
              placeholder="Description..."
              containerClassName="mt-3"
              onChange={this.onChange}
            />
          </Form>
        </ApplicationActionModal>
      </div>
    );
  }

  getFooter = () => {
    return (
      <React.Fragment>
        <CefaloButton variant="light" size="sm" onClick={this.props.onToggle()}>
          Close
        </CefaloButton>
        <CefaloButton color="primary" size="sm" onClick={this.onSubmit}>
          Save
        </CefaloButton>
      </React.Fragment>
    );
  };
}

ExtraWorkForm.propTypes = {
  submit: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  action: PropTypes.string.isRequired,
  application: PropTypes.object,
};
