import React from "react";
import { DataTable } from "../../../library/table";
import WithTableData from "./hoc/WithTableData";
import { Card } from "react-bootstrap";
import CreateOfficeTiming from "./actions/CreateOfficeTiming";

function OfficeTimingView({ tableData, officeTimingCallbacks }) {
  return (
    <Card>
      <Card.Header>
        <CreateOfficeTiming onCreateCallback={officeTimingCallbacks.onCreate} />
      </Card.Header>
      <Card.Body>
        <DataTable {...tableData} />
      </Card.Body>
    </Card>
  );
}

export default WithTableData(OfficeTimingView);
