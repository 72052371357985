import React from "react";
import { ToastContainer } from "react-toastify";
import AppFooter from "./Footer";
import "./styles/Layout.css";
import styles from "./styles/Layout.module.css";

const Layout = (props) => {
  return (
    <div>
      <ToastContainer />
      <div className="container-fluid">
        <div className="flex-center">
          <div className="custom_container_xl  custom_container_lg custom_container_md custom_container_sm">
            <div className={styles.contentContainer}>{props.children}</div>
            <AppFooter />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
