import { AuthenticatedAxios } from "../settings/Axios";
const OfficeTimingEndpoint = "OfficeTimings";

export default {
  create: (data) => {
    let endpoint = `${OfficeTimingEndpoint}`;
    return AuthenticatedAxios.post(endpoint, data);
  },
  update: (id, data) => {
    let endpoint = `${OfficeTimingEndpoint}/${id}`;
    return AuthenticatedAxios.put(endpoint, data);
  },
  get: () => {
    let endpoint = `${OfficeTimingEndpoint}`;
    return AuthenticatedAxios.get(endpoint);
  },
  delete: (id) => {
    let endpoint = `${OfficeTimingEndpoint}/${id}`;
    return AuthenticatedAxios.delete(endpoint);
  },
};
