import React from "react";
import UpdateOfficeTming from "../actions/UpdateOfficeTming";
import DeleteActions from "../../../../shared-components/application-action-form/DeleteActions";
import {
  DefaultOfficeTimingTableCol,
  CustomOfficeTimingTableCol,
  OfficeTimingTable,
} from "../Const";
import { _toLocaleDateString } from "../../../../helpers/DateUtility";
import CustomTable from "../../../../shared-components/table/CustomTable";
import { v4 as uuid4 } from "uuid";

const WithTableData = (WrappedComponent) => (props) => {
  const { officeTimingCallbacks } = props;

  const getActions = (officeTiming) => {
    let actions = [];
    actions.push(
      <UpdateOfficeTming
        officeTiming={officeTiming}
        onUpdateCallback={officeTimingCallbacks.onUpdate}
        key={uuid4()}
      />
    );
    if (!officeTiming.isDefaultOfficeTiming) {
      actions.push(
        <DeleteActions
          onDeleteCallback={officeTimingCallbacks.onDelete}
          message={"Are you sure to delete this custom office timing?"}
          successMessage="Custom office timing Deleted Successfully"
          applicationId={officeTiming.id}
          key={uuid4()}
        />
      );
    }

    return actions;
  };

  const buildTableRow = (officeTiming) => {
    let officeTimingType = "";
    let columns = [];

    if (officeTiming.isDefaultOfficeTiming) {
      officeTimingType = "Default Office Timing";
      columns = DefaultOfficeTimingTableCol;
    } else {
      officeTimingType = "Custom Office Timing";
      columns = CustomOfficeTimingTableCol;
    }
    const tableData = {
      columns,
      rows: [
        {
          ...officeTiming,
          startDate: _toLocaleDateString(officeTiming.startDate),
          endDate: _toLocaleDateString(officeTiming.endDate),
          officeStartTime: officeTiming.officeStartTime.slice(0, -3),
        },
      ],
    };
    return {
      settingsType: officeTimingType,
      settingsData: <CustomTable tableData={tableData} striped={true} />,
      action: <>{getActions(officeTiming)}</>,
    };
  };

  const getTableData = () => {
    let { officeTimings } = props;
    let rows = officeTimings.map(buildTableRow);
    return {
      columns: OfficeTimingTable,
      rows: rows,
    };
  };

  return <WrappedComponent {...props} tableData={getTableData()} />;
};

export default WithTableData;
