import React, { useState, useEffect, useCallback } from "react";
import ProfileApi from "../../apis/endpoints/ProfileApi";
import { defaultErrorResponseParser } from "../../helpers/ErrorParser";

const BloodGroupDropdown = (props) => {

  const [selectedBloodGroup, setSelectedBloodGroup] = useState("");
  const [bloodGroups, setBloodGroups] = useState([]);

  const loadBloodGroups = useCallback(() => {
    ProfileApi.getBloodGroups()
      .then((res) => {
        let options = !props.shouldIncludeAll ? [] : [{ label: "All", value: null }];
        options = options.concat(
          Object.keys(res.data).map((key) => {
            return { label: res.data[key], value: res.data[key] };
          }));

        setBloodGroups(options);
      })
      .catch((err) => {
        defaultErrorResponseParser(err, "Failed to generate blood group dropdown");
      });
  },[props.shouldIncludeAll]);

  useEffect(() => {
    loadBloodGroups();
  }, [loadBloodGroups]);

  useEffect(() => {
    if (props.selectedValue) {
      setSelectedBloodGroup(props.selectedValue);
    }
  }, [props.selectedValue]);

  const handleChange = (event) => {
    let selectedValue = event.target.value;
    props.onSelectChange(selectedValue);
    setSelectedBloodGroup(selectedValue);
  }

  return (
    <select
      name="selectedBloodGroup"
      className="form-inline form-control form-control-sm"
      value={selectedBloodGroup}
      onChange={handleChange}>
      {bloodGroups.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default BloodGroupDropdown;