import React from "react";
import { ToastContainer } from "react-toastify";
import AppFooter from "./Footer";
import "./styles/Layout.css";
import { Container } from "react-bootstrap";

const LightLayout = (props) => {
  return (
    <div className="container-fluid">
      <div className="row flex-center justify-content-center">
        <div className="custom_container_xl  custom_container_lg custom_container_md custom_container_sm">
          <Container
            fluid
            style={{ marginTop: "52px", paddingLeft: "0", paddingRight: "0" }}
          >
            <ToastContainer />
            {props.children}
            <AppFooter />
          </Container>
        </div>
      </div>
    </div>
  );
};

export default LightLayout;
