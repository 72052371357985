import React, { useState } from "react";
import FileUplodeApi from "../../apis/endpoints/FileUplodeApi";
import {
  showSuccessMessages,
  showErrorMessages,
} from "../response-message/ResponseMessage";
import { Form } from "react-bootstrap";
import { getReadableFileSize } from "../../helpers/FileSizeConversionUtility";

export default function FileUpload({
  uploadType = "LeaveDocuments",
  onFileNameChange,
  fieldName,
  label = "Upload Medical Document",
}) {
  const [fileName, setFileName] = useState(label);
  const [fileValidationCriteria, setfileValidationCriteria] = useState(null)
  if (!!fileValidationCriteria === false) {
    FileUplodeApi.getValidationCriteria(uploadType).then(resp => setfileValidationCriteria(resp.data))
  }

  const handleSaveImage = (event) => {
    var selectedFile = event.target.files[0];
    if (!!selectedFile === false) {
      setFileName('')
      onFileNameChange({ [fieldName]: '' });
      return
    }
    setFileName(selectedFile.name);
    var formData = new FormData();
    formData.append("FormFile", selectedFile, selectedFile.name);
    formData.append("FileType", uploadType);

    FileUplodeApi.Upload(formData)
      .then((res) => {
        setFileName(res.data);
        onFileNameChange({ [fieldName]: res.data });
        showSuccessMessages("File Saved Temporarily");
      })
      .catch((err) => showErrorMessages(err.response.data));
  };
  return (
    <div className="pt-3">
      <Form.File
        name={fieldName}
        id="custom-file"
        label={fileName}
        style={{zIndex: 0}}
        onChange={handleSaveImage}
        custom
        accept={!!fileValidationCriteria?.allowedExtensions ? fileValidationCriteria.allowedExtensions.join(',') : 'all'}
      />
      {!!fileValidationCriteria && <label style={{ color: '#909090' }}>max size:{getReadableFileSize(fileValidationCriteria.maxSizeInBytes)}</label>}
    </div>
  );
}
