import React from "react";
import PropTypes from "prop-types";
import { SkillDto } from "../../../models/Skill";
import SkillCreateAction from "../actions/SkillCreateAction";
import SkillTableView from "./SkillTableView";
import { Loading } from "../../../shared-components/notification/Notification";

const SkillManageView = (props) => {
  return (
    <div className="p-2">
      <Loading show={props.isLoading} />
      <div>
        <div className="mt-3 mb-3 d-inline-block">
          <SkillCreateAction onCreateCallback={props.onCreate} parentSkillOptions={props.parentSkillOptions}/>
        </div>
        <SkillTableView {...props} />
      </div>
    </div>
  );
};

SkillManageView.prototype = {
  skills: PropTypes.arrayOf(SkillDto),
  isLoading: PropTypes.bool.isRequired,
  onCreate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default SkillManageView;
