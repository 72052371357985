import React from "react";
import NocUpsertForm from "../forms/NocUpsertForm";
import { Form, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import { CefaloButton } from "../../../library/button";

export default class NocUpdateApplication extends React.Component {
  state = {
    show: false,
  };

  toggle = (e) => () => {
    this.setState({ show: !this.state.show });
  };
  onSubmit = ({
    name,
    passportNumber,
    designation,
    joiningDate,
    fromDate,
    toDate,
    description,
    country,
    status,
    userId,
    id
  }) => {
    let application = {
        name,
        passportNumber,
        designation,
        joiningDate,
        fromDate,
        toDate,
        description,
        country,
        status,
        userId,
        id
    };
  
    return this.props.onUpdateCallback(id, application).then((res) => {
      this.setState({ show: false });
      return res;
    });
  };

  render() {
    const { show } = this.state;
    const { application, isSupervision } = this.props;
    return (
      <div className="d-inline-block">
        <CefaloButton
          className="btn-font-12"
          variant="info"
          size="sm"
          onClick={this.toggle()}
        >
          Edit
        </CefaloButton>
        <NocUpsertForm
          onToggle={this.toggle}
          show={show}
          userId={application.userId}
          action={"UPDATE"}
          onSubmit={this.onSubmit}
          defaultValues={application}
          isSupervision={isSupervision}
          header={"Edit Application"}
          formName="Update NOC Information"
          successMessage="Noc application Updated Successfully"
        >
          {this.getPreview()}
        </NocUpsertForm>
      </div>
    );
  }

  getPreview = () => {
    const { application } = this.props;
    return (
      <Form.Row>
        <Col>
          <Form.Group>
            <Form.Label>Applicant</Form.Label>
            <Form.Control
              size="sm"
              readOnly
              value={application.name}
            />
          </Form.Group>
        </Col>
      </Form.Row>
    );
  };
}

NocUpdateApplication.propTypes = {
  onUpdateCallback: PropTypes.func.isRequired,
  application: PropTypes.object.isRequired,
};
