import React, { useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { DateOnlyString } from "../../../helpers/Builders";
import { CefaloButton } from "../../../library/button";
import { FormModal } from "../../../library/modal";
import { Form, Input, DateRange } from "../../../library/form";
import { Col } from "../../../library/grid-layout";
import { NocStatusValueDictionary } from "../Const";
import UserManager from "../../../navigation/UserManager";
const NocUpsertForm = ({
  show,
  onToggle,
  onSubmit,
  defaultValues,
  profile,
  isSupervision,
  formName,
  userId,
}) => {
  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      ...defaultValues,
    },
  });

  const [fromDate, setFromDate] = useState(
    !!defaultValues ? new Date(defaultValues?.fromDate) : new Date()
  );
  const [toDate, setToDate] = useState(
    !!defaultValues ? new Date(defaultValues?.toDate) : new Date()
  );
  const [passportNumber, setPassportNumber] = useState(null);
  const [name, setName] = useState(null);
  const [country, setCountry] = useState(null);
  const [description, setDescription] = useState(null);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handlePassportNumberChange = (e) => {
    setPassportNumber(e.target.value);
  };

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const setDate = ({ fromDate, toDate }) => {
    setFromDate(fromDate);
    setToDate(toDate);
  };

  const onFormSubmit = (data) => {
    var nocApplicationData = { ...data };
    nocApplicationData.fromDate = DateOnlyString(fromDate);
    nocApplicationData.toDate = DateOnlyString(toDate);
    nocApplicationData.status =
      defaultValues !== undefined &&
      defaultValues?.status !== NocStatusValueDictionary.Drafted
        ? defaultValues?.status
        : NocStatusValueDictionary.Requested;

    if (defaultValues?.id !== undefined) {
      nocApplicationData["id"] = defaultValues["id"];
    }

    if (defaultValues?.userId !== undefined) {
      nocApplicationData["userId"] = defaultValues["userId"];
    }

    onSubmit(nocApplicationData);
  };

  const onDraftFormSubmit = () => {
    const nocApplicationData = {
      name:
        !!name
          ? name
          : !!defaultValues?.name
          ? defaultValues?.name
          : profile?.name,
      passportNumber:
        !!passportNumber
          ? passportNumber
          : !!defaultValues?.passportNumber
          ? defaultValues?.passportNumber
          : profile?.personalInfo?.passportNumber,
      designation: profile?.designation,
      joiningDate: DateOnlyString(profile?.joiningDate),
      fromDate: DateOnlyString(fromDate),
      toDate: DateOnlyString(toDate),
      description: description != null ? description : defaultValues?.description,
      country: !!country ? country : defaultValues?.country,
      status:
        !!defaultValues
          ? defaultValues?.status
          : NocStatusValueDictionary.Drafted,
    };

    if (defaultValues?.id !== undefined) {
      nocApplicationData["id"] = defaultValues["id"];
    }

    if (defaultValues?.userId !== undefined) {
      nocApplicationData["userId"] = defaultValues["userId"];
    }

    onSubmit(nocApplicationData);
  };

  return (
    <FormModal
      onSubmit={handleSubmit(onFormSubmit)}
      toggle={onToggle()}
      show={show}
      formName={formName}
    >
      <div key={FormModal.ControlContainer}>
        <Form.Row>
          <Col sm>
            <Input
              label="Full Name(as in passport)"
              placeholder="Enter name(as in passport)."
              name="name"
              size="sm"
              defaultValue={profile?.name}
              ref={register({ required: "Name is required" })}
              onChange={handleNameChange}
              errors={errors}
            />
          </Col>
          <Col sm>
            <Input
              label="Passport Number"
              placeholder="Enter passport number."
              name="passportNumber"
              size="sm"
              defaultValue={profile?.personalInfo?.passportNumber}
              onChange={handlePassportNumberChange}
              ref={register({ required: "Passport number is required" })}
              errors={errors}
            />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col sm>
            <Input
              label="Designation"
              readOnly
              ref={register}
              defaultValue={
                !!defaultValues
                  ? defaultValues?.user?.profile?.designation
                  : profile?.designation
              }
              name="designation"
              placeholder="Enter designation"
            />
          </Col>
          <Col sm>
            <Input
              label="Joining Date"
              readOnly
              ref={register}
              defaultValue={DateOnlyString(
                !!defaultValues
                  ? defaultValues?.user?.joiningDate
                  : profile?.joiningDate
              )}
              name="joiningDate"
            />
          </Col>
        </Form.Row>

        <Form.Row>
          <DateRange fromDate={fromDate} toDate={toDate} onChange={setDate} />
        </Form.Row>
        <Form.Row>
          <Col sm={6}>
            <Input
              label="Country to Travel"
              placeholder="Enter country name."
              name="country"
              size="sm"
              ref={register({ required: "Country name is required" })}
              onChange={handleCountryChange}
              errors={errors}
            />
          </Col>
          <Col sm>
            <Input
              label="Purpose"
              size="sm"
              name="description"
              placeholder="Enter purpose of NOC."
              onChange={handleDescriptionChange}
              ref={register({ required: "Purpose is required" })}
              errors={errors}
            />
          </Col>
        </Form.Row>
      </div>
      <div key={FormModal.ActionContainer}>
        <CefaloButton
          size="sm"
          variant="light"
          onClick={onToggle()}
          type="button"
        >
          Close
        </CefaloButton>
        {!isSupervision && userId === UserManager.getUser().id && (
          <CefaloButton
            size="sm"
            variant="secondary"
            onClick={onDraftFormSubmit}
          >
            Draft
          </CefaloButton>
        )}
        <CefaloButton size="sm" variant="primary" type="submit">
          Save
        </CefaloButton>
      </div>
    </FormModal>
  );
};

NocUpsertForm.prototype = {
  onSubmit: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  defaultValues: PropTypes.object,
  onToggle: PropTypes.func.isRequired,
};

export default NocUpsertForm;
