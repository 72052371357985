import React from "react";
import PropTypes from "prop-types";
import RowActions from "../actions/RowActions";
import { SkillDevelopmentRequestDto } from "../../../models/SkillDevelopmentFund";
import { DataTable } from "../../../library/table/Index";
import { getTableFormatDate } from "../../../helpers/DateUtility";
import {
  SkillDevelopmentSupervisionTableColumns,
  SkillDevelopmentTableColumns,
  SkillDevelopmentTableViewerColumns,
  SkillDevelopmentTableSortOrder,
} from "../Consts";
import { FaExternalLinkAlt, FaFileDownload } from "react-icons/fa";
import { config } from "../../../config.json";

function LinkComponent(props) {
  const label = !!props.label ? props.label : "Link";
  const url = new URL(props.href, config.API_BASE_URL)
  return !!props.href ? (
    <a href={url} target="_blank" rel="noopener noreferrer">
      {label} <FaExternalLinkAlt size={10} />
    </a>
  ) : "N/A";
}

function DownloadComponent(props) {
  const label = !!props.label ? props.label : "Link";
  return !!props.name ? (
    <button className="btn btn-link btn-sm p-0" onClick={props.onDownloadUrl}>
      {label} <FaFileDownload size={10} />
    </button>
  ) : "N/A";
}

function ApplicationTable({
  requests,
  callbacks,
  userId,
  selectedUserId,
  isSupervision,
  isTeamManagerVisualizingOtherMembers
}) {
  const rows = requests.map(item => item instanceof SkillDevelopmentRequestDto ? ({
    name: item.name,
    createdAt: getTableFormatDate(item.createdAt),
    requestStatus: item.requestStatusName,
    courseName: item.courseName,
    courseLink: (
      <LinkComponent label={item.courseLink} href={item.courseLink} />
    ),
    amount: item.amount,
    purpose: item.purpose,
    reason: item.reason,
    invoice: (
      <DownloadComponent
        label="Invoice"
        name={item.invoiceFileName}
        onDownloadUrl={() => callbacks.onDownloadUrl(item.invoiceFileName)}
      />
    ),
    certificate: (
      <DownloadComponent
        label="Certificate"
        name={item.certificationFileName}
        onDownloadUrl={() => callbacks.onDownloadUrl(item.certificationFileName)}
      />
    ),
    action: (
      <RowActions
        isSupervision={isSupervision}
        callbacks={callbacks}
        request={item}
        userId={userId}
      />
    ),
  }) : null)
    .filter(item => item !== null);

  const [sizePerPage, setSizePerPage] = React.useState(10);

  return (
    <DataTable
      defaultSorted={SkillDevelopmentTableSortOrder}
      pageSizeOptions={[10, 25, 50, 100, 150, 200, 250, 300]}
      initialPageSize={sizePerPage}
      sizePerPage={sizePerPage}
      rows={rows}
      extraPaginationOptions={{
        onSizePerPageChange: setSizePerPage
      }}
      columns={isSupervision ? SkillDevelopmentSupervisionTableColumns
        : isTeamManagerVisualizingOtherMembers(selectedUserId) ? SkillDevelopmentTableViewerColumns
          : SkillDevelopmentTableColumns}
    />
  );
};

ApplicationTable.propTypes = {
  requests: PropTypes.arrayOf(PropTypes.instanceOf(SkillDevelopmentRequestDto)).isRequired,
  callbacks: PropTypes.object.isRequired,
};

export default ApplicationTable;
