import React from "react";
import { Col, Form } from "react-bootstrap";
import CefaloDatePicker from "./DatePicker";

let defaultClassesNames = {
  fromDateColClassName: "",
  toDateColClassName: "",
};

const DateRange = ({
  fromDate,
  toDate,
  onChange,
  classesNames = defaultClassesNames,
  minDate
}) => {
  const setDate = (date, isFromDate) => {
    if (isFromDate) {
      fromDate = date;
      toDate = fromDate > toDate ? fromDate : toDate;
    } else {
      toDate = date;
      fromDate = toDate < fromDate ? toDate : fromDate;
    }
    onChange({ fromDate, toDate });
  };

  return (
    <>
      <Col sm className={classesNames.fromDateColClassName}>
        <Form.Group>
          <Form.Label className="d-block">From Date:</Form.Label>
          <CefaloDatePicker
            date={fromDate}
            onChange={(d) => setDate(d, true)}
            minDate={minDate}
          />
        </Form.Group>
      </Col>
      <Col sm className={classesNames.toDateColClassName}>
        <Form.Group>
          <Form.Label className="d-block">To Date:</Form.Label>
          <CefaloDatePicker
            date={toDate}
            onChange={(d) => setDate(d, false)}
            minDate={minDate}
          />
        </Form.Group>
      </Col>
    </>
  );
};

export default DateRange;
