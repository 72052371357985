import React, { useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { Form, Col } from "react-bootstrap";
import { FormModal } from "../../../library/modal";
import { CefaloButton } from "../../../library/button";
import { Input, CheckBox, Select } from "../../../library/form";
import { WeekDays } from "../../settings-officeTiming/settings/Const";
import { v4 as uuid4 } from "uuid";

const TeamUpsertModalForm = ({
  onSubmit,
  show,
  onToggle,
  formName,
  clients,
  defaultValues = {
    isActive: true,
    officeWeekDays: [],
  },
}) => {
  const { register, handleSubmit, errors } = useForm({
    defaultValues: defaultValues,
  });

  const [officeDaysCheckBoxes, setOfficeDaysCheckBoxes] = useState({
    monday: defaultValues.officeWeekDays.includes(WeekDays.Monday),
    tuesday: defaultValues.officeWeekDays.includes(WeekDays.Tuesday),
    wednesday: defaultValues.officeWeekDays.includes(WeekDays.Wednesday),
    thursday: defaultValues.officeWeekDays.includes(WeekDays.Thursday),
    friday: defaultValues.officeWeekDays.includes(WeekDays.Friday),
  });

  const onFormSubmit = (data) => {
    const selectedDays = Object.keys(officeDaysCheckBoxes)
      .filter((day) => officeDaysCheckBoxes[day])
      .map((day) => WeekDays[day.charAt(0).toUpperCase() + day.slice(1)]);

    const updatedData = { ...data, OfficeWeekDays: selectedDays };
    if (defaultValues.id) {
      updatedData["id"] = defaultValues.id;
    }
    if (updatedData["clientId"] === "*") {
      updatedData["clientId"] = null;
    }
    onSubmit(updatedData);
  };

  const handleOfficeDaysChange = (event) => {
    const { name, checked } = event.target;
    setOfficeDaysCheckBoxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [name]: checked,
    }));
  };

  return (
    <FormModal
      onSubmit={handleSubmit(onFormSubmit)}
      show={show}
      toggle={onToggle()}
      formName={formName}
    >
      <div key={FormModal.ControlContainer}>
        <Form.Row>
          <Col>
            <Input
              ref={register({ required: "Team name is required" })}
              placeholder="Enter Team Name"
              name="name"
              label="Team Name"
              errors={errors}
            />
          </Col>
          <Col>
            <Input
              placeholder="Enter Team Email"
              label="Team Email"
              type="email"
              ref={register({ required: "Team email is required" })}
              name="teamEmail"
              errors={errors}
            />
          </Col>
        </Form.Row>

        <Form.Row>
          <Col xs={6}>
            <Select
              ref={register({
                required: true, validate: {
                  clientIdRequireValidation: (val) => {
                    if (val === "*" || val === "None" || val === undefined) {
                      return "Client is required"
                    }
                  }
                }
              })}
              label="Client Name"
              name="clientId"
              options={
                clients !== undefined
                  ? [{ value: "*", label: "None" }, ...clients]
                  : ""
              }
              errors={errors}
            />
          </Col>
        </Form.Row>

        <Input
          placeholder="Enter Client Email(comma separated multiple values are allowed)"
          type="text"
          ref={register}
          label="Client Email"
          name="clientEmail"
          errors={errors}
        />

        <Form.Row>
          <Col>
            <Form.Label>Office Days</Form.Label>
          </Col>
        </Form.Row>
        <Form.Row>
          {Object.keys(officeDaysCheckBoxes).map((day) => (
            <Col key={uuid4()}>
              <CheckBox
                name={day}
                label={day.charAt(0).toUpperCase() + day.slice(1)}
                checked={officeDaysCheckBoxes[day]}
                onChange={handleOfficeDaysChange}
              />
            </Col>
          ))}
        </Form.Row>

        <Form.Row className="pt-3">
          <Col>
            <CheckBox label="Is Active" ref={register} name="isActive" />
          </Col>
        </Form.Row>
      </div>

      <div key={FormModal.ActionContainer}>
        <CefaloButton size="sm" variant="light" onClick={onToggle()}>
          Close
        </CefaloButton>
        <CefaloButton size="sm" variant="primary" type="submit">
          Save
        </CefaloButton>
      </div>
    </FormModal>
  );
};
TeamUpsertModalForm.prototype = {
  onCreate: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default TeamUpsertModalForm;
