import React from "react";
import { CefaloButton } from "../../../library/button/Index";
import { FormModal } from "../../../library/modal/Index";
import UserTrainingApi from "../../../apis/endpoints/UserTrainingApi";
import { defaultErrorResponseParser } from "../../../helpers/ErrorParser";
import { toaster, Loading } from "../../../shared-components/notification";

class TrainingHistoryDeleteAction extends React.Component {
  state = {
    isLoading: false,
    show: false,
  };

  toggle = (e) => () => {
    this.setState({ show: !this.state.show });
  };

  onDelete = (e) => {
    this.setState({ isLoading: true });
    UserTrainingApi.delete(this.props.trainingHistoryRow.id)
      .then((res) => {
        toaster.success("Training History is Deleted Successfully");
        this.setState({ isLoading: false, show: false });
        this.props.onDeleteCallback(this.props.trainingHistoryRow.userId);
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        defaultErrorResponseParser(err, "Failed To Delete Training History");
      });
  };

  render() {
    return (
      <div className="d-inline-block">
        <Loading show={this.state.isLoading} />
        <FormModal
          show={this.state.show}
          toggle={this.toggle()}
          formName="Delete Training History"
        >
          <div key={FormModal.ControlContainer}>
            <p>Are you sure to delete?</p>
          </div>
          <div key={FormModal.ActionContainer}>
            <CefaloButton size="sm" variant="light" onClick={this.toggle()}>
              No
            </CefaloButton>
            <CefaloButton
              size="sm"
              variant="primary"
              onClick={this.onDelete}
              type="button"
            >
              Yes
            </CefaloButton>
          </div>
        </FormModal>
        <CefaloButton
          className="btn-font-12"
          size="sm"
          variant="danger"
          onClick={this.toggle()}
        >
          Delete
        </CefaloButton>
      </div>
    );
  }
}

export default TrainingHistoryDeleteAction;
