import { AuthenticatedAxios } from "../settings/Axios";
const BaseEndPoint = "Export";

export default {
  getBillsOfMonth: (Month, year) => {
    let endpoint = `${BaseEndPoint}/Bills/${Month}/${year}`;
    return AuthenticatedAxios.get(endpoint, null, null);
  },
  downloadBills: (path, month, year) => {
    let endpoint = `${BaseEndPoint}/Bills/${month}/${year}/Download/${path}`;
    return AuthenticatedAxios({
      url: endpoint,
      method: "GET",
      responseType: "arraybuffer",
    });
  },
  getBillsOfTeamAndUser: (path) => {
    let endpoint = `${BaseEndPoint}/Bills/${path}`;
    return AuthenticatedAxios.get(endpoint, null, null);
  },
  downloadEmployeeInformation: (data, teamhistoryInfo) => {
    let endpoint = `${BaseEndPoint}/EmployeeDirectory/${teamhistoryInfo}/${data}`;
    return AuthenticatedAxios({
      url: endpoint,
      method: "GET",
      responseType: "arraybuffer",
    });
  },
  getAvailableEmployeeInfo: (availabilityType) => {
    let endpoint = `${BaseEndPoint}/Employees/Available/${availabilityType}`;
    return AuthenticatedAxios.get(endpoint, null, null);
  },
  downloadAvailableEmployeeInfo: (availabilityType) => {
    let endpoint = `${BaseEndPoint}/Employees/Available/${availabilityType}/Download`;
    return AuthenticatedAxios({
      url: endpoint,
      method: "GET",
      responseType: "arraybuffer",
    });
  },
};
