import React, { useState } from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { Form, Select, DateRange } from "../../../library/form/Index";
import { Col } from "../../../library/grid-layout/Index";
import { CefaloButton } from "../../../library/button/Index";
import { DateOnlyString } from "../../../helpers/Builders";

const FilterFormView = (props) => {
  const { register, handleSubmit } = useForm();

  const [fromDate, setFromDate] = useState(
    new Date(new Date().getFullYear(), 0, 1)
  );
  const [toDate, setToDate] = useState(
    new Date(new Date().getFullYear(), 11, 31)
  );

  const setDate = ({ fromDate, toDate }) => {
    setFromDate(fromDate);
    setToDate(toDate);
  };

  let leaveStatusOptions = props.leaveStatusTypes.map((i) => {
    return { label: i.name, value: i.value };
  });
  let leaveTypesOptions = props.leaveApplicationTypes.map((i) => {
    return { label: i.name, value: i.value };
  });

  const onFilter = (data) => {
    var filteredData = { ...data };
    filteredData.fromDate = DateOnlyString(fromDate);
    filteredData.toDate = DateOnlyString(toDate);
    props.onFilterAction(filteredData);
  };

  return (
    <form onSubmit={handleSubmit(onFilter)}>
      <Form.Row
        style={{
          alignItems: "flex-end",
        }}
      >
        <DateRange
          fromDate={fromDate}
          toDate={toDate}
          onChange={setDate}
          classesNames={{
            fromDateColClassName: "pr-lg-3",
            toDateColClassName: "pr-lg-3 pl-lg-3",
          }}
        />

        <Col md>
          <Select
            label="Leave Type:"
            options={[{ value: "", label: "All" }, ...leaveTypesOptions]}
            ref={register}
            name="leaveType"
            containerClassName="pr-lg-3 pl-lg-2"
          />
        </Col>

        <Col md>
          <Select
            label="Leave Status:"
            options={[{ value: "", label: "All" }, ...leaveStatusOptions]}
            defaultValue={leaveStatusOptions[0].value}
            ref={register}
            name="leaveStatus"
            containerClassName="pr-lg-3 pl-lg-1"
          />
        </Col>

        <Col md className="p-0">
          <div className="mb-3">
            <CefaloButton
              type="submit"
              className="mt-auto filter_panel_btn"
              size="sm"
              variant="primary"
            >
              Show Applications
            </CefaloButton>
          </div>
        </Col>
      </Form.Row>
    </form>
  );
};
FilterFormView.prototype = {
  onFilterAction: PropTypes.func.isRequired,
};

export default FilterFormView;
