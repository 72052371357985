import React, { Component } from "react";
import { CefaloButton } from "../../../library/button";
import PropTypes from "prop-types";
import NocUpsertForm from "../forms/NocUpsertForm";
export default class NocCreateApplication extends Component {
  state = {
    show: false,
  };

  toggle = (e) => () => {
    this.setState({ show: !this.state.show });
  };

  onSubmit = ({
    name,
    passportNumber,
    designation,
    joiningDate,
    fromDate,
    toDate,
    description,
    country,
    status,
  }) => {
    let application = {
      name,
      passportNumber,
      designation,
      joiningDate,
      fromDate,
      toDate,
      description,
      country,
      status,
      userId: this.props.userId,
    };

    return this.props.onCreateCallback(application).then((res) => {
      this.setState({ show: false });
      return res;
    });
  };
  render() {
    const { show } = this.state;
    const { profile, isSupervision, userId } = this.props;
    return (
      <div className="d-inline-block" style={{ marginLeft: "auto" }}>
        <CefaloButton
          size="sm"
          variant="primary"
          className="filter_panel_btn"
          onClick={this.toggle()}
        >
          New Application
        </CefaloButton>
        <NocUpsertForm
          onToggle={this.toggle}
          show={show}
          userId={userId}
          action={"CREATE"}
          onSubmit={this.onSubmit}
          header={"New Application"}
          profile={profile}
          isSupervision={isSupervision}
          formName="Add NOC Information"
          successMessage="Noc application Saved Successfully"
        />
      </div>
    );
  }
}

NocCreateApplication.propTypes = {
  onCreateCallback: PropTypes.func.isRequired,
};
