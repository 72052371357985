import React from "react";
import { dataTableColumns, supervisionDataTableColumns } from "../Const";
import { getCefaloFormatDate } from "../../../helpers/DateUtility";

const WithTableData = (WrappedComponent) => (props) => {
  let isAnyActions = false;
  const buildTableRow = (application, isSupervision) => {
    const { getActions } = props;
    const actions = getActions(application);

    let tempRow = {};
    if (isSupervision) {
      tempRow["name"] = application.name;
      tempRow["team"] = application.applicant.teamNames.join(", ");
    }
    if (actions.length > 0) {
      isAnyActions = true;
      tempRow["actions"] = <>{actions}</>;
    }

    tempRow["created"] = getCefaloFormatDate(application.created);
    tempRow["fromDate"] = getCefaloFormatDate(application.fromDate);
    tempRow["toDate"] = getCefaloFormatDate(application.toDate);
    tempRow["designation"] = application.user?.profile?.designation;
    tempRow["joiningDate"] = getCefaloFormatDate(application.user?.joiningDate);
    tempRow["passportNumber"] = application.passportNumber;
    tempRow["country"] = application.country;
    tempRow["status"] = application.nocStatusDisplayName;
    tempRow["description"] = application.description;
    return tempRow;
  };

  const getTableData = () => {
    let { applications, isSupervision } = props;
    let rows = applications.map((application) =>
      buildTableRow(application, isSupervision)
    );

    let tableColumns = isSupervision
      ? supervisionDataTableColumns
      : dataTableColumns;
    if (isAnyActions === false && isSupervision) {
      tableColumns = tableColumns.slice(0, -1);
    }
    return {
      columns: tableColumns,
      rows: rows,
    };
  };

  return <WrappedComponent {...props} tableData={getTableData()} />;
};

export default WithTableData;
