import React from "react";
import Layout from "../../shared-components/layout";
import SupervisionView from "./views/SupervisionView";
import LeaveApplicationApi from "../../apis/endpoints/LeaveAppliactionApi";
import SettingsApi from "../../apis/endpoints/SettingsApi";
import { Helmet } from "react-helmet";
import { Loading } from "../../shared-components/notification";
import { LeaveStatusValueDictionary } from "../../shared-components/leave-application/Consts";
import { defaultErrorResponseParser } from "../../helpers/ErrorParser";
import { LeaveApplicationDetailDto } from "../../models/LeaveApplication";
import { DateOnlyString } from "../../helpers/Builders";

class SupervisionContainer extends React.Component {
  state = {
    applications: [],
    isLoading: false,
    fromDate: DateOnlyString(
      new Date(new Date(new Date().getFullYear(), 0, 1))
    ),
    toDate: DateOnlyString(new Date(new Date().getFullYear(), 11, 31)),
    selectedLeaveStatus: LeaveStatusValueDictionary.Requested,
    leaveStatusTypes: null,
    leaveApplicationTypes: null,
    leaveSettings: null,
  };

  componentDidMount() {
    this.setState({ shouldRender: true });
    this.loadLeaveApplicationTypes();
    this.loadLeaveStatusTypes();
    this.loadLeaveSettings();
    this.loadApplicationsData();
  }

  loadApplicationsData = (
    options = {
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
      leaveStatus: this.state.selectedLeaveStatus,
    }
  ) => {
    this.setState({ isLoading: true });
    LeaveApplicationApi.all(options)
      .then((res) => {
        this.setState({
          isLoading: false,
          applications: res.data.map(
            (item) => new LeaveApplicationDetailDto(item)
          ),
        });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        defaultErrorResponseParser(err, "Failed To Load Leave Applications");
      });
  };

  loadLeaveStatusTypes = () => {
    LeaveApplicationApi.getAllLeaveStatus()
      .then((res) => {
        this.setState({
          leaveStatusTypes: res.data,
        });
      })
      .catch((err) => {
        defaultErrorResponseParser(err, "Failed To Load Leave Status Types");
      });
  };

  loadLeaveApplicationTypes = () => {
    LeaveApplicationApi.getAllLeaveType()
      .then((res) => {
        this.setState({
          leaveApplicationTypes: res.data,
        });
      })
      .catch((err) => {
        defaultErrorResponseParser(err, "Failed To Load Application Types");
      });
  };

  loadLeaveSettings = () => {
    SettingsApi.getLeaveSettings()
      .then((res) => {
        this.setState({ leaveSettings: res.data });
      })
      .catch((err) => {
        defaultErrorResponseParser(err, "Failed To Load Leave Settings");
      });
  };

  onApplicationStatusUpdate = () => {
    this.loadApplicationsData();
  };

  onFilter = (data) => {
    this.setState({
      fromDate: data.fromDate,
      toDate: data.toDate,
      leaveType: data.leaveType,
      selectedLeaveStatus: data.leaveStatus,
    });
    this.loadApplicationsData(data);
  };

  onApplicationDelete = () => {
    this.loadApplicationsData();
  };

  onApplicationUpdate = (updatedApplications) => {
    let applications = this.state.applications;
    updatedApplications.forEach((updatedApplication) => {
      let index = applications.findIndex(
        (item) => item.id === updatedApplication.id
      );
      applications[index] = new LeaveApplicationDetailDto(updatedApplication);
    });
    this.setState({
      applications: applications,
    });
  };

  checkIsComponentReadyToRender = () => {
    return (
      !!this.state.leaveSettings &&
      !!this.state.leaveStatusTypes &&
      !!this.state.leaveApplicationTypes
    );
  };

  render() {
    if (!this.checkIsComponentReadyToRender()) {
      return <></>;
    }
    return (
      <Layout>
        <Helmet>
          <title>Leave Supervision- Cefalo</title>
        </Helmet>
        <div className="pr-3 pl-3 pt-3 pb-0">
          <Loading show={this.state.isLoading} />
          <SupervisionView
            applications={this.state.applications}
            onCreate={this.onApplicationCreate}
            onUpdate={this.onApplicationUpdate}
            onDelete={this.onApplicationDelete}
            onStatusUpdate={this.onApplicationStatusUpdate}
            onFilter={this.onFilter}
            leaveStatusTypes={this.state.leaveStatusTypes}
            leaveApplicationTypes={this.state.leaveApplicationTypes}
            leaveSettings={this.state.leaveSettings}
          />
        </div>
      </Layout>
    );
  }
}

export default SupervisionContainer;
