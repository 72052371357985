export class ClientDto {
    constructor(obj) {
      this.id = obj.id;
      this.name = obj.name;
      this.keyAccountManagerId = obj.keyAccountManagerId;
      this.lastModified = obj.lastModified;
      this.creatorId = obj.creatorId;
      this.lastModifiderName = obj.lastModifiderName;
    }
  }
  