import React from "react";
import { Form, Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { Loading } from "../../../shared-components/notification";
import { DateRange } from "../../../library/form";
import {
  showSuccessMessages,
  showErrorMessages,
} from "../../../shared-components/response-message/ResponseMessage";
import { DateOnlyString } from "../../../helpers/Builders";
import { CefaloButton } from "../../../library/button";

export default class DataSyncForm extends React.Component {
  state = {
    fromDate: new Date(),
    toDate: new Date(),
    isLoading: false,
  };

  setDate = ({ fromDate, toDate }) => {
    this.setState({ fromDate, toDate });
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    const { fromDate, toDate } = this.state;
    this.props
      .handleSubmit({
        fromDate: DateOnlyString(fromDate),
        toDate: DateOnlyString(toDate),
      })
      .then((res) => {
        showSuccessMessages("Attendance Data Synced successfully");
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        showErrorMessages(err.response.data);
      });
  };

  render() {
    const { fromDate, toDate, isLoading } = this.state;
    return (
      <div>
        <Loading show={isLoading} />
        <Form>
          <Row sm={4} style={{ display: "flex", flexDirection: "column" }}>
            <DateRange
              fromDate={fromDate}
              toDate={toDate}
              onChange={this.setDate}
            />
          </Row>
          <Row>
            <Col>
              <CefaloButton
                variant="primary"
                size="sm"
                type="button"
                onClick={this.onSubmit}
              >
                Sync Attedance Data
              </CefaloButton>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

DataSyncForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};
