export const RemoteWorkStatusesDictionary = Object.freeze({
  1: "Requested",
  2: "Approved",
  3: "Rejected",
  4: "Canceled",
  5: "Cancel Request",
  6: "Canceled By User",
  7: "Cancel Request Approved",
  8: "Cancel Request Rejected",
});

export const RemoteWorkStatusValueDictionary = Object.freeze({
  Requested: 1,
  Approved: 2,
  Rejected: 3,
  Canceled: 4,
  CancelRequest: 5,
  CancelByUser: 6,
  CancelRequestApproved: 7,
  CancelRequestRejected: 8,
});

export const RemoteWorkTypesDictionary = Object.freeze({
  1: "Client Visit",
  2: "Work From Home",
  3: "Office Outing",
  4: "Exchange Office Day"
});

export const RemoteWorkTypesValueDictionary = Object.freeze({
  ClientVisit: 1,
  WorkFromHome: 2,
  OfficeOuting: 3,
  ExchangeOfficeDay: 4,
});
