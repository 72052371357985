import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { DateOnlyString } from "../../../helpers/Builders";
import { CefaloButton } from "../../../library/button/Index";
import { FormModal } from "../../../library/modal/Index";
import {
  Form,
  Input,
  Select,
  DatePicker,
} from "../../../library/form/Index";
import { Col } from "../../../library/grid-layout/Index";

const UserTeamHistoryUpsertForm = ({
  teamNames,
  show,
  toggle,
  onSubmit,
  profile,
  defaultValues,
  formName,
}) => {
  const { handleSubmit, register } = useForm({});

  const [billingDesignationOptions] = useState([
    { value: "", label: "None" },
    { value: "0", label: "Trainee" },
    { value: "1", label: "Junior" },
    { value: "2", label: "Senior" },
    { value: "3", label: "Lead" },
    { value: "4", label: "Coach" },
  ]);

  const [billableToOptions] = useState([
    { value: "", label: "None" },
    { value: "0", label: "Customer" },
    { value: "1", label: "Cefalo" },
  ]);

  const [teamJoiningDate, setTeamJoiningDate] = useState(
    defaultValues?.fromDate !== undefined
      ? new Date(defaultValues?.fromDate)
      : null
  );

  const [teamLeavingDate, setTeamLeavingDate] = useState(
    defaultValues?.toDate !== undefined ? new Date(defaultValues?.toDate) : null
  );

  const [selectedTeam, setSelectedTeam] = useState(
    defaultValues?.teamId !== undefined
      ? defaultValues?.teamId
      : teamNames[0]?.value
  );

  const [selectedBillableTo, setBillableTo] = useState(
    defaultValues?.billableTo !== undefined
      ? defaultValues?.billableTo
      : billableToOptions[1].value
  );

  const [selectedBillingDesignation, setSelectedBillingDesignation] = useState(
    defaultValues?.billingDesignation !== undefined
      ? defaultValues?.billingDesignation
      : null
  );

  const [allocationPercentage, setAllocationPercentage] = useState(
    defaultValues?.allocationPercentage !== undefined
      ? defaultValues?.allocationPercentage
      : 100
  );

  const handleTeamChange = (e) => {
    setSelectedTeam(e.target.value);
  };

  const handleBillableToChange = (e) => {
    setBillableTo(e.target.value);
  };

  const handleBillingDesignationChange = (e) => {
    const selectedOption = e.target.value;

    const billingDesignationAsCoach = billingDesignationOptions.find(
      (option) => option.label === "Coach"
    ).value;

    const billableToCefalo = billableToOptions.find(
      (option) => option.label === "Cefalo"
    ).value;

    setSelectedBillingDesignation(selectedOption);

    setBillableTo((prevValue) =>
    billingDesignationAsCoach === selectedOption ? billableToCefalo : prevValue
    );
  };

  const handleAllocationPercentageChange = (e) => {
    setAllocationPercentage(e.target.value);
  };

  const onTeamHistorySubmit = () => {
    const teamHistoryData = {
      UserId: profile?.userId,
      TeamId: selectedTeam !== undefined ? selectedTeam : teamNames[0]?.value,
      AllocationPercentage: allocationPercentage,
      BillableTo:
        selectedBillableTo !== undefined
          ? selectedBillableTo
          : defaultValues?.billingDesignation,
      BillingDesignation:
        selectedBillingDesignation !== null ? selectedBillingDesignation : null,
      FromDate:
        teamJoiningDate === null ? null : DateOnlyString(teamJoiningDate),
      ToDate: teamLeavingDate === null ? null : DateOnlyString(teamLeavingDate),
    };
    if (defaultValues?.id !== undefined) {
      teamHistoryData["id"] = defaultValues["id"];
    }

    onSubmit(teamHistoryData);
  };

  return (
    <FormModal
      onSubmit={handleSubmit()}
      toggle={toggle()}
      show={show}
      formName={formName}
    >
      <div key={FormModal.ControlContainer}>
        <Form.Row>
          <Col>
            <Select
              id="teamId"
              ref={register}
              label="Team"
              name="teamId"
              options={teamNames}
              defaultValue={defaultValues?.teamId}
              placeholder="All Teams"
              onChange={handleTeamChange}
            />
          </Col>
          <Col>
            <Input
              label="Allocation Percentage"
              type="number"
              min="0"
              max="100"
              ref={register}
              value={
                allocationPercentage !== undefined ? allocationPercentage : 100
              }
              onChange={handleAllocationPercentageChange}
              name="allocationPercentage"
              placeholder="Enter Allocation Percentage"
            />
          </Col>
        </Form.Row>

        <Form.Row>
          <Col>
            <Select
              label="Billing Designation"
              ref={register}
              options={billingDesignationOptions}
              defaultValue={defaultValues?.billingDesignation}
              name="billingDesignation"
              placeholder="Enter Billing Designation"
              onChange={handleBillingDesignationChange}
            />
          </Col>
          <Col>
            <Select
              label="Billable To"
              ref={register}
              options={billableToOptions}
              defaultValue={
                defaultValues?.billableTo !== undefined
                  ? defaultValues?.billableTo
                  : billableToOptions[1].value
              }
              value={selectedBillableTo !== undefined ? selectedBillableTo : ""}
              name="billableTo"
              placeholder="Enter Billable To"
              onChange={handleBillableToChange}
            />
          </Col>
        </Form.Row>

        <Form.Row>
          <Col>
            <Form.Label>From Date</Form.Label>
            <DatePicker
              date={teamJoiningDate}
              extraOptions={{ isClearable: true }}
              onChange={(date) => setTeamJoiningDate(date)}
            />
          </Col>

          <Col>
            <Form.Label>To Date</Form.Label>
            <DatePicker
              date={teamLeavingDate}
              onChange={(date) => setTeamLeavingDate(date)}
              extraOptions={{ isClearable: true }}
            />
          </Col>
        </Form.Row>
      </div>
      <div key={FormModal.ActionContainer}>
        <CefaloButton
          size="sm"
          variant="light"
          onClick={toggle()}
          type="button"
        >
          Close
        </CefaloButton>

        <CefaloButton
          size="sm"
          variant="primary"
          onClick={onTeamHistorySubmit}
          type="button"
        >
          Save
        </CefaloButton>
      </div>
    </FormModal>
  );
};

export default UserTeamHistoryUpsertForm;
