import React from "react";
import styles from "../styles/UserSelectionView.module.css";
import { SearchableSelect } from "../../../library/form";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: 14,
    fontFamily: "Helvetica", // Arial, sans-serif,
    border: "none !important",
    cursor: state.isFocused ? "pointer" : "default",
    backgroundColor: state.isFocused
      ? "rgba(25,118,210,0.3) !important"
      : "white",
    color: state.isSelected ? "blue" : "black",
  }),
};

export const UserSelectOptionsView = ({
  userOptions,
  onChange,
  selectedUser,
  isHrOrAdmin,
}) => {
  return (
    <div className={styles["user-selection-container"]}>
      <SearchableSelect
        value={{ label: selectedUser.name, value: selectedUser.id }}
        options={userOptions}
        styles={customStyles}
        onChange={onChange}
        isHrOrAdmin={isHrOrAdmin}
        placeholder={
          selectedUser.id === "*"
            ? "Enter User's Name to filter"
            : "Filter Name"
        }
      />
      <span className={styles["team-name-label"]}>
        {selectedUser.teamName === "" ? <br /> : selectedUser.teamName}
      </span>
    </div>
  );
};

export const ReadOnlySelectedUserView = ({ userName, userTeamName }) => {
  return (
    <div className={styles["read-only-container"]}>
      <p>{userName}</p>
      <small>{userTeamName}</small>
    </div>
  );
};
