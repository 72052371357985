import { AuthenticatedAxios } from "../settings/Axios";
import { QueryBuilder } from "../utils/QueryBuilder";
const BaseEndPoint = "NocApplications";
const NocUserEndPoint = "Users";
let config = {
  headers: {
    "Content-Type": "application/json",
  },
};

export default {
  getAll: (options) => {
    let endpoint = `${BaseEndPoint}?fromDate=${options.fromDate}&toDate=${options.toDate}`;
    if (options.applicationStatus !== "*") {
      endpoint += `&ApplicationStatus=${options.applicationStatus}`;
    }
    return AuthenticatedAxios.get(endpoint, null, null);
  },

  getAllByUser: (options, userId) => {
    let endpoint = `${NocUserEndPoint}/${userId}/${BaseEndPoint}${QueryBuilder(
      options
    )}`;
    return AuthenticatedAxios.get(endpoint, null, null);
  },

  create: (data) => {
    let endpoint = `${BaseEndPoint}`;
    let body = JSON.stringify(data);
    return AuthenticatedAxios.post(endpoint, body, config);
  },

  updateStatus: (data) => {
    let endpoint = `${BaseEndPoint}/Status`;
    let body = JSON.stringify(data);
    return AuthenticatedAxios.patch(endpoint, body, config);
  },

  delete: (id) => {
    let endpoint = `${BaseEndPoint}/${id}`;
    return AuthenticatedAxios.delete(endpoint, null, null);
  },

  download: (id) => {
    let downloadConfig = {
      headers: {
        "Content-Type": "application/pdf"
      },
      responseType: 'blob'
    };

    let endpoint = `${BaseEndPoint}/Download/${id}`;
    return AuthenticatedAxios.post(endpoint, null, downloadConfig);
  },

  update: (id, data) => {
    let endpoint = `${BaseEndPoint}/${id}`;
    let body = JSON.stringify(data);
    return AuthenticatedAxios.put(endpoint, body, config);
  },

  getAllStatus: () => {
    let endpoint = `${BaseEndPoint}/AllStatus`;
    return AuthenticatedAxios.get(endpoint);
  },
};
