import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  SizePerPageDropdownStandalone,
  PaginationTotalStandalone,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Row, Col } from "react-bootstrap";
import { v4 as uuid4 } from "uuid";
import {
  pageButtonRenderer,
  SizePerPageOptionRenderer,
  SizePerPageRenderer,
  isPastDate,
  EmptyRowMessage,
} from "./DataTableCustomUtility";

const { SearchBar, ClearSearchButton } = Search;

const BaseDataTable = (props) => {
  let { rowMutedDateColumnName, columns, ...tableProps } = props;
  if (
    !!rowMutedDateColumnName &&
    !!columns.find((c) => c.dataField === rowMutedDateColumnName)
  ) {
    var finalStyle = {};
    var inlineRowStyleCallback = tableProps.rowStyle;
    tableProps.rowStyle = (row, rowIndex) => {
      let mutedStyle = isPastDate(row, rowIndex, rowMutedDateColumnName);
      finalStyle = { ...mutedStyle };
      if (
        !!inlineRowStyleCallback &&
        typeof inlineRowStyleCallback === "function"
      ) {
        let inlineStyle = inlineRowStyleCallback(row, rowIndex);
        finalStyle = { ...finalStyle, ...inlineStyle };
      }
      return finalStyle;
    };
  }
  return (
    <BootstrapTable
      bootstrap4
      wrapperClasses="table-responsive"
      columns={columns}
      noDataIndication={EmptyRowMessage}
      {...tableProps}
    />
  );
};

const DataTableWithSearch = (props) => {
  const { data, columns, ...tableProps } = props;

  return (
    <ToolkitProvider keyField={"key"} data={data} columns={columns} search>
      {(toolkitProps) => (
        <div>
          <div>
            <div>
              <span style={{ fontWeight: "bold" }}>{"Search: "}</span>
              <SearchBar
                {...toolkitProps.searchProps}
                className="form-control-sm"
                placeholder={` `}
              />
              {!!toolkitProps.searchProps.searchText ?
                (
                  <ClearSearchButton
                    {...props.searchProps}
                    onClear={() => toolkitProps.searchProps.onSearch("")}
                    text="&#x2715;"
                  />
                )
                : null}
            </div>
          </div>
          <hr />
          <BaseDataTable {...toolkitProps.baseProps} {...tableProps} />
        </div>
      )}
    </ToolkitProvider>
  );
};

const DataTableWithPagination = (props) => {
  const {
    initialPageSize,
    pageSizeOptions,
    extraPaginationOptions,
    excludePageOptions,
    ...tableProps
  } = props;
  let paginationOptions = {
    ...(extraPaginationOptions ? extraPaginationOptions : {}),
    custom: true,
    sizePerPage: initialPageSize ? initialPageSize : 25,
    sizePerPageList: pageSizeOptions ? pageSizeOptions : [10, 25, 50, 100, 150, 200, 250, 300],
    showTotal: true,
    pageButtonRenderer: pageButtonRenderer,
  };

  if (!excludePageOptions) {
    paginationOptions.sizePerPageRenderer = SizePerPageRenderer;
    paginationOptions.sizePerPageOptionRenderer = SizePerPageOptionRenderer;
  } else {
    paginationOptions.sizePerPageRenderer = (props) => <></>;
    paginationOptions.sizePerPageOptionRenderer = (props) => <></>;
    paginationOptions.hideSizePerPage = false;
  }
  return (
    <PaginationProvider pagination={paginationFactory(paginationOptions)}>
      {({ paginationProps, paginationTableProps }) => (
        <ToolkitProvider
          keyField="key"
          data={tableProps.data}
          columns={tableProps.columns}
          search
        >
          {(toolkitProps) => (
            <div>
              <SizePerPageDropdownStandalone {...paginationProps} />
              <div>
                <BaseDataTable
                  {...toolkitProps.baseProps}
                  {...tableProps}
                  {...paginationTableProps}
                />
              </div>
              <div className="pb-4">
                <PaginationListStandalone {...paginationProps} />
                <PaginationTotalStandalone
                  {...paginationProps}
                  dataSize={tableProps.data.length}
                />
              </div>
            </div>
          )}
        </ToolkitProvider>
      )}
    </PaginationProvider>
  );
};

const DataTableWithSearchAndPagination = (props) => {
  const {
    initialPageSize,
    pageSizeOptions,
    extraPaginationOptions,
    data,
    columns,
    ...tableProps
  } = props;
  const paginationOptions = {
    ...(extraPaginationOptions ? extraPaginationOptions : {}),
    custom: true,
    sizePerPage: initialPageSize ? initialPageSize : 25,
    sizePerPageList: pageSizeOptions ? pageSizeOptions : [10, 25, 50, 100, 150, 200, 250, 300],
    showTotal: true,
    sizePerPageRenderer: SizePerPageRenderer,
    sizePerPageOptionRenderer: SizePerPageOptionRenderer,
    pageButtonRenderer: pageButtonRenderer,
  };
  return (
    <PaginationProvider pagination={paginationFactory(paginationOptions)}>
      {({ paginationProps, paginationTableProps }) => (
        <ToolkitProvider keyField="key" data={data} columns={columns} search>
          {(toolkitProps) => (
            <div>
              <Row style={{ display: "flex", justifyContent: "space-between" }}>
                <Col xs={7}>
                  <span style={{ fontWeight: "bold" }}>{"Search: "}</span>
                  <SearchBar
                    {...toolkitProps.searchProps}
                    className="form-control-sm"
                    placeholder={` `}
                  />
                  {!!toolkitProps.searchProps.searchText ?
                    (
                      <ClearSearchButton
                        {...props.searchProps}
                        onClear={() => toolkitProps.searchProps.onSearch("")}
                        text="&#x2715;"
                      />
                    )
                    : null}
                </Col>
                <Col xs={5}>
                  <SizePerPageDropdownStandalone {...paginationProps} />
                </Col>
              </Row>
              <div>
                <BaseDataTable
                  {...toolkitProps.baseProps}
                  {...tableProps}
                  {...paginationTableProps}
                />
              </div>
              <div className="pb-4">
                <PaginationListStandalone {...paginationProps} />
                <PaginationTotalStandalone
                  {...paginationProps}
                  dataSize={data.length}
                />
              </div>
            </div>
          )}
        </ToolkitProvider>
      )}
    </PaginationProvider>
  );
};

const DataTable = (props) => {
  let { rows, excludeSearch, excludePagination, ...restProps } = props;
  // inserting key for each row if not available
  rows.forEach((r) => {
    if (!r.key) {
      r.key = uuid4();
    }
  });

  if (excludePagination && excludeSearch) {
    return <BaseDataTable keyField="key" data={rows} {...restProps} />;
  } else if (excludeSearch) {
    return <DataTableWithPagination data={rows} {...restProps} />;
  } else if (excludePagination) {
    return <DataTableWithSearch data={rows} {...restProps} />;
  } else {
    return <DataTableWithSearchAndPagination data={rows} {...restProps} />;
  }
};

export default DataTable;
