import React from "react";
import { NormalUserTableColumns, HrOrAdminTableColumns } from "./Const";
import UserManager from "../../navigation/UserManager";
import UpdateFormModal from "./UpdateFormModal";
import DeleteFormModal from "./DeleteFormModal";
import { DataTable } from "../../library/table";
import { v4 as uuid4 } from "uuid";
const user = UserManager.getUser();

const Actions = ({ callbacks, publicHoliday }) => {
  return (
    <div className="float-right">
      <UpdateFormModal
        key={uuid4()}
        publicHoliday={publicHoliday}
        onUpdateCallback={callbacks.onUpdate}
      />
      <DeleteFormModal
        key={uuid4()}
        publicHolidayId={publicHoliday.id}
        onDeleteCallback={callbacks.onDelete}
        holidayName={publicHoliday.name}
      />
    </div>
  );
};

const getFormattedDate = (date) => {
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let formattedDate =
    days[date.getDay()] +
    ", " +
    date.getDate() +
    " " +
    months[date.getMonth()] +
    " " +
    date.getFullYear();
  return formattedDate;
};

const buildTableRows = (rows, actionsCallbacks) => {
  let tempRows = [];
  rows.forEach((item) => {
    let tempRow = {};
    tempRow["date"] = getFormattedDate(new Date(item.date));
    tempRow["name"] = item.name;
    tempRow["description"] = item.description;
    if (user.role === 1 || user.role === 2) {
      tempRow["actions"] = (
        <Actions callbacks={actionsCallbacks} publicHoliday={item} />
      );
    }
    tempRows.push(tempRow);
  });
  return tempRows;
};

const PublicHolidayTable = (props) => {
  let rows = buildTableRows(props.publicHolidays, props.actionsCallbacks);
  return (
    <DataTable
      rows={rows}
      columns={
        user.isAdminOrHr() ? HrOrAdminTableColumns : NormalUserTableColumns
      }
      rowMutedDateColumnName="date"
    />
  );
};

const PublicHolidayView = (props) => {
  let actionsCallbacks = {
    onDelete: props.onDelete,
    onUpdate: props.onUpdate,
    onUpdateStatus: props.onUpdateStatus,
  };
  return (
    <div className="mt-3">
      <PublicHolidayTable
        publicHolidays={props.publicHolidays}
        actionsCallbacks={actionsCallbacks}
      />
    </div>
  );
};

export default PublicHolidayView;
