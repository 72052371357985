import React from "react";
import PropTypes from "prop-types";
import { FormModal } from "../../../library/modal";
import { Input } from "../../../library/form";
import { useForm } from "react-hook-form";
import { CefaloButton } from "../../../library/button";
import { IsUrlValid } from "../../../helpers/Validators";

function validateCourseUrl(value) {
  if (!IsUrlValid(value)) {
    return "Invalid URL";
  }
  return true;
}

function ApplicationCreateFormModal({
  userId,
  show,
  onToggle,
  callbacks,
  availableFund,
}) {
  const { register, handleSubmit, errors } = useForm({
    defaultValues: {},
  });

  const onFormSubmit = (data) => {
    onToggle();
    const payload = { ...data };
    payload["title"] = payload["title"].replace(/\s{2,}/g, ' ');
    payload["link"] = encodeURI(payload["link"]);
    payload["purpose"] = payload["purpose"].replace(/\s{2,}/g, ' ');
    payload["userId"] = isNaN(userId) ? null : Number(userId);
    payload["amount"] = isNaN(data.amount) ? null : Number(data.amount);
    callbacks.onCreate(payload);
  };

  return (
    <FormModal
      show={show}
      toggle={onToggle}
      formName="Fund Request"
      onSubmit={handleSubmit(onFormSubmit)}
    >
      <div key={FormModal.ControlContainer}>
        <Input
          containerClassName="mt-2"
          label="Course Title:"
          name="title"
          placeholder="Title of the course..."
          ref={register({
            required: "Course title is required", validate: (val) => {
              let _val = val.trim();
              if (_val.length < 2) {
                return "Title can not be empty";
              }
            }
          })}
          errors={errors}
        />
        <Input
          containerClassName="mt-2"
          label="Course Link:"
          name="link"
          placeholder="Link to the course..."
          ref={register({ required: "Course link is required", validate: validateCourseUrl })}
          errors={errors}
        />
        <Input
          containerClassName="mt-2"
          label="Amount: (BDT)"
          name="amount"
          type="number"
          placeholder="Course amount..."
          ref={register({
            required: "Course amount is required", validate: (val) => {
              if (val < 1) {
                return "Invalid amount";
              } else if (val > availableFund) {
                return `Maximum available fund is ${availableFund}`
              } else {
                return true;
              }
            }
          })}
          errors={errors}
        />
        <Input
          containerClassName="mt-2"
          label="Purpose"
          as="textarea"
          name="purpose"
          placeholder="Fund request purpose..."
          ref={register({
            required: "Purpose is required", validate: (value) => {
              if (typeof value != "string") {
                return "Invalid input";
              }

              let _val = value.trim();
              if (_val.length < 2) {
                return "Purpose can not be empty";
              }

              _val = _val.replace(/\s{2,}/g, ' ');

              if (_val.length < 10) {
                return "Purpose must be at least 10 characters."
              }
              return true;
            }
          })}
          errors={errors}
        />
        <label className="small">*Purpose should be at least 10 characters</label>
      </div>
      <div key={FormModal.ActionContainer}>
        <CefaloButton size="sm" variant="light" onClick={onToggle}>
          Close
        </CefaloButton>
        <CefaloButton variant="primary" size="sm" type="submit">
          Save
        </CefaloButton>
      </div>
    </FormModal>
  );
};

ApplicationCreateFormModal.propTypes = {
  show: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
  callbacks: PropTypes.object.isRequired,
  availableFund: PropTypes.number.isRequired,
};

export default ApplicationCreateFormModal;
