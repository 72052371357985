import React from "react";
import { CefaloButton } from "../../../library/button/Index";
import UserTeamHistoryUpsertForm from "../forms/UserTeamHistoryUpsertForm";
import TeamHistoryApi from "../../../apis/endpoints/TeamHistoryApi";
import { defaultErrorResponseParser } from "../../../helpers/ErrorParser";
import { toaster, Loading } from "../../../shared-components/notification";
import { showErrorMessages } from "../../../shared-components/response-message/ResponseMessage";

class UserTeamHistoryUpdateAction extends React.Component {
  state = {
    show: false,
    isLoading: false,
  };

  toggle = (e) => () => {
    this.setState({ show: !this.state.show });
  };

  onUpdateAction = (data) => {
    this.setState({ isLoading: true });

    TeamHistoryApi.update(data)
      .then((res) => {
        this.setState({
          isLoading: false,
          show: false,
        });
        toaster.success("Team History Updated Successfully");
        this.props.onUpdateCallback(this.props.teamHistoryRow.userId);
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        if (err.response.data) showErrorMessages(err.response.data);
        else defaultErrorResponseParser(err, "Failed To Update Team History");
      });
  };

  render() {
    return (
      <div className="d-inline-block">
        <Loading show={this.state.isLoading} />
        <CefaloButton
          className="btn-font-12"
          size="sm"
          variant="primary"
          onClick={this.toggle()}
        >
          Edit
        </CefaloButton>
        <UserTeamHistoryUpsertForm
          teamNames={this.props.teamNames}
          show={this.state.show}
          toggle={this.toggle}
          onSubmit={this.onUpdateAction}
          profile={this.props.profile}
          defaultValues={this.props.teamHistoryRow}
          formName={"Update Team"}
        />
      </div>
    );
  }
}

export default UserTeamHistoryUpdateAction;
