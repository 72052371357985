import React from "react";
import { getDisplayName } from "../DisplayName";

const WithCrudActionsOnObject = (api) => (WrappedComponent) => {
  class WithCrudActionsOnObject extends React.Component {
    state = {
      crudObject: {},
    };

    setStateCallback = (crudObject) => {
      if (crudObject?.constructor === Object) {
        this.setState({ crudObject });
      }
    };

    onCreate = (data, modifier) => {
      return api.create(data).then((res) => {
        if (!!modifier) {
          this.setStateCallback(modifier(data));
        } else {
          this.setStateCallback(res.data);
        }
        return res;
      });
    };

    onUpdate = (id, data, modifier) => {
      return api.update(id, data).then((res) => {
        if (!!modifier) {
          this.setStateCallback(modifier(data));
        } else {
          this.setStateCallback(res.data);
        }
        return res;
      });
    };

    onDelete = (id) => {
      return api.delete(id).then((res) => this.setStateCallback({}));
    };

    crudCallbacks = {
      onCreate: this.onCreate,
      onUpdate: this.onUpdate,
      onDelete: this.onDelete,
    };

    render() {
      const { crudObject } = this.state;
      return (
        <WrappedComponent
          setObjectStateCallback={this.setStateCallback}
          crudObject={crudObject}
          objectCrudCallbacks={this.crudCallbacks}
          {...this.props}
        />
      );
    }
  }
  WithCrudActionsOnObject.displayName = `WithCrudActionsOnObject(${getDisplayName(
    WrappedComponent
  )})`;

  return WithCrudActionsOnObject;
};

export default WithCrudActionsOnObject;
