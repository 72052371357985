import React from "react";
import PropTypes from "prop-types";
import UserManager from "../../../navigation/UserManager";
import { useForm } from "react-hook-form";
import { UserWithTeamDto } from "../../../models/User";
import { DateOnlyString } from "../../../helpers/Builders";
import { CefaloButton } from "../../../library/button/Index";
import { DateRange, Select, Form } from "../../../library/form/Index";
import { Col } from "../../../library/grid-layout/Index";

const FilterFormView = (props) => {
  let user = UserManager.getUser();
  const { register, handleSubmit } = useForm();

  const [fromDate, setFromDate] = React.useState(
    new Date(new Date().getFullYear(), 0, 1)
  );
  const [toDate, setToDate] = React.useState(
    new Date(new Date().getFullYear(), 11, 31)
  );

  const setDate = ({ fromDate, toDate }) => {
    setFromDate(fromDate);
    setToDate(toDate);
  };

  let leaveTypesOptions = props.leaveApplicationTypes.map((i) => {
    return { label: i.name, value: i.value };
  });
  
  let userOptions = [];

  if (user.isAdminOrHrOrTeamManager() && props.users) {
    props.users.forEach((user) => {
      const userOption = { value: user.id, label: user.name };
      userOptions.push(userOption);
    });
  }

  const onFilter = (data) => {
    var filteredData = { ...data };
    if (!user.isAdminOrHr()) filteredData.userId = user.id;
    filteredData.fromDate = DateOnlyString(fromDate);
    filteredData.toDate = DateOnlyString(toDate);
    filteredData.userId = Number.parseInt(filteredData.userId);
    props.onFilterAction(filteredData);
  };

  return (
    <form onSubmit={handleSubmit(onFilter)}>
      <Form.Row
        style={{
          alignItems: "flex-end",
        }}
      >
        {user.isAdminOrHrOrTeamManager() ? (
          <Col sm>
            <Select
              value={props.selectedUserId}
              label="User:"
              options={userOptions}
              ref={register}
              onChange={(e) => {
                let userId = Number.parseInt(e.target.value);
                props.setSelectedUserId(userId);
                props.onUserChange(userId);
              }}
              name="userId"
            />
          </Col>
        ) : (
          <></>
        )}
        <DateRange
          fromDate={fromDate}
          toDate={toDate}
          onChange={setDate}
          classesNames={{
            fromDateColClassName: "pr-lg-3 pl-lg-4",
            toDateColClassName: "pr-lg-3 pl-lg-3",
          }}
        />
        <Col sm>
          <Select
            label="Leave Types:"
            options={[{ value: "", label: "All" }, ...leaveTypesOptions]}
            ref={register}
            name="leaveType"
            containerClassName="pr-lg-3 pl-lg-2"
          />
        </Col>

        <Col lg={4} className="p-0 pl-lg-1">
          <Form.Row className="mb-3">
            <CefaloButton
              type="submit"
              className="mt-auto filter_panel_btn"
              size="sm"
              variant="primary"
            >
              Show Applications
            </CefaloButton>
            {props.children}
          </Form.Row>
        </Col>
      </Form.Row>
    </form>
  );
};
FilterFormView.prototype = {
  onFilterAction: PropTypes.func.isRequired,
  onUserChange: PropTypes.func,
  users: PropTypes.arrayOf(UserWithTeamDto),
};

export default FilterFormView;
