import React from "react";
import Layout from "../../shared-components/layout/Layout";
import UserManager from "../../navigation/UserManager";
import { Helmet } from "react-helmet";
import { Container, Row, Col } from "react-bootstrap";
import LeaveDataUploadModal from "./LeaveDataUploadView";
import CarryForwardDataUploadModal from "./CarryForwardUploadView";
import ExtraWorkDataUploadModal from "./ExtraWorkDataUploadView";
import {
  LeaveDataTable,
  CarryForwardDataTable,
  ExtraWorkingDayDataTable,
  DefaultPreviewTable,
} from "./Tables";
import LeaveAppliactionApi from "../../apis/endpoints/LeaveAppliactionApi";
import LeaveBalanceApi from "../../apis/endpoints/LeaveBalanceApi";
import ExtraWorkApplicationApi from "../../apis/endpoints/ExtraWorkApplicationApi";
import { defaultErrorResponseParser } from "../../helpers/ErrorParser";
import { Loading, toaster } from "../../shared-components/notification";
import { CefaloButton } from "../../library/button";

const TableType = Object.freeze({
  LeaveData: 1,
  ExtraWorkData: 2,
  CarryForwardData: 3,
});

const PreviewTable = ({ data, type }) => {
  if (type === TableType.LeaveData) {
    return <LeaveDataTable rows={data} />;
  } else if (type === TableType.CarryForwardData) {
    return <CarryForwardDataTable rows={data} />;
  } else if (type === TableType.ExtraWorkData) {
    return <ExtraWorkingDayDataTable rows={data} />;
  } else {
    return <DefaultPreviewTable />;
  }
};

export default class LeaveDataUploadContainier extends React.Component {
  state = {
    shouldRender: false,
    tableType: "N/A",
    data: null,
    isLoading: false,
  };

  componentDidMount() {
    let user = UserManager.getUser();
    if (user.isAdminOrHr) {
      this.setState({ shouldRender: true });
    } else {
      window.location.replace("/Report/Index");
    }
  }

  previewLeaveDataCallback = (data) => {
    this.setState({
      data: data,
      tableType: TableType.LeaveData,
    });
  };

  previewCarryForwardDataCallback = (data) => {
    this.setState({
      data: data,
      tableType: TableType.CarryForwardData,
    });
  };

  previewExtraWorkDayDataCallback = (data) => {
    this.setState({
      data: data,
      tableType: TableType.ExtraWorkData,
    });
  };

  handleUpload = (e) => {
    e.preventDefault();
    switch (this.state.tableType) {
      case TableType.LeaveData:
        this.handleLeaveDataUpload(e);
        break;
      case TableType.CarryForwardData:
        this.handleCarryForwardDataUpload(e);
        break;
      case TableType.ExtraWorkData:
        this.handleExtraWorkDataUpload(e);
        break;
      default:
        toaster.error("Please Select A File To Upload");
        return;
    }
  };

  handleLeaveDataUpload = (e) => {
    this.setState({ isLoading: true });
    LeaveAppliactionApi.upload(this.state.data)
      .then((res) => {
        this.resetState();
        toaster.success("Leave data is uploaded successfully");
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        defaultErrorResponseParser(err, "Failed to upload leave data");
      });
  };

  handleCarryForwardDataUpload = (e) => {
    LeaveBalanceApi.upload(this.state.data)
      .then((res) => {
        this.resetState();
        toaster.success("Carry forward data is uploaded successfully");
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        defaultErrorResponseParser(err, "Failed to upload Carry forward data");
      });
  };

  handleExtraWorkDataUpload = (e) => {
    ExtraWorkApplicationApi.upload(this.state.data)
      .then((res) => {
        this.resetState();
        toaster.success("EWD data is uploaded successfully");
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        defaultErrorResponseParser(err, "Failed to upload EWD data");
      });
  };

  resetState = () => {
    this.setState({
      data: [],
      isLoading: false,
    });
  };

  render() {
    if (!this.state.shouldRender) {
      return <></>;
    }
    return (
      <Layout>
        <Helmet>
          <title>Leave Data Management - Cefalo</title>
        </Helmet>
        <Loading show={this.state.isLoading} />
        <Container fluid className="pt-4">
          <Row className="pb-3">
            <Col sm="3">
              <LeaveDataUploadModal
                previewCallback={this.previewLeaveDataCallback}
              />
            </Col>
            <Col sm="4">
              <CarryForwardDataUploadModal
                previewCallback={this.previewCarryForwardDataCallback}
              />
            </Col>
            <Col sm="3">
              <ExtraWorkDataUploadModal
                previewCallback={this.previewExtraWorkDayDataCallback}
              />
            </Col>
            <Col sm="2">
              <CefaloButton
                size="sm"
                className="float-right"
                variant="primary"
                type="submit"
                onClick={this.handleUpload}
              >
                Save Data
              </CefaloButton>
            </Col>
          </Row>
          <PreviewTable type={this.state.tableType} data={this.state.data} />
        </Container>
      </Layout>
    );
  }
}
