import React from "react";
import UserManager from "../../../navigation/UserManager";
import FilterFormView from "./FilterFormView";
import ApplicationTable from "./ApplicationTable";
import { SkillDevelopmentRequestDto } from "../../../models/SkillDevelopmentFund";
import PropTypes from "prop-types";

function SkillDevelopmentSupervisionView (props) {
  const user = UserManager.getUser()
  return (
    <>
      <div>
        <FilterFormView
          selectedUserId={props.selectedUserId}
          users={props.users}
          userId={props.userId}
          callbacks={props.callbacks}
          isSupervision={true}
        />
        <ApplicationTable
          isTeamManagerVisualizingOtherMembers={user.isTeamManagerVisualizingOtherMembers}
          selectedUserId={props.selectedUserId}
          userId={props.userId}
          isSupervision={true}
          requests={props.requests}
          callbacks={props.callbacks}
        />
      </div>
    </>
  );
};

SkillDevelopmentSupervisionView.propType = {
  requests: PropTypes.arrayOf(PropTypes.objectOf(SkillDevelopmentRequestDto)).isRequired,
}

export default SkillDevelopmentSupervisionView;
