const SortOrder = Object.freeze({
  Ascending: "asc",
  Descending: "desc",
});

export default {
  dateSorter: (a, b, order) => {
    if (order === SortOrder.Ascending) {
      return new Date(a) - new Date(b);
    } else {
      return new Date(b) - new Date(a);
    }
  },
};
