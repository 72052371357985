import React from "react";
import PropTypes from "prop-types";
import RemoteWorkApi from "../../../apis/endpoints/RemoteWorkApi";
import { Loading, toaster } from "../../notification";
import { FormModal } from "../../../library/modal";
import { defaultErrorResponseParser } from "../../../helpers/ErrorParser";
import { CefaloButton } from "../../../library/button";

export default class ApplicationDeleteAction extends React.Component {
  static propTypes = {
    applicationId: PropTypes.number.isRequired,
    onDeleteCallback: PropTypes.func.isRequired,
  };

  state = {
    isLoading: false,
    show: false,
  };

  toggle = (e) => () => {
    this.setState({ show: !this.state.show });
  };

  onDelete = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    RemoteWorkApi.delete(this.props.applicationId)
      .then((res) => {
        toaster.success("Application Is Deleted Successfully");
        this.setState({ isLoading: false, show: false });
        this.props.onDeleteCallback();
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        defaultErrorResponseParser(
          err,
          "Failed To Delete Remote Work Application"
        );
      });
  };

  render() {
    return (
      <div className="d-inline-block">
        <Loading show={this.state.isLoading} />
        <FormModal
          formName="Remote Work Application Delete"
          onSubmit={this.onDelete}
          show={this.state.show}
          toggle={this.toggle()}
        >
          <div key={FormModal.ControlContainer}>
            <p>Are you sure to Delete this remote work application?</p>
          </div>
          <div key={FormModal.ActionContainer}>
            <CefaloButton
              size="sm"
              variant="danger"
              onClick={this.toggle()}
              type="button"
            >
              No
            </CefaloButton>
            <CefaloButton size="sm" variant="success" type="submit">
              Yes
            </CefaloButton>
          </div>
        </FormModal>
        <CefaloButton
          className="btn-font-12"
          size="sm"
          variant="danger"
          onClick={this.toggle()}
        >
          Delete
        </CefaloButton>
      </div>
    );
  }
}
