import { AuthenticatedAxios } from "../settings/Axios";

const BaseEndPoint = "AuditLogs";

export default {
  getAll: () => {
    return AuthenticatedAxios.get(BaseEndPoint, null, null);
  },

  getAllByEntityType: (entiityType) => {
    let endpoint = `${BaseEndPoint}/${entiityType}`;
    return AuthenticatedAxios.get(endpoint);
  },
  getAllAuditableEntity: () => {
    let endpoint = `${BaseEndPoint}/EntityName`;
    return AuthenticatedAxios.get(endpoint);
  },
};
