import React from "react";
import { Form } from "react-bootstrap";
import Error from "./Error";

const Input = React.forwardRef((props, ref) => {
  const {
    label,
    name,
    type,
    errors,
    containerClassName = "",
    ...restProps
  } = props;
  return (
    <Form.Group className={containerClassName}>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        type={type ? type : "text"}
        name={name}
        size="sm"
        ref={ref}
        {...restProps}
      />
      <Error errors={errors} field={name} />
    </Form.Group>
  );
});

export default Input;
