import React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { FormModal } from "../../../library/modal";
import {
  Form,
  Input,
  Select,
  CheckBox,
  DateRange,
} from "../../../library/form";
import { Col } from "../../../library/grid-layout";
import { LeaveApplicationDetailDto } from "../../../models/LeaveApplication";
import { DateOnlyString } from "../../../helpers/Builders";
import { CefaloButton } from "../../../library/button";

const ApplicantPreview = ({ userName, teamName }) => {
  return (
    <div>
      <Form.Row>
        <Col>
          <Input label="Applicant" readOnly value={userName} />
        </Col>
        <Col>
          <Input label="Team" readOnly value={teamName} />
        </Col>
      </Form.Row>
    </div>
  );
};

const ApplicationUpdateFormModal = ({
  fromDate,
  toDate,
  totalWorkingDays,
  show,
  onToggle,
  onUpdate,
  periodSelectCallBack,
  application = new LeaveApplicationDetailDto(),
  includeHalfDay,
  leaveTypes,
}) => {
  const { register, handleSubmit, getValues, reset } = useForm({
    defaultValues: {
      includeSingleHalfDay: includeHalfDay,
      description: application.description,
    },
  });

  React.useEffect(() => {
    const componentDidUpdate = () => {
      reset({
        includeSingleHalfDay: includeHalfDay,
        description: application.description,
      });
    };
    componentDidUpdate();
  }, [application, includeHalfDay, reset]);

  const onFormSubmit = (data) => {
    let applicationData = { ...data };
    applicationData["fromDate"] = DateOnlyString(fromDate);
    applicationData["toDate"] = DateOnlyString(toDate);
    applicationData["leaveType"] = Number.parseInt(data["leaveType"]);
    applicationData["totalNumberOfWorkingDays"] = Number.parseFloat(
      data["totalNumberOfWorkingDays"]
    );

    onUpdate(applicationData);
  };

  let leaveTypesOptions = leaveTypes.map((i) => {
    return { label: i.name, value: i.value };
  });

  return (
    <FormModal
      onSubmit={handleSubmit(onFormSubmit)}
      show={show}
      toggle={onToggle()}
      formName="Leave Application"
    >
      <div key={FormModal.ControlContainer}>
        <ApplicantPreview
          userName={application.applicant.name}
          teamName={application.applicant.teamName}
        />
        <Form.Row>
          <DateRange
            fromDate={fromDate}
            toDate={toDate}
            onChange={({ fromDate, toDate }) =>
              periodSelectCallBack(
                fromDate,
                toDate,
                getValues("includeSingleHalfDay")
              )
            }
          />
        </Form.Row>
        <Form.Row>
          <Col>
            <Input
              label="Total Working Days"
              type="number"
              name="totalNumberOfWorkingDays"
              disabled
              value={totalWorkingDays}
              ref={register}
            />
          </Col>
          <Col>
            <Select
              ref={register}
              name="leaveType"
              options={leaveTypesOptions}
              defaultValue={application.leaveType}
              label="Leave Type"
            />
          </Col>
        </Form.Row>

        <div>
          <CheckBox
            label="Include Single Halfday Leave"
            name="includeSingleHalfDay"
            style={{ zIndex: 0 }}
            ref={register}
            onChange={(e) => {
              periodSelectCallBack(fromDate, toDate, e.target.checked);
              return e.target.checked;
            }}
          />
        </div>

        <Input
          label="Description"
          as="textarea"
          placeholder="Leave Description..."
          name="description"
          ref={register}
          containerClassName="mt-2"
        />
      </div>
      <div key={FormModal.ActionContainer}>
        <CefaloButton size="sm" variant="light" onClick={onToggle()}>
          Close
        </CefaloButton>
        <CefaloButton variant="primary" size="sm" type="submit">
          Save
        </CefaloButton>
      </div>
    </FormModal>
  );
};

ApplicationUpdateFormModal.prototype = {
  fromDate: PropTypes.objectOf(Date).isRequired,
  toDate: PropTypes.objectOf(Date).isRequired,
  totalWorkingDays: PropTypes.number.isRequired,
  show: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  periodSelectCallBack: PropTypes.func.isRequired,
  application: PropTypes.objectOf(LeaveApplicationDetailDto),
  includeHalfDay: PropTypes.bool,
  leaveTypes: PropTypes.arrayOf(Object).isRequired,
};

export default ApplicationUpdateFormModal;
