import React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { Form, Col } from "react-bootstrap";
import { FormModal } from "../../../library/modal";
import { CefaloButton } from "../../../library/button";
import { Input, Select } from "../../../library/form";

const ClientUpsertModalForm = ({
  onSubmit,
  show,
  onToggle,
  formName,
  keyAccountManagers,
  defaultValues,
}) => {
  const { register, handleSubmit, errors } = useForm({
    defaultValues: defaultValues,
  });

  const onFormSubmit = (data) => {
    if (defaultValues?.id) {
      data["id"] = defaultValues.id;
    }
    if (data["keyAccountManagerId"] === "*") {
      data["keyAccountManagerId"] = null;
    }
    onSubmit(data);
  };

  return (
    <FormModal
      onSubmit={handleSubmit(onFormSubmit)}
      show={show}
      toggle={onToggle()}
      formName={formName}
    >
      <div key={FormModal.ControlContainer}>
        <Form.Row>
          <Col sm>
            <Input
              ref={register({ required: "Client name is required" })}
              placeholder="Enter Client Name"
              name="name"
              label="Client Name"
              errors={errors}
            />
          </Col>
          <Col sm>
            <Select
              ref={register({ required: true })}
              label="Key Account Manager"
              name="keyAccountManagerId"
              options={[{ value: "*", label: "None" }, ...keyAccountManagers]}
              errors={errors}
            />
          </Col>
        </Form.Row>
      </div>
      <div key={FormModal.ActionContainer}>
        <CefaloButton size="sm" variant="light" onClick={onToggle()}>
          Close
        </CefaloButton>
        <CefaloButton size="sm" variant="primary" type="submit">
          Save
        </CefaloButton>
      </div>
    </FormModal>
  );
};

ClientUpsertModalForm.prototype = {
  onCreate: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default ClientUpsertModalForm;
