import React from "react";

// authentication
import GoogleLoginContainer from "../../components/google-login";
import InternalLoginContainer from "../../components/internal-login";
import Logout from "../../components/logout";

//user
import AllUserManageContainer from "../../components/user-manage/AllUserManageContainer";

//profile
import UserProfileUpsertContainer from "../../components/profile/UserProfileUpsertContainer";
import UserProfileViewContainer from "../../components/profile/UserProfileViewContainer";

//team
import TeamManageContainer from "../../components/team-manage";

//training
import TrainingManageContainer from "../../components/training-manage/TrainingManageContainer";

//attednace
import AttendanceContainer from "../../components/attendances";
import AttendanceDataSyncContainer from "../../components/attendance-data-sync/AttendanceDataSyncContainer";

//leave
import LeaveApplication from "../../components/leave-application";
import LeaveApplicationSupervision from "../../components/leave-application-supervision";
import LeaveDataUploadContainer from "../../components/leave-data-upload";
import EscalateCarryForwardContainer from "../../components/escalate-carry-forward";
import TeamLeaveReportContainer from "../../components/team-leave-report";

// dashboard
import DashboardContainer from "../../components/dashboard";

// remote
import RemoteWorkApplicationContainer from "../../components/remote-work-application/RemoteWorkApplicationContainer";
import RemoteWorkApplicationSupervisionContainer from "../../components/remote-work-application-supervision/SupervisionContainer";

//extra work
import ExtraWorkApplicationContainer from "../../components/extra-work-application/ExtraWorkApplicationContainer";
import ExtraWorkSupervisionContainer from "../../components/extra-work-application/ExtraWorkSupervisionContainer";

//settings
import CombinedSettingsContainer from "../../components/settings-officeTiming/CombinedSettingsViews";

//MiscellaneousRoute
import MemberDirectory from "../../components/member-directory/MemberdirectoryContainer";
import PublicHoliday from "../../components/public-holiday/PublicHolidayContainer";
import HrPolicyContainer from "../../components/policy/HrPolicyContainer";
import ItPolicyContainer from "../../components/policy/ItPolicyContainer";

//LeaveStatistics
import AllLeaveStatisticsContainer from "../../components/Leave-statistics/AllLeaveStatisticsContainer";
import LeaveStatisticsPerTeamContainer from "../../components/leave-statistics-per-team/LeaveStatisticsPerTeamContainer";

//Billing
import BillingContainer from "../../components/Billing/BillingContainer";

//EmployeeInfo
import ExportEmployeeInfoContainer from "../../components/Export-Employee-Information/ExportEmployeeInfoContainer";

//AvailableEmployeeInfo
import ExportAvailableEmployeeContainer from "../../components/export-available-employee/ExportAvailableEmployeeContainer";

//Skill
import SkillManageContainer from "../../components/skill-manage/SkillManageContainer";

//Client
import ClientManageContainer from "../../components/client-manage/ClientManageContainer";

//Noc
import NocApplicationContainer from "../../components/noc/NocApplicationContainer";
import NocSupervisionContainer from "../../components/noc/NocSupervisionContainer";

//AuditLog
import AuditLogContainer from "../../components/audit-log/AuditLogContainer";

//route
import DefaultRoute from "../../shared-components/protected-route/DefaultRoute";
import ProtectedRoute from "../../shared-components/protected-route/ProtectedRoute";
import { Switch, Route } from "react-router-dom";
import KnowledgeSharingSessionsContainer from "../../components/policy/KnowledgeSharingSessionsContainer";
import SkillDevelopmentApplicationContainer from "../../components/skill-development-fund/SkillDevelopmentApplicationContainer";
import SkillDevelopmentSupervisionContainer from "../../components/skill-development-fund/SkillDevelopmentSupervisionContainer";

export default function RootRoute() {
  return (
    <Switch>
      {/* Authentication Route  */}
      <Route path="/login" exact component={GoogleLoginContainer} />
      <Route path="/internallogin" exact component={InternalLoginContainer} />
      <Route path="/logout" exact component={Logout} />
      {/* <UserRoute /> */}
      <ProtectedRoute
        path="/UserInfo/Index"
        exact
        component={AllUserManageContainer}
      />
      {/* Export Data Route*/}
      <ProtectedRoute
        path="/LeaveInfo/Index"
        exact
        component={AllLeaveStatisticsContainer}
      />
      <ProtectedRoute
        path="/LeaveInfo/Client/Index"
        exact
        component={LeaveStatisticsPerTeamContainer}
      />
      <ProtectedRoute
        path="/BillingInfo/Index"
        exact
        component={BillingContainer}
      />
      <ProtectedRoute
        path="/Export/AvailableEmployeeInfo/Index"
        exact
        component={ExportAvailableEmployeeContainer}
      />
      <ProtectedRoute
        path="/Export/EmpoyeeInfo/Index"
        exact
        component={ExportEmployeeInfoContainer}
      />
      {/* User Profile Route */}
      <ProtectedRoute
        exact
        path="/profile/edit"
        component={UserProfileUpsertContainer}
      />
      <ProtectedRoute
        exact
        path="/profile/view/:id"
        component={UserProfileViewContainer}
      />
      {/* <ClientRoute /> */}
      <ProtectedRoute
        path="/ClientInfo/Index"
        exact
        component={ClientManageContainer}
      />
      {/* <TeamRoute /> */}
      <ProtectedRoute
        exact
        path="/Team/Index"
        component={TeamManageContainer}
      />
      {/* <TrainingRoute /> */}
      <ProtectedRoute
      exact
      path="/Training/Index"
      component={TrainingManageContainer} 
      />
      {/* <SkillRoute /> */}
      <ProtectedRoute
        exact
        path="/Skill/Index"
        component={SkillManageContainer}
      />
      
      {/* <AuditLogRoute /> */}
      <ProtectedRoute
        exact
        path="/AuditLog/Index"
        component={AuditLogContainer}
      />

      {/* <LeaveApplicationRoute /> */}
      <ProtectedRoute
        exact
        path="/LeaveApplication/index"
        component={LeaveApplication}
      />
      <ProtectedRoute
        exact
        path="/LeaveApplication/supervision"
        component={LeaveApplicationSupervision}
      />
      <ProtectedRoute
        exact
        path="/LeaveApplication/DataUpload"
        component={LeaveDataUploadContainer}
      />
      <ProtectedRoute
        exact
        path="/LeaveApplication/EscalateCarryForwardData"
        component={EscalateCarryForwardContainer}
      />
      <ProtectedRoute
        exact
        path="/LeaveApplication/LeaveReport"
        component={TeamLeaveReportContainer}
      />
      {/* NOC Route */}
      <ProtectedRoute
        exact
        path="/NocApplication/Index"
        component={NocApplicationContainer}
      />
      <ProtectedRoute
        exact
        path="/NocApplication/Supervision"
        component={NocSupervisionContainer}
      />
      {/* Attendance Route */}
      <ProtectedRoute
        path="/Report/Index"
        exact
        component={AttendanceContainer}
      />
      <ProtectedRoute
        path="/Report/Index/:id"
        component={AttendanceContainer}
      />
      <ProtectedRoute
        path="/Report/Sync"
        component={AttendanceDataSyncContainer}
      />
      {/* <DashboardRoute /> */}
      <ProtectedRoute path="/Home/Index" exact component={DashboardContainer} />
      {/* <RemoteWorkApplicationRoute /> */}
      <ProtectedRoute
        exact
        path="/WorkingFromOutsideApplication/Index"
        component={RemoteWorkApplicationContainer}
      />
      <ProtectedRoute
        exact
        path="/WorkingFromOutsideApplication/Supervision"
        component={RemoteWorkApplicationSupervisionContainer}
      />
      <ProtectedRoute
        exact
        path="/FundRequest/SkillDevelopment/Index"
        component={SkillDevelopmentApplicationContainer}
      />
      <ProtectedRoute
        exact
        path="/FundRequest/SkillDevelopment/Supervision"
        component={SkillDevelopmentSupervisionContainer}
      />
      {/* <ExtraWorkApplication /> */}
      <ProtectedRoute
        exact
        path="/ExtraWorkingDayApplication/Index"
        component={ExtraWorkApplicationContainer}
      />
      <ProtectedRoute
        exact
        path="/ExtraWorkingDayApplication/Supervision"
        component={ExtraWorkSupervisionContainer}
      />
      {/* <Settings /> */}
      <ProtectedRoute
        path="/Settings/Index"
        exact
        component={CombinedSettingsContainer}
      />
      {/* <Miscellaneous /> */}
      <ProtectedRoute
        exact
        path="/HolidayInfo/Index"
        component={PublicHoliday}
      />
      <ProtectedRoute
        exact
        path="/UserProfile/Index"
        component={MemberDirectory}
      />
      <ProtectedRoute
        exact
        path="/KnowledgeSharingSessions"
        component={KnowledgeSharingSessionsContainer}
      />
      <ProtectedRoute exact path="/Policy/Hr" component={HrPolicyContainer} />
      <ProtectedRoute exact path="/Policy/It" component={ItPolicyContainer} />
      {/* default */}
      <DefaultRoute />
    </Switch>
  );
}
