import React from "react";
import PropTypes from "prop-types";
import { DataTable } from "../../../library/table/Index";
import { AuditLogDto } from "../../../models/AuditLogDto";
import ReactJsonViewCompare from "react-json-view-compare";
import { Modal } from "react-bootstrap";
import { CefaloButton } from "../../../library/button/Index";
import { useState } from "react";

const dataTableColumns = [
  {
    text: "TimeStamp",
    dataField: "timeStamp",
    sort: true,
  },
  {
    text: "Table Name",
    dataField: "tableName",
    sort: true,
  },
  {
    text: "Action Type",
    dataField: "actionType",
    sort: true,
  },
  {
    text: "Employee Id",
    dataField: "employeeId",
    sort: false,
  },
  {
    text: "Employee Name",
    dataField: "employeeName",
    sort: false,
  },
  {
    text: "Performer Id",
    dataField: "performerId",
    sort: false,
  },
  {
    text: "Performer Name",
    dataField: "performerName",
    sort: true,
  },
  {
    text: "Changes",
    dataField: "changes",
    sort: false,
  },
];

const ChangesViewModal = ({ show, handleCloseModal, oldData, newData }) => {
  return (
    <Modal show={show} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Changes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ReactJsonViewCompare oldData={oldData} newData={newData} />
      </Modal.Body>
      <Modal.Footer>
        <CefaloButton variant="secondary" onClick={handleCloseModal}>
          Close
        </CefaloButton>
      </Modal.Footer>
    </Modal>
  );
};

ChangesViewModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  oldData: PropTypes.any,
  newData: PropTypes.any,
};

const buildTableRows = (rows, handleShowModal) => {
  let tempRows = [];
  rows.forEach((item) => {
    if (item instanceof AuditLogDto) {
      let tempRow = {
        timeStamp: item.timeStamp,
        tableName: item.tableName,
        actionType: item.actionType,
        employeeId: item.employeeId,
        employeeName: item.employeeName,
        performerId: item.performerId,
        performerName: item.performerName,
        changes: (
          <CefaloButton
            variant="primary"
            onClick={() => handleShowModal(item.oldValues, item.newValues)}
          >
            View
          </CefaloButton>
        ),
      };
      tempRows.push(tempRow);
    }
  });
  return tempRows;
};

const AuditLogTable = (props) => {
  const [show, setShow] = useState(false);
  const [oldData, setOldData] = useState(null);
  const [newData, setNewData] = useState(null);

  const handleCloseModal = () => {
    setShow(false);
    setOldData(null);
    setNewData(null);
  };

  const handleShowModal = (oldData, newData) => {
    setOldData(oldData);
    setNewData(newData);
    setShow(true);
  };

  let rows = buildTableRows(props.auditLogs, handleShowModal);
  return (
    <div>
      <DataTable rows={rows} columns={dataTableColumns} />
      <ChangesViewModal
        show={show}
        handleCloseModal={handleCloseModal}
        oldData={oldData}
        newData={newData}
      />
    </div>
  );
};
const AuditLogView = (props) => {
  return (
    <div>
      <AuditLogTable {...props} />
    </div>
  );
};

AuditLogView.propTypes = {
  auditLogs: PropTypes.arrayOf(PropTypes.instanceOf(AuditLogDto)),
};

export default AuditLogView;
