import React from "react";

import {
  Loading,
  toaster,
} from "../../../shared-components/notification/Notification";
import { defaultErrorResponseParser } from "../../../helpers/ErrorParser";
import { CefaloButton } from "../../../library/button/Index";
import { showErrorMessages } from "../../../shared-components/response-message/ResponseMessage";
import EmployeeCertificationApi from "../../../apis/endpoints/EmployeeCertificationApi";
import CertificationUpsertForm from "../forms/EmployeeCertificationUpsertForm";

export default class EmployeeCertificationCreateAction extends React.Component {
  state = {
    show: false,
    isLoading: false,
  };

  toggle = (e) => () => {
    this.setState({ show: !this.state.show });
  };

  onCreateAction = (data) => {
    this.setState({ isLoading: true });
    EmployeeCertificationApi.create(data)
      .then((res) => {
        this.setState({
          isLoading: false,
          show: false,
        });
        toaster.success("Employee Certification Created Successfully!");
        this.props.onCreateCallBack(this.props.userId);
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        if (err.response.data) showErrorMessages(err.response.data);
        else
          defaultErrorResponseParser(
            err,
            "Failed To Create Employee Certification!"
          );
      });
  };

  render() {
    const { profile } = this.props;
    return (
      <div className="d-inline-block">
        <Loading show={this.state.isLoading} />
        <CefaloButton size="sm" variant="primary" onClick={this.toggle()}>
          Add Certification
        </CefaloButton>
        <CertificationUpsertForm
          formName={"Add Certification"}
          toggle={this.toggle}
          onSubmit={this.onCreateAction}
          show={this.state.show}
          profile={profile}
        />
      </div>
    );
  }
}
