import React, { useState } from "react";
import AttendanceApi from "../../../apis/endpoints/AttendanceApi";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { CefaloButton } from "../../../library/button";
import { Loading } from "../../../shared-components/notification";

const getExportApiByOption = (exportOptions) => {
  var api;
  if (exportOptions.id !== undefined) {
    if (exportOptions.options === null) {
      api = AttendanceApi.downloadAllByUser(
        exportOptions.id,
        exportOptions.fromDate,
        exportOptions.toDate
      );
    } else {
      api = AttendanceApi.downloadAllByUser(
        exportOptions.id,
        exportOptions.fromDate,
        exportOptions.toDate,
        exportOptions.options
      );
    }
  } else {
    if (exportOptions.options === null) {
      api = AttendanceApi.downloadAll(
        exportOptions.fromDate,
        exportOptions.toDate
      );
    } else {
      api = AttendanceApi.downloadAll(
        exportOptions.fromDate,
        exportOptions.toDate,
        exportOptions.options
      );
    }
  }
  return api;
};

const AttendanceExportAction = ({ exportOptions }) => {
  const [show, setShow] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const handleExport = () => {
    setIsDownloading(true);
    var api = getExportApiByOption(exportOptions);
    api
      .then((res) => {
        setIsDownloading(false);
        setShow(true);
        let fileName = res.headers["x-attachmentname"];
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("Download", fileName);
        link.click();
      })
      .catch((err) => {
        setIsDownloading(false);
        if (err.response) {
          if (err.response.data.Title) {
          } else {
          }
        } else {
        }
      });
  };

  const toggle = () => () => {
    setShow(false);
  };

  return (
    <>
      <Loading show={isDownloading} />
      <OverlayTrigger
        key="bottom"
        placement="bottom"
        overlay={
          <Tooltip id="attendance-export-tooltip-bottom">Export</Tooltip>
        }
      >
        <CefaloButton size="sm" variant="primary" onClick={handleExport}>
          Export Report
        </CefaloButton>
      </OverlayTrigger>
      <Modal isOpen={show} toggle={toggle()} centered>
        <Modal.Body>File Download will start Shortly</Modal.Body>
        <Modal.Footer>
          <CefaloButton size="sm" variant="primary" onClick={toggle()}>
            OK
          </CefaloButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AttendanceExportAction;
