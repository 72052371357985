import React, { Component } from "react";
import NocApplicationApi from "../../../apis/endpoints/NocApplicationApi";
import UserManager from "../../../navigation/UserManager";
import { defaultErrorResponseParser } from "../../../helpers/ErrorParser";
import { CefaloButton } from "../../../library/button/Index";
class NocReportDownloadAction extends Component {
  state = {
    shouldRender: false,
    isLoading: false,
  };
  componentDidMount() {
    let user = UserManager.getUser();
    if (user.isAdminOrHr() || `${this.props.application.userId}` === `${user.id}`) {
      this.setState({ shouldRender: true });
    } else {
      window.location.replace(`/Report/Index/${user.id}`);
    }
  }
  submitNocReportGeneration(application) {
    NocApplicationApi.download(application.id)
      .then((res) => {
        this.setState({ isLoading: false});
        this.downloadReportFile(res);
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        defaultErrorResponseParser(err, "Failed To generate NOC report");
      });

  }

  downloadReportFile(res) {
    let fileName = res.headers["x-attachmentname"];
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("Download", fileName);
    link.click();
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.submitNocReportGeneration(this.props.application);
  };
  render() {
    if (!this.state.shouldRender) {
      return <></>;
    } else {
      return (
        <div className="d-inline-block">
          <form encType="multipart/form-data" onSubmit={this.handleSubmit}>
            <CefaloButton
              variant="success"
              className="btn-font-12"
              size="sm"
              type="submit"
            >
              Download
            </CefaloButton>
          </form>
        </div>
      );
    }
  }
}

export default NocReportDownloadAction;
