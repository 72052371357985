export class LeaveSettingsDto {
  constructor(obj) {
    this.allowedCancellationPeriod = obj.allowedCancellationPeriod
      ? Number.parseInt(obj.allowedCancellationPeriod)
      : 0;
    this.casualLeavePerMonth = obj.casualLeavePerMonth
      ? Number.parseFloat(obj.casualLeavePerMonth)
      : 0.0;
    this.maxForwardedLeave = obj.maxForwardedLeave
      ? Number.parseFloat(obj.maxForwardedLeave)
      : 0.0;
    this.sickLeaveWithoutDocPerYear = obj.sickLeaveWithoutDocPerYear
      ? Number.parseFloat(obj.sickLeaveWithoutDocPerYear)
      : 0.0;
    this.totalSickLeavePerYear = obj.totalSickLeavePerYear
      ? Number.parseFloat(obj.totalSickLeavePerYear)
      : 0.0;
  }
}
