import React from "react";
import ReactCrop from "react-image-crop";
import { CefaloButton } from "../../../library/button/Index";
import FormModal from "../../../library/modal/FormModal";
import { useForm } from "react-hook-form";
import styles from "../styles/ProfileImage.module.css";

const ImageCroppingModal = ({
  imageFile,
  showModal,
  toggleTheModalView,
  crop,
  onImageLoaded,
  onComplete,
  onChange,
  handlePropPassing,
  minCropWidth,
  maxCropWidth,
}) => {
  const { handleSubmit } = useForm({});
  const onFormSubmit = (data) => {
    let imageData = { ...data };
    imageData["img"] = Image;
  };

  return (
    <FormModal
      onSubmit={handleSubmit(onFormSubmit)}
      show={showModal}
      toggle={toggleTheModalView}
      className={styles.modal}
      showHeader={false}
    >
      <div key={FormModal.ControlContainer} className={styles.responsive}>
        {imageFile && (
          <ReactCrop
            src={imageFile}
            crop={crop}
            onImageLoaded={onImageLoaded}
            onComplete={onComplete}
            onChange={onChange}
            minWidth={minCropWidth}
            maxWidth={maxCropWidth}
            minHeight={minCropWidth}
            maxHeight={maxCropWidth}
            keepSelection={true}
          />
        )}
      </div>
      <div key={FormModal.ActionContainer}>
        <CefaloButton
          size="sm"
          variant="light"
          onClick={toggleTheModalView}
          type="button"
        >
          Close
        </CefaloButton>

        <CefaloButton onClick={handlePropPassing} variant="primary" size="sm">
          Ok
        </CefaloButton>
      </div>
    </FormModal>
  );
};

class ProfileImageCroppingAction extends React.Component {
  state = {
    imageData: null,
    imageType: null,
    croppedFile: null,
    src: null,
    croppedImageSrc: null,
    crop: {
      aspect: 1,
      unit: "px",
      width: 50,
      height: 50,
      x: 0,
      y: 0,
    },
    setProfileImageFilename: null,
    maxCropHeight: null,
    minCropHeight: null,
    maxCropWidth: null,
    minCropWidth: null,
    minSize: 340,
    maxSize: 1024,
  };

  onImageLoaded = (image) => {
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    var imageMaxCropValue = image.width;

    const currentState = this.state;
    currentState.maxCropHeight = this.state.maxSize / (scaleY * pixelRatio);
    currentState.minCropHeight = this.state.minSize / (scaleY * pixelRatio);
    currentState.maxCropWidth = this.state.maxSize / (scaleX * pixelRatio);
    currentState.minCropWidth = this.state.minSize / (scaleX * pixelRatio);

    imageMaxCropValue =
      imageMaxCropValue > image.height ? image.height : imageMaxCropValue;

    currentState.crop.x = 0;
    currentState.crop.y = 0;
    currentState.crop.width =
      imageMaxCropValue < currentState.maxCropWidth
        ? imageMaxCropValue
        : currentState.maxCropWidth;
    currentState.crop.height =
      imageMaxCropValue < currentState.maxCropHeight
        ? imageMaxCropValue
        : currentState.maxCropHeight;

    this.setState(currentState)
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl });
    }
  }

  async getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    if (canvas.width !== canvas.height) {
      canvas.height = canvas.width;
    }
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            //reject(new Error('Canvas is empty'));
            console.error("Canvas is empty");
            return;
          }
          blob.name = fileName;
          window.URL.revokeObjectURL(this.fileUrl);
          this.fileUrl = window.URL.createObjectURL(blob);

          this.setState({
            croppedFile: blob,
          });
          resolve(this.fileUrl);
        },
        "image/jpeg",
        1
      );
    });
  }

  handlePropPassing = (e) => {
    this.props.onGetImage(this.state.croppedFile);
  };

  render() {
    return (
      <div>
        <ImageCroppingModal
          imageFile={this.props.image}
          showModal={this.props.showModal}
          uploadCroppedImage={this.uploadCroppedImage}
          crop={this.state.crop}
          onImageLoaded={this.onImageLoaded}
          onComplete={this.onCropComplete}
          onChange={this.onCropChange}
          toggleTheModalView={this.props.toggleTheModalView}
          handlePropPassing={this.handlePropPassing}
          maxCropHeight={this.state.maxCropHeight}
          minCropHeight={this.state.minCropHeight}
          maxCropWidth={this.state.maxCropWidth}
          minCropWidth={this.state.minCropWidth}
        />
      </div>
    );
  }
}

export default ProfileImageCroppingAction;
