import React, { useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import PublicHolidayApi from "../../apis/endpoints/PublicHolidayApi";
import { Loading, toaster } from "../../shared-components/notification";
import { DateOnlyString } from "../../helpers/Builders";
import { defaultErrorResponseParser } from "../../helpers/ErrorParser";
import { CefaloButton } from "../../library/button";
import { FormModal } from "../../library/modal";
import { Form, DatePicker, Input } from "../../library/form";
import { Col } from "../../library/grid-layout";

const defaultFromDate = () => {
  let today = new Date();
  let thisYear = today.getUTCFullYear();
  let thisMonth = today.getMonth();
  let thisDay = today.getDate();
  return new Date(
    thisYear,
    thisMonth,
    thisDay,
    today.getHours(),
    today.getMinutes()
  );
};

const PublicHolidayCreateFormModal = ({ onCreate, show, onToggle }) => {
  const [date, setDate] = useState(defaultFromDate());
  const { register, handleSubmit, errors } = useForm({});

  const onFormSubmit = (data) => {
    let createdData = { ...data };
    createdData.date = DateOnlyString(date);
    onCreate(createdData);
  };

  return (
    <FormModal
      onSubmit={handleSubmit(onFormSubmit)}
      show={show}
      toggle={onToggle()}
      formName="Add New Holiday"
    >
      <div key={FormModal.ControlContainer} style={{ display: "block" }}>
        <Form.Row>
          <Col>
            <Form.Group>
              <Form.Label className="d-block">Holiday Date</Form.Label>
              <DatePicker date={date} onChange={(d) => setDate(d)} />
            </Form.Group>
          </Col>
          <Col>
            <Input
              label="Name"
              style={{ width: "100%" }}
              placeholder="Enter Name"
              errors={errors}
              ref={register({ required: "Holiday name is required" })}
              name="name"
            />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col className="col-sm-12">
            <Input
              label="Description"
              style={{ width: "100%" }}
              placeholder="Enter Description"
              ref={register}
              size="sm"
              name="description"
            />
          </Col>
        </Form.Row>
      </div>
      <div key={FormModal.ActionContainer}>
        <CefaloButton
          size="sm"
          variant="light"
          onClick={onToggle()}
          type="button"
        >
          Close
        </CefaloButton>
        <CefaloButton size="sm" variant="primary" type="submit">
          Save
        </CefaloButton>
      </div>
    </FormModal>
  );
};
PublicHolidayCreateFormModal.prototype = {
  onCreate: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
};

class CreateFormModal extends React.Component {
  static propTypes = {
    onCreateCallback: PropTypes.func.isRequired,
  };
  state = {
    show: false,
    isLoading: false,
  };

  toggle = (e) => () => {
    this.setState({ show: !this.state.show });
  };

  onCreateAction = (data) => {
    this.setState({ isLoading: true });
    PublicHolidayApi.create(data)
      .then((res) => {
        this.setState({
          isLoading: false,
          show: false,
        });
        toaster.success("Holiday Saved Successfully");
        this.props.onCreateCallback(res.data);
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        defaultErrorResponseParser(err, "Failed To Save Holiday");
      });
  };
  render() {
    return (
      <div className="d-inline-block">
        <Loading show={this.state.isLoading} />
        <CefaloButton size="sm" variant="primary" onClick={this.toggle()}>
          Add New Holiday
        </CefaloButton>
        <PublicHolidayCreateFormModal
          onCreate={this.onCreateAction}
          show={this.state.show}
          onToggle={this.toggle}
        />
      </div>
    );
  }
}
export default CreateFormModal;
