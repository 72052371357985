import React from "react";
import LightLayout from "../../shared-components/layout/LightLayout";
import { Helmet } from "react-helmet";
import TeamApi from "../../apis/endpoints/TeamApi";
import UserApi from "../../apis/endpoints/UserApi";
import AllMemberView from "./MemberView";
import { Row, Col, Container } from "react-bootstrap";
import styles from "./styles/MemberdirectoryContainer.module.css";
import { defaultErrorResponseParser } from "../../helpers/ErrorParser";
import { Loading } from "../../shared-components/notification/Notification";
import BloodGroupDropdown from "../../shared-components/dropdowns/blood-group.dropdown";
import SkillTagInput from "../../shared-components/dropdowns/skill-tag-input/skill-tag.input";
import CheckBox from "../../library/form/CheckBox";
import { Form } from "../../library/form";
import { MultiSelect } from "react-multi-select-component";
class MemberDirectory extends React.Component {
  state = {
    profiles: [],
    filteredProfile: [],
    teams: [],
    isLoading: false,
    teamOptions: [],
    designationOptions: [],
    experienceFromYear: 0,
    experienceToYear: 100,
    jobTenureFromYear: 0,
    jobTenureToYear: 100,
    selectedName: "",
    selectedSkills: [],
    selectedExperienceRange: [],
    selectedJobTenureRange: [],
    selectedInstituteName: "",
    selectedTeams: [],
    selectedTeamNames: "",
    selectedDesignations: [],
    selectedDesignationNames: "",
    selectedBloodGroup: "",
    isAdvancedFilteringEnabled: false,
    hasExperienceFromValueChanged: false,
    hasExperienceToValueChanged: false,
    hasJobTenureFromValueChanged: false,
    hasJobTenureToValueChanged: false,
  };

  overrideStringsForTeam = {
    allItemsAreSelected: "All",
    clearSearch: "Clear Search",
    clearSelected: "Clear Selected",
    noOptions: "No options",
    search: "Search",
    selectAll: "All",
    selectAllFiltered: "Select All (Filtered)",
    selectSomeItems: "Team",
    create: "Create",
  };

  overrideStringsForDesignation = {
    allItemsAreSelected: "All",
    clearSearch: "Clear Search",
    clearSelected: "Clear Selected",
    noOptions: "No options",
    search: "Search",
    selectAll: "All",
    selectAllFiltered: "Select All (Filtered)",
    selectSomeItems: "Designation",
    create: "Create",
  };
  componentDidMount = () => {
    this.loadTeamData();
    this.loadProfileData();
  };

  loadTeamData = () => {
    TeamApi.all()
      .then((res) => {
        this.manageLoadedTeams(res.data);
      })
      .catch((err) => {
        defaultErrorResponseParser(err, "Failed to load Team information");
      });
  };

  manageLoadedTeams = (teams) => {
    let teamOptions = [];
    teams.forEach((team) => {
      if (team.name !== "Guest" && team.isActive) {
        const teamOption = { value: team.id, label: team.name };
        teamOptions.push(teamOption);
      }
    });

    this.setState({
      teams: teams,
      teamOptions: teamOptions,
    });
  };

  loadProfileData = () => {
    this.setState({ isLoading: true });
    UserApi.getActiveUsersProfiles()
      .then((res) => {
        this.manageLoadedProfiles(res.data);
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        defaultErrorResponseParser(err, "Failed to load Team information");
      });
  };

  manageLoadedProfiles = (profiles) => {
    const uniqueDesignations = [
      ...new Set(
        profiles
          .filter((item) => item.designation.trim().length > 0)
          .map((item) => item.designation.trim())
      ),
    ].sort();
    const designationOptions = uniqueDesignations.map((designation, index) => ({
      label: designation,
      value: index,
    }));

    this.setState({
      isLoading: false,
      profiles: profiles,
      filteredProfile: profiles,
      designationOptions: designationOptions,
    });
  };

  filterBloodGroups = (items) => {
    let filteredItems = [];
    if (items.length > 0) {
      filteredItems = items.filter(
        (item) =>
          item.bloodGroup && item.bloodGroup === this.state.selectedBloodGroup
      );
    } else {
      filteredItems = this.state.profiles.filter(
        (item) =>
          item.bloodGroup && item.bloodGroup === this.state.selectedBloodGroup
      );
    }
    return filteredItems;
  };

  filterProfilesByExperienceRange = (
    filteredItems,
    selectedRange,
    experienceKey
  ) => {
    const subFilteredItems =
      filteredItems.length > 0 ? filteredItems : this.state.profiles;
    const selectedExperienceRange =
      selectedRange.length > 0 ? selectedRange : [0, 25];

    const filteredProfiles = subFilteredItems.filter((profile) => {
      if (profile[experienceKey]) {
        const yearsOfExperience = profile[experienceKey].includes("year")
          ? parseInt(profile[experienceKey].split("years")[0].trim())
          : 0;
        return (
          yearsOfExperience >= selectedExperienceRange[0] &&
          yearsOfExperience <= selectedExperienceRange[1]
        );
      }
      return false;
    });

    return filteredProfiles;
  };

  filterProfilesByName = (filteredItems, selectedName) => {
    let subFilteredItems = [];
    if (filteredItems.length > 0) {
      subFilteredItems = filteredItems.filter((item) =>
        item.name.toLowerCase().includes(selectedName.toLowerCase())
      );
    } else {
      subFilteredItems = this.state.profiles.filter((profile) =>
        profile.name.toLowerCase().includes(selectedName.toLowerCase())
      );
    }

    return subFilteredItems;
  };

  filterProfilesByInstitute = (filteredItems, selectedInstituteName) => {
    let subFilteredItems = [];
    if (filteredItems.length > 0) {
      subFilteredItems = filteredItems.filter((item) =>
        item.educationalInstitutionNames
          .join(", ")
          .toLowerCase()
          .includes(selectedInstituteName.toLowerCase())
      );
    } else {
      subFilteredItems = this.state.profiles.filter((profile) =>
        profile.educationalInstitutionNames
          .join(", ")
          .toLowerCase()
          .includes(selectedInstituteName.toLowerCase())
      );
    }

    return subFilteredItems;
  };

  filterProfilesBySkills = (filteredItems, selectedSkills) => {
    let subFilteredItems = [];
    let matchFound = true;

    if (filteredItems.length > 0) {
      subFilteredItems = filteredItems.filter(
        (item) => item.skills && item.skills.length > 0
      );
    } else {
      subFilteredItems = this.state.profiles.filter(
        (item) => item.skills && item.skills.length > 0
      );
    }

    const filteredProfiles = subFilteredItems.filter((profile) => {
      matchFound = true;
      selectedSkills.forEach((skill) => {
        const item = profile.skills.find((x) => x.name === skill.name);
        if (!item) {
          matchFound = false;
        }
      });

      return matchFound;
    });

    return filteredProfiles;
  };

  filterProfilesByTeam = (filteredItems, selectedTeamNames) => {
    let subFilteredItems = [];

    if (filteredItems.length > 0) {
      subFilteredItems = filteredItems.filter((profile) =>
        selectedTeamNames.some((team) => profile.teamNames.includes(team))
      );
    } else {
      subFilteredItems = this.state.profiles.filter((profile) =>
        selectedTeamNames.some((team) => profile.teamNames.includes(team))
      );
    }

    return subFilteredItems;
  };

  filterDesignations = (filteredItems, selectedDesignationNames) => {
    let subFilteredItems = [];

    if (filteredItems.length > 0) {
      subFilteredItems = filteredItems.filter((profile) =>
        selectedDesignationNames.includes(profile.designation.trim())
      );
    } else {
      subFilteredItems = this.state.profiles.filter((profile) =>
        selectedDesignationNames.includes(profile.designation.trim())
      );
    }

    return subFilteredItems;
  };

  filterProfiles = () => {
    var filteredItems = [];

    if (
      !this.state.selectedName &&
      !this.state.selectedTeamNames &&
      !this.state.selectedBloodGroup &&
      !this.state.selectedDesignationNames &&
      !this.state.selectedInstituteName
    ) {
      const profiles = this.state.profiles;
      this.setState({
        filteredProfile: profiles,
      });
    }

    if (this.state.selectedTeamNames) {
      filteredItems = this.filterProfilesByTeam(
        filteredItems,
        this.state.selectedTeamNames
      );

      this.setState({
        filteredProfile: filteredItems,
      });
    }

    if (this.state.selectedBloodGroup) {
      filteredItems = this.filterBloodGroups(filteredItems);

      this.setState({
        filteredProfile: filteredItems,
      });

      if (filteredItems.length === 0) return;
    }

    if (this.state.selectedDesignationNames) {
      filteredItems = this.filterDesignations(
        filteredItems,
        this.state.selectedDesignationNames
      );

      this.setState({
        filteredProfile: filteredItems,
      });

      if (filteredItems.length === 0) return;
    }

    if (this.state.selectedSkills.length > 0) {
      filteredItems = this.filterProfilesBySkills(
        filteredItems,
        this.state.selectedSkills
      );

      this.setState({
        filteredProfile: filteredItems,
      });

      if (filteredItems.length === 0) return;
    }

    if (this.state.selectedJobTenureRange.length > 0) {
      filteredItems = this.filterProfilesByExperienceRange(
        filteredItems,
        this.state.selectedJobTenureRange,
        "yearsOfExperienceAtCefalo"
      );
      this.setState({ filteredProfile: filteredItems });
      if (filteredItems.length === 0) return;
    }

    if (this.state.selectedExperienceRange.length > 0) {
      filteredItems = this.filterProfilesByExperienceRange(
        filteredItems,
        this.state.selectedExperienceRange,
        "totalWorkingExperience"
      );
      this.setState({ filteredProfile: filteredItems });
      if (filteredItems.length === 0) return;
    }

    if (this.state.selectedName) {
      filteredItems = this.filterProfilesByName(
        filteredItems,
        this.state.selectedName
      );
      this.setState({
        filteredProfile: filteredItems,
      });
    }

    if (this.state.selectedInstituteName) {
      filteredItems = this.filterProfilesByInstitute(
        filteredItems,
        this.state.selectedInstituteName
      );
      this.setState({
        filteredProfile: filteredItems,
      });
    }
  };

  handleInputChange = (event) => {
    this.setState(
      {
        selectedName: event.target.value,
      },
      () => {
        this.filterProfiles();
      }
    );
  };

  handleEducationalInstituteChange = (event) => {
    this.setState(
      {
        selectedInstituteName: event.target.value,
      },
      () => {
        this.filterProfiles();
      }
    );
  };

  handleSelectedSkillChange = (selectedSkills) => {
    this.setState(
      {
        selectedSkills: selectedSkills,
      },
      () => {
        this.filterProfiles();
      }
    );
  };

  handleExperienceFromYearChange = (event) => {
    const newExperienceFromYear = parseInt(event.target.value);
    this.setState(
      {
        experienceFromYear: newExperienceFromYear,
        hasExperienceFromValueChanged: true,
      },
      () => {
        this.updateSelectedExperienceRange();
      }
    );
  };

  handleExperienceToYearChange = (event) => {
    const newExperienceToYear = parseInt(event.target.value);
    this.setState(
      {
        experienceToYear: newExperienceToYear,
        hasExperienceToValueChanged: true,
      },
      () => {
        this.updateSelectedExperienceRange();
      }
    );
  };

  updateSelectedExperienceRange = () => {
    const { experienceFromYear, experienceToYear } = this.state;

    let selectedRange = [];

    if (isNaN(experienceFromYear) && isNaN(experienceToYear)) {
      selectedRange = [0, 100];
    } else if (isNaN(experienceFromYear)) {
      selectedRange = [0, experienceToYear];
    } else if (isNaN(experienceToYear)) {
      selectedRange = [experienceFromYear, 100];
    } else {
      selectedRange = [experienceFromYear, experienceToYear];
    }

    this.setState({ selectedExperienceRange: selectedRange }, () => {
      this.filterProfiles();
    });
  };

  handleJobTenureFromYearChange = (event) => {
    const newExperienceFromYear = parseInt(event.target.value);
    this.setState(
      {
        jobTenureFromYear: newExperienceFromYear,
        hasJobTenureFromValueChanged: true,
      },
      () => {
        this.updateJobTenureRange();
      }
    );
  };

  handleJobTenureToYearChange = (event) => {
    const newExperienceToYear = parseInt(event.target.value);
    this.setState(
      {
        jobTenureToYear: newExperienceToYear,
        hasJobTenureToValueChanged: true,
      },
      () => {
        this.updateJobTenureRange();
      }
    );
  };

  updateJobTenureRange = () => {
    const { jobTenureFromYear, jobTenureToYear } = this.state;

    let selectedRange = [];

    if (isNaN(jobTenureFromYear) && isNaN(jobTenureToYear)) {
      selectedRange = [0, 100];
    } else if (isNaN(jobTenureFromYear)) {
      selectedRange = [0, jobTenureToYear];
    } else if (isNaN(jobTenureToYear)) {
      selectedRange = [jobTenureFromYear, 100];
    } else {
      selectedRange = [jobTenureFromYear, jobTenureToYear];
    }

    this.setState({ selectedJobTenureRange: selectedRange }, () => {
      this.filterProfiles();
    });
  };

  handleTeamSelect = (value) => {
    if (value === null) {
      this.setState({
        selectedTeams: [],
      })
    } else {
      const selectedTeamNames = value.map((option) => option.label);

      this.setState(
        {
          selectedTeams: value,
          selectedTeamNames: selectedTeamNames,
        },
        () => {
          this.filterProfiles();
        }
      );
    }
  };

  handleDesignationChange = (value) => {
    if (value === null) {
      this.setState({
        selectedDesignations: [],
      })
    } else {
      const selectedDesignationNames = value.map((option) => option.label);

      this.setState(
        {
          selectedDesignations: value,
          selectedDesignationNames: selectedDesignationNames,
        },
        () => {
          this.filterProfiles();
        }
      );
    }
  };

  handleBloodGroupChange = (selectedValue) => {
    this.setState(
      {
        selectedBloodGroup: selectedValue !== "All" ? selectedValue : null,
      },
      () => {
        this.filterProfiles();
      }
    );
  };

  handleAdvancedFiltering = () => {
    this.setState(
      (prevState) => ({
        isAdvancedFilteringEnabled: !prevState.isAdvancedFilteringEnabled,
        selectedBloodGroup:
          !prevState.isAdvancedFilteringEnabled === false
            ? null
            : prevState.selectedBloodGroup,
        selectedDesignationNames:
          !prevState.isAdvancedFilteringEnabled === false
            ? null
            : prevState.selectedDesignationNames,
        selectedInstituteName:
          !prevState.isAdvancedFilteringEnabled === false
            ? null
            : prevState.selectedInstituteName,
        selectedJobTenureRange:
          !prevState.isAdvancedFilteringEnabled === false
            ? []
            : prevState.selectedJobTenureRange,
        jobTenureSliderValue:
          !prevState.isAdvancedFilteringEnabled === false
            ? [0, 25]
            : prevState.jobTenureSliderValue,
      }),
      () => {
        this.filterProfiles();
      }
    );
  };

  render() {
    return (
      <LightLayout>
        <Helmet>
          <title>Team Members- Cefalo</title>
        </Helmet>
        <Container fluid>
          <Loading show={this.state.isLoading} />
          <Row>
            <Col className="pt-2 m-auto">
              <div className="row no-gutters">
                <div className="col-sm-2 pr-4">
                  <div className={styles.inputLabel}>Name:</div>
                  <div className={styles.inputField}>
                    <input
                      type="text"
                      value={this.state.selectedName}
                      className="form-control form-control-sm"
                      placeholder="Name"
                      onChange={this.handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-sm-4 pr-4">
                  <div className={styles.inputLabel}>Skills:</div>
                  <div className={styles.inputField}>
                    <SkillTagInput
                      numberOfAllowedSkills={3}
                      onTagChange={this.handleSelectedSkillChange}
                      className="form-control form-control-sm"
                    ></SkillTagInput>
                  </div>
                </div>
                <div className="col-sm-2 pr-4">
                  <div className={styles.inputLabel}>Experience (Years):</div>
                  <div className="d-flex">
                    <Form.Group className="d-flex align-items-center mr-1">
                      <Form.Control
                        type="number"
                        name="experienceFromYear"
                        step={1}
                        min={0}
                        placeholder="From"
                        value={
                          this.state.hasExperienceFromValueChanged
                            ? this.state.experienceFromYear
                            : ""
                        }
                        onChange={this.handleExperienceFromYearChange}
                        size="sm"
                      />
                    </Form.Group>
                    <Form.Group className="d-flex align-items-center">
                      <span className="mr-1 align-self-center">-</span>
                    </Form.Group>
                    <Form.Group className="d-flex align-items-center">
                      <Form.Control
                        type="number"
                        name="experienceToYear"
                        placeholder="To"
                        step={1}
                        min={0}
                        value={
                          this.state.hasExperienceToValueChanged
                            ? this.state.experienceToYear
                            : ""
                        }
                        onChange={this.handleExperienceToYearChange}
                        size="sm"
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="col-sm-2 pr-4">
                  <div className={styles.inputLabel}>Team:</div>

                  <MultiSelect
                    options={this.state.teamOptions}
                    value={this.state.selectedTeams}
                    onChange={this.handleTeamSelect.bind(this)}
                    className={styles.multiSelectFilter}
                    labelledBy="All"
                    overrideStrings={this.overrideStringsForTeam}
                  />
                </div>
                <div className={`col-sm-2 pr-2 ${styles.checkBox}`}>
                  <CheckBox
                    label="Advanced Filtering"
                    onChange={this.handleAdvancedFiltering}
                    style={{ zIndex: 0 }}
                  />
                </div>
              </div>
            </Col>
          </Row>
          {this.state.isAdvancedFilteringEnabled && (
            <Row>
              <Col className="pt-2 m-auto">
                <div className="row no-gutters">
                  <div className="col-sm-2 pr-4">
                    <div className={styles.inputLabel}>Designation:</div>
                    <MultiSelect
                      options={this.state.designationOptions}
                      value={this.state.selectedDesignations}
                      onChange={this.handleDesignationChange.bind(this)}
                      className={styles.multiSelectFilter}
                      labelledBy="All"
                      overrideStrings={this.overrideStringsForDesignation}
                    />
                  </div>
                  <div className="col-sm-4 pr-4">
                    <div className={styles.inputLabel}>
                      Educational Institue:
                    </div>
                    <input
                      type="text"
                      value={this.state.selectedInstituteName}
                      className={`${styles.inputField} form-control form-control-sm`}
                      placeholder="Educational Insitute Name"
                      onChange={this.handleEducationalInstituteChange}
                    />
                  </div>
                  <div className="col-sm-2 pr-4">
                    <div className={styles.inputLabel}>Job Tenure (Years):</div>
                    <div className="d-flex">
                      <Form.Group className="d-flex align-items-center mr-1">
                        <Form.Control
                          type="number"
                          name="jobTenureFromYear"
                          placeholder="From"
                          step={1}
                          min={0}
                          value={
                            this.state.hasJobTenureFromValueChanged
                              ? this.state.jobTenureFromYear
                              : ""
                          }
                          onChange={this.handleJobTenureFromYearChange}
                          size="sm"
                        />
                      </Form.Group>
                      <Form.Group className="d-flex align-items-center">
                        <span className="mr-1 align-self-center">-</span>
                      </Form.Group>
                      <Form.Group className="d-flex align-items-center">
                        <Form.Control
                          type="number"
                          name="jobTenureToYear"
                          placeholder="To"
                          step={1}
                          min={0}
                          value={
                            this.state.hasJobTenureToValueChanged
                              ? this.state.jobTenureToYear
                              : ""
                          }
                          onChange={this.handleJobTenureToYearChange}
                          size="sm"
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="col-sm-2 pr-4">
                    <div className={styles.inputLabel}>Blood Group:</div>
                    <div className={styles.inputField}>
                      <BloodGroupDropdown
                        onSelectChange={this.handleBloodGroupChange}
                        shouldIncludeAll="true"
                      ></BloodGroupDropdown>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          )}
          <AllMemberView profiles={this.state.filteredProfile} />
        </Container>
      </LightLayout>
    );
  }
}

export default MemberDirectory;
