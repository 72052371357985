import React from "react";
import PropTypes from "prop-types";
import { FormModal } from "../../../library/modal";
import { Form, Input } from "../../../library/form";
import { Col } from "../../../library/grid-layout";
import { LeaveStatusValueDictionary } from "../Consts";
import { LeaveApplicationDetailDto } from "../../../models/LeaveApplication";
import { toaster } from "../../notification";
import { CefaloButton } from "../../../library/button";

const ApplicationPreview = ({
  application = new LeaveApplicationDetailDto(),
}) => {
  return (
    <div>
      <h5 className="text-center">
        <u>Application Details</u>
      </h5>
      <Form.Row>
        <Col>
          <Input
            label="Applicant"
            readOnly
            value={application.applicant.name}
          />
        </Col>
        <Col>
          <Input label="Team" readOnly value={application.applicant.teamName} />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Input
            label="From Date"
            readOnly
            value={application.fromDate.toLocaleDateString()}
          />
        </Col>
        <Col>
          <Input
            label="To Date"
            readOnly
            value={application.toDate.toLocaleDateString()}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Input
            label="Total Working Days"
            readOnly
            value={application.totalNumberOfWorkingDays}
          />
        </Col>
        <Col>
          <Input
            label="Leave Type"
            readOnly
            value={application.leaveTypeDisplayName}
          />
        </Col>
      </Form.Row>
      <Input
        label="Description"
        readOnly
        as="textarea"
        value={application.description}
      />
    </div>
  );
};
ApplicationPreview.prototype = {
  application: PropTypes.objectOf(LeaveApplicationDetailDto).isRequired,
};

const RespondApproveRejectCancel = ({ onRespond }) => (
  <>
    <CefaloButton
      size="sm"
      variant="success"
      key={1}
      onClick={(e) => {
        onRespond(LeaveStatusValueDictionary.Approved);
      }}
    >
      Approve
    </CefaloButton>
    <CefaloButton
      size="sm"
      variant="danger"
      key={2}
      onClick={(e) => {
        onRespond(LeaveStatusValueDictionary.Rejected);
      }}
    >
      Reject
    </CefaloButton>
    <CefaloButton
      size="sm"
      variant="primary"
      key={3}
      onClick={(e) => {
        onRespond(LeaveStatusValueDictionary.Canceled);
      }}
    >
      Cancel
    </CefaloButton>
  </>
);
RespondApproveRejectCancel.prototype = {
  onRespond: PropTypes.func.isRequired,
};

const RespondCancelRequestApproveReject = ({ onRespond }) => (
  <>
    <CefaloButton
      size="sm"
      variant="success"
      key={1}
      onClick={(e) => {
        onRespond(LeaveStatusValueDictionary.CancelRequestApproved);
      }}
    >
      Approve Cancel Request
    </CefaloButton>
    <CefaloButton
      size="sm"
      variant="danger"
      key={2}
      onClick={(e) => {
        onRespond(LeaveStatusValueDictionary.CancelRequestRejected);
      }}
    >
      Reject
    </CefaloButton>
  </>
);
RespondCancelRequestApproveReject.prototype = {
  onRespond: PropTypes.func.isRequired,
};

const AvilableResponds = ({ status, onRespond }) => {
  if (LeaveStatusValueDictionary.Requested === status) {
    return <RespondApproveRejectCancel onRespond={onRespond} />;
  } else {
    return <RespondCancelRequestApproveReject onRespond={onRespond} />;
  }
};
AvilableResponds.prototype = {
  status: PropTypes.number.isRequired,
  onRespond: PropTypes.func.isRequired,
};

const ApplicationRespondFormModal = ({
  show,
  onToggle,
  application = new LeaveApplicationDetailDto(),
  onRespond,
}) => {
  const [comment, setComment] = React.useState("");

  const onRespondSelect = (respondType) => {
    if (
      (respondType === LeaveStatusValueDictionary.Rejected ||
        respondType === LeaveStatusValueDictionary.CancelRequestRejected) &&
      comment === ""
    ) {
      toaster.error("Supervisor's Comment is Mandatory for Rejection");
      return;
    } else {
      let data = {
        id: application.id,
        status: respondType,
        message: comment,
      };
      onRespond(data);
    }
  };

  return (
    <FormModal
      show={show}
      toggle={onToggle()}
      onSubmit={(e) => e.preventDefault()}
      formName="Edit Leave Application"
    >
      <div key={FormModal.ControlContainer}>
        <Input
          label="Supervisor's Comments"
          onChange={(e) => {
            setComment(e.target.value);
          }}
          placeholder="Supervisor's Comments..."
          as="textarea"
        />

        <ApplicationPreview application={application} />
      </div>
      <div key={FormModal.ActionContainer}>
        <div className="float-right">
          <AvilableResponds
            status={application.status}
            toDate={application.toDate}
            onRespond={onRespondSelect}
          />
          <CefaloButton size="sm" variant="light" onClick={onToggle()}>
            Close
          </CefaloButton>
        </div>
      </div>
    </FormModal>
  );
};
ApplicationRespondFormModal.prototype = {
  show: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
  application: PropTypes.objectOf(LeaveApplicationDetailDto).isRequired,
  onRespond: PropTypes.func.isRequired,
};

export default ApplicationRespondFormModal;
