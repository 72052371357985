import React from "react";
import { toast, Zoom } from "react-toastify";
import { CefaloButton } from "../../library/button";
import { Modal, Spinner } from "react-bootstrap";
import "./styles/loadingStyles.css";

export const toastConfigurations = {
  autoClose: 2000,
  hideProgressBar: true,
  transition: Zoom,
};

export const toaster = {
  success: (content) => toast.success(content, toastConfigurations),

  warn: (content) => toast.warn(content, toastConfigurations),

  error: (content) => toast.error(content, toastConfigurations),

  info: (content) => toast.info(content, toastConfigurations),
};

export const Loading = ({ show }) => (
  <Modal show={show} className="spinningLoadingModal" centered>
    <Modal.Body>
      <div>
        <div>
          <Spinner className="topSpinner" animation="grow" />
        </div>
        <div>
          <Spinner className="middleSpinner" animation="grow" />
        </div>
        <div>
          <Spinner className="bottomSpinner" animation="grow" />
        </div>
      </div>
    </Modal.Body>
  </Modal>
);

export const ConfimationPopup = ({ show, text, onConfirmCallback }) => {
  return (
    <Modal show={show} centered className="z-index-5">
      <Modal.Body className="flex-center">
        <p align="left" style={{ fontWeight: 400 }}>
          {text}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <CefaloButton
          size="sm"
          variant="danger"
          onClick={(e) => {
            onConfirmCallback(false);
          }}
          type="button"
        >
          No
        </CefaloButton>
        <CefaloButton
          size="sm"
          variant="success"
          type="submit"
          onClick={(e) => {
            onConfirmCallback(true);
          }}
        >
          Yes
        </CefaloButton>
      </Modal.Footer>
    </Modal>
  );
};
