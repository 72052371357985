export class TeamDto {
  constructor(obj) {
    this.id = obj.id;
    this.name = obj.name;
    this.teamEmail = obj.teamEmail;
    this.clientId = obj.clientId;
    this.clientEmail = obj.clientEmail;
    this.isActive = obj.isActive;
    this.lastModified = obj.lastModified;
    this.creatorId = obj.creatorId;
    this.lastModifiderName = obj.lastModifiderName;
    this.officeWeekDays = obj.officeWeekDays;
  }
}
