import React from "react";
import Layout from "../../shared-components/layout/Layout";
import { Helmet } from "react-helmet";
import {
	toaster,
	Loading,
} from "../../shared-components/notification/Notification";
import ManageExportApi from "../../apis/endpoints/ManageExportApi";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/saga-blue/theme.css";
import { CefaloButton } from "../../library/button/Index";
import CheckBox from "../../library/form/CheckBox";
import UserManager from "../../navigation/UserManager";
const ViewContainer = (props) => <div className="p-2">{props.children}</div>;

class ExportEmployeeInfoContainer extends React.Component {
	state = {
		isLoading: false,
		shouldRender: false,
		Month: new Date().getMonth(),
		isOpen1: false,
		sections: [],
	};
	componentDidMount = () => {
		let user = UserManager.getUser();
		if (!user.isAdminOrHr()) {
			window.location.replace(`/Report/Index/${user.id}`);
		} else {
			this.initializeSections();
		}
	};
	initializeSections = () => {
		let sections = [];
		let basicInfo = {
			Name: "Basic Info",
			value: false,
			child: [
				{ key: "Name", value: false },
				{ key: "Employee Id", value: false },
				{ key: "Status", value: false },
				{ key: "InActive Date", value: false },
				{ key: "Team Name", value: false },
				{ key: "Designation", value: false },
				{ key: "Joining Date", value: false },
				{ key: "Job Tenure", value: false },
				{ key: "Job Start Date", value: false },
				{ key: "Experience", value: false },
				{ key: "Email", value: false },
				{ key: "Phone", value: false },
				{ key: "Slack", value: false },
				{ key: "Skills", value: false },
			],
		};
		sections.push(basicInfo);
		let personalInfo = {
			Name: "Personal Info",
			value: false,
			child: [
				{ key: "Blood Group", value: false },
				{ key: "DOB", value: false },
				{ key: "Age (years)", value: false },
				{ key: "Marital Status", value: false },
				{ key: "NID", value: false },
				{ key: "Passport", value: false },
			],
		};
		sections.push(personalInfo);
		let educationInfo = {
			Name: "Educational Info",
			value: false,
			child: [
				{ key: "Highest Education", value: false },
				{ key: "Institute Name", value: false },
				{ key: "Batch", value: false },
				{ key: "Highest Education1", value: false },
				{ key: "Institute Name1", value: false },
				{ key: "Batch1", value: false },
				{ key: "HSC Year", value: false },
			],
		};
		sections.push(educationInfo);
		let contactInfo = {
			Name: "Contact Info",
			valueL: false,
			child: [
				{ key: "Personal Email", value: false },
				{ key: "Present Address", value: false },
				{ key: "Permanent Address", value: false },
				{ key: "Secondary Contact Name", value: false },
				{ key: "Secondary Contact Phone", value: false },
				{ key: "Secondary Contact Relation", value: false },
			],
		};
		sections.push(contactInfo);
		let teamhistoryInfo = {
			Name: "Team History Info",
			value: false,
			child: [
				{ key: "Team Joining Date", value: false },
				{ key: "Team Leaving Date", value: false },
			],
		};
		sections.push(teamhistoryInfo);
		this.setState({
			sections: sections,
		});
	};
	onParentSectionChecked = (e) => {
		let sections = [];
		this.state.sections.forEach((element) => {
			if (element.Name === e.currentTarget.name) {
				element.value = !element.value;
				element.child.forEach((c) => {
					c.value = element.value;
				});
			}
			sections.push(element);
		});
		this.setState({
			sections: sections,
		});
	};
	onChildChecked = (e) => {
		let sections = [];
		this.state.sections.forEach((element) => {
			element.child.forEach((c) => {
				if (c.key === e.currentTarget.name) {
					c.value = !c.value;
				}
			});
			sections.push(element);
		});
		this.setState({
			sections: sections,
		});
	};
	handleExport = () => {
		let filters = [];
		let teamData = false;
		this.state.sections.forEach((element) => {
			element.child.forEach((c) => {
				if (element.Name === "Team History Info" && c.value === true) {
					teamData = true;
				}
				if (c.value === true) {
					let key = c.key;
					if (key.includes("")) {
						key = key.replace(/\s+/g, "");
					}
					filters.push(key);
				}
			});
		});
		let filterString = filters.join(",");
		if (filterString) {
			this.setState({ isLoading: true });
			ManageExportApi.downloadEmployeeInformation(filterString, teamData)
				.then((res) => {
					this.setState({ isLoading: false });
					this.initializeSections();
					let fileName = res.headers["x-attachmentname"];
					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("Download", fileName);
					link.click();
				})
				.catch((err) => {
					this.setState({ isLoading: false });
					this.initializeSections();
					toaster.error("Failed To export data");
				});
		} else {
			toaster.error("Seletc data field to export");
		}
	};

	render() {
		return (
			<Layout>
				<Helmet>
					<title>Export Employee Info - Cefalo</title>
				</Helmet>
				<Loading show={this.state.isLoading} />
				<ViewContainer>
					<div className="d-flex justify-content-end">
						<div className="z-index-3 pt-1 pb-2">
							<div className=" d-inline-block pt-2 pr-3 pl-2">
								<CefaloButton
									block={true}
									variant="primary"
									size="sm"
									type="button"
									onClick={this.handleExport}
								>
									Export Info
								</CefaloButton>
							</div>
						</div>
					</div>
					<div className="container">
						<div className="row px-4 py-4">
							{this.state.sections.map((sec, index) => (
								<div className="col">
									<CheckBox
										id={sec.Name}
										label={sec.Name}
										name={sec.Name}
										checked={sec.value}
										onChange={(e) => {
											this.onParentSectionChecked(e);
										}}
									/>
									<hr />
									<div className="pt-2">
										{sec.child.map((c, index) => (
											<CheckBox
												id={c.key}
												label={c.key}
												name={c.key}
												checked={c.value}
												onChange={(e) => {
													this.onChildChecked(e);
												}}
											/>
										))}
									</div>
								</div>
							))}
						</div>
					</div>
				</ViewContainer>
			</Layout>
		);
	}
}
export default ExportEmployeeInfoContainer;
