import React from "react";
import PropTypes from "prop-types";
import { FormModal } from "../../../library/modal";
import { CefaloButton } from "../../../library/button";

const TeamDeleteModalForm = ({ onSubmit, show, onToggle, teamData }) => {
  const onFormSubmit = (e) => {
    e.preventDefault();
    onSubmit(e);
  };

  return (
    <FormModal
      onSubmit={onFormSubmit}
      show={show}
      toggle={onToggle()}
      formName="Delete Team"
    >
      <div key={FormModal.ControlContainer}>
        <p className="text-left">
          Are you sure to delete team: <b>{teamData.name}?</b>
        </p>
      </div>
      <div key={FormModal.ActionContainer}>
        <CefaloButton size="sm" variant="light" onClick={onToggle()}>
          No
        </CefaloButton>
        <CefaloButton size="sm" variant="primary" type="submit">
          Yes
        </CefaloButton>
      </div>
    </FormModal>
  );
};
TeamDeleteModalForm.prototype = {
  onSubmit: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default TeamDeleteModalForm;
