import React from "react";
import PropTypes from "prop-types";
import { toaster, Loading } from "../../../shared-components/notification/Notification";
import { FormModal } from "../../../library/modal";
import { defaultErrorResponseParser } from "../../../helpers/ErrorParser";
import { CefaloButton } from "../../../library/button";
import { NocStatusValueDictionary } from "../Const";
import NocApplicationApi from "../../../apis/endpoints/NocApplicationApi";

export default class NocCancelApplication extends React.Component {
  state = {
    isLoading: false,
    show: false,
  };

  toggle = (e) => () => {
    this.setState({ show: !this.state.show });
  };

  onCancel = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    
    let data = {
      id: this.props.application.id,
      status: NocStatusValueDictionary.CancelledByUser,
    };
    NocApplicationApi.updateStatus(data)
      .then((res) => {
        this.setState({ show: false, isLoading: false });
        toaster.success("NOC Application Canceled Successfully");
        this.props.onCancelCallback();
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        defaultErrorResponseParser(err, "Failed To Cancel Request");
      });
  };

  render() {
    return (
      <div className="d-inline-block">
        <Loading show={this.state.isLoading} />
        <FormModal
          onSubmit={this.onCancel}
          show={this.state.show}
          toggle={this.toggle()}
        >
          <div key={FormModal.ControlContainer}>
            Are you sure to cancel this NOC application?
          </div>

          <div key={FormModal.ActionContainer}>
            <CefaloButton
              size="sm"
              variant="danger"
              onClick={this.toggle()}
              type="button"
            >
              No
            </CefaloButton>
            <CefaloButton size="sm" variant="success" type="submit">
              Yes
            </CefaloButton>
          </div>
        </FormModal>
        <CefaloButton
          className="btn-font-12"
          size="sm"
          variant="danger"
          onClick={this.toggle()}
        >
          Cancel
        </CefaloButton>
      </div>
    );
  }
}

NocCancelApplication.propTypes = {
  application: PropTypes.object,
  onCancelCallback: PropTypes.func.isRequired,
};
