import React, { Component } from "react";
import officeTimingApi from "../../../apis/endpoints/OfficeTimingApi";
import WithCrudActionsOnArray from "../../../shared-components/hoc/crud/WithCrudActionsOnArray";
import OfficeTimingView from "./OfficeTimingView";
import { Loading } from "../../../shared-components/notification";

class OfficeTimingContainer extends Component {
  state = {
    isLoading: false,
  };
  componentDidMount() {
    const { setArrayStateCallback } = this.props;
    this.setState({ isLoading: true });
    officeTimingApi
      .get()
      .then((resp) => {
        this.setState({ isLoading: false });
        setArrayStateCallback(resp.data);
      })
      .catch((err) => this.setState({ isLoading: false }));
  }

  render() {
    const { crudArray: officeTimings } = this.props;
    return (
      <>
        <Loading show={this.state.isLoading} />
        <OfficeTimingView
          officeTimingCallbacks={this.props.arrayCrudCallbacks}
          officeTimings={officeTimings}
        />
      </>
    );
  }
}

export default WithCrudActionsOnArray(officeTimingApi)(OfficeTimingContainer);
