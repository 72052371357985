export class AvailableEmployeeDto {
    constructor(obj) {
      this.name = obj.name;
      this.shortName = obj.shortName;
      this.designation = obj.designation;
      this.totalWorkingExperience = obj.totalWorkingExperience;
      this.yearsOfExperienceAtCefalo = obj.yearsOfExperienceAtCefalo;
      this.skills = obj.skills;
      this.cvLink = obj.cvLink;
      this.availabilityDate = obj.availabilityDate;
      this.availabilityPercentage = obj.availabilityPercentage;
      this.lastTeamAssignment = obj.lastTeamAssignment
    }
  }
  