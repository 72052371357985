import React from 'react';
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { Form, Col } from "react-bootstrap";
import { FormModal } from "../../../library/modal/Index";
import { CefaloButton } from "../../../library/button/Index";
import { Input } from "../../../library/form/Index";
import { Select } from "../../../library/form/Index";
const SkillUpsertModalForm = ({
  onSubmit,
  show,
  onToggle,
  formName,
  defaultValues = {},
  parentSkillOptions,
}) => {

  const [parentSkillId, setparentSkillId] = React.useState(
    defaultValues.parentSkillId
  );
  function onSelectBoxChange(e) {
    if(isNaN(e.target.value)){
      setparentSkillId(null);
    } else setparentSkillId(e.target.value);
  };

  const { register, handleSubmit, errors } = useForm({
    defaultValues: defaultValues,
  });
  const defalultParent = {
          label: defaultValues.parentSkillName,
          value: defaultValues.parentSkillId,
  }
  const onFormSubmit = (data) => {
    if (defaultValues.id) {
      data["id"] = defaultValues.id;
    }
    if(parentSkillId != null){
      data["parentSkillId"] = parentSkillId;
    }
    onSubmit(data);
  };

  return (
    <FormModal
      onSubmit={handleSubmit(onFormSubmit)}
      show={show}
      toggle={onToggle()}
      formName={formName}
    >
      <div key={FormModal.ControlContainer}>
        <Form.Row>
          <Col>
            <Input
              ref={register({ required: "Skill name is required" })}
              placeholder="Enter Skill Name"
              name="name"
              label="Skill Name"
              errors={errors}
            />
          </Col>
          <Col sm>
            <Select
              label="Parent Skill"
              name="applicationType"
              containerClassName="pr-lg-3 pl-lg-2"
              defaultValue = {defalultParent.value}
              options={parentSkillOptions}
              onChange={onSelectBoxChange}
            />
          </Col>
        </Form.Row>
      </div>
      <div key={FormModal.ActionContainer}>
        <CefaloButton size="sm" variant="light" onClick={onToggle()}>
          Close
        </CefaloButton>
        <CefaloButton size="sm" variant="primary" type="submit">
          Save
        </CefaloButton>
      </div>
    </FormModal>
  );
};
SkillUpsertModalForm.prototype = {
  onCreate: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default SkillUpsertModalForm;
