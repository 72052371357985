import React, { useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import PublicHolidayApi from "../../apis/endpoints/PublicHolidayApi";
import { Loading } from "../../shared-components/notification";
import { FormModal } from "../../library/modal";
import { Form, DatePicker, Input } from "../../library/form";
import { Col } from "../../library/grid-layout";
import { showSuccessMessages } from "../../shared-components/response-message/ResponseMessage";
import { DateOnlyString } from "../../helpers/Builders";
import { defaultErrorResponseParser } from "../../helpers/ErrorParser";
import { CefaloButton } from "../../library/button";

const defaultFromDate = (props) => {
  let today = new Date(props);
  let thisYear = today.getUTCFullYear();
  let thisMonth = today.getMonth();
  let thisDay = today.getDate();
  return new Date(
    thisYear,
    thisMonth,
    thisDay,
    today.getHours(),
    today.getMinutes()
  );
};

const PublicHolidayUpdateFormModal = ({
  publicHoliday,
  onUpdate,
  show,
  onToggle,
}) => {
  const [date, setDate] = useState(defaultFromDate(publicHoliday.date));
  const { register, handleSubmit, errors, reset } = useForm({
    defaultValues: {
      date: publicHoliday.date,
      name: publicHoliday.name,
      description: publicHoliday.description,
    },
  });

  const onFormSubmit = (data) => {
    reset(data);
    let updatedData = { ...data };
    updatedData.date = DateOnlyString(date);
    updatedData.id = publicHoliday.id;
    onUpdate(updatedData);
  };

  return (
    <FormModal
      onSubmit={handleSubmit(onFormSubmit)}
      show={show}
      toggle={onToggle()}
      formName="Edit Holiday"
    >
      <div key={FormModal.ControlContainer}>
        <Form.Row>
          <Col md>
            <Form.Group>
              <Form.Label className="d-block">Holiday Date</Form.Label>
              <DatePicker date={date} onChange={setDate} />
            </Form.Group>
          </Col>
          <Col md>
            <Input
              label="Name"
              ref={register({ required: "Holiday name is required" })}
              defaultValue={publicHoliday.name}
              name="name"
              errors={errors}
            />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col sm>
            <Input
              label="Description"
              errors={errors}
              size="sm"
              defaultValue={publicHoliday.description}
              ref={register}
              name="description"
            />
          </Col>
        </Form.Row>
      </div>
      <div key={FormModal.ActionContainer}>
        <CefaloButton
          size="sm"
          variant="light"
          onClick={onToggle()}
          type="button"
        >
          Close
        </CefaloButton>
        <CefaloButton size="sm" variant="primary" type="submit">
          Save
        </CefaloButton>
      </div>
    </FormModal>
  );
};
PublicHolidayUpdateFormModal.prototype = {
  publicHoliday: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
};

class UpdateFormModal extends React.Component {
  static propTypes = {
    publicHoliday: PropTypes.object.isRequired,
    onUpdateCallback: PropTypes.func.isRequired,
  };
  state = {
    show: false,
    isLoading: false,
  };

  toggle = (e) => () => {
    this.setState({ show: !this.state.show });
  };

  onUpdateAction = (data) => {
    this.setState({ isLoading: true });
    PublicHolidayApi.update(data)
      .then((res) => {
        this.setState({
          isLoading: false,
          show: false,
        });
        showSuccessMessages("Holiday Saved Successfully");
        this.props.onUpdateCallback(res.data);
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        defaultErrorResponseParser(err, "Failed To Update Holiday");
      });
  };
  render() {
    return (
      <div className="d-inline-block">
        <Loading show={this.state.isLoading} />
        <CefaloButton
          className="btn-font-12"
          size="sm"
          variant="primary"
          onClick={this.toggle()}
        >
          Edit
        </CefaloButton>
        <PublicHolidayUpdateFormModal
          publicHoliday={this.props.publicHoliday}
          onUpdate={this.onUpdateAction}
          show={this.state.show}
          onToggle={this.toggle}
        />
      </div>
    );
  }
}
export default UpdateFormModal;
