import { AuthenticatedAxios } from "../settings/Axios";
const BaseEndPoint = "TotalWorkingDaysAndMonth";
// let config = {
//   headers: {
//     "Content-Type": "application/json",
//   },
// };
export default {
  getCount: (fromDate, toDate, halfDay = false) => {
    let endpoint = `${BaseEndPoint}/${fromDate}/${toDate}`;
    if (halfDay) {
      endpoint += "?includeHalfDay=true";
    }
    return AuthenticatedAxios.get(endpoint, null, null);
  },

  getToDate: (fromDate, totalWorkingDays) => {
    let endpoint = `${BaseEndPoint}/${fromDate}`;
    endpoint += `?totalWorkingDays=${totalWorkingDays}`;
    return AuthenticatedAxios.get(endpoint, null, null);
  },

  getWorkFromHomeDaysCount: (fromDate, toDate, userId) => {
    let endpoint = `${BaseEndPoint}/WorkFromHome/${fromDate}/${toDate}/${userId}`;
    return AuthenticatedAxios.get(endpoint, null, null);
  },
};
