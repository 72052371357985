import React from "react";
import PropTypes from "prop-types";
import CefaloUtility from "../../../helpers/CefaloUtility";
import ApplicationCreateFormModal from "../forms/CreateForm";
import { Loading, toaster } from "../../notification";
import { defaultErrorResponseParser } from "../../../helpers/ErrorParser";
import { CefaloButton } from "../../../library/button";
import RemoteWorkApi from "../../../apis/endpoints/RemoteWorkApi";

export default class ApplicationCreateAction extends React.Component {
  static propTypes = {
    userId: PropTypes.number.isRequired,
    onCreateCallback: PropTypes.func.isRequired,
  };

  state = {
    show: false,
    fromDate: new Date(),
    toDate: new Date(),
    totalWorkingDays: 0,
    isLoading: false,
    remoteWorkApplicationTypes: this.props.remoteWorkApplicationTypes,
  };

  toggle = (e) => () => {
    this.setState({ show: !this.state.show });
    this.onApplicationPeriodSelect(new Date(), new Date(), false);
  };

  componentDidMount() {
    this.onApplicationPeriodSelect(this.state.fromDate, this.state.toDate, false);
  }

  onApplicationPeriodSelect = (fromDate, toDate, isWorkFromHome) => {
    this.setState({
      fromDate: fromDate,
      toDate: toDate,
    });
    if (isWorkFromHome) {
      CefaloUtility.getWorkfromHomeApplicationDaysCount(
        fromDate,
        toDate,
        this.props.userId,
        (days) => {
          this.setState({ totalWorkingDays: days });
        }
      );
    } else {
      CefaloUtility.getWorkingDaysCount(fromDate, toDate, false, (days) => {
        this.setState({ totalWorkingDays: days });
      });
    }
  };

  onCreateAction = (applicationData) => {
    applicationData["userId"] = this.props.userId;

    this.setState({ isLoading: true });

    return RemoteWorkApi.create(applicationData)
      .then((res) => {
        toaster.success("Application Submitted Successfully");

        this.props.onCreateCallback(res.data);
        this.setState({
          isLoading: false,
          show: false,
        });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        defaultErrorResponseParser(err, "Failed To Submit Application");
        throw err;
      });
  };

  render() {
    return (
      <div className="d-inline-block" style={{ marginLeft: "auto" }}>
        <Loading show={this.state.isLoading} />
        <CefaloButton
          size="sm"
          variant="primary"
          className="mt-auto filter_panel_btn"
          onClick={this.toggle()}
        >
          New Application
        </CefaloButton>
        <ApplicationCreateFormModal
          show={this.state.show}
          onCreate={this.onCreateAction}
          onToggle={this.toggle}
          toDate={this.state.toDate}
          fromDate={this.state.fromDate}
          totalWorkingDays={this.state.totalWorkingDays}
          periodSelectCallBack={this.onApplicationPeriodSelect}
          remoteWorkApplicationTypes={this.state.remoteWorkApplicationTypes}
        />
      </div>
    );
  }
}
