import React, { Component } from "react";
import extraWorkApi from "../../apis/endpoints/ExtraWorkApplicationApi";
import ExtraWorkApplicationView from "./ExtraWorkApplicationView";
import { Helmet } from "react-helmet";
import UserManager from "../../navigation/UserManager";
import PropTypes from "prop-types";
import {
  getCurrentYearStartDate,
  getCurrentYearEndDate,
} from "../../helpers/DateUtility";
import WithCrudActionsOnArray from "../../shared-components/hoc/crud/WithCrudActionsOnArray";
import { setConstants } from "./Const";
import { DateOnlyString } from "../../helpers/Builders";
import TeamApi from "../../apis/endpoints/TeamApi";
import { toaster } from "../../shared-components/notification";

class ExtraWorkApplicationContainer extends Component {
  state = {
    userId: UserManager.getUser().id,
    constSetted: false,
    isLoading: false,
    teamNames: [],
  };
  toggleOnLoader = () => this.setState({ isLoading: true });
  toggleOffLoader = () => this.setState({ isLoading: false });

  componentDidMount() {
    let { userId } = this.state;
    let fromDate = DateOnlyString(getCurrentYearStartDate());
    let toDate = DateOnlyString(getCurrentYearEndDate());
    let filter = {
      fromDate,
      toDate,
      extraWorkTeamId: 0
    };

    this.toggleOnLoader();
    extraWorkApi
      .getAllByUser(filter, userId)
      .then((resp) => {
        this.toggleOffLoader();
        this.props.setArrayStateCallback(resp.data);
      })
      .catch(() => this.toggleOffLoader());

    this.loadTeams();
    setConstants(() => this.setState({ constSetted: !this.state.constSetted }));
  }

  onChangeUser = (userId) => this.setState({ userId });
  loadTeams = () => {
    TeamApi.all()
      .then((res) => {
        let teams = res.data;
        let names = [];
        teams.forEach((element) => {
          var option = { label: element.name, value: element.id };
          names.push(option);
        });
        this.setState({
          teamNames: names,
        });
      })
      .catch((err) => {
        toaster.error("Failed to load Team information");
      });
  };

  onFilter = (
    {
      fromDate,
      toDate,
      applicationType,
      notBillableToCustomer,
      extraWorkTeamId,
    },
    userId
  ) => {
    let applicationFilter = {
      fromDate,
      toDate,
      applicationType,
      extraWorkTeamId,
    };
    if (!!notBillableToCustomer && applicationType !== 2) {
      applicationFilter = {
        ...applicationFilter,
        notBillableToCustomer,
      };
      return extraWorkApi
        .getAllByUser(applicationFilter, userId)
        .then((resp) => this.props.setArrayStateCallback(resp.data));
    } else {
      return extraWorkApi
        .getAllByUser(applicationFilter, userId)
        .then((resp) => this.props.setArrayStateCallback(resp.data));
    }
  };

  actionsCallbacks = {
    ...this.props.arrayCrudCallbacks,
    onFilter: this.onFilter,
  };

  render() {
    const { crudArray: applications } = this.props;
    const { userId, isLoading } = this.state;
    return (
      <>
        <Helmet>
          <title>Extra Working Day Applications- Cefalo</title>
        </Helmet>
        <ExtraWorkApplicationView
          applications={applications}
          actionsCallbacks={this.actionsCallbacks}
          userId={userId}
          onChangeUser={this.onChangeUser}
          isLoading={isLoading}
          isSupervision={false}
          teamNames={this.state.teamNames}
        />
      </>
    );
  }
}

export default WithCrudActionsOnArray(extraWorkApi)(
  ExtraWorkApplicationContainer
);

ExtraWorkApplicationContainer.propTypes = {
  setArrayStateCallback: PropTypes.func.isRequired,
  crudArray: PropTypes.array.isRequired,
  arrayCrudCallbacks: PropTypes.object.isRequired,
};
