import React from "react";
import { TrainingDto } from "../../../models/TrainingDto";
import { DataTable } from "../../../library/table";
import ActionsGroupView from "./ActionsGroupView";
import { DateOnlyStringMMDDYY } from "../../../helpers/Builders";
import { multiLineTextFormatter } from "../../../helpers/Formatter";

const TrainingTableView = ({ trainings, activeUsers, onUpdate, onDelete }) => {
  const buildTableRows = (
    trainings,
  ) => {
    let tempRows = [];
    trainings.forEach((item) => {
      if (item instanceof TrainingDto) {
        
        let tempRow = {};
        tempRow["id"] = item.id;
        tempRow["title"] = item.title;
        tempRow["organizer"] = item.organizer;
        tempRow["duration"] = item.duration;
        tempRow["description"] = item.description;
        tempRow["startDate"] = DateOnlyStringMMDDYY(item.startDate);
        tempRow["actions"] = (
          <ActionsGroupView
            training={item}
            activeUsers={activeUsers}
            deleteAction={onDelete}
            updateAction={onUpdate}
          />
        );
        tempRows.push(tempRow);
      }
    });
    return tempRows;
  };

  const trainingTableColumns = [
    {
      text: "Training Id",
      dataField: "id",
      sort: true,
    },
    {
      text: "Title",
      dataField: "title",
      sort: true,
    },
    {
      text: "Organizer",
      dataField: "organizer",
      sort: true,
    },
    {
      text: "Duration (In Hours)",
      dataField: "duration",
      sort: true,
    },
    {
      text: "Start Date",
      dataField: "startDate",
      sort: true,
    },
    {
      text: "Description",
      dataField: "description",
      sort: true,
      formatter: multiLineTextFormatter
    },
    {
      text: "Actions",
      dataField: "actions",
      sort: false,
    },
  ];

  let rows = buildTableRows(trainings);
  return <DataTable rows={rows} columns={trainingTableColumns} />;
};

export default TrainingTableView;
