import React from "react";
import UserManager from "../../../navigation/UserManager";
import UpdateApplication from "../actions/UpdateApplication";
import { ToExtraWorkStatusValue } from "../Const";
import ResponseApplication from "../actions/ResponseApplication";
import DeleteActions from "../../../shared-components/application-action-form/DeleteActions";
import { v4 as uuid4 } from "uuid";

const WithPermittedAction = (WrappedComponent) => (props) => {
  const user = UserManager.getUser();
  const { actionsCallbacks, teamNames } = props;

  const canEdit = (status) =>
    user.isHrOrAdmin() &&
    (status === ToExtraWorkStatusValue.Requested ||
      status === ToExtraWorkStatusValue.Approved);

  const canResponse = (status) => {
    return user.isHrOrAdmin() && status === ToExtraWorkStatusValue.Requested;
  };

  const canDelete = () => user.isHrOrAdmin();

  const getActions = (application) => {
    let actions = [];
    const { status } = application;
    if (canEdit(status)) {
      actions.push(
        <UpdateApplication
          application={application}
          teamNames={teamNames}
          onUpdateCallback={actionsCallbacks.onUpdate}
          key={uuid4()}
        />
      );
    }
    if (canResponse(status)) {
      actions.push(
        <ResponseApplication
          onUpdateStatusCallback={actionsCallbacks.onResponse}
          key={uuid4()}
          application={application}
        />
      );
    }
    if (canDelete()) {
      actions.push(
        <DeleteActions
          onDeleteCallback={actionsCallbacks.onDelete}
          applicationId={application.id}
          key={uuid4()}
        />
      );
    }
    return actions;
  };

  return <WrappedComponent {...props} getActions={getActions} />;
};

export default WithPermittedAction;
