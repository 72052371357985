import React, { Component } from "react";
import OfficeTimingContainer from "./office-timing/OfficeTimingContainer";
import SettingsContainer from "./settings/SettingsContainer";
import { Helmet } from "react-helmet";
import LightLayout from "../../shared-components/layout/LightLayout";

class CombinedSettingsContainer extends Component {
  render() {
    return (
      <LightLayout>
        <Helmet>
          <title>Settings Management- Cefalo</title>
        </Helmet>
        <OfficeTimingContainer />
        <SettingsContainer />
      </LightLayout>
    );
  }
}

export default CombinedSettingsContainer;
