import React, { Component } from "react";
import GoogleLoginView from "./GoogleLoginView";
import authApi from "../../apis/endpoints/AuthApi";
import LightLayout from "../../shared-components/layout/LightLayout";

export default class GoogleLoginContainer extends Component {
  state = {
    submitError: false,
    googleAuthError: false,
    loading: false,
  };

  onRequest = () => {
    this.setState({ googleAuthError: false });
  };

  onSuccess = (googleResponse) => {
    this.setState({
      submitError: false,
      loading: true,
    });
    authApi
      .googleLogin({ GoogleTokenID: googleResponse.tokenId })
      .then((userInfo) => {
        // let user = { ...userInfo };
        // UserManager.setUser(user);
        localStorage.setItem("__C_Token", userInfo.token);
        window.location.replace("/");
      })
      .catch((error) => {
        this.setState({ submitError: true, loading: false });
      });
  };

  onFailure = (googleError) => {
    this.setState({ googleAuthError: true });
  };

  render() {
    return (
      <LightLayout>
        <div className="loginJumbotron">
          <GoogleLoginView
            onSuccess={this.onSuccess}
            onFailure={this.onFailure}
            onRequest={this.onRequest}
            {...this.state}
          />
        </div>
      </LightLayout>
    );
  }
}
