import React from "react";
import Layout from "../../shared-components/layout/Layout";
import UserManager from "../../navigation/UserManager";
import AttendanceApi from "../../apis/endpoints/AttendanceApi";
import DashboardView from "./DashboardView";
import { Helmet } from "react-helmet";
import { Loading } from "../../shared-components/notification";
import { defaultErrorResponseParser } from "../../helpers/ErrorParser";

const ViewContainer = (props) => {
  return (
    <div className="container-fluid m-0 p-0">
      <div className="row flex-center">
        <div className="col-md-12" style={{ background: "white" }}>
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default class DashboardContainer extends React.Component {
  state = {
    isLoading: false,
    shouldRender: false,
    entryReports: [],
  };

  componentDidMount() {
    let user = UserManager.getUser();
    if (!user.isAdminOrHr()) {
      return window.location.replace(`/Report/Index/${user.id}`);
    }
    this.setState({ shouldRender: true });
    this.loadEntryReports();
  }

  loadEntryReports = () => {
    this.setState({ isLoading: true });
    AttendanceApi.getTodayEntries()
      .then((res) => {
        this.setState({ isLoading: false, entryReports: res.data });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        defaultErrorResponseParser(err, "Failed To Load Attendance Entries");
      });
  };

  render() {
    if (!this.state.shouldRender) {
      return <span></span>;
    }
    return (
      <Layout>
        <Helmet>
          <title>Home - Cefalo</title>
        </Helmet>
        <Loading show={this.state.isLoading} />
        <ViewContainer>
          <DashboardView 
            entries={this.state.entryReports}
          />
        </ViewContainer>
      </Layout>
    );
  }
}
