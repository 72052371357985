import React from "react";
import { Modal } from "react-bootstrap";
import { useState } from "react";
import { v4 as uuid4 } from "uuid";
import { CefaloButton } from "../../library/button";

export default function ConfirmationModal({
  message = "Are you sure to delete this application?",
  toggleButtonText = "action",
  toggleButtonColor = "primary",
  btnStyleClass = "",
  doAction = () => {},
}) {
  const [show, toggle] = useState(false);

  return (
    <>
      <CefaloButton
        className={btnStyleClass}
        id={uuid4()}
        size="sm"
        variant={toggleButtonColor}
        onClick={() => toggle(true)}
      >
        {toggleButtonText}
      </CefaloButton>
      <Modal show={show} centered onHide={() => () => toggle(false)}>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
          <CefaloButton
            size="sm"
            variant="danger"
            onClick={() => toggle(false)}
          >
            No
          </CefaloButton>
          <CefaloButton
            size="sm"
            variant="success"
            onClick={() => {
              toggle(false);
              doAction();
            }}
          >
            Yes
          </CefaloButton>
        </Modal.Footer>
      </Modal>
    </>
  );
}
