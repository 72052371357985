import React from "react";
import {
  toaster,
} from "../../shared-components/notification/Notification";
import UserManager from "../../navigation/UserManager";
import TrainingHistoryView from "./views/TrainingHistoryView";
import TrainingApi from "../../apis/endpoints/TrainingApi";
import { Form } from "../../library/form/Index";
import TrainingHistoryCreateAction from "./actions/TrainingHistoryCreateAction";
import { Col } from "react-bootstrap";
import UserApi from "../../apis/endpoints/UserApi";

const ViewContainer = (props) => <div className="p-2">{props.children}</div>;

class TrainingHistoryContainer extends React.Component {
  state = {
    isLoading: false,
    shouldRender: false,
    trainingHistory: [],
    trainings: [],
  };

  componentDidMount = () => {
    let user = UserManager.getUser();
    if (user.isAdminOrHr()) {
      this.setState({
        shouldRender: true,
        isLoading: true,
      });
      this.loadTrainingHistory(this.props.userId);
      this.loadTrainings();
    }
  };

  loadTrainingHistory = (userId) => {
    this.setState({
      isLoading: true,
    });

    UserApi.getUserTrainingsByUserId(userId)
      .then((res) => {
        this.setState({
          isLoading: false,
          shouldRender: true,
          trainingHistory: res.data,
        });
      })
      .catch((err) => {
        this.setState({
          shouldRender: false,
        });
        toaster.error("Failed to load user training history");
      });
  };

  loadTrainings = () => {
    TrainingApi.all()
      .then((res) => {
        let trainings = res.data;
        let trainingOptions = [];
        trainings.forEach((element) => {
          var option = { label: element.title, value: element.id };
          trainingOptions.push(option);
        });

        this.setState({
          trainings: trainingOptions,
        });
      })
      .catch((err) => {
        toaster.error("Failed to load training information");
      });
  };

  render() {
    return (
      <Form>
        <Form.Row>
          <Col>
            TRAINING HISTORY
            <hr />
          </Col>
          <Col sm="auto">
            <TrainingHistoryCreateAction
              trainings={this.state.trainings}
              userId={this.props.userId}
              onCreateCallBack={this.loadTrainingHistory}
            />
          </Col>
        </Form.Row>

        <Form.Row>
          <Col>
            <ViewContainer>
              <TrainingHistoryView
                trainingHistory={this.state.trainingHistory}
                onDelete={this.loadTrainingHistory}
                hasActionGroup={true}
              />
            </ViewContainer>
          </Col>
        </Form.Row>
      </Form>
    );
  }
}

export default TrainingHistoryContainer;
