import React from "react";
import FilterFormView from "./FilterFormView";
import ApplicationTable from "./ApplicationTable";
import LeaveBalanceTables from "./LeaveBalanceTables";
import ApplicationCreateAction from "../../../shared-components/leave-application/actions/CreateAction";
import UserManager from "../../../navigation/UserManager";

const LeaveApplicationView = (props) => {
  let actionsCallbacks = {
    onDelete: props.onDelete,
    onUpdate: props.onUpdate,
    onStatusUpdate: props.onStatusUpdate,
    onCancel: props.onCancel,
  };
  let user=UserManager.getUser()
  return (
    <>
      <div>
        <FilterFormView
          setSelectedUserId={props.setSelectedUserId}
          selectedUserId={props.selectedUserId}
          userId={props.userId}
          onUserChange={props.onUserChange}
          onCreateCallback={props.onCreate}
          onFilterAction={props.onFilter}
          users={props.users}
          onCreate={props.onCreate}
          leaveStatusTypes={props.leaveStatusTypes}
          leaveApplicationTypes={props.leaveApplicationTypes}
        >
          {
            !user.isTeamManagerVisualizingOtherMembers(props.selectedUserId) && 
          <ApplicationCreateAction
            onCreateCallback={props.onCreate}
            userId={props.userId}
            leaveApplicationTypes={props.leaveApplicationTypes}
            leaveSettings={props.leaveSettings}
          />
}
        </FilterFormView>

        <ApplicationTable
          isTeamManagerVisualizingOtherMembers={user.isTeamManagerVisualizingOtherMembers}
          selectedUserId={props.selectedUserId}
          userId={props.userId}
          actionsCallbacks={actionsCallbacks}
          applications={props.applications}
          leaveSettings={props.leaveSettings}
          leaveStatuses={props.leaveStatusTypes}
          leaveTypes={props.leaveApplicationTypes}
        />
      </div>
      <hr />
      <LeaveBalanceTables userId={props.userId} />
    </>
  );
};

export default LeaveApplicationView;
