import { AuthenticatedAxios } from "../settings/Axios";
const BaseEndPoint = "Profiles";
const BaseEndpointForUser = "Users";
let config = {
  headers: {
    "Content-Type": "application/json",
  },
};
export default {
  all: () => {
    return AuthenticatedAxios.get(BaseEndPoint, null, null);
  },
  get: (id) => {
    let endpoint = `${BaseEndpointForUser}/${id}/Profile`;
    return AuthenticatedAxios.get(endpoint, null, null);
  },
  getShortProfile: (id) => {
    let endpoint = `${BaseEndpointForUser}/${id}/Profile/Short`;
    return AuthenticatedAxios.get(endpoint, null, null);
  },
  save: (profile) => {
    let body = JSON.stringify(profile);
    return AuthenticatedAxios.post(BaseEndPoint, body, config);
  },
  getBloodGroups: () => {
    let endpoint = `/BloodGroups`;
    return AuthenticatedAxios.get(endpoint, null, null);
  },
  getSkills: () => {
    let endpoint = `/Profile/Skills`;
    return AuthenticatedAxios.get(endpoint, null, null);
  },
};
