import React, { Component } from "react";
import { DateRange, Form, Select } from "../../../library/form";
import { Col } from "../../../library/grid-layout";
import UserManager from "../../../navigation/UserManager";
import UserApi from "../../../apis/endpoints/UserApi";
import { toaster, Loading } from "../../../shared-components/notification";
import { DateOnlyString } from "../../../helpers/Builders";
import {
  getCurrentYearStartDate,
  getCurrentYearEndDate,
} from "../../../helpers/DateUtility";
import CreateApplication from "../actions/CreateApplication";
import { CefaloButton } from "../../../library/button";
import { showErrorMessages } from "../../../shared-components/response-message/ResponseMessage";
import ExtraWorkApplicationApi from "../../../apis/endpoints/ExtraWorkApplicationApi";
import { enumsToValueLabelArray } from "../../../shared-components/enum-values/EnumValuesComverter";
class ExtraWorkController extends Component {
  constructor(props) {
    super(props);
    this.user = UserManager.getUser();
    this.state = {
      userId: this.user.id,
      userOptions: [this.userOption(this.user)],
      fromDate: getCurrentYearStartDate(),
      toDate: getCurrentYearEndDate(),
      applicationType: null,
      billableTo: 2,
      applicationStatus: 1,
      isLoading: false,
      billingOptions: this.getBillableToOptions(),
      userTeams: [],
      extraWorkTeamId: 0,
    };
  }

  getBillableToOptions = () => {
    ExtraWorkApplicationApi.getAllBillableTo().then((resp) => {
      let dataArray = resp.data;
      this.billingOptions = [{ value: 2, label: "All" }].concat(
        enumsToValueLabelArray(dataArray)
      );
    });
  };

  userOption = (user) => ({
    label: user.name,
    value: user.id,
  });

  makeUserOptions = (users) => users.map((user) => this.userOption(user));

  componentDidMount = () => {
    const { isUserFilterEnabled, isSupervision } = this.props;
    if (isUserFilterEnabled && this.user.isHrOrAdmin()) {
      UserApi.all().then((resp) => {
        let userOptions = this.makeUserOptions(resp.data);
        this.setState({ userOptions });
      });
    } else if (!isSupervision && this.user.hasTeamManagerPermission) {
      UserApi.getTeamMembers(this.user.id)
        .then((resp) => {
          let userOptions = this.makeUserOptions(resp.data);
          this.setState({ userOptions });
        })
        .catch((err) => {
          showErrorMessages("Failed to load Team Members information");
        });
    }

    this.loadUserTeams();
  };

  loadUserTeams = () => {
    UserApi.getTeamsByUserId(this.state.userId)
      .then((res) => {
        let teams = res.data;
        this.setState({
          userTeams: teams,
        });
      })
      .catch((err) => {
        toaster.error("Failed to load user team information");
      });
  };

  setDate = ({ fromDate, toDate }) => {
    this.setState({ fromDate, toDate });
  };

  setTeam = (event) => {
    this.setState({
      extraWorkTeamId: event.currentTarget.value,
    });
  };

  onChange = (event) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value });
  };

  onSelectBoxChange = (event) => {
    if (isNaN(event.currentTarget.value)) {
      this.setState({
        [event.currentTarget.name]: null,
      });
    } else {
      this.setState({
        [event.currentTarget.name]: parseInt(event.currentTarget.value),
      });
    }
    if (
      event.currentTarget.name === "applicationType" &&
      event.currentTarget.value !== 1
    ) {
      this.setState({ billableTo: 2 });
    }
  };

  onChangeUser = (event) => {
    let value = parseInt(event.currentTarget.value);
    this.setState({ [event.currentTarget.name]: value });
    this.props.onChangeUser(value);
    this.triggerFilter(value);
  };

  handleFilter = (e) => {
    e.preventDefault();
    const { userId } = this.state;
    this.triggerFilter(userId);
  };

  triggerFilter = (userId) => {
    let {
      fromDate,
      toDate,
      applicationType,
      applicationStatus,
      extraWorkTeamId,
    } = this.state;
    this.setState({ isLoading: true });
    let notBillableToCustomer = null;

    if (this.state.billableTo === 0) {
      notBillableToCustomer = false;
    } else if (this.state.billableTo === 1) {
      notBillableToCustomer = true;
    }

    if (extraWorkTeamId === "*") {
      extraWorkTeamId = 0;
    }

    this.props
      .onFilterAction(
        {
          fromDate: DateOnlyString(fromDate),
          toDate: DateOnlyString(toDate),
          applicationType,
          applicationStatus,
          notBillableToCustomer,
          extraWorkTeamId,
        },
        userId
      )
      .then(() => this.setState({ isLoading: false }))
      .catch(() => this.setState({ isLoading: false }));
  };

  render() {
    const { fromDate, toDate, userOptions } = this.state;
    const {
      isUserFilterEnabled,
      isStatusEnabled,
      statusOptions,
      typesOptions,
      isSupervision,
      onCreateCallback,
      teamNames,
    } = this.props;

    const { applicationStatus, applicationType, isLoading, userId } =
      this.state;

    return (
      <Form onSubmit={this.handleFilter}>
        <Loading show={isLoading} />
        <Form.Row
          style={{
            alignItems: "flex-end",
          }}
        >
          {isUserFilterEnabled && this.user.isAdminOrHrOrTeamManager() && (
            <Col sm>
              <Select
                label="User:"
                options={userOptions}
                value={userId}
                name="userId"
                onChange={this.onChangeUser}
              />
            </Col>
          )}
          <DateRange
            fromDate={fromDate}
            toDate={toDate}
            onChange={this.setDate}
            classesNames={
              isSupervision
                ? {
                    fromDateColClassName: "pr-lg-3",
                    toDateColClassName: "pr-lg-3 pl-lg-3",
                  }
                : {
                    fromDateColClassName: "pr-lg-3 pl-lg-4",
                    toDateColClassName: "pr-lg-3 pl-lg-3",
                  }
            }
          />
          <Col sm>
            <Select
              label="Adjustment Type"
              name="applicationType"
              containerClassName="pr-lg-3 pl-lg-2"
              options={typesOptions}
              value={applicationType}
              onChange={this.onSelectBoxChange}
            />
          </Col>

          <Col sm>
            <Select
              label="Extra Work Team"
              name="team"
              containerClassName="pr-lg-3 pl-lg-2"
              options={[{ value: "*", label: "All" }, ...teamNames]}
              onChange={this.setTeam}
            />
          </Col>

          {applicationType !== 2 && (
            <Col sm>
              <Select
                label="Billable To"
                name="billableTo"
                containerClassName="pr-lg-3 pl-lg-2"
                options={this.billingOptions}
                value={this.billableTo}
                onChange={this.onSelectBoxChange}
              />
            </Col>
          )}
          {isStatusEnabled && (
            <Col sm>
              <Select
                label="Application Status"
                name="applicationStatus"
                containerClassName="pr-lg-3 pl-lg-3"
                options={statusOptions}
                value={applicationStatus}
                onChange={this.onSelectBoxChange}
              />
            </Col>
          )}

          <Col
            md={isSupervision ? 2 : 12}
            lg={isSupervision ? 2 : 4}
            className="p-0"
          >
            <Form.Row className="mb-3">
              <CefaloButton
                type="submit"
                className="mt-auto filter_panel_btn"
                size="sm"
                variant="primary"
              >
                Show Applications
              </CefaloButton>
              {!isSupervision &&
                !this.user.isTeamManagerVisualizingOtherMembers(userId) && (
                  <CreateApplication
                    userId={userId}
                    onCreateCallback={onCreateCallback}
                    teamNames={this.props.teamNames}
                    userTeams={this.state.userTeams}
                  />
                )}
            </Form.Row>
          </Col>
        </Form.Row>
      </Form>
    );
  }
}

export default ExtraWorkController;
