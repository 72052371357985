import React from "react";
import ImageUploadApi from "../../../apis/endpoints/FileUplodeApi";
import { defaultErrorResponseParser } from "../../../helpers/ErrorParser";
import DefaultImage from "../../../static/images/defaultProfileImage.png";
import "react-image-crop/dist/ReactCrop.css";
import ProfileImageCroppingAction from "../actions/ProfileImageCroppingAction";
import styles from "../styles/ProfileImage.module.css";
import ResizeIamgeAction from "../actions/ResizeImageAction";
import { toaster } from "../../../shared-components/notification";
import { Loading } from "../../../shared-components/notification";
export default class ProfilePictureView extends React.Component {
  state = {
    imageData: null,
    imageType: null,
    isLoading: false,
    displayFile: null,
    isHovering: false,
    croppedFile: null,
    showModal: false,
    src: null,
    croppedImageSrc: null,
    crop: {
      aspect: 1,
      unit: "%",
      width: 50,
      height: 50,
      x: 25,
      y: 25,
    },
  };

  getCroppedImageFile = (croppedImage) => {
    this.uploadCroppedImage(croppedImage);
  };
  toggleTheModalView = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  componentDidMount() {
    // profile id = 0 means there is no profile exist for that user
    // this is default user profile, so we should no load for default user
    if (
      this.props.profile.id !== 0 &&
      this.props.profile.photoFileName != null
    ) {
      this.loadImageData(this.props.profile);
    }
  }

  componentDidUpdate(prevProps, prevStates) {
    if (
      !!this.props.profile.photoFileName &&
      this.props.profile.photoFileName !== prevProps.profile.photoFileName
    ) {
      this.loadImageData(this.props.profile);
    }
  }

  loadImageData = (profile) => {
    var extention =
      profile.photoFileName.substring(
        profile.photoFileName.lastIndexOf(".") + 1,
        profile.photoFileName.length
      ) || profile.photoFileName;
    var imageName = profile.userId + "." + extention;

    ImageUploadApi.Get(imageName)
      .then((res) => {
        this.setState({
          imageData: res.data,
          imageType: res.headers["content-type"],
        });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
  };

  saveImage = (event) => {
    var selectedFile = null;
    selectedFile = event.target.files[0];
    var url = URL.createObjectURL(selectedFile);
    var imgHeight = 0,
      imgWidth = 0;

    var img = new Image();
    img.onload = async () => {
      imgHeight = img.height;
      imgWidth = img.width;      

      if (imgWidth < 340 || imgHeight <340) {       
        toaster.error("Minimum height and width of the image must be 340 px!");
       this.setState({
        showModal: false,
        isLoading: false,
       })
      }else{        
        (this.setState({         
          src: null,
          showModal: false
        }), async () => {
          this.setState({
             isLoading: true,
          });
          const image = await ResizeIamgeAction.resizeFile(selectedFile);
          this.setState({               
            src: image,
            isLoading: false,
            showModal: true
          });
        })()
      }
      
    };
    img.src = url;

    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        () =>
        false
      );
      reader.readAsDataURL(selectedFile);
    }
  };

  uploadCroppedImage = (imgValue) => {
    var createdForm = new FormData();
    var fileName = null;
    createdForm.append("FormFile", imgValue, "croppedImage.jpg");
    createdForm.append("FileType", "ProfilePicture");

    ImageUploadApi.Upload(createdForm)
      .then((res) => {
        this.setState({
          isLoading: false,
        });
        fileName = res.data;
        this.props.setPhotoFileNameCallback(fileName);
        this.setState({
          displayFile: URL.createObjectURL(imgValue),
        });
        this.toggleTheModalView();
      })
      .catch((err) => {
        defaultErrorResponseParser(err, "Failed To Save Image");
      });
  };

  handleMouseHover = () => {
    this.setState((prevState) => ({
      isHovering: !prevState.isHovering,
    }));
  };

  getCurrentImageForRender = () => {
    let imageSrc = DefaultImage;
    if (!!this.state.displayFile) {
      imageSrc = this.state.displayFile;
    } else if (!!this.state.imageData) {
      imageSrc = `data:${this.state.imageType};base64,${this.state.imageData}`;
    }
    return (
      <img
        style={{ cursor: "pointer" }}
        width="100%"
        src={imageSrc}
        alt="profile"
      />
    );
  };

  render() {
    return (
      <div>
        <label htmlFor="profile_pic">
          <div
            className={styles.imagediv}
            onMouseEnter={this.handleMouseHover}
            onMouseLeave={this.handleMouseHover}
          >
            {this.getCurrentImageForRender()}
            {this.state.isHovering && (
              <div className={styles.imagesrc}>Upload Profile Picture</div>
            )}
          </div>
        </label>
        <input
          type="file"
          id="profile_pic"
          style={{ display: "none" }}
          onChange={this.saveImage}
          accept=".jpg, .jpeg, .png"
        />
        <Loading show={this.state.isLoading} />
        <ProfileImageCroppingAction
          image={this.state.src}
          showModal={this.state.showModal}
          toggleTheModalView={this.toggleTheModalView}
          setPhotoFileNameCallback={this.props.setPhotoFileNameCallback}
          croppedFile={this.state.croppedFile}
          onGetImage={this.getCroppedImageFile}
        />
      </div>
    );
  }
}
