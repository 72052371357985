import React from "react";
import { Form } from "../../../library/form/Index";
import { Col } from "react-bootstrap";
import styles from "../styles/Profile.module.css";

export default class UserProfileEducationInfoView extends React.Component {
  render() {
    return (
      <Form.Row className="mb-2">
        {this.props.educationInfos && this.props.educationInfos.length > 0 && (
          <Col sm="12">
            <Form.Row>
              <Col sm="12" className={styles.profileTitleHeader}>
                Education
                <hr className="mt-0" />
              </Col>
            </Form.Row>
            <Form.Row>
              {this.props.educationInfos.map((item) => (
                <Col sm="12" key={item.id}>
                  <Form.Row className="mb-2">
                    {!!item.passingYear && (
                      <ul key={item.id}>
                        <li>
                          {item.institute} <br></br>
                          {item.degree} - {item.passingYear}
                        </li>
                      </ul>
                    )}
                  </Form.Row>
                </Col>
              ))}
            </Form.Row>
          </Col>
        )}
      </Form.Row>
    );
  }
}
