export class TrainingDto {
    constructor(obj) {
      this.id = obj.id;
      this.title = obj.title;
      this.organizer = obj.organizer;
      this.duration = obj.duration;
      this.description = obj.description;
      this.startDate = obj.startDate;
    }
  }
  