import React from "react";
import SortUtility from "../../../helpers/SortUtility";
import DataTable from "../../../library/table/DataTable";

const ReportTableBaseColumns = [
  {
    text: "Date",
    dataField: "date",
    type: "date",
    sort: true,
    sortFunc: SortUtility.dateSorter,
  },
  {
    text: "StartTime",
    dataField: "startTime",
    sort: false,
  },
  {
    text: "EndTime",
    dataField: "endTime",
    sort: false,
  },
  {
    text: "WorkHour",
    dataField: "totalWorkingHour",
    sort: false,
    headerStyle: { width: "18%" },
  },
];

const prepareAttendanceDataTableRows = (attendances) => {
  let rows = [];
  if (!Array.isArray(attendances)) {
    return rows;
  }

  attendances.forEach((att, index) => {
    let reportRow = {
      date: new Date(att.date).toLocaleDateString(),
      startTime: att.startTime,
      endTime: att.endTime,
      totalWorkingHour: att.totalWorkingHour,
      status: att.statusDisplayText,
    };

    rows.push(reportRow);
  });
  return rows;
};

const ReportTable = ({ attendances }) => {
  let rows = prepareAttendanceDataTableRows(attendances);

  return (
    <DataTable
      rows={rows}
      columns={ReportTableBaseColumns}
      excludeSearch
      excludePagination
    />
  );
};

const UserProfileAttendanceReportView = ({ attendances }) => {
  let recentFiveDaysAttendances = attendances?.slice(0, 5);
  return <ReportTable attendances={recentFiveDaysAttendances} />;
};

export default UserProfileAttendanceReportView;
