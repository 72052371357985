import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { WeekDays } from "../Const";
import { CheckBox } from "../../../../library/form";
import { v4 as uuid4 } from "uuid";

export default function OfficeDaysSettingsView({
  settings,
  setOfficeDaysState,
}) {
  return (
    <Container>
      <Row>
        <Col>
          <CheckBox
            key={uuid4()}
            name="monday"
            label="Monday"
            checked={settings.officeWeekDays.includes(WeekDays.Monday)}
            onChange={(event) => setOfficeDaysState(event)}
          />
        </Col>
        <Col>
          <CheckBox
            key={uuid4()}
            name="tuesday"
            label="Tuesday"
            checked={settings.officeWeekDays.includes(WeekDays.Tuesday)}
            onChange={(event) => setOfficeDaysState(event)}
          />
        </Col>
        <Col>
          <CheckBox
            key={uuid4()}
            name="wednesday"
            label="Wednesday"
            checked={settings.officeWeekDays.includes(WeekDays.Wednesday)}
            onChange={(event) => setOfficeDaysState(event)}
          />
        </Col>
        <Col>
          <CheckBox
            key={uuid4()}
            name="thursday"
            label="Thursday"
            checked={settings.officeWeekDays.includes(WeekDays.Thursday)}
            onChange={(event) => setOfficeDaysState(event)}
          />
        </Col>
        <Col>
          <CheckBox
            key={uuid4()}
            name="friday"
            label="Friday"
            checked={settings.officeWeekDays.includes(WeekDays.Friday)}
            onChange={(event) => setOfficeDaysState(event)}
          />
        </Col>
      </Row>
    </Container>
  );
}
