import React from "react";
import Layout from "../../shared-components/layout";
import { Helmet } from "react-helmet";
import TrainingApi from "../../apis/endpoints/TrainingApi";
import TrainingManageView from "./views/TrainingManageView";
import { toaster } from "../../shared-components/notification";
import UserManager from "../../navigation/UserManager";
import { TrainingDto } from "../../models/TrainingDto";
import UserApi from "../../apis/endpoints/UserApi";
export default class TrainingManageContainer extends React.Component {
  state = {
    trainings: [],
    isLoading: false,
    shouldRender: false,
    activeUsers: []
  };

  componentDidMount() {
    let user = UserManager.getUser();
    if (!user.isAdminOrHr()) {
      window.location.replace(`/Report/Index/${user.id}`);
    } else {
      this.setState({ shouldRender: true });
      this.loadTrainingsData();
      this.loadActiveUsers();
    }
  }
  loadTrainingsData = () => {
    this.setState({ isLoading: true });
    TrainingApi.all()
      .then((res) => {
        let trainings = res.data.map((t) => new TrainingDto(t));
        this.setState({
          isLoading: false,
          trainings: trainings,
        });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        toaster.error("Failed to load training information");
      });
  };

  loadActiveUsers = () => {
    UserApi.getActiveUsersShortProfiles()
      .then((res) => {
        let users = res.data;
        let usersOptions = [];
        users.forEach((element) => {
          var option = { label: element.name, value: element.id };
          usersOptions.push(option);
        });
        this.setState({
          activeUsers: usersOptions,
        });
      })
      .catch((err) => {
        toaster.error("Failed to load active users");
      });
  };

  onTrainingCreate = (createdTraining) => {
    let trainings = this.state.trainings;
    let training = new TrainingDto(createdTraining);
    trainings.push(training);
    this.setState({
      trainings: trainings,
    });
  };

  onTrainingDelete = () => {
    this.loadTrainingsData();
  };

  onTrainingUpdate = (updatedTraining) => {
    let training = new TrainingDto(updatedTraining);
    let trainings = this.state.trainings;
    let index = trainings.findIndex((item) => item.id === updatedTraining.id);
    trainings[index] = training;
    this.setState({ trainings: trainings });
  };

  render() {
    if (!this.state.shouldRender) {
      return <></>;
    }
    return (
      <Layout>
        <Helmet>
          <title>Training Management- Cefalo</title>
        </Helmet>
        <TrainingManageView
          {...this.state}
          trainings={this.state.trainings}
          activeUsers={this.state.activeUsers}
          onUpdate={this.onTrainingUpdate}
          onDelete={this.onTrainingDelete}
          onCreate={this.onTrainingCreate}
        />
      </Layout>
    );
  }
}
