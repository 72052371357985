import React from "react";
import PropTypes from "prop-types";
import SkillDeleteModalForm from "../forms/SkillDeleteModalForm";
import { CefaloButton } from "../../../library/button/Index";
import { Loading, toaster } from "../../../shared-components/notification/Notification";
import { defaultErrorResponseParser } from "../../../helpers/ErrorParser";
import SkillApi from "../../../apis/endpoints/SkillApi";
export default class SkillDeleteAction extends React.Component {
  static propTypes = {
    skill: PropTypes.object.isRequired,
    onDeleteCallback: PropTypes.func.isRequired,
  };
  state = {
    isLoading: false,
    show: false,
  };

  toggle = (e) => () => {
    this.setState({ show: !this.state.show });
  };

  onDelete = (e) => {
    this.setState({ isLoading: true });
    SkillApi.delete(this.props.skill.id)
      .then((res) => {
        toaster.success("Skill Deleted Successfully");
        this.setState({ isLoading: false, show: false });
        this.props.onDeleteCallback();
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        defaultErrorResponseParser(err, "Failed To Delete Skill");
      });
  };

  render() {
    return (
      <div className="d-inline-block">
        <Loading show={this.state.isLoading} />
        <SkillDeleteModalForm
          onSubmit={this.onDelete}
          show={this.state.show}
          onToggle={this.toggle}
          skillData={this.props.skill}
        />
        <CefaloButton
          className="btn-font-12"
          variant="danger"
          onClick={this.toggle()}
        >
          Delete
        </CefaloButton>
      </div>
    );
  }
}
