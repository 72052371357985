import React from "react";
import Layout from "../../shared-components/layout/Layout";
import SkillDevelopmentFundApi from "../../apis/endpoints/SkillDevelopmentFundApi";
import UserApi from "../../apis/endpoints/UserApi";
import UserManager from "../../navigation/UserManager";
import { Helmet } from "react-helmet";
import { toaster, Loading } from "../../shared-components/notification/Notification";
import { UserWithTeamDto } from "../../models/User";
import { DateOnlyString } from "../../helpers/Builders";
import SkillDevelopmentSupervisionView from "./views/SkillDevelopmentSupervisionView";
import { SkillDevelopmentRequestDto } from "../../models/SkillDevelopmentFund";
import { SkillDevelopmentRequestStatus } from "./Consts";

class SkillDevelopmentSupervisionContainer extends React.Component {

  constructor(props) {
    super(props);
    let userId = UserManager.getUser().id;
    this.state = {
      users: [],
      requests: [],
      userId: userId,
      fromDate: DateOnlyString(new Date(new Date().getFullYear(), 0, 1)),
      toDate: DateOnlyString(new Date(new Date().getFullYear(), 12, 31)),
      requestType: null,
      isLoading: false,
      selectedUserId: userId,
    };
  }

  setSelectedUserId = (selectedUserId) => this.setState({ selectedUserId });

  async componentDidMount() {
    this.setState({ isLoading: true });
    const user = UserManager.getUser();
    if (user.isAdminOrHr()) {
      await this.loadUserData();
    }
    await this.loadRequestData();
    this.setState({ isLoading: false });
  }

  loadRequestData = async (filters) => {
    const filterSrc = !!filters ? filters : this.state;
    let responseData;
    try {
      const filters = {
        "StartDate": filterSrc.fromDate,
        "EndDate": filterSrc.toDate,
      };
      if (!isNaN(filterSrc.requestType)) {
        filters["ApplicationStatus"] = filterSrc.requestType;
      }
      const res = await SkillDevelopmentFundApi.all(filters);
      responseData = res.data.map((item) => new SkillDevelopmentRequestDto(item));
    } catch (err) {
      toaster.error("Failed to load fund requests");
      responseData = [];
    }
    this.setState({
      requests: responseData,
    });
  }

  loadUserData = async () => {
    try {
      const res = await UserApi.all();
      this.setState({
        users: res.data.map((item) => new UserWithTeamDto(item)),
      });
    } catch (err) {
      toaster.error("Failed to load User information");
    }
  };

  onUserChangeCallback = (userId) => {
    this.setState({ userId: userId });
  };

  onFilter = (opts) => {
    const filters = {
      fromDate: opts.fromDate,
      toDate: opts.toDate,
      requestType: opts.requestType
    };
    this.setState({
      ...filters,
    });
    this.loadRequestData(filters);
  }

  canRenderComponent = () => {
    const user = UserManager.getUser();
    if (user.isAdminOrHr()) {
      return (
        !this.state.isLoading &&
        Array.isArray(this.state.users) &&
        this.state.users.length > 0
      );
    }
    return !this.state.isLoading;
  };

  getRequests = () => {
    this.state.requests.forEach((item) => {
      if (item instanceof SkillDevelopmentRequestDto) {
        const user = this.state.users.find(i => `${i.id}` === `${item.userId}`);
        if (!!user) {
          item.name = user.name;
        }
      }
    });
    return this.state.requests;
  }

  onDelete = async (id) => {
    this.setState({ isLoading: true });
    try {
      await SkillDevelopmentFundApi.delete(id);
      toaster.success("Fund Request deleted");
      await this.loadRequestData();
    } catch (err) {
      toaster.error("Failed to delete Fund Request");
    }
    this.setState({ isLoading: false });
  }

  onApprove = async (id) => {
    this.setState({ isLoading: true });
    try {
      await SkillDevelopmentFundApi.updateStatus(id, SkillDevelopmentRequestStatus.Approved);
      toaster.success("Fund Request approved");
      await this.loadRequestData();
    } catch (err) {
      toaster.error("Failed to approve Fund Request");
    }
    this.setState({ isLoading: false });
  }

  onReject = async (id, reason) => {
    this.setState({ isLoading: true });
    try {
      await SkillDevelopmentFundApi.updateStatus(id, SkillDevelopmentRequestStatus.Rejected, reason);
      toaster.success("Fund Request rejected");
      await this.loadRequestData();
    } catch (err) {
      toaster.error("Failed to reject Fund Request");
    }
    this.setState({ isLoading: false });
  }

  onClaimReject = async (id, reason) => {
    this.setState({ isLoading: true });
    try {
      await SkillDevelopmentFundApi.updateStatus(id, SkillDevelopmentRequestStatus.ClaimRejected, reason);
      toaster.success("Fund Request claim rejected");
      await this.loadRequestData();
    } catch (err) {
      toaster.error("Failed to reject Fund Request claim");
    }
    this.setState({ isLoading: false });
  }

  onRefund = async (id) => {
    this.setState({ isLoading: true });
    try {
      await SkillDevelopmentFundApi.updateStatus(id, SkillDevelopmentRequestStatus.Refunded);
      toaster.success("Fund Request refunded");
      await this.loadRequestData();
    } catch (err) {
      toaster.error("Failed to refund Request");
    }
    this.setState({ isLoading: false });
  }

  onDownloadUrl = async (fileName) => {
    const userId = UserManager.getUser().id;
    const opts = {
      fileName,
    }
    this.setState({ isLoading: true });
    try {
      const res = await SkillDevelopmentFundApi.documentData(userId, opts);
      const blobUrl = URL.createObjectURL(res.data);
      const a = document.createElement('a')
      a.href = blobUrl;
      a.download = fileName;
      a.click();
      setTimeout(() => URL.revokeObjectURL(blobUrl), 10000);
    } catch (err) {
      toaster.error("Failed to download attachment");
    }
    this.setState({ isLoading: false });
  }

  onAcceptCancelForApprovedRequest = async (id) => {
    this.setState({ isLoading: true });
    try {
      await SkillDevelopmentFundApi.updateStatus(id, SkillDevelopmentRequestStatus.CanceledApprovedAfterInitialApproved);
      toaster.success("Fund request canceled");
      await this.loadRequestData();
    } catch (err) {
      toaster.error("Failed to cancel fund request");
    }
    this.setState({ isLoading: false });
  }

  render() {
    if (!this.canRenderComponent()) {
      return <Loading show={true} />;
    }

    return (
      <Layout>
        <Helmet>
          <title>Skill Development Fund Request - Cefalo</title>
        </Helmet>
        <div className="p-3">
          <Loading show={this.state.isLoading} />
          <SkillDevelopmentSupervisionView
            users={this.state.users}
            requests={this.getRequests()}
            userId={this.state.userId}
            setSelectedUserId={this.setSelectedUserId}
            selectedUserId={this.state.selectedUserId}
            callbacks={{
              setSelectedUserId: this.setSelectedUserId,
              onUserChange: this.onUserChangeCallback,
              onFilter: this.onFilter,
              onRefund: this.onRefund,
              onApprove: this.onApprove,
              onReject: this.onReject,
              onClaimReject: this.onClaimReject,
              onDownloadUrl: this.onDownloadUrl,
              onDelete: this.onDelete,
              onAcceptCancelForApprovedRequest: this.onAcceptCancelForApprovedRequest,
            }}
          />
        </div>
      </Layout>
    );
  }
}

export default SkillDevelopmentSupervisionContainer
