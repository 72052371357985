import React from "react";
import PropTypes from "prop-types";
import { DataTable } from "../../../library/table/Index";
import { AvailableEmployeeDto } from "../../../models/AvailableEmployeeDto";

function urlFormatter(cell, row) {
    return (
      <a href={cell}>
        {cell ? <i className="fa fa-link" aria-hidden="true"></i> : <></>}
      </a>
    );
  }

let dataTableColumns = [
  {
    text: "Name",
    dataField: "name",
    sort: true,
  },
  {
    text: "Short Name",
    dataField: "shortName",
    sort: false,
  },
  {
    text: "Designation",
    dataField: "designation",
    sort: false,
  },
  {
    text: "Total Experience",
    dataField: "totalWorkingExperience",
    sort: false,
  },
  {
    text: "Experience At Cefalo",
    dataField: "yearsOfExperienceAtCefalo",
    sort: false,
  },
  {
    text: "CV Link",
    dataField: "cvLink",
    sort: false,
    formatter: urlFormatter
  },
  {
    text: "Last Team Assignment",
    dataField: "lastTeamAssignment",
    sort: false,
  },
  {
    text: "Availability Date",
    dataField: "availabilityDate",
    sort: false,
  },
  {
    text: "Availability Percentage",
    dataField: "availabilityPercentage",
    sort: false,
  },
  {
    text: "Skills",
    dataField: "skills",
    sort: false,
  }
];

const buildTableRows = (rows) => {
  let tempRows = [];
  rows.forEach((item) => {
    if (item instanceof AvailableEmployeeDto) {
      let tempRow = {
        name: item.name,
        shortName: item.shortName,
        designation: item.designation,
        totalWorkingExperience: item.totalWorkingExperience,
        yearsOfExperienceAtCefalo: item.yearsOfExperienceAtCefalo,
        skills: item.skills,
        cvLink:  item.cvLink,
        availabilityDate: item.availabilityDate,
        availabilityPercentage: item.availabilityPercentage,
        lastTeamAssignment: item.lastTeamAssignment
      };
      tempRows.push(tempRow);
    }
  });
  return tempRows;
};
const AvailableEmployeeTable = (props) => {
  let rows = buildTableRows(
    props.availableEmployees
  );
  return <DataTable rows={rows} columns={dataTableColumns} />;
};
const ExportAvailableEmployeeView = (props) => {
  return (
    <div>
      <AvailableEmployeeTable {...props} />
    </div>
  );
};
ExportAvailableEmployeeView.prototype = {
  availableEmployees: PropTypes.arrayOf(AvailableEmployeeDto),
};

export default ExportAvailableEmployeeView;
