import React from "react";
import PropTypes from "prop-types";
import { LeaveStatisticsDto } from "../../../models/LeaveStatistics";
import { DataTable } from "../../../library/table/Index";

let dataTableColumns = [
  {
    text: "Name",
    dataField: "employeeName",
    sort: true,
  },
  {
    text: "Team Name",
    dataField: "teams",
    sort: true,
  },
  {
    text: "Total Available Leaves",
    dataField: "totalAvailableLeaves",
    sort: false,
  },
  {
    text: "Casual Leaves",
    dataField: "casualLeaves",
    sort: false,
  },
  {
    text: "Remaining Leaves",
    dataField: "remainingLeaves",
    sort: false,
  },
  {
    text: "Sick Leaves W/O doc",
    dataField: "sickLeavesWoDoc",
    sort: false,
  },
  {
    text: "Sick Leaves With doc",
    dataField: "sickLeaves",
    sort: false,
  },
  {
    text: "Parental Leaves",
    dataField: "parentalLeaves",
    sort: false,
  },
  {
    text: "Total Leaves Availed",
    dataField: "totalLeavesAvailed",
    sort: false,
  },
];

const buildTableRows = (rows) => {
  let tempRows = [];
  rows.forEach((item) => {
    if (item instanceof LeaveStatisticsDto) {
      let tempRow = {
        employeeName: item.employeeName,
        casualLeaves: item.casualLeaves,
        parentalLeaves: item.parentalLeaves,
        remainingLeaves: item.remainingLeaves,
        sickLeaves: item.sickLeaves,
        sickLeavesWoDoc: item.sickLeavesWoDoc,
        totalAvailableLeaves: item.totalAvailableLeaves,
        totalLeavesAvailed: item.totalLeavesAvailed,
        teams: item.teamName,
      };
      tempRows.push(tempRow);
    }
  });
  return tempRows;
};
const LeaveStatisticsTable = (props) => {
  let rows = buildTableRows(props.LeaveStatistics, props.profile, props.teams, props.userRoles);
  return <DataTable rows={rows} columns={dataTableColumns} />;
};
const LeaveStatisticsView = (props) => {
  return (
    <div>
      <LeaveStatisticsTable {...props} />
    </div>
  );
};
LeaveStatisticsView.prototype = {
  LeaveStatistics: PropTypes.arrayOf(LeaveStatisticsDto),
};

export default LeaveStatisticsView;
