import React from "react";
import { DataTable } from "../../../library/table/Index";
import { v4 as uuidv4 } from "uuid";
import TrainingHistoryDeleteAction from "../actions/TrainingHistoryDeleteAction";
import { DateOnlyStringMMDDYY } from "../../../helpers/Builders";
import { multiLineTextFormatter } from "../../../helpers/Formatter";

let dataTableColumnsWithoutActions = [
  {
    text: "Title",
    dataField: "title",
    sort: true,
  },
  {
    text: "Organizer",
    dataField: "organizer",
    sort: true,
  },
  {
    text: "Start Date",
    dataField: "startDate",
    sort: true,
  },
  {
    text: "Duration (In Hours)",
    dataField: "duration",
    sort: true,
  },
  {
    text: "Description",
    dataField: "description",
    sort: true,
    formatter: multiLineTextFormatter
  },
];
let dataTableColumnsWithActions = [
  {
    text: "Title",
    dataField: "title",
    sort: true,
  },
  {
    text: "Organizer",
    dataField: "organizer",
    sort: true,
  },
  {
    text: "Start Date",
    dataField: "startDate",
    sort: true,
  },
  {
    text: "Duration (In Hours)",
    dataField: "duration",
    sort: true,
  },
  {
    text: "Description",
    dataField: "description",
    sort: true,
    formatter: multiLineTextFormatter
  },
  {
    text: "Actions",
    dataField: "actions",
    sort: false,
  },
];

const ActionGroup = (props) => {
  return (
    <div className="d-inline-block action-col">
      <TrainingHistoryDeleteAction
        trainingHistoryRow={props.trainingHistoryRow}
        onDeleteCallback={props.onDeleteCallback}
        key={uuidv4()}
      />
    </div>
  );
};

const buildTableRows = (trainingHistory, hasActionGroup, onDeleteCallback) => {
  let tempRows = [];
  if (trainingHistory !== null) {
    trainingHistory.forEach((item) => {
      let tempRow = {
        title: item.title,
        organizer: item.organizer,
        duration: item.duration,
        startDate: DateOnlyStringMMDDYY(item.startDate),
        description: item.description,
      };
      if (hasActionGroup) {
        tempRow["actions"] = (
          <ActionGroup
            trainingHistoryRow={item}
            onDeleteCallback={onDeleteCallback}
          />
        );
      }
      tempRows.push(tempRow);
    });
  }
  return tempRows;
};
const TrainingHistoryTable = (props) => {
  let rows = buildTableRows(
    props.trainingHistory,
    props.hasActionGroup,
    props.onDelete
  );
  return (
    <DataTable
      rows={rows}
      columns={
        props.hasActionGroup
          ? dataTableColumnsWithActions
          : dataTableColumnsWithoutActions
      }
      excludeSearch={true}
      excludePagination={true}
    />
  );
};
const TrainingHistoryView = (props) => {
  return (
    <div>
      <TrainingHistoryTable {...props} />
    </div>
  );
};
export default TrainingHistoryView;
