import React from "react";
import UserManager from "../../../navigation/UserManager";
import NocUpdateApplication from "../actions/NocUpdateApplication";
import NocResponseApplication from "../actions/NocResponseApplication";
import DeleteActions from "../../../shared-components/application-action-form/DeleteActions";
import { v4 as uuid4 } from "uuid";
import NocReportDownloadAction from "../actions/NocReportDownloadAction";
import NocCancelApplication from "../actions/NocCancelApplication";
import { NocStatusValueDictionary } from "../Const";

const WithPermittedAction = (WrappedComponent) => (props) => {
  const user = UserManager.getUser();
  const { actionsCallbacks, onCancelCallback, profile, isSupervision, userId } =
    props;
  const canEdit = (status) => {
    return (
      status === NocStatusValueDictionary.Drafted ||
      (user.isHrOrAdmin() &&
        (status === NocStatusValueDictionary.Requested ||
          status === NocStatusValueDictionary.Approved))
    );
  };

  const canResponse = (status) => {
    return (
      (user.isHrOrAdmin() && status === NocStatusValueDictionary.Requested) ||
      status === NocStatusValueDictionary.CancelRequested
    );
  };

  const canDownload = (status) => {
    return status === NocStatusValueDictionary.Approved;
  };

  const canDelete = () => user.isHrOrAdmin();

  const canCancel = (status) => {
    return status === NocStatusValueDictionary.Requested;
  };

  const getActions = (application) => {
    let actions = [];
    const { status } = application;

    if (canEdit(status)) {
      actions.push(
        <NocUpdateApplication
          application={application}
          onUpdateCallback={actionsCallbacks.onUpdate}
          isSupervision={isSupervision}
          profile={profile}
          key={uuid4()}
        />
      );
    }
    if (canResponse(status)) {
      actions.push(
        <NocResponseApplication
          onUpdateStatusCallback={actionsCallbacks.onResponse}
          key={uuid4()}
          application={application}
        />
      );
    }
    if (canDelete()) {
      actions.push(
        <DeleteActions
          onDeleteCallback={actionsCallbacks.onDelete}
          applicationId={application.id}
          key={uuid4()}
        />
      );
    }

    if (canDownload(status)) {
      actions.push(
        <NocReportDownloadAction
          applicationId={application.id}
          application={application}
          key={uuid4()}
        />
      );
    }

    if (userId === application.userId && canCancel(status)) {
      actions.push(
        <NocCancelApplication
          onCancelCallback={onCancelCallback}
          application={application}
          applicationId={application.id}
          key={uuid4()}
        />
      );
    }

    return actions;
  };

  return <WrappedComponent {...props} getActions={getActions} />;
};

export default WithPermittedAction;
