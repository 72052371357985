import React from "react";
import { SkillDto } from "../../../models/Skill";
import { DataTable } from "../../../library/table/Index";
import ActionsGroupView from "./ActionsGroupView";

const SkillTableView = ({ skills, onUpdate, onDelete, parentSkillOptions }) => {
  const buildTableRowsFromTeamData = (skills, parentSkillOptions, deleteAction, updateAction, ) => {
    let tempRows = [];
    skills.forEach((item) => {
      if (item instanceof SkillDto) {
        let tempRow = {};
        tempRow["id"] = item.id;
        tempRow["name"] = item.name;
        tempRow["parentSkillName"] = item.parentSkillName;
        tempRow["actions"] = (
          <ActionsGroupView
            parentSkillOptions={parentSkillOptions}
            skill={item}
            deleteAction={onDelete}
            updateAction={onUpdate}
          />
        );
        tempRows.push(tempRow);
      }
    });
    return tempRows;
  };

  const skillTableColumns = [
    {
      text: "Skill Id",
      dataField: "id",
      sort: true,
    },
    {
      text: "Name",
      dataField: "name",
      sort: true,
    },
    {
      text: "Parent Skill",
      dataField: "parentSkillName",
      sort: true,
    },
    {
      text: "Actions",
      dataField: "actions",
      sort: false,
    },
  ];

  let rows = buildTableRowsFromTeamData(skills, parentSkillOptions);
  return <DataTable rows={rows} columns={skillTableColumns} />;
};

export default SkillTableView;
