import React from "react";
import { Nav } from "react-bootstrap";
import { isPathActive, getNavItemStyleClass } from "./Const";

export default function NavbarItem({
  navInfo = {},
  activePath,
  ...restProps
}) {
  return (
    <Nav.Link
      {...restProps}
      className={getNavItemStyleClass(isPathActive(activePath,navInfo.path))}
      href={navInfo.path}
    >
      {navInfo.title}
    </Nav.Link>
  );
}
