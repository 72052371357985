import React from "react";
import { Table } from "react-bootstrap";

const UserProfileAttendanceStatView = (props) => {
  const profileAttendanceStat = props.attendanceStat;
  // const bindPageData = useCallback(() => {}, [props.userId]);

  // useEffect(() => {
  //   bindPageData();
  // }, [bindPageData]);

  return (
    <div className="mt-4">
      <div className="table-striped">
        <Table bordered className="table-sm">
          <thead>
            <tr>
              <th>
                <b>Attendance</b>
              </th>
              <th>
                <b>Count</b>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Total Working Days</td>
              <td>{profileAttendanceStat?.totalWorkingDays}</td>
            </tr>
            <tr>
              <td>Office Presence</td>
              <td>{profileAttendanceStat?.officePresence}</td>
            </tr>
            <tr>
              <td>Late Entries</td>
              <td>{profileAttendanceStat?.lateEntries}</td>
            </tr>
            <tr>
              <td>Less Hours</td>
              <td>{profileAttendanceStat?.lessHours}</td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div className="table-striped"></div>
    </div>
  );
};

export default UserProfileAttendanceStatView;
