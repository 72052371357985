import React from "react";
import ApplicationCreateAction from "../actions/CreateAction";
import UserManager from "../../../navigation/UserManager";
import FilterFormView from "./FilterFormView";
import ApplicationTable from "./ApplicationTable";
import { SkillDevelopmentRequestDto } from "../../../models/SkillDevelopmentFund";
import PropTypes from "prop-types";
import { SkillDevelopmentFundSummary } from "./SkillDevelopmentFundSummary";

function SkillDevelopmentApplicationView(props) {
  const user = UserManager.getUser();
  return (
    <>
      <div>
        <FilterFormView
          selectedUserId={props.selectedUserId}
          users={props.users}
          userId={props.userId}
          callbacks={props.callbacks}
        >
          {
            !user.isTeamManagerVisualizingOtherMembers(props.selectedUserId) &&
            <ApplicationCreateAction
              callbacks={props.callbacks}
              availableFund={props.fundSummary?.fundAvailable ?? 0}
              userId={props.userId}
            />
          }
        </FilterFormView>
        <ApplicationTable
          isTeamManagerVisualizingOtherMembers={user.isTeamManagerVisualizingOtherMembers}
          selectedUserId={props.selectedUserId}
          userId={props.userId}
          callbacks={props.callbacks}
          requests={props.requests}
        />
        <SkillDevelopmentFundSummary callbacks={props.callbacks} fundSummary={props.fundSummary} />
      </div>
    </>
  );
};

SkillDevelopmentApplicationView.propType = {
  callbacks: PropTypes.object.isRequired,
  requests: PropTypes.arrayOf(PropTypes.objectOf(SkillDevelopmentRequestDto)).isRequired,
}

export default SkillDevelopmentApplicationView;
