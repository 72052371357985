import React from "react";
import { CefaloButton } from "../../../library/button/Index";
import EmployeeCertificationUpsertForm from "../forms/EmployeeCertificationUpsertForm";
import { defaultErrorResponseParser } from "../../../helpers/ErrorParser";
import {
  toaster,
  Loading,
} from "../../../shared-components/notification/Notification";
import { showErrorMessages } from "../../../shared-components/response-message/ResponseMessage";
import EmployeeCertificationApi from "../../../apis/endpoints/EmployeeCertificationApi";

class EmployeeCertificationUpdateAction extends React.Component {
  state = {
    show: false,
    isLoading: false,
  };

  toggle = (e) => () => {
    this.setState({ show: !this.state.show });
  };

  onUpdateAction = (data) => {
    this.setState({ isLoading: true });

    EmployeeCertificationApi.update(data)
      .then((res) => {
        this.setState({
          isLoading: false,
          show: false,
        });
        toaster.success("Employee Certification Updated Successfully");
        this.props.onUpdateCallback(this.props.profile.userId);
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        if (err.response.data) showErrorMessages(err.response.data);
        else
          defaultErrorResponseParser(
            err,
            "Failed To Update Employee Certification"
          );
      });
  };

  render() {
    return (
      <div className="d-inline-block">
        <Loading show={this.state.isLoading} />
        <CefaloButton
          className="btn-font-12"
          size="sm"
          variant="primary"
          onClick={this.toggle()}
        >
          Edit
        </CefaloButton>
        <EmployeeCertificationUpsertForm
          formName={"Update Team"}
          show={this.state.show}
          toggle={this.toggle}
          onSubmit={this.onUpdateAction}
          profile={this.props.profile}
          defaultValues={this.props.employeeCertificationRow}
        />
      </div>
    );
  }
}

export default EmployeeCertificationUpdateAction;
