export const getOfficeStartTimeOptions = (start = 4, end = 11, step = 30) => {
  let length = ((end - start + 1) * 60) / step;
  let requiredArray = Array(length)
    .fill()
    .map((_, index) => {
      let totalMinutes = index * step;
      let hours = parseInt(start + totalMinutes / 60);
      let minutes = totalMinutes % 60;
      minutes = minutes === 0 ? "00" : minutes;
      hours = hours < 10 ? `0${hours}` : hours;

      return {
        value: `${hours}:${minutes}:00`,
        label: `${hours}:${minutes}`,
      };
    });

  return requiredArray;
};

export const getIntervalOptions = (start = 0, end = 10, step = 0.5) => {
  let length = (end - start + 1) / step;
  let requiredArray = Array(length)
    .fill()
    .map((_, index) => start + index * step);
  return requiredArray.map((value) => ({
    value,
    label: value,
  }));
};

export const OfficeTimingTable = [
  {
    text: "Settings Type",
    dataField: "settingsType",
  },
  {
    text: "Settings Data",
    dataField: "settingsData",
  },
  {
    text: "Actions",
    dataField: "action",
  },
];

export const DefaultOfficeTimingTableCol = [
  {
    label: "Office Start",
    field: "officeStartTime",
  },
  {
    label: "Office Start Grace Hour",
    field: "officeStartGraceHour",
  },
  {
    label: "Office Hour",
    field: "officeHour",
  },
  {
    label: "Lunch Hour",
    field: "lunchHour",
  },
];

export const CustomOfficeTimingTableCol = [
  {
    label: "Start Date",
    field: "startDate",
  },
  {
    label: "End Date",
    field: "endDate",
  },
  ...DefaultOfficeTimingTableCol,
];
