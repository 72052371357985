import React from "react";
import { Button } from "react-bootstrap";
import "./button.css";
import cefaloButtonStyles from "./button.module.css";

export const CefaloButton = (props) => {
  const {
    children,
    size = "sm",
    variant = "primary",
    className = "",
    bootstrap4 = false,
    ...restProps
  } = props;

  let styleClassName = bootstrap4
    ? `${className}`
    : `${cefaloButtonStyles[variant]} ${cefaloButtonStyles.cefalo_btn} ${className}`;

  return (
    <Button
      className={styleClassName}
      size={size}
      variant={variant}
      {...restProps}
    >
      {children}
    </Button>
  );
};
