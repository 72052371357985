import { AuthenticatedAxios } from "../settings/Axios";
const BaseEndPoint = "LeaveApplications";
const LeaveUserEndPoint = "Users";
let config = {
  headers: {
    "Content-Type": "application/json",
  },
};
export default {
  all: (options) => {
    let endpoint = `${BaseEndPoint}?fromDate=${options.fromDate}&toDate=${options.toDate}`;
    if (options.leaveType) {
      endpoint += `&ApplicationType=${options.leaveType}`;
    }
    if (options.leaveStatus) {
      endpoint += `&ApplicationStatus=${options.leaveStatus}`;
    }
    return AuthenticatedAxios.get(endpoint, null, null);
  },
  getLeave: (options, userId) => {
    let endpoint = `${LeaveUserEndPoint}/${userId}/LeaveApplications?fromDate=${options.fromDate}&toDate=${options.toDate}`;
    if (options.leaveType) {
      endpoint += `&ApplicationType=${options.leaveType}`;
    }
    if (options.leaveStatus) {
      endpoint += `&ApplicationStatus=${options.leaveStatus}`;
    }
    return AuthenticatedAxios.get(endpoint, null, null);
  },

  create: (data) => {
    let endpoint = `${BaseEndPoint}`;
    let body = JSON.stringify(data);
    return AuthenticatedAxios.post(endpoint, body, config);
  },

  updateStatus: (data) => {
    let endpoint = `${BaseEndPoint}/Status`;
    let body = JSON.stringify(data);
    return AuthenticatedAxios.patch(endpoint, body, config);
  },

  delete: (id) => {
    let endpoint = `${BaseEndPoint}/${id}`;
    return AuthenticatedAxios.delete(endpoint, null, null);
  },

  update: (id, data) => {
    let endpoint = `${BaseEndPoint}/${id}`;
    let body = JSON.stringify(data);
    return AuthenticatedAxios.put(endpoint, body, config);
  },

  previewLeaveData: (formData) => {
    let fileUploadConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    let endpoint = `${BaseEndPoint}/DataFilePreview`;
    return AuthenticatedAxios.post(endpoint, formData, fileUploadConfig);
  },

  upload: (dataList) => {
    let body = JSON.stringify(dataList);
    let endpoint = `${BaseEndPoint}/Upload`;
    return AuthenticatedAxios.post(endpoint, body, config);
  },

  getAllLeaveStatus: () => {
    return AuthenticatedAxios.get(`${BaseEndPoint}/AllStatus`);
  },

  getAllLeaveType: () => {
    return AuthenticatedAxios.get(`${BaseEndPoint}/AllTypes`);
  },

  getLeaveTypesByUserId: (userId) => {
    return AuthenticatedAxios.get(`${BaseEndPoint}/LeaveTypes/${userId}`);
  },
};
