import React from "react";
import PropTypes from "prop-types";
import ApplicationClaimFormModal from "../forms/ClaimForm";
import { CefaloButton } from "../../../library/button/Index";
import { SkillDevelopmentRequestDto } from "../../../models/SkillDevelopmentFund";

function ApplicationClaimAction(props) {
  const [show, setShow] = React.useState(false);
  const toggleShow = React.useCallback(() => setShow(state => !state), [setShow]);

  return (
    <div className="d-inline-block" style={{ marginLeft: "auto" }}>
      <CefaloButton
        size="sm"
        variant="primary"
        className="btn-font-12"
        onClick={toggleShow}
      >
        Claim
      </CefaloButton>
      <ApplicationClaimFormModal
        request={props.request}
        show={show}
        onClaim={props.onClaim}
        onToggle={toggleShow}
      />
    </div>
  );
}

ApplicationClaimAction.propTypes = {
  request: PropTypes.instanceOf(SkillDevelopmentRequestDto).isRequired,
  onClaim: PropTypes.func.isRequired,
};

export default ApplicationClaimAction;
