import React from "react";
import Layout from "../../shared-components/layout/Layout";
import { Helmet } from "react-helmet";
import ExportAvailableEmployeeView from "./views/ExportAvailableEmployeeView";
import {
  toaster,
  Loading,
} from "../../shared-components/notification/Notification";
import UserManager from "../../navigation/UserManager";
import ManageExportApi from "../../apis/endpoints/ManageExportApi";
import { defaultErrorResponseParser } from "../../helpers/ErrorParser";
import { Row } from "react-bootstrap";
import Select from "react-select";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/saga-blue/theme.css";
import { CefaloButton } from "../../library/button/Index";
import { FaFilter, FaCaretDown } from "react-icons/fa";
import CefaloIcon from "../../library/Icons/Icon";
import Collapse from "@kunukn/react-collapse";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { Container } from "../../library/grid-layout/Index";
import { AvailableEmployeeDto } from "../../models/AvailableEmployeeDto";
import styles from "./styles/AvailableEmployee.module.css";

const ViewContainer = (props) => <div className="p-2">{props.children}</div>;

class ExportAvailableEmployeeContainer extends React.Component {
  state = {
    availableEmployees: [],
    availabilityType: 0,
    teams: [],
    teamNames: [],
    userRoles: [],
    profile: null,
    isLoading: false,
    shouldRender: false,
  };

  overrideStrings = {
    allItemsAreSelected: "All",
    clearSearch: "Clear Search",
    clearSelected: "Clear Selected",
    noOptions: "No options",
    search: "Search",
    selectAll: "All",
    selectAllFiltered: "Select All (Filtered)",
    selectSomeItems: "Select",
    create: "Create",
  };

  componentDidMount = () => {
    let user = UserManager.getUser();
    if (!user.isAdminOrHr()) {
      window.location.replace(`/Report/Index/${user.id}`);
    } else {
      this.setState({ shouldRender: true, isLoading: true });
      this.getAvailableEmployess();
    }
  };

  getAvailableEmployess = () => {
    this.setState({ isLoading: true });

    ManageExportApi.getAvailableEmployeeInfo(this.state.availabilityType)
      .then((res) => {
        let availableEmployees = res.data.map(
          (e) => new AvailableEmployeeDto(e)
        );
        this.setState({
          isLoading: false,
          availableEmployees: availableEmployees,
        });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        defaultErrorResponseParser(
          err,
          "Failed to load available employee information"
        );
      });
  };

  handleAvailabilityType = (e) => {
    this.setState({
      availabilityType: e.value,
    });
  };

  handleExport = () => {
    this.setState({ isLoading: true });
    this.getAvailableEmployess();
    ManageExportApi.downloadAvailableEmployeeInfo(this.state.availabilityType)
      .then((res) => {
        this.setState({ isLoading: false });
        let fileName = res.headers["x-attachmentname"];
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("Download", fileName);
        link.click();
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        toaster.error("Failed to download available employee information");
      });
  };

  toggle = (index) => {
    let collapse = "isOpen" + index;

    this.setState((prevState) => ({ [collapse]: !prevState[collapse] }));
  };

  render() {
    const availabilityOptions = [
      { label: "All", value: 0 },
      { label: "Current", value: 1 },
      { label: "Future", value: 2 },
    ];

    if (!this.state.shouldRender) {
      return <></>;
    }

    return (
      <Layout>
        <Helmet>
          <title>Available Employee - Cefalo</title>
        </Helmet>
        <Loading show={this.state.isLoading} />
        <ViewContainer>
          <div className="d-flex justify-content-end">
            <div className="z-index-3 pt-1 pb-2">
              <div className=" d-inline-block pt-2 pr-2 pl-2">
                <CefaloButton
                  block={true}
                  variant="primary"
                  size="sm"
                  type="button"
                  onClick={this.handleExport}
                >
                  Export Report
                </CefaloButton>
              </div>
              <OverlayTrigger
                key="bottom"
                placement="bottom"
                overlay={
                  <Tooltip id="attendance-tooltip-bottom">Filter</Tooltip>
                }
              >
                <CefaloButton
                  className="mb-1"
                  style={{ zIndex: 100 }}
                  onClick={() => this.toggle(1)}
                  variant="light"
                  size="sm"
                  aria-controls="attendance-filter-collpase"
                  aria-expanded={this.state.collapse}
                >
                  <CefaloIcon Icon={FaFilter} />
                  <CefaloIcon Icon={FaCaretDown} />
                </CefaloButton>
              </OverlayTrigger>
            </div>
          </div>
          <Collapse
            isOpen={this.state.isOpen1}
            style={this.state.isOpen1 ? { overflow: "visible" } : {}}
          >
            <Container fluid className="p-0">
              <div>
                <Row>
                  <div className="col">
                    <h6
                      className="d-inline-block pr-2"
                      style={{ lineHeight: "30px", fontSize: "17px" }}
                    >
                      Availability Type-
                    </h6>
                    <Select
                      className={styles["availability-dropdown"]}
                      name="availabilityType"
                      options={availabilityOptions}
                      onChange={this.handleAvailabilityType.bind(this)}
                      placeholder="All"
                    />
                  </div>

                  <div className="col mr-1 pb-5">
                    <div style={{ marginTop: '20px'}} className="pt-3 pr-4 pl-3">
                      <CefaloButton
                        block={true}
                        variant="primary"
                        size=""
                        type="button"
                        onClick={this.getAvailableEmployess}
                      >
                        Filter Report
                      </CefaloButton>
                    </div>
                  </div>
                </Row>
              </div>
            </Container>
          </Collapse>
          <ExportAvailableEmployeeView
            availableEmployees={this.state.availableEmployees}
          />
        </ViewContainer>
      </Layout>
    );
  }
}
export default ExportAvailableEmployeeContainer;
