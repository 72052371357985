import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import { Loading } from "../../shared-components/notification";
import LoginCefaloHeader from "../../shared-components/login/loginCefaloHeader";
import PasswordField from "../../shared-components/forms/PasswordField";
import { CefaloButton } from "../../library/button/Index";

export default function InternalLoginView({
  onChange,
  onSubmit,
  email,
  password,
  submitError,
  loading,
}) {
  return (
    <div>
      <div className="loginHeaderContainer">
        <LoginCefaloHeader />
      </div>
      <div className="container-fluid flex-center">
        <h4 className="text-uppercase text-muted">Internal Login</h4>
      </div>
      <br />
      <Loading show={loading} />
      <div className="container-fluid">
        <div className="row flex-center">
          <div className="col-xs-11 col-sm-10 col-md-6">
            <Form onSubmit={onSubmit}>
              <Form.Group as={Row} controlId="formHorizontalEmail">
                <Form.Label column sm={2}>
                  Email
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type="email"
                    name="email"
                    onChange={onChange}
                    value={email}
                    placeholder="Email"
                    required
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formHorizontalPassword">
                <Form.Label column sm={2}>
                  Password
                </Form.Label>
                <Col sm={10}>
                  <PasswordField
                    fieldName="password"
                    fieldValue={password}
                    onChange={onChange}
                    required={true}
                    placeholder="Password"
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Col sm={{ offset: 2 }}>
                  <CefaloButton
                    bootstrap4={true}
                    type="submit"
                    variant="success"
                    size="lg"
                  >
                    Sign In
                  </CefaloButton>
                </Col>
              </Form.Group>
              {submitError && (
                <Row className="justify-content-md-center">
                  <Alert variant={"danger"}>Invalid email or password</Alert>
                </Row>
              )}
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}
