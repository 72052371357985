import React from "react";
import PropTypes from "prop-types";
import { DataTable } from "../../../library/table/Index";
import { BillDto } from "../../../models/Bill";

let dataTableColumns = [
  {
    text: "Name",
    dataField: "name",
    sort: true,
  },
  {
    text: "Short Name",
    dataField: "shortName",
    sort: false,
  },
  {
    text: "Career Start Date",
    dataField: "careerStartDate",
    sort: false,
  },
  {
    text: "Joining Date",
    dataField: "joiningDate",
    sort: true,
  },
  {
    text: "Team Name",
    dataField: "teamName",
    sort: true,
  },
  {
    text: "Status",
    dataField: "status",
    sort: false,
  },
  {
    text: "Customer",
    dataField: "customer",
    sort: false,
  },
  {
    text: "KAM",
    dataField: "kam",
    sort: false,
  },
  {
    text: "Experience",
    dataField: "experience",
    sort: false,
  },
  {
    text: "Job Tenure",
    dataField: "jobTenure",
    sort: false,
  },
  {
    text: "Designation",
    dataField: "designation",
    sort: false,
  },
  {
    text: "Billing Designation",
    dataField: "billingDesignation",
    sort: false,
  },
  {
    text: "Allocation",
    dataField: "allocation",
    sort: false,
  },
  {
    text: "FromDate",
    dataField: "fromDate",
    sort: false,
  },
  {
    text: "ToDate",
    dataField: "toDate",
    sort: false,
  },
  {
    text: "ExtraWorkHour",
    dataField: "extraWorkHour",
    sort: false,
  },
  {
    text: "LeavesThisMonth",
    dataField: "leavesThisMonth",
    sort: false,
  },
  {
    text: "VacationLeavesThisMonth",
    dataField: "vacationLeavesThisMonth",
    sort: false,
  },
  {
    text: "OtherLeavesThisMonth",
    dataField: "otherLeavesThisMonth",
    sort: false,
  },
  {
    text: "LeavesThisYear",
    dataField: "leavesThisYear",
    sort: false,
  },
  {
    text: "VacationLeavesThisYear",
    dataField: "vacationLeavesThisYear",
    sort: false,
  },
  {
    text: "OtherLeavesThisYear",
    dataField: "otherLeavesThisYear",
    sort: false,
  },
  {
    text: "BillingStatus",
    dataField: "billingStatus",
    sort: false,
  },
];

const buildTableRows = (rows) => {
  let tempRows = [];
  rows.forEach((item) => {
    
    if (item instanceof BillDto) {
      let tempRow = {
        name: item.name,
        shortName: item.shortName,
        teamName: item.teamName,
        jobTenure: item.jobTenure,
        experience: item.experience,
        designation: item.designation,
        billingDesignation: item.billingDesignation,
        allocation: item.allocation,
        fromDate: item.fromDate,
        toDate: item.toDate,
        careerStartDate: item.careerStartDate,
        joiningDate: item.joiningDate,
        extraWorkHour: item.extraWorkHour,
        leavesThisMonth: item.leavesThisMonth,
        vacationLeavesThisMonth: item.vacationLeavesThisMonth,
        otherLeavesThisMonth: item.otherLeavesThisMonth,
        billingStatus: item.billingStatus,
        leavesThisYear: item.leavesThisYear,
        vacationLeavesThisYear: item.vacationLeavesThisYear,
        otherLeavesThisYear: item.otherLeavesThisYear,
        kam: item.kam,
        customer: item.customer,
        status: item.status, 
      };
      tempRows.push(tempRow);
    }
  });
  return tempRows;
};
const BillingTable = (props) => {
  let rows = buildTableRows(props.Bills, props.profile, props.teams, props.userRoles);
  return <DataTable rows={rows} columns={dataTableColumns} />;
};
const BillingView = (props) => {
  return (
    <div>
      <BillingTable {...props} />
    </div>
  );
};
BillingView.prototype = {
  Bills: PropTypes.arrayOf(BillDto),
};

export default BillingView;
