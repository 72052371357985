import React from "react";
import DatePicker from "react-datepicker";
import { Form } from "react-bootstrap";

const CefaloTimepicker = ({ time, onChange, hasLabel = true, label = "" }) => {
  return (
    <Form.Group>
      {hasLabel ? <Form.Label>{label}</Form.Label> : <></>}
      <DatePicker
        className="form-control form-control-sm"
        selected={time}
        onChange={onChange}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={5}
        timeCaption="Time"
        dateFormat="HH: mm"
      />
    </Form.Group>
  );
};

export default CefaloTimepicker;
