import { AuthenticatedAxios } from "../settings/Axios";

const BaseEndPoint = "Clients";
let config = {
  headers: {
    "Content-Type": "application/json",
  },
};

export default {
  getAll: () => {
    return AuthenticatedAxios.get(BaseEndPoint, null, null);
  },

  getById: (id) => {
    let endpoint = `${BaseEndPoint}/${id}`;
    return AuthenticatedAxios.get(endpoint);
  },

  getKeyAccountManagers: () => {
    let endpoint = `${BaseEndPoint}/KAM`;
    return AuthenticatedAxios.get(endpoint);
  },

  create: (client) => {
    let body = JSON.stringify(client);
    return AuthenticatedAxios.post(BaseEndPoint, body, config);
  },

  update: (client) => {
    let endpoint = `${BaseEndPoint}/${client.id}`;
    let body = JSON.stringify(client);
    return AuthenticatedAxios.put(endpoint, body, config);
  },

  delete: (id) => {
    let endpoint = `${BaseEndPoint}/${id}`;
    return AuthenticatedAxios.delete(endpoint, null, {});
  },
};
