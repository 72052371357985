import { AuthenticatedAxios } from "../settings/Axios";
const BaseEndPoint = "TeamHistory";
const UserBaseEndPoint = "Users";
let config = {
  headers: {
    "Content-Type": "application/json",
  },
};

export default {
  getAll: () => {
    return AuthenticatedAxios.get(BaseEndPoint, null, null);
  },
  getByUserId: (id) => {
    let endpoint = `${UserBaseEndPoint}/${id}/${BaseEndPoint}`;
    return AuthenticatedAxios.get(endpoint);
  },
  create: (teamHistory) => {
    let body = JSON.stringify(teamHistory);
    return AuthenticatedAxios.post(BaseEndPoint, body, config);
  },
  update: (teamHistory) => {
    let endpoint = `${BaseEndPoint}/${teamHistory.id}`;
    let body = JSON.stringify(teamHistory);

    return AuthenticatedAxios.put(endpoint, body, config);
  },
  delete: (id) => {
    let endpoint = `${BaseEndPoint}/${id}`;
    return AuthenticatedAxios.delete(endpoint, null, {});
  },
};
