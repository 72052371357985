import React from "react";
import { withRouter } from "react-router-dom";
import AppNavbarView from "./AppNavbarView";

class AppNavbar extends React.Component {
  getCurrentPath=()=>this.props.location.pathname
  state = {
    activePath:this.getCurrentPath()
  };
  componentDidMount = () => {
    this.setState({activePath:this.getCurrentPath()})
  };
  componentDidUpdate = () => {
    if(this.state.activePath!==this.getCurrentPath()){
      this.setState({activePath:this.getCurrentPath()})
    }
  };
  handleNavigation = (path)=>this.props.history.push(path)

  render() {
    return (
      <AppNavbarView
        handleNavigation={this.handleNavigation}
        activePath={this.state.activePath}
      />
    );
  }
}

export default withRouter(AppNavbar);
