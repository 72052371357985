import React from "react";
import PropTypes from "prop-types";

import SortUtility from "../../../helpers/SortUtility";
import { getTableFormatDate } from "../../../helpers/DateUtility";
import { DataTable } from "../../../library/table/Index";
import { RemoteWorkApplicationDetailDto } from "../../../models/RemoteWorkApplication";
import RowActions from "../../../shared-components/remote-application/actions/RowActions";

let dataTableColumns = [
  {
    text: "User Name",
    dataField: "userName",
    sort: true,
  },
  {
    text: "Team Name",
    dataField: "teamName",
    sort: true,
  },
  {
    text: "Application Date",
    dataField: "created",
    sort: true,
    sortFunc: SortUtility.dateSorter,
  },
  {
    text: "From Date",
    dataField: "fromDate",
    sort: true,
    sortFunc: SortUtility.dateSorter,
  },
  {
    text: "To Date",
    dataField: "toDate",
    sort: true,
    sortFunc: SortUtility.dateSorter,
  },
  {
    text: "Remote Work Type",
    dataField: "remoteWorkType",
    sort: true,
  },
  {
    text: "Working Days",
    dataField: "totalNumberOfWorkingDays",
    sort: true,
  },
  {
    text: "Alternative Office Date",
    dataField: "alternativeOfficeDate",
    sort: true,
  },
  {
    text: "Remote Work Status",
    dataField: "status",
    sort: true,
  },
  {
    text: "Actions",
    dataField: "actions",
    sort: false,
  },
];

const ApplicationTable = ({
  applications,
  actionsCallbacks,
  remoteWorkApplicationTypes,
  remoteWorkStatusTypes,
}) => {
  let rows = [];
  applications.forEach((item) => {
    if (item instanceof RemoteWorkApplicationDetailDto) {
      let tempRow = {};
      tempRow["userName"] = item.applicant.name;
      tempRow["teamName"] = item.applicant.teamNames.join(", ");
      tempRow["created"] = getTableFormatDate(item.created);
      tempRow["fromDate"] = getTableFormatDate(item.fromDate);
      tempRow["toDate"] = getTableFormatDate(item.toDate);
      tempRow["totalNumberOfWorkingDays"] = item.totalNumberOfWorkingDays;
      tempRow["alternativeOfficeDate"] =
        item.alternativeOfficeDate !== undefined
          ? getTableFormatDate(item.alternativeOfficeDate)
          : "";
      tempRow["status"] = item.remoteWorkStatusDisplayName;
      tempRow["remoteWorkType"] = item.remoteWorkTypeDisplayName;
      tempRow["actions"] = (
        <RowActions
          remoteWorkApplicationTypes={remoteWorkApplicationTypes}
          remoteWorkApplicationStatuses={remoteWorkStatusTypes}
          callbacks={actionsCallbacks}
          application={item}
          userId={item.userId}
        />
      );
      rows.push(tempRow);
    }
  });
  return (
    <DataTable
      rowMutedDateColumnName="toDate"
      rows={rows}
      columns={dataTableColumns}
    />
  );
};
ApplicationTable.prototype = {
  applications: PropTypes.arrayOf(RemoteWorkApplicationDetailDto).isRequired,
  actionsCallbacks: PropTypes.arrayOf(Function).isRequired,
  remoteWorkStatuses: PropTypes.arrayOf(Object).isRequired,
  remoteWorkTypes: PropTypes.arrayOf(Object).isRequired,
  remoteWorkSettings: PropTypes.object.isRequired,
};

export default ApplicationTable;
