import { toaster } from "../shared-components/notification";

export const defaultErrorResponseParser = (err, message = "") => {
  if (err.response) {
    if (err.response.data.Title) {
      if (Array.isArray(err.response.data.Title)) {
        toaster.error(err.response.data.Title.join(","))
      } else {
        toaster.error(err.response.data.Title);
      }
    } else {
      toaster.error(message);
    }
  } else {
    toaster.error("Failed to perform the request");
  }
};
