import React from "react";
import Layout from "../../shared-components/layout/Layout";
import { Helmet } from "react-helmet";
import UserApi from "../../apis/endpoints/UserApi";
import TeamApi from "../../apis/endpoints/TeamApi";
import {
  toaster,
  Loading,
} from "../../shared-components/notification/Notification";
import UserManager from "../../navigation/UserManager";
import LeaveBalanceApi from "../../apis/endpoints/LeaveBalanceApi";
import LeaveStatisticsPerTeamView from "./LeaveStatisticsPerTeamView";
import { LeaveStatisticsPerTeamDto } from "../../models/LeaveStatisticsPerTeamDto";
import { defaultErrorResponseParser } from "../../helpers/ErrorParser";
import { Form, Row } from "react-bootstrap";
import Select from "react-select";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/saga-blue/theme.css";
import { CefaloButton } from "../../library/button/Index";
import { FaFilter, FaCaretDown } from "react-icons/fa";
import CefaloIcon from "../../library/Icons/Icon";
import Collapse from "@kunukn/react-collapse";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { Container } from "../../library/grid-layout/Index";
import styles from "./styles/LeaveStatisticsPerTeam.module.css";

const ViewContainer = (props) => (
  <div className="p-2 m-2">{props.children}</div>
);

class LeaveStatisticsPerTeamContainer extends React.Component {
  state = {
    leaveStatistics: [],
    teams: [],
    teamNames: [],
    userRoles: [],
    profile: null,
    isLoading: false,
    shouldRender: false,
    leaveInfo: [],
    selectedTeam: [],
    users: [],
    selectedUser: [],
    year: new Date().getFullYear(),
    isOpen1: false,
  };

  componentDidMount = () => {
    let user = UserManager.getUser();
    let currentYear = new Date().getFullYear();
    this.setState({ year: currentYear });

    if (!user.isAdminOrHr()) {
      window.location.replace(`/Report/Index/${user.id}`);
    } else {
      this.setState({ shouldRender: true, isLoading: true });
      this.loadLeavStatisticsInformationOfYear();
      this.loadUserData();
      this.loadTeamData();
    }
  };

  loadTeamData = () => {
    TeamApi.all()
      .then((res) => {
        let teamNames = res.data;
        let names = [];
        names.push({ label: "All Teams", value: "ALL" });
        teamNames.forEach((element) => {
          var option = { label: element.name, value: element.id };
          names.push(option);
        });
        this.setState({
          teamNames: names,
        });
      })
      .catch((err) => {
        toaster.error("Failed to load Team information");
      });
  };

  loadUserData = () => {
    this.setState({ isLoading: true });
    UserApi.allCefaloUsers()
      .then((res) => {
        let usersData = res.data;
        let users = [];
        users.push({ label: "All Employees", value: "ALL" });
        usersData.forEach((element) => {
          var option = { label: element.name, value: element.id };
          users.push(option);
        });
        this.setState({
          users: users,
        });
      })
      .catch((err) => {
        toaster.error("Failed to load user information");
      });
  };

  loadLeavStatisticsInformationOfYear = () => {
    let year = this.state.year;
    this.setState({ isLoading: true });
    let endpoint = `?year=${year}`;
    LeaveBalanceApi.getLeaveStatisticsPerTeam(endpoint)
      .then((res) => {
        let leavestatistics = res.data.map(
          (u) => new LeaveStatisticsPerTeamDto(u)
        );
        this.setState({ isLoading: false, leaveStatistics: leavestatistics });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        defaultErrorResponseParser(err, "Failed to load Leave information");
      });
  };

  filterReport = () => {
    const endpoint = this.getEndPoint();
    this.getLeavStatisticsOfTeamAndUser(endpoint);
  };

  getEndPoint = () => {
    let teamIds = null;
    let userIds = null;

    if (this.state.selectedTeam.length !== 0) {
      const hasAll = this.state.selectedTeam.some(
        (item) => item.value === "ALL"
      );

      if (!hasAll) {
        teamIds = this.state.selectedTeam.map((item) => item.value);
      }
    }

    if (this.state.selectedUser.length !== 0) {
      const hasAll = this.state.selectedUser.some(
        (item) => item.value === "ALL"
      );

      if (!hasAll) {
        userIds = this.state.selectedUser.map((item) => item.value);
      }
    }

    let endpoint = `?year=${this.state.year}`;

    if (teamIds != null) {
      const teamIdsQueryString = teamIds
        .map((teamId) => `&teamIds=${teamId}`)
        .join("");
      endpoint += `${teamIdsQueryString}`;
    }

    if (userIds != null) {
      const userIdsQueryString = userIds
        .map((userId) => `&userIds=${userId}`)
        .join("");
      endpoint += `${userIdsQueryString}`;
    }

    return endpoint;
  };

  onYearSelect = (e) => {
    let year = Number.parseInt(e.target.value);
    this.setState({
      year: year,
    })
  };

  getLeavStatisticsOfTeamAndUser(endpoint) {
    this.setState({ isLoading: true });
    LeaveBalanceApi.getLeaveStatisticsPerTeam(endpoint)
      .then((res) => {
        let leavestatistics = res.data.map(
          (u) => new LeaveStatisticsPerTeamDto(u)
        );
        this.setState({ isLoading: false, leaveStatistics: leavestatistics });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        defaultErrorResponseParser(err, "Failed to load Leave information");
      });
  }
  teamhandleOnChange(event) {
    if (event == null) {
      this.setState({
        selectedTeam: [],
      });
    } else {
      this.setState({
        selectedTeam: event,
      });
    }
  }
  userhandleOnChange(event) {
    if (event == null) {
      this.setState({
        selectedUser: [],
      });
    } else {
      this.setState({
        selectedUser: event,
      });
    }
  }

  handleExport = () => {
    this.setState({ isLoading: true });

    const endpoint = this.getEndPoint();

    LeaveBalanceApi.downloadLeaveStatisticsPerTeam(endpoint)
      .then((res) => {
        this.setState({ isLoading: false });
        let fileName = res.headers["x-attachmentname"];
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("Download", fileName);
        link.click();
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        defaultErrorResponseParser(err, "Failed to export Leave statistics");
      });
  };

  toggle = (index) => {
    let collapse = "isOpen" + index;
    this.setState((prevState) => ({ [collapse]: !prevState[collapse] }));
  };
  render() {
    if (!this.state.shouldRender) {
      return <></>;
    }
    let currentYear = new Date().getFullYear();

    return (
      <Layout>
        <Helmet>
          <title>Leave Statistics - Cefalo</title>
        </Helmet>
        <Loading show={this.state.isLoading} />
        <ViewContainer>
          <div className="d-flex justify-content-end">
            <div className="z-index-3 pt-1 pb-2">
              <div className=" d-inline-block pt-2 pr-2 pl-2">
                <CefaloButton
                  block={true}
                  variant="primary"
                  size="sm"
                  type="button"
                  onClick={this.handleExport}
                >
                  Export Report
                </CefaloButton>
              </div>
              <OverlayTrigger
                key="bottom"
                placement="bottom"
                overlay={
                  <Tooltip id="attendance-tooltip-bottom">Filter</Tooltip>
                }
              >
                <CefaloButton
                  style={{ zIndex: 100, marginBottom: "3px" }}
                  onClick={() => this.toggle(1)}
                  variant="light"
                  size="sm"
                  aria-controls="attendance-filter-collpase"
                  aria-expanded={this.state.collapse}
                >
                  <CefaloIcon Icon={FaFilter} />
                  <CefaloIcon Icon={FaCaretDown} />
                </CefaloButton>
              </OverlayTrigger>
            </div>
          </div>
          <Collapse
            isOpen={this.state.isOpen1}
            style={this.state.isOpen1 ? { overflow: "visible" } : {}}
          >
            <Container fluid className="p-0">
              <div>
                <Row>
                  <div className="col-sm-1 pl-3 float-left">
                    <Form.Label
                      className={`${styles.customLabel} d-inline-block pr-2`}
                    >
                      Year:
                    </Form.Label>
                    <div className="inline-block">
                      <Form.Control
                        as="select"
                        defaultValue={currentYear}
                        className="d-inline-block"
                        onChange={this.onYearSelect}
                      >
                        <option value={currentYear}>{currentYear}</option>
                        <option value={currentYear - 1}>
                          {currentYear - 1}
                        </option>
                      </Form.Control>
                    </div>
                  </div>
                  <div className="col-sm-3 pl-3 ml-2">
                    <Form.Label
                      className={`${styles.customLabel} d-inline-block pr-2`}
                    >
                      Employees:
                    </Form.Label>
                    <Select
                      name="users"
                      isMulti={true}
                      isSearchable={true}
                      options={this.state.users}
                      onChange={this.userhandleOnChange.bind(this)}
                      carl
                      placeholder="All"
                    />
                  </div>
                  <div className="col-sm-3 pl-3 ml-2">
                    <Form.Label
                      className={`${styles.customLabel} d-inline-block pr-2`}
                    >
                      Teams:
                    </Form.Label>
                    <Select
                      name="teams"
                      isMulti={true}
                      isSearchable={true}
                      options={this.state.teamNames}
                      onChange={this.teamhandleOnChange.bind(this)}
                      placeholder="All"
                    />
                    <div className="d-inline-block" />
                  </div>
                  <div className={`${styles.filterMarginTop} col-sm-4 mr-2`}>
                    <div className="pt-3 pr-4 pl-3">
                      <CefaloButton
                        block={true}
                        variant="primary"
                        size=""
                        type="button"
                        onClick={this.filterReport}
                      >
                        Filter Report
                      </CefaloButton>
                    </div>
                  </div>
                </Row>
              </div>
            </Container>
          </Collapse>
          <LeaveStatisticsPerTeamView
            leaveStatistics={this.state.leaveStatistics}
          />
        </ViewContainer>
      </Layout>
    );
  }
}
export default LeaveStatisticsPerTeamContainer;
