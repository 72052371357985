import React from "react";
import PropTypes from "prop-types";
import FilterFormView from "./FilterFormView";
import ApplicationTable from "./ApplicationTable";

const SupervisionView = (props) => {
  let actionsCallbacks = {
    onDelete: props.onDelete,
    onUpdate: props.onUpdate,
    onStatusUpdate: props.onStatusUpdate,
  };
  return (
    <>
      <div>
        <FilterFormView
          onFilterAction={props.onFilter}
          leaveStatusTypes={props.leaveStatusTypes}
          leaveApplicationTypes={props.leaveApplicationTypes}
        />
        <ApplicationTable
          actionsCallbacks={actionsCallbacks}
          applications={props.applications}
          leaveSettings={props.leaveSettings}
          leaveStatuses={props.leaveStatusTypes}
          leaveTypes={props.leaveApplicationTypes}
        />
      </div>
    </>
  );
};
SupervisionView.prototype = {
  applications: PropTypes.arrayOf(Object).isRequired,
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdateStatus: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
  onStatusUpdate: PropTypes.func.isRequired,
  leaveStatusTypes: PropTypes.arrayOf(Object).isRequired,
  leaveApplicationTypes: PropTypes.arrayOf(Object).isRequired,
  leaveSettings: PropTypes.object.isRequired,
};

export default SupervisionView;
