import React from "react";
import Layout from "../../shared-components/layout/Layout";
import UserApi from "../../apis/endpoints/UserApi";
import SkillDevelopmentFundApi from "../../apis/endpoints/SkillDevelopmentFundApi";
import UserManager from "../../navigation/UserManager";
import { Helmet } from "react-helmet";
import { toaster, Loading } from "../../shared-components/notification";
import { UserWithTeamDto } from "../../models/User";
import { DateOnlyString } from "../../helpers/Builders";
import SkillDevelopmentApplicationView from "./views/SkillDevelopmentApplicationView";
import { SkillDevelopmentRequestDto } from "../../models/SkillDevelopmentFund";
import { GetInitialEndDateForFilter, GetInitialStartDateForFilter } from "./Utils";
import { defaultErrorResponseParser } from "../../helpers/ErrorParser";

class SkillDevelopmentApplicationContainer extends React.Component {

  constructor(props) {
    super(props);
    const userId = UserManager.getUser().id;
    this.state = {
      users: [],
      requests: [],
      userId: userId,
      fromDate: DateOnlyString(GetInitialStartDateForFilter()),
      toDate: DateOnlyString(GetInitialEndDateForFilter()),
      isLoading: false,
      selectedUserId: userId,
      fundSummaryYear: new Date().getFullYear(),
      fundSummary: null,
    };
  }

  setSelectedUserId = (selectedUserId) => this.setState({ selectedUserId });

  async componentDidMount() {
    this.setState({ isLoading: true });
    const user = UserManager.getUser();
    if (user.isAdminOrHr()) {
      await this.loadUserData();
    }
    await this.loadRequestData();
    this.setState({ isLoading: false });
  }

  loadRequestData = async (filters) => {
    const filterSrc = !!filters ? filters : this.state;
    let responseData, summary;
    try {
      const filters = {
        "StartDate": filterSrc.fromDate,
        "EndDate": filterSrc.toDate,
      };
      const res = await SkillDevelopmentFundApi.getAllByUser(this.state.userId, filters);
      responseData = res.data.map((item) => new SkillDevelopmentRequestDto(item));
      try {
        summary = await SkillDevelopmentFundApi.summary(this.state.userId, this.state.fundSummaryYear);
      } catch (err) {
        summary = {
          data: null
        };
        // toaster.error("Failed to load available fund for the user");
        defaultErrorResponseParser(err, "Failed to load information");
      }
    } catch (err) {
      defaultErrorResponseParser(err, "Failed to load information");
      // toaster.error("Failed to load fund requests");
      responseData = [];
    }
    this.setState({
      requests: responseData,
      fundSummary: summary.data
    });
  }

  loadUserData = async () => {
    try {
      const res = await UserApi.all();
      this.setState({
        users: res.data.map((item) => new UserWithTeamDto(item)),
      });
    } catch (err) {
      toaster.error("Failed to load User information");
    }
  };

  onUserChangeCallback = (userId) => {
    this.setState({ userId: userId });
  };

  onFilter = (opts) => {
    const filters = {
      fromDate: opts.fromDate,
      toDate: opts.toDate
    };
    this.setState({
      ...filters,
    });
    this.loadRequestData(filters);
  }

  canRenderComponent = () => {
    const user = UserManager.getUser();
    if (user.isAdminOrHr()) {
      return (
        !this.state.isLoading &&
        Array.isArray(this.state.users) &&
        this.state.users.length > 0
      );
    }
    return !this.state.isLoading;
  };

  onCreate = async (payload) => {
    this.setState({ isLoading: true });
    try {
      await SkillDevelopmentFundApi.create(payload);
      toaster.success("Fund Request created successfully");
      await this.loadRequestData();
    } catch (err) {
      defaultErrorResponseParser(err, "Failed to create Fund Request");
    }
    this.setState({ isLoading: false });
  }

  onDelete = async (id) => {
    this.setState({ isLoading: true });
    try {
      await SkillDevelopmentFundApi.delete(id);
      toaster.success("Fund Request deleted");
      await this.loadRequestData();
    } catch (err) {
      defaultErrorResponseParser(err, "Failed to delete Fund Request");
    }
    this.setState({ isLoading: false });
  }

  onCancel = async (id, reason) => {
    this.setState({ isLoading: true });
    try {
      await SkillDevelopmentFundApi.cancel(id, reason);
      toaster.success("Fund Request canceled");
      await this.loadRequestData();
    } catch (err) {
      defaultErrorResponseParser(err, "Failed to cancel Fund Request");
    }
    this.setState({ isLoading: false });
  }

  onClaim = async (id, claimOpts) => {
    const userId = this.state.userId ?? UserManager.getUser().id;
    const opts = {
      userId,
      ...claimOpts,
    }
    this.setState({ isLoading: true });
    try {
      await SkillDevelopmentFundApi.claim(id, opts);
      toaster.success("Fund claim submitted");
      await this.loadRequestData();
    } catch (err) {
      defaultErrorResponseParser(err, "Failed to submit fund claim");
    }
    this.setState({ isLoading: false });
  }

  onDownloadUrl = async (fileName) => {
    const userId = this.state.userId ?? UserManager.getUser().id;
    const opts = {
      fileName,
    }
    this.setState({ isLoading: true });
    try {
      const res = await SkillDevelopmentFundApi.documentData(userId, opts);
      debugger;
      const blobUrl = URL.createObjectURL(res.data);
      const a = document.createElement('a')
      a.href = blobUrl;
      a.download = fileName;
      a.click();
      setTimeout(() => URL.revokeObjectURL(blobUrl), 10000);
    } catch (err) {
      toaster.error("Failed to download attachment");
    }
    this.setState({ isLoading: false });
  }

  onSummaryYearChange = async (year) => {
    this.setState({ isLoading: true, fundSummaryYear: year });
    let summary;
    try {
      summary = await SkillDevelopmentFundApi.summary(this.state.userId, year);
    } catch (err) {
      summary = {
        data: null
      };
      toaster.error("Failed to load available fund for the user");
    }
    this.setState({
      isLoading: false,
      fundSummary: summary.data
    });
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <title>Skill Development Fund Request - Cefalo</title>
        </Helmet>
        <div className="p-3">
          <Loading show={this.state.isLoading || !this.canRenderComponent()} />
          <SkillDevelopmentApplicationView
            users={this.state.users}
            userId={this.state.userId}
            requests={this.state.requests}
            selectedUserId={this.state.selectedUserId}
            fundSummary={this.state.fundSummary}
            callbacks={{
              setSelectedUserId: this.setSelectedUserId,
              onUserChange: this.onUserChangeCallback,
              onCreate: this.onCreate,
              onFilter: this.onFilter,
              onCancel: this.onCancel,
              onClaim: this.onClaim,
              onRefund: () => { },
              onDelete: this.onDelete,
              onDownloadUrl: this.onDownloadUrl,
              onSummaryYearChange: this.onSummaryYearChange,
            }}
          />
        </div>
      </Layout>
    );
  }
}

export default SkillDevelopmentApplicationContainer
