import React from "react";
import AttendanceFilterForm from "../forms/AttendanceFilterForm";
import AttendanceExportAction from "../actions/AttendanceExportAction";
import UserSelectAction from "../actions/UserSelectAction";
import styles from "../styles/NavView.module.css";
import { Collapse, Tooltip, OverlayTrigger } from "react-bootstrap";
import { CefaloButton } from "../../../library/button";
import { FaFilter, FaCaretDown } from "react-icons/fa";
import CefaloIcon from "../../../library/Icons/Icon";

const NavView = (props) => {
  const [collapse, setCollapse] = React.useState(false);
  return (
    <div>
      <div className={styles["nav-container"]}>
        <div className="float-right z-index-2 pt-2">
          <AttendanceExportAction exportOptions={props.exportOptions} />
          <OverlayTrigger
            key="bottom"
            placement="bottom"
            overlay={<Tooltip id="attendance-tooltip-bottom">Filter</Tooltip>}
          >
            <CefaloButton
              className={styles["filter-btn"]}
              onClick={(e) => {
                setCollapse(!collapse);
              }}
              variant="light"
              aria-controls="attendance-filter-collpase"
              aria-expanded={collapse}
            >
              <CefaloIcon Icon={FaFilter} />
              <CefaloIcon Icon={FaCaretDown} />
            </CefaloButton>
          </OverlayTrigger>
        </div>
        <div>
          <UserSelectAction
            onUserSelect={props.onUserSelect}
            initialSelectedId={props.initialSelectedId}
          />
        </div>
      </div>

      <Collapse
        in={collapse}
        className={`list-group-item ${styles["filter-form-container"]}`}
      >
        <div id="attendance-filter-collpase">
          <AttendanceFilterForm
            onFilterAction={props.onFilter}
            statusTypes={props.statusTypes}
            selectedUserId={props.selectedUserId}
            teamNames={props.teamNames}
            filterWithTeam={props.filterWithTeam}
          />
        </div>
      </Collapse>
    </div>
  );
};

export default NavView;
