import React, { Component } from "react";
import AttendanceDataSyncView from "./AttendanceDataSyncView";
import { Helmet } from "react-helmet";
import AttendanceApi from "../../apis/endpoints/AttendanceApi";
export default class AttendanceDataSyncContainer extends Component {
  handleSubmit = (params) => AttendanceApi.syncData(params);

  render() {
    return (
      <>
        <Helmet>
          <title>Attendance Data Sync</title>
        </Helmet>
        <AttendanceDataSyncView handleSubmit={this.handleSubmit} />
      </>
    );
  }
}
