import React from "react";
import PropTypes from "prop-types";
import FilterFormView from "./FilterFormView";
import ApplicationTable from "./ApplicationTable";

const SupervisionView = (props) => {
  let actionsCallbacks = {
    onDelete: props.onDelete,
    onUpdate: props.onUpdate,
    onStatusUpdate: props.onStatusUpdate,
  };
  return (
    <>
      <div>
        <FilterFormView
          onFilterAction={props.onFilter}
          remoteWorkStatusTypes={props.remoteWorkStatusTypes}
          remoteWorkApplicationTypes={props.remoteWorkApplicationTypes}
        />

        <ApplicationTable
          actionsCallbacks={actionsCallbacks}
          applications={props.applications}
          remoteWorkStatusTypes={props.remoteWorkStatusTypes}
          remoteWorkApplicationTypes={props.remoteWorkApplicationTypes}
        />
      </div>
    </>
  );
};
SupervisionView.prototype = {
  applications: PropTypes.arrayOf(Object).isRequired,
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdateStatus: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
  onStatusUpdate: PropTypes.func.isRequired,
  remoteWorkStatusTypes: PropTypes.arrayOf(Object).isRequired,
  remoteWorkApplicationTypes: PropTypes.arrayOf(Object).isRequired,
};

export default SupervisionView;
