import React from "react";
import PropTypes from "prop-types";
import UserManager from "../../../navigation/UserManager";
import { SkillDevelopmentRequestDto } from "../../../models/SkillDevelopmentFund";
import ApplicationDeleteAction from "./DeleteAction";
import ApplicationClaimAction from "./ClaimAction";
import { RequestCancelAction } from "./CancelAction";
import { SkillDevelopmentRequestStatus } from "../Consts";
import { RequestApproveAction } from "./ApproveAction";
import { RequestRejectAction } from "./RejectAction";
import { RequestRefundAction } from "./RefundAction";
import { RequestClaimRejectAction } from "./ClaimRejectAction";
import { RequestApproveCancelAction } from "./ApproveCancelAction";
import { RequestCancelAfterApproveAction } from "./CancelAfterApproveAction";

function RowActions({
  request,
  isSupervision,
  callbacks,
  userId,
}) {
  const user = UserManager.getUser();
  const status = request.requestStatus;
  return (
    <div className="d-inline-block action-col">
      {
        !!isSupervision ? (
          status === SkillDevelopmentRequestStatus.Requested
            ? (<>
              <RequestApproveAction request={request} onApprove={callbacks.onApprove} />
              <RequestRejectAction request={request} onReject={callbacks.onReject} />
            </>)
            : status === SkillDevelopmentRequestStatus.CanceledRequestedAfterApproved
              ? <RequestApproveCancelAction request={request} onApproveCancel={callbacks.onAcceptCancelForApprovedRequest} />
              : status === SkillDevelopmentRequestStatus.ClaimRequested
                ? (<>
                  <RequestRefundAction request={request} onRefund={callbacks.onRefund} />
                  <RequestClaimRejectAction request={request} onClaimReject={callbacks.onClaimReject} />
                </>)
                : null
        ) : (
          status === SkillDevelopmentRequestStatus.Requested
            ? <RequestCancelAction request={request} onCancel={callbacks.onCancel} />
            : status === SkillDevelopmentRequestStatus.Approved
              ? (
                <>
                  <ApplicationClaimAction request={request} onClaim={callbacks.onClaim} />
                  <RequestCancelAfterApproveAction request={request} onCancel={callbacks.onCancel} />
                </>
              )
              : null
        )
      }
      {user.isAdminOrHr() ? (
        <>
          <ApplicationDeleteAction
            request={request}
            onDelete={callbacks.onDelete}
          />
        </>
      ) : null}
    </div>
  );
};

RowActions.propTypes = {
  request: PropTypes.instanceOf(SkillDevelopmentRequestDto).isRequired,
  callbacks: PropTypes.instanceOf(Object).isRequired,
};

export default RowActions;
