import React, { useState } from "react";
import FileUplodeApi from "../../../apis/endpoints/FileUplodeApi";
import {
  showSuccessMessages,
} from "../../../shared-components/response-message/ResponseMessage";
import { Form } from "react-bootstrap";
import { getReadableFileSize } from "../../../helpers/FileSizeConversionUtility";
import PropTypes from "prop-types"

function FileUpload({
  uploadType,
  onFileNameChange,
  fieldName,
  label,
}) {
  const [fileName, setFileName] = useState(label);
  const [errMsg, setErrMsg] = useState(null);
  const [fileValidationCriteria, setfileValidationCriteria] = useState(null)
  if (!!fileValidationCriteria === false) {
    FileUplodeApi.getValidationCriteria(uploadType).then(resp => setfileValidationCriteria(resp.data))
  }

  const handleSaveImage = (event) => {
    var selectedFile = event.target.files[0];
    if (!!selectedFile === false) {
      setFileName('')
      onFileNameChange({ [fieldName]: '' });
      return
    }
    setFileName(selectedFile.name);
    var formData = new FormData();
    formData.append("FormFile", selectedFile, selectedFile.name);
    formData.append("FileType", uploadType);

    FileUplodeApi.Upload(formData)
      .then((res) => {
        setFileName(res.data);
        onFileNameChange({ [fieldName]: res.data });
        showSuccessMessages("File Saved Temporarily");
        setErrMsg(null)
      })
      .catch((err) => {
        if (err.response.headers["content-type"] === "application/json") {
          setErrMsg(err.response.data.Title)
        } else if (err.response.status === 413) {
          setErrMsg("File is too large")
        } else {
          setErrMsg("Unknown error")
        }
      });
  };
  return (
    <div className="pt-3">
      <Form.File
        name={fieldName}
        id="custom-file"
        label={fileName}
        style={{ zIndex: 0 }}
        onChange={handleSaveImage}
        custom
        accept={!!fileValidationCriteria?.allowedExtensions ? fileValidationCriteria.allowedExtensions.join(',') : 'all'}
      />
      {!!fileValidationCriteria && <label style={{ color: '#909090' }}>max size:{getReadableFileSize(fileValidationCriteria.maxSizeInBytes)}</label>}
      {!!errMsg ? <div className="text-danger small">*{errMsg}</div> : null}
    </div>
  );
}

FileUpload.propTypes = {
  uploadType: PropTypes.string.isRequired,
  onFileNameChange: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default FileUpload;