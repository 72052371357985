import React from 'react'
import {FaSlackHash} from 'react-icons/fa'

const CefaloIcon = ({Icon=FaSlackHash,color='',size='12px'}) => {
    return (
        <>
          <Icon  size={size} color={color} />  
        </>
    )
}

export default CefaloIcon
