import React, { Component } from "react";
import { Form, Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import ResponseForm from "../forms/ResponseForm";
import { _toLocaleDateString } from "../../../helpers/DateUtility";
import { CefaloButton } from "../../../library/button";

export default class NocResponseApplication extends Component {
  state = {
    show: false,
  };

  toggle = (e) => () => {
    this.setState({ show: !this.state.show });
  };

  onResponseCallback = (data) => {
    const { application } = this.props;
    return this.props.onUpdateStatusCallback({ ...data, id: application.id });
  };
  render() {
    return (
      <div className="d-inline-block">
        <CefaloButton
          className="btn-font-12"
          size="sm"
          variant="primary"
          onClick={this.toggle()}
        >
          Respond
        </CefaloButton>
        <ResponseForm
          onResponseCallback={this.onResponseCallback}
          show={this.state.show}
          onToggle={this.toggle}
        >
          {this.getPreview()}
        </ResponseForm>
      </div>
    );
  }

  getPreview = () => {
    const { application } = this.props;
    return (
      <React.Fragment>
        <Row>
          <Col>
            <h6 style={{ textAlign: "center", fontWeight: "bold" }}>
              Application Details
            </h6>
          </Col>
        </Row>
        <Form.Row>
          <Col>
            <Form.Group>
              <Form.Label>Applicant</Form.Label>
              <Form.Control size="sm" readOnly value={application.name} />
            </Form.Group>

            <Form.Group>
              <Form.Label>Passport Number</Form.Label>
              <Form.Control
                size="sm"
                readOnly
                value={application.passportNumber}
              />
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <Form.Group>
              <Form.Label>From Date</Form.Label>
              <Form.Control
                size="sm"
                readOnly
                value={_toLocaleDateString(application.fromDate)}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>To Date</Form.Label>
              <Form.Control
                size="sm"
                readOnly
                value={_toLocaleDateString(application.toDate)}
              />
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <Form.Group>
              <Form.Label>Designation</Form.Label>
              <Form.Control
                size="sm"
                readOnly
                value={application.user?.profile?.designation}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Joining Date</Form.Label>
              <Form.Control
                size="sm"
                readOnly
                value={_toLocaleDateString(application.user?.joiningDate)}
              />
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <Form.Group>
              <Form.Label>Country</Form.Label>
              <Form.Control readOnly size="sm" value={application.country} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Purpose</Form.Label>
              <Form.Control
                readOnly
                size="sm"
                value={application.description}
              />
            </Form.Group>
          </Col>
        </Form.Row>
      </React.Fragment>
    );
  };
}

NocResponseApplication.propTypes = {
  onUpdateStatusCallback: PropTypes.func.isRequired,
  application: PropTypes.object.isRequired,
};
