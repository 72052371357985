import React from "react";
import AttendanceUpdateAction from "../actions/AttendanceUpdateAction";
import styles from "../styles/ReportTableView.module.css";
import { DataTable } from "../../../library/table";
import { v4 as uuid4 } from "uuid";
import SortUtility from "../../../helpers/SortUtility";
import UserManager from "../../../navigation/UserManager";

const PresenceStatusTypes = Object.freeze({
  Normal: 1,
  Absent: 2,
  LateInOrEarlyOut: 3,
  NonWorkingDay: 4,
  NotLoggedOut: 5,
  ExtraWorkingDay: 6,
});

// table callback function to color edited rows
const edittedRowHightlightingByStyle = (row, rowIndex) => {
  if (row.hightlight) {
    return { color: "blue" };
  }
};

// builder function to build data table rows from attendance object array
const prepareAttendanceDataTableRows = (
  attendances,
  includeName = false,
  includeAction = false,
  updateCallback = () => {},
  users
) => {
  let rows = [];
  if (!Array.isArray(attendances)) {
    return rows;
  }
  let user = UserManager.getUser();

  attendances.forEach((att, index) => {

    let isHightLighted = user.isAdminOrHr() && att.isEdited;
    let reportRow = {
      date: new Date(att.date).toLocaleDateString(),
      startTime: att.startTime,
      endTime: att.endTime,
      totalWorkingHour: att.totalWorkingHour,
      status: att.statusDisplayText,
      //This two attribute is using to table row/col styling during callbacks
      hightlight: isHightLighted,
      statusType: att.presenceStatus,
    };

    let teamsData="";
    if(users!==undefined) {
      users.forEach((user) => {
        if(`${att.userId}`===`${user.id}`)
        {
          user.teams.forEach(team => {
            teamsData = `${teamsData} ${teamsData==="" ? "" : ","} ${team.name}`;
          });
        }
      })
    }

    if (includeName) {
      reportRow["name"] = att.name;
      reportRow["teams"] = teamsData;
    }
    if (includeAction) {
      reportRow["teams"] = teamsData.trim();
      reportRow["action"] = (
        <AttendanceUpdateAction
          key={uuid4()}
          updateCallback={updateCallback}
          attendance={att}
        />
      );
    }
    rows.push(reportRow);
  });
  return rows;
};

const getAttendanceStatusColorByType = (type) => {
  let styles = {};
  if (type === PresenceStatusTypes.Normal) {
    styles = { backgroundColor: "#A6E1A9", color: "#005396" };
  } else if (
    type === PresenceStatusTypes.LateInOrEarlyOut ||
    type === PresenceStatusTypes.NotLoggedOut
  ) {
    styles = { backgroundColor: "#FFD280", color: "#005396" };
  } else if (type === PresenceStatusTypes.NonWorkingDay) {
    styles = { backgroundColor: "#D3D3D3", color: "#005396" };
  } else {
    styles = { backgroundColor: "#C88080", color: "#F5F5F5" };
  }
  return styles;
};

const ReportTableBaseColumns = [
  {
    text: "Date",
    dataField: "date",
    type: "date",
    sort: true,
    sortFunc: SortUtility.dateSorter,
  },
  {
    text: "Start Time",
    dataField: "startTime",
    sort: false,
  },
  {
    text: "End Time",
    dataField: "endTime",
    sort: false,
  },
  {
    text: "Total Work Hour",
    dataField: "totalWorkingHour",
    sort: false,
    headerStyle: { width: "18%" },
  },
  {
    text: "Status",
    dataField: "status",
    sort: false,
    classes: styles["attendanceStatus"],
    style: (cell, row, rowIndex, colIndex) => {
      return getAttendanceStatusColorByType(row.statusType);
    },
    headerStyle: { width: "15%" },
  },
];

const ReportTableWithAction = ({ attendances, updateCallback, users}) => {
  const columns = [
    ...ReportTableBaseColumns, 
    {
      text: "Action",
      dataField: "action",
      sort: false,
    },
  ];

  let rows = prepareAttendanceDataTableRows(
    attendances,
    false,
    true,
    updateCallback,
    users
  );

  return (
    <DataTable
      rows={rows}
      columns={columns}
      rowStyle={edittedRowHightlightingByStyle}
      excludeSearch
      excludePageOptions
      initialPageSize={30}
    />
  );
};

const ReportTableWithUserName = ({ attendances, updateCallback, users }) => {
  const columns = [
    ReportTableBaseColumns[0],
    {
      text: "Name",
      dataField: "name",
      sort: false,
    }, 
    {
      text: "Team(s)",
      dataField: "teams",
      sort: true,
    },
    ...ReportTableBaseColumns.slice(1),
  ];

  let rows = prepareAttendanceDataTableRows(
    attendances,
    true,
    true,
    updateCallback,
    users
  );
  return (
    <DataTable
      rows={rows}
      columns={columns}
      rowStyle={edittedRowHightlightingByStyle}
      excludePagination
      excludeSearch
    />
  );
};

const ReportTable = ({ attendances }) => {
  let rows = prepareAttendanceDataTableRows(attendances);

  return (
    <DataTable
      rows={rows}
      columns={ReportTableBaseColumns}
      excludeSearch
      excludePageOptions
      initialPageSize={30}
    />
  );
};

const ReportTableView = ({
  attendances,
  includeUserName = false,
  includeAction = false,
  updateCallback = null,
  users
}) => {
  if (includeUserName) {
    return (
      <ReportTableWithUserName
        attendances={attendances}
        updateCallback={updateCallback}
        users={users}
      />
    );
  }
  if (includeAction) {
    return (
      <ReportTableWithAction
        attendances={attendances}
        updateCallback={updateCallback}
        users={users}
      />
    );
  }
  return <ReportTable attendances={attendances} />;
};

export default ReportTableView;
