import React from "react";
import ApplicationActionModal from "../../../../shared-components/modals/ApplicationActionModal";
import { Loading } from "../../../../shared-components/notification";
import { getIntervalOptions, getOfficeStartTimeOptions } from "../Const";
import { DateRange, Select, CheckBox, Form } from "../../../../library/form";
import { Col } from "../../../../library/grid-layout";
import PropTypes from "prop-types";
import {
  showErrorMessages,
  showSuccessMessages,
} from "../../../../shared-components/response-message/ResponseMessage";
import { CefaloButton } from "../../../../library/button";
import { v4 as uuid4 } from "uuid";

export default class OfficeTimingForm extends React.Component {
  state = {
    id: 0,
    startDate: new Date(),
    endDate: new Date(),
    isDefaultOfficeTiming: this.props.isDefaultOfficeTiming,
    officeStartTime: "08:00:00",
    officeStartGraceHour: 1,
    officeHour: 8,
    lunchHour: 1,
    isLoading: false,

    header: "Add Custom Office Timing",
  };
  componentMounted = false;
  componentDidMount = () => (this.componentMounted = true);
  componentWillUnmount = () => (this.componentMounted = false);

  componentWillReceiveProps = (nextProps) => {
    const { officeTiming } = nextProps;
    if (!!officeTiming === true) {
      officeTiming.startDate = new Date(officeTiming.startDate);
      officeTiming.endDate = new Date(officeTiming.endDate);
      this.setState({ ...officeTiming });
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.isDefaultOfficeTiming !== prevState.isDefaultOfficeTiming) {
      this.setState({ header: this.getHeader() });
    }
  };

  setDate = ({ fromDate, toDate }) => {
    this.setState({ startDate: fromDate, endDate: toDate });
  };

  onChange = (event) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value });
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    const { startDate, endDate } = this.state;
    this.props
      .submit({
        ...this.state,

        startDate: startDate,
        endDate: endDate,
      })
      .then((res) => {
        if (this.componentMounted) {
          this.setState({ isLoading: false });
        }
        showSuccessMessages(`Office Timing  is saved successfully`);
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        showErrorMessages(err.response.data);
      });
  };
  getHeader = () => {
    const { isDefaultOfficeTiming } = this.state;
    const { actionType } = this.props;

    if (actionType === "UPDATE") {
      return isDefaultOfficeTiming
        ? "Edit Default Office Timing"
        : "Edit Custom Office Timing";
    } else {
      return isDefaultOfficeTiming
        ? "Add Default Office Timing"
        : "Add Custom Office Timing";
    }
  };

  render() {
    const {
      startDate,
      endDate,
      officeStartTime,
      officeStartGraceHour,
      officeHour,
      lunchHour,
      isLoading,
      isDefaultOfficeTiming,
      header,
    } = this.state;
    const { show, onToggle, actionType } = this.props;
    return (
      <>
        <Loading show={isLoading} />
        <ApplicationActionModal
          isOpen={show}
          onToggle={onToggle}
          header={header}
          footer={this.getFooter()}
        >
          <Form>
            {actionType === "CREATE" && (
              <Form.Row>
                <Col sm>
                  <CheckBox
                    name="isDefaultOfficeTiming"
                    key={uuid4()}
                    label="Default OfficeTiming"
                    checked={isDefaultOfficeTiming}
                    onChange={() =>
                      this.setState({
                        isDefaultOfficeTiming: !isDefaultOfficeTiming,
                      })
                    }
                  />
                </Col>
              </Form.Row>
            )}
            {!isDefaultOfficeTiming && (
              <Form.Row>
                <DateRange
                  fromDate={startDate}
                  toDate={endDate}
                  onChange={this.setDate}
                />
              </Form.Row>
            )}
            <Form.Row>
              <Col sm>
                <Select
                  label="OfficeStartTime (AM)"
                  name="officeStartTime"
                  options={getOfficeStartTimeOptions()}
                  value={officeStartTime}
                  onChange={this.onChange}
                />
              </Col>

              <Col sm>
                <Select
                  label="OfficeStartGraceHours"
                  name="officeStartGraceHour"
                  options={getIntervalOptions(1, 5, 0.5)}
                  value={officeStartGraceHour}
                  onChange={this.onChange}
                />
              </Col>
            </Form.Row>
            <Form.Row>
              <Col sm>
                <Select
                  label="OfficeHours"
                  name="officeHour"
                  options={getIntervalOptions(1, 8, 0.5)}
                  value={officeHour}
                  onChange={this.onChange}
                />
              </Col>

              <Col sm>
                <Select
                  label="LunchHours"
                  name="lunchHour"
                  options={getIntervalOptions(1, 5, 0.5)}
                  value={lunchHour}
                  onChange={this.onChange}
                />
              </Col>
            </Form.Row>
          </Form>
        </ApplicationActionModal>
      </>
    );
  }

  getFooter = () => {
    return (
      <>
        <CefaloButton
          size="sm"
          variant="danger"
          onClick={this.props.onToggle()}
          type="button"
        >
          Close
        </CefaloButton>
        <CefaloButton
          variant="success"
          size="sm"
          type="button"
          onClick={this.onSubmit}
        >
          Save
        </CefaloButton>
      </>
    );
  };
}

OfficeTimingForm.propTypes = {
  show: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  actionType: PropTypes.string.isRequired,
};
