export class LeaveStatisticsDto {
  constructor(obj) {
    this.userId = obj.userId;
    this.employeeName = obj.employeeName;
    this.casualLeaves = obj.casualLeaves;
    this.parentalLeaves = obj.parentalLeaves;
    this.remainingLeaves = obj.remainingLeaves;
    this.sickLeaves = obj.sickLeaves;
    this.sickLeavesWoDoc = obj.sickLeavesWoDoc;
    this.totalAvailableLeaves = obj.totalAvailableLeaves;
    this.totalLeavesAvailed = obj.totalLeavesAvailed;
    this.teamName = obj.teamNames;
  }

  hasTeam() {
    return !!this.teams;
  }
}
