import { convertToBoolean } from "../helpers/Utils";

class User {
  constructor(obj) {
    this.email = obj.email;
    this.hasAdminPermission = convertToBoolean(obj.hasAdminPermission);
    this.hasHrPermission = convertToBoolean(obj.hasHrPermission);
    this.hasTeamManagerPermission = convertToBoolean(obj.hasTeamManagerPermission);
    this.id = obj.nameid;
    this.name = obj.name;
    this.role = obj.role;
  }

  isAdminOrHr() {
    return this.hasAdminPermission || this.hasHrPermission;
  }

  isAdminOrHrOrTeamManager() {
    return (
      this.hasAdminPermission ||
      this.hasHrPermission ||
      this.hasTeamManagerPermission
    );
  }

  isHrOrAdmin = () => {
    return this.role === 1 || this.role === 2;
  };
  isGuestUser = () => (this.role === 5)

  isTeamManagerVisualizingOtherMembers = (otherUserId) => {
    if (this.hasTeamManagerPermission && this.id !== otherUserId) {
      return true
    } else {
      return false
    }
  }
}

export default {
  getUser: () => {
    let token = localStorage.getItem('__C_Token');
    try {
      const decodedToken = JSON.parse(window.atob(token.split('.')[1]));
      return new User(decodedToken);
    } catch (e) {
      // in case the token is corrupted/malformed, then
      // forcefully logout the user
      if (typeof token == "string" && token.length > 0) {
        window.localStorage.clear();
        window.sessionStorage.clear();
        window.location.href = "/Login";
      }
      return null;
    }
  },

  clearUser: () => {
    localStorage.clear();
  },
};
