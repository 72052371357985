import React from "react";
import PropTypes from "prop-types";
import AttendanceUpdateForm from "../forms/AttendanceUpdateForm";
import { Loading, toaster } from "../../../shared-components/notification";
import { CefaloButton } from "../../../library/button";
import AttendanceApi from "../../../apis/endpoints/AttendanceApi";
import { defaultErrorResponseParser } from "../../../helpers/ErrorParser";
import styles from "../styles/AttendanceUpdateAction.module.css";

class AttendanceUpdateFormContainer extends React.Component {
  static propTypes = {
    attendance: PropTypes.object.isRequired,
  };

  state = {
    show: false,
    isLoading: false,
  };

  toggle = (e) => () => {
    this.setState({ show: !this.state.show });
  };

  onUpdateAction = (data) => {
    this.setState({ isLoading: true });
    AttendanceApi.update(data.id, data)
      .then((res) => {
        toaster.success("Attendance Data Updated Successfully");
        this.props.updateCallback();
        this.setState({ isLoading: false, show: false });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        defaultErrorResponseParser(err, "Failed To Update Attendance");
      });
  };

  render() {
    return (
      <div className={styles.updateContainer}>
        <Loading show={this.state.isLoading} />
        <CefaloButton
          className="btn-font-12"
          size="sm"
          variant="primary"
          onClick={this.toggle()}
        >
          Edit
        </CefaloButton>
        <AttendanceUpdateForm
          attendance={this.props.attendance}
          onUpdate={this.onUpdateAction}
          show={this.state.show}
          onToggle={this.toggle}
        />
      </div>
    );
  }
}

export default AttendanceUpdateFormContainer;
