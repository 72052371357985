import React from "react";
import DatePicker from "react-datepicker";
import { Form } from "react-bootstrap";

const CefaloDatePicker = ({ date, onChange, extraOptions = {}, minDate, disabled }) => {
  return (
    <DatePicker
      selected={date}
      minDate={minDate}
      onChange={onChange}
      disabled={disabled}
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      {...extraOptions}
      customInput={<CefaloDateView />}
    />
  );
};

const CefaloDateView = React.forwardRef(({ value, onClick }, ref) => (
  <Form.Control size="sm" readOnly value={value} ref={ref} onClick={onClick} />
));

export default CefaloDatePicker;
