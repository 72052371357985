import { AuthenticatedAxios, jsonContentConfig } from "../settings/Axios";
const BaseEndPoint = "Teams";

export default {
  all: () => {
    let endpoint = `${BaseEndPoint}/Active`;
    return AuthenticatedAxios.get(endpoint, null, null);
  },

  allIncludingInactive: () => {
    let endpoint = `${BaseEndPoint}/All`;
    return AuthenticatedAxios.get(endpoint, null, null);
  },

  update: (team) => {
    let endpoint = `${BaseEndPoint}/${team.id}`;
    let body = JSON.stringify(team);

    return AuthenticatedAxios.put(endpoint, body, jsonContentConfig);
  },

  create: (team) => {
    let body = JSON.stringify(team);
    return AuthenticatedAxios.post(BaseEndPoint, body, jsonContentConfig);
  },

  delete: (id) => {
    let endpoint = `${BaseEndPoint}/${id}`;
    return AuthenticatedAxios.delete(endpoint, null, {});
  },

  getMembersByTeam: (id) => {
    let endpoint = `${BaseEndPoint}/${id}/Member`;
    return AuthenticatedAxios.get(endpoint, null, null);
  },

  getMembersByTeamIds: (selectedTeamIds) => {
    let endpoint = `${BaseEndPoint}/Members`;
    let body = JSON.stringify(selectedTeamIds);

    return AuthenticatedAxios.post(endpoint, body, jsonContentConfig);
  },

  getAllTeamsWithMembersAsync: () => {
    let endpoint = `${BaseEndPoint}/Member`;
    return AuthenticatedAxios.get(endpoint, null, null);
  },
};
