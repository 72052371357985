import React from "react";
import Layout from "../../shared-components/layout";
import { Helmet } from "react-helmet";
import AllUserView from "./views/AllUserView";
import UserApi from "../../apis/endpoints/UserApi";
import ProfileApi from "../../apis/endpoints/ProfileApi";
import TeamApi from "../../apis/endpoints/TeamApi";
import { toaster, Loading } from "../../shared-components/notification";
import { TeamDto } from "../../models/Team";
import { UserWithTeamDto } from "../../models/User";
import UserManager from "../../navigation/UserManager";

const ViewContainer = (props) => <div className="p-2">{props.children}</div>;

class AllUserManageContainer extends React.Component {
  state = {
    users: [],
    teams: [],
    userRoles:[],
    profile: null,
    isLoading: false,
    shouldRender: false,
  };

  componentDidMount = () => {
    let user = UserManager.getUser();
    if (!user.isAdminOrHr()) {
      window.location.replace(`/Report/Index/${user.id}`);
    } else {
      this.setState({ shouldRender: true, isLoading: true });
      this.loadUserData();
      this.loadTeamData();
      this.loadUserRolesData()
    }
  };

  loadTeamData = () => {
    TeamApi.all()
      .then((res) => {
        let teams = res.data.map((t) => new TeamDto(t));
        this.setState({
          isLoading: false,
          teams: teams,
        });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        toaster.error("Failed to load Team information");
      });
  };

  loadUserData = () => {
    UserApi.allCefaloUsers()
      .then((res) => {
        let users = res.data.map((u) => new UserWithTeamDto(u));
        this.setState({ isLoading: false, users: users });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        toaster.error("Failed to load User information");
      });
  };

  loadUserRolesData = () => {
    UserApi.allUserRoles()
    .then(resp=>this.setState({userRoles:resp.data}))
      .catch((err) => {
        this.setState({ isLoading: false });
        toaster.error("Failed to load User Roles information");
      });
  };

  adjustCreatedUser = (id) => {
    UserApi.getDetails(id)
      .then((res) => {
        let users = this.state.users;
        users.push(new UserWithTeamDto(res.data));
        this.setState({
          isLoading: false,
          users: users,
        });
      })
      .catch((err) => {});
  };

  adjustUpdatedUser = (id) => {
    UserApi.getDetails(id)
      .then((res) => {
        let updatedUser = new UserWithTeamDto(res.data);
        let users = this.state.users;
        let index = users.findIndex((item) => item.id === updatedUser.id);
        users[index] = new UserWithTeamDto(updatedUser);
        this.setState({ users: users, isLoading: false });
      })
      .catch((err) => {});
  };

  onUserCreate = (user) => {
    this.adjustCreatedUser(user.id);
  };

  onUserDelete = () => {
    this.loadUserData();
  };

  onUserUpdate = (updatedUser) => {
    this.adjustUpdatedUser(updatedUser.id);
  };

  loadProfileData = (id) => {
    this.setState({ isLoading: true });
    ProfileApi.get(id)
      .then((res) => {
        this.manageLoadedProfile(res.data);
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        if (err.response.status === 404) {
          this.setState({ profile: null });
        } else toaster.error("Failed to load User Profile");
      });
  };

  manageLoadedProfile = (profile) => {
    this.setState({ isLoading: false, profile: profile });
  };

  onProfileSave = (profile) => {
    this.setState({ isLoading: false, profile: profile });
  };

  render() {
    if (!this.state.shouldRender) {
      return <></>;
    }
    return (
      <Layout>
        <Helmet>
          <title>User Management- Cefalo</title>
        </Helmet>
        <Loading show={this.state.isLoading} />
        <ViewContainer>
          <AllUserView
            users={this.state.users}
            teams={this.state.teams}
            userRoles={this.state.userRoles}
            onProfileLoad={this.loadProfileData}
            onProfileSave={this.onProfileSave}
            onCreate={this.onUserCreate}
            onUpdate={this.onUserUpdate}
            onDelete={this.onUserDelete}
          />
        </ViewContainer>
      </Layout>
    );
  }
}

export default AllUserManageContainer;
