import { ApplicantDto } from "./Common";
import { DateOnlyBuilder } from "../helpers/Builders";

export class LeaveApplicationDetailDto {
  constructor(obj = {}) {
    this.id = obj.id ? obj.id : 0;
    this.applicant = new ApplicantDto(obj.applicant ? obj.applicant : {});
    this.userId = obj.userId ? obj.userId : 0;
    this.totalNumberOfWorkingDays = obj.totalNumberOfWorkingDays
      ? obj.totalNumberOfWorkingDays
      : 0.0;
    this.includeSingleHalfDay = obj.includeSingleHalfDay
      ? obj.includeSingleHalfDay
      : false;
    this.leaveTypeDisplayName = obj.leaveTypeDisplayName
      ? obj.leaveTypeDisplayName
      : "";
    this.leaveSupportingDocumentName = obj.leaveSupportingDocumentName
      ? obj.leaveSupportingDocumentName
      : "";
    this.leaveStatusDisplayName = obj.leaveStatusDisplayName;
    this.leaveType = obj.leaveType ? obj.leaveType : 0;
    this.status = obj.status ? obj.status : 0;
    this.created = obj.created ? DateOnlyBuilder(obj.created) : null;
    this.fromDate = obj.fromDate ? DateOnlyBuilder(obj.fromDate) : null;
    this.toDate = obj.toDate ? DateOnlyBuilder(obj.toDate) : null;
    this.description = obj.description ? obj.description : "";
    this.supervisorComment = obj.supervisorComment ? obj.supervisorComment : "";
  }
}
