import React from "react";
import PropTypes from "prop-types";
import ExtraWorkForm from "../forms/ExtraWorkForm";
import { CefaloButton } from "../../../library/button";
export default class CreateApplication extends React.Component {
  state = {
    show: false,
  };

  toggle = (e) => () => {
    this.setState({ show: !this.state.show });
  };

  onSubmit = ({
    fromDate,
    toDate,
    totalHours,
    adjustmentType,
    description,
    notBillableToCustomer,
    extraWorkTeamId,
    extraWorkTeamName,
  }) => {
    let application = {
      fromDate,
      toDate,
      totalHours,
      adjustmentType,
      extraWorkTeamId,
      description,
      userId: this.props.userId,
      extraWorkTeamName,
    };
    if (adjustmentType === 1) {
      application = {
        ...application,
        notBillableToCustomer,
      };
    }
    if (extraWorkTeamId === 0) {
      application.extraWorkTeamId =
        this.props.userTeams !== undefined && this.props.userTeams.length > 0
          ? this.props.userTeams[0]?.id
          : this.props.teamNames[0]?.value;
    }
    if (extraWorkTeamName === null) {
      application.extraWorkTeamName =
        this.props.userTeams !== undefined && this.props.userTeams.length > 0
          ? this.props.userTeams[0]?.name
          : this.props.teamNames[0]?.label;
    }
    return this.props.onCreateCallback(application).then((res) => {
      this.setState({ show: false });
      return res;
    });
  };
  render() {
    const { show } = this.state;
    return (
      <div className="d-inline-block" style={{ marginLeft: "auto" }}>
        <CefaloButton
          size="sm"
          variant="primary"
          className="filter_panel_btn"
          onClick={this.toggle()}
        >
          New Application
        </CefaloButton>
        <ExtraWorkForm
          onToggle={this.toggle}
          show={show}
          action={"CREATE"}
          submit={this.onSubmit}
          header={"New Application"}
          successMessage="Extra working day application Saved Successfully"
          teamNames={this.props.teamNames}
          userTeams={this.props.userTeams}
        />
      </div>
    );
  }
}

CreateApplication.propTypes = {
  onCreateCallback: PropTypes.func.isRequired,
};
