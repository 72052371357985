import React from "react";
import PropTypes from "prop-types";
import FileUpload from "../../forms/FileUpload";
import { FormModal } from "../../../library/modal";
import { Col } from "../../../library/grid-layout";
import {
  Form,
  Select,
  Input,
  CheckBox,
} from "../../../library/form";
import { useForm } from "react-hook-form";
import { toaster } from "../../notification";
import { DateOnlyString } from "../../../helpers/Builders";
import { CefaloButton } from "../../../library/button";
import UserManager from "../../../navigation/UserManager";
import CefaloDatePicker from "../../../library/form/DatePicker";
import { LeaveTypeValueDictionary } from "../Consts";
const ApplicationCreateFormModal = ({
  fromDate,
  toDate,
  totalWorkingDays,
  show,
  onToggle,
  onCreate,
  periodSelectCallBack,
  paternityLeaveToDateSelectCallBack,
  paternityLeaveToDate,
  leaveTypes,
  leaveSettings,
}) => {
  const { register, handleSubmit, getValues, watch } = useForm({
    defaultValues: {
      includeSingleHalfDay: false,
    },
  });

  const [leaveSupportingDocumentName, setLeaveSupportingDocumentName] =
    React.useState();

  const checkIfValidLeaveRequest = (leaveApplicationData) => {
    let validationResponse = {
      result: true,
      errorMessage: "",
    };

    if (leaveApplicationData.totalNumberOfWorkingDays <= 0) {
      validationResponse.result = false;
      validationResponse.errorMessage =
        "Leave must include at least one working day";
    }

    return validationResponse;
  };

  const setDate = (date, isFromDate) => {
    if (isFromDate) {
      fromDate = date;
      toDate = fromDate > toDate ? fromDate : toDate;
    } else {
      toDate = date;
      fromDate = toDate < fromDate ? toDate : fromDate;
    }
    periodSelectCallBack(fromDate, toDate, getValues("includeSingleHalfDay"));
  };

  const updatePaternityLeaveToDate = (date) => {
    paternityLeaveToDateSelectCallBack(date);
  };

  const getMinDate = () => {
    const leaveType = parseInt(watch("leaveType"));
    const isAdminOrHr = UserManager.getUser().isAdminOrHr();

    const isSickLeaveWithDoc =
      leaveType === LeaveTypeValueDictionary.SickLeaveWithDoc;
    const isSickLeaveWithoutDoc =
      leaveType === LeaveTypeValueDictionary.SickLeaveWithoutDoc;

    if (!isSickLeaveWithDoc && !isSickLeaveWithoutDoc && !isAdminOrHr) {
      return new Date();
    }

    return null;
  };

  const isSickLeaveWithDoc = () => {
    const leaveType = parseInt(watch("leaveType"));
    return leaveType === LeaveTypeValueDictionary.SickLeaveWithDoc;
  };
  const isMaternityLeave = () => {
    const leaveType = parseInt(watch("leaveType"));
    return leaveType === LeaveTypeValueDictionary.MaternityLeave;
  };
  const isPaternityLeave = () => {
    const leaveType = parseInt(watch("leaveType"));
    return leaveType === LeaveTypeValueDictionary.PaternityLeave;
  };

  const isMaternityOrPaternityLeave = () => {
    return isMaternityLeave() || isPaternityLeave();
  };

  const getLastDayOfMonth = (year, month) => {
    const nextMonthFirstDay = new Date(year, month + 1, 1);
    const lastDayOfMonth = new Date(nextMonthFirstDay.getTime() - 1).getDate();
    return lastDayOfMonth;
  };

  const calculateMaternityLeaveToDate = (fromDate) => {
    const maternityLeaveFromDate = new Date(fromDate);

    const newDate = new Date(maternityLeaveFromDate);
    newDate.setMonth(
      newDate.getMonth() + leaveSettings.maternityLeavePerYearInMonths
    );
    const toDateYear = newDate.getFullYear();

    let toDateMonth =
      (maternityLeaveFromDate.getMonth() +
        leaveSettings.maternityLeavePerYearInMonths) %
      12;

    const lastDayOfMonth = getLastDayOfMonth(toDateYear, toDateMonth);

    //the day of the maternity leave toDate will be the same day as fromDate
    //if the day is not available on that month the day will be the last day of the month
    const toDateDay =
      maternityLeaveFromDate.getDate() > lastDayOfMonth
        ? lastDayOfMonth
        : maternityLeaveFromDate.getDate();

    const maternityLeaveToDate = new Date(toDateYear, toDateMonth, toDateDay);

    toDate = maternityLeaveToDate;

    return maternityLeaveToDate;
  };

  const onFormSubmit = (data) => {
    let applicationData = { ...data };

    applicationData["fromDate"] = DateOnlyString(fromDate);
    applicationData["toDate"] = isPaternityLeave()
      ? DateOnlyString(paternityLeaveToDate)
      : DateOnlyString(toDate);
    applicationData["leaveType"] = Number.parseInt(data["leaveType"]);
    applicationData["totalNumberOfWorkingDays"] = Number.parseFloat(
      data["totalNumberOfWorkingDays"]
    );
    if (!!leaveSupportingDocumentName) {
      applicationData["leaveSupportingDocumentName"] =
        leaveSupportingDocumentName;
    }

    const isValidRequest = checkIfValidLeaveRequest(applicationData);

    if (isValidRequest.result) {
      onCreate(applicationData)
        .then((resp) => setLeaveSupportingDocumentName(null))
        .catch((err) => {});
    } else {
      toaster.error(isValidRequest.errorMessage);
    }
  };

  let leaveTypesOptions = leaveTypes.map((i) => {
    return { label: i.name, value: i.value };
  });

  return (
    <FormModal
      show={show}
      toggle={onToggle()}
      formName="Leave Application"
      onSubmit={handleSubmit(onFormSubmit)}
    >
      <div key={FormModal.ControlContainer}>
        <Form.Row>
          <Col sm={6}>
            <Select
              className="form-inline"
              ref={register}
              name="leaveType"
              options={leaveTypesOptions}
              label="Leave Type"
              onChange={() => {
                if (isPaternityLeave()) {
                  updatePaternityLeaveToDate(fromDate);
                }
              }}
            />
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>From Date</Form.Label>
              <CefaloDatePicker
                date={fromDate}
                onChange={
                  isPaternityLeave()
                    ? (d) => updatePaternityLeaveToDate(d)
                    : (d) => setDate(d, true)
                }
                minDate={getMinDate()}
              />
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col sm={6}>
            <Form.Group>
              <Form.Label>To Date</Form.Label>
              <CefaloDatePicker
                date={
                  isMaternityLeave()
                    ? calculateMaternityLeaveToDate(fromDate)
                    : isPaternityLeave()
                    ? paternityLeaveToDate
                    : toDate
                }
                onChange={(d) => setDate(d, false)}
                minDate={getMinDate()}
                disabled={isMaternityOrPaternityLeave()}
              />
            </Form.Group>
          </Col>
          <Col>
            <Input
              label={isMaternityLeave() ? "Total Months" : "Total Working Days"}
              type="number"
              name="totalNumberOfWorkingDays"
              disabled
              value={
                isPaternityLeave()
                  ? leaveSettings.paternityLeavePerYearInDays
                  : isMaternityLeave()
                  ? leaveSettings.maternityLeavePerYearInMonths
                  : totalWorkingDays
              }
              ref={register}
            />
          </Col>
        </Form.Row>
        {!isMaternityOrPaternityLeave() && (
          <CheckBox
            id="half-day-selection"
            label="Include Single Half Day Leave"
            name="includeSingleHalfDay"
            style={{ zIndex: 0 }}
            ref={register}
            onChange={(e) => {
              periodSelectCallBack(fromDate, toDate, e.target.checked);
              return e.target.checked;
            }}
          />
        )}
        {isSickLeaveWithDoc() && (
          <Form.Row>
            <Col sm>
              <FileUpload
                uploadType="LeaveDocuments"
                onFileNameChange={(fileInfo) =>
                  setLeaveSupportingDocumentName(
                    fileInfo["leaveSupportingDocumentName"]
                  )
                }
                fieldName="leaveSupportingDocumentName"
              />
            </Col>
          </Form.Row>
        )}
        <Input
          containerClassName="mt-2"
          label="Description"
          as="textarea"
          name="description"
          placeholder="Leave Description..."
          ref={register}
        />
      </div>
      <div key={FormModal.ActionContainer}>
        <CefaloButton size="sm" variant="light" onClick={onToggle()}>
          Close
        </CefaloButton>
        <CefaloButton variant="primary" size="sm" type="submit">
          Save
        </CefaloButton>
      </div>
    </FormModal>
  );
};

ApplicationCreateFormModal.prototype = {
  fromDate: PropTypes.objectOf(Date).isRequired,
  toDate: PropTypes.objectOf(Date).isRequired,
  totalWorkingDays: PropTypes.number.isRequired,
  show: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  periodSelectCallBack: PropTypes.func.isRequired,
  leaveTypes: PropTypes.arrayOf(Object).isRequired,
};

export default ApplicationCreateFormModal;
