import React, { useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { DateOnlyString } from "../../../helpers/Builders";
import { CefaloButton } from "../../../library/button";
import { FormModal } from "../../../library/modal";
import {
  Form,
  Input,
  Select,
  CheckBox,
  DatePicker,
} from "../../../library/form";
import { Col } from "../../../library/grid-layout";
import { enumsToValueLabelArray } from "../../../shared-components/enum-values/EnumValuesComverter";

const DEFAULT_SELECT_VALUE = "N/A";

const UserUpsertForm = ({
  teams,
  userRoles,
  onSubmit,
  show,
  onToggle,
  defaultValues = { isActive: true },
}) => {
  const { register, handleSubmit, errors, setError } = useForm({
    defaultValues: {
      ...defaultValues,
      teams: enumsToValueLabelArray(defaultValues.teams, "name", "id"),
    },
  });

  let userRoleOptions = [
    { value: DEFAULT_SELECT_VALUE, label: "Select One" },
  ].concat(enumsToValueLabelArray(userRoles));
  var teamOptions = [];
  teams.forEach((team) => {
    var option = { label: team.name, value: team.id };
    teamOptions.push(option);
  });

  const [joiningDate, setJoiningDate] = useState(defaultValues.joiningDate);
  const [lastWorkingDate, setLastWorkingDate] = useState(
    defaultValues.lastWorkingDate
  );

  const onFormSubmit = (data) => {
    if (!!data.teams) {
      data.teamIds = data.teams.map((team) => team.value);
      delete data.teams;
    } else {
      data.teamIds = [];
    }
    if (data.role === DEFAULT_SELECT_VALUE) {
      setError("role", "notMatch", "select a role");
      return;
    }
    if (joiningDate !== undefined) {
      data["joiningDate"] = DateOnlyString(joiningDate);
    }
    if (!!lastWorkingDate) {
      data["lastWorkingDate"] = DateOnlyString(lastWorkingDate);
    }
    if (defaultValues["id"]) {
      data["id"] = defaultValues["id"];
    }
    onSubmit(data);
  };

  return (
    <FormModal
      onSubmit={handleSubmit(onFormSubmit)}
      toggle={onToggle()}
      show={show}
      formName="Add User Information"
    >
      <div key={FormModal.ControlContainer}>
        <Form.Row>
          <Col sm>
            <Input
              label="Full Name"
              placeholder="Enter Full Name"
              name="name"
              size="sm"
              ref={register({ required: "User name is required" })}
              errors={errors}
            />
          </Col>
          <Col sm>
            <Input
              label="Email"
              placeholder="Enter Email"
              type="email"
              name="email"
              size="sm"
              ref={register({ required: "User email is required" })}
              errors={errors}
            />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col sm>
            <Input
              label="Attendance System Id"
              placeholder="Number Only"
              type="number"
              name="attSysUserId"
              ref={register({ required: "AttSysUserId is required" })}
              errors={errors}
            />
          </Col>
          <Col sm>
            <Select
              ref={register({ required: true })}
              label="Role"
              name="role"
              options={userRoleOptions}
              errors={errors}
            />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col sm>
            <Form.Group>
              <Form.Label>Joining Date</Form.Label>
              <DatePicker date={joiningDate} onChange={setJoiningDate} />
            </Form.Group>
          </Col>
          <Col sm>
            <Form.Group>
              <Form.Label>Last Working Date</Form.Label>
              <DatePicker
                date={lastWorkingDate}
                onChange={setLastWorkingDate}
                extraOptions={{ isClearable: true }}
              />
            </Form.Group>
          </Col>
        </Form.Row>
        <Form.Row>
          <Col sm>
            <Input
              label="Client Email"
              name="clientEmail"
              ref={register}
              placeholder="Enter Client Email(comma separated multiple values are allowed)"
            />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col sm={3}>
            <CheckBox
              label="Is Active"
              ref={register}
              name="isActive"
              style={{ zIndex: 0 }}
            />
          </Col>
          <Col sm={3}>
            <CheckBox
              label="Hide Email"
              ref={register}
              name="hideEmail"
              style={{ zIndex: 0 }}
            />
          </Col>
          <Col sm={6}>
            <CheckBox
              label="Set Leave Balanace to Zero"
              ref={register}
              name="isSetLeaveBalanceToZero"
              style={{ zIndex: 0 }}
            />
          </Col>
        </Form.Row>
      </div>
      <div key={FormModal.ActionContainer}>
        <CefaloButton
          size="sm"
          variant="light"
          onClick={onToggle()}
          type="button"
        >
          Close
        </CefaloButton>
        <CefaloButton size="sm" variant="primary" type="submit">
          Save
        </CefaloButton>
      </div>
    </FormModal>
  );
};
UserUpsertForm.prototype = {
  onSubmit: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  defaultValues: PropTypes.object,
  onToggle: PropTypes.func.isRequired,
};

export default UserUpsertForm;
