import SortUtility from "../../helpers/SortUtility";

const NormalUserTableColumns = Object.freeze([
  {
    text: "Holiday Date",
    dataField: "date",
    sort: true,
    sortFunc: SortUtility.dateSorter,
    defaultSortDirection: "asc",
    order: "asc",
  },
  {
    text: "Holiday Name",
    dataField: "name",
    sort: true,
  },
  {
    text: "Description",
    dataField: "description",
    sort: true,
  },
]);

const HrOrAdminTableColumns = Object.freeze([
  {
    text: "Holiday Date",
    dataField: "date",
    sort: true,
    sortFunc: SortUtility.dateSorter,
    order: "asc",
  },
  {
    text: "Holiday Name",
    dataField: "name",
    sort: true,
  },
  {
    text: "Description",
    dataField: "description",
    sort: true,
  },
  {
    text: "Actions",
    dataField: "actions",
    sort: false,
  },
]);

export { NormalUserTableColumns, HrOrAdminTableColumns };
