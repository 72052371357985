import { AuthenticatedAxios } from "../settings/Axios";
const BaseEndPoint = "RemoteWorkApplications";
const RemoteWorkUserEndPoint = "Users";
let config = {
  headers: {
    "Content-Type": "application/json",
  },
};

export default {
  all: (options) => {
    let endpoint = `${BaseEndPoint}?fromDate=${options.fromDate}&toDate=${options.toDate}`;
    if (options.remoteWorkType) {
      endpoint += `&applicationType=${options.remoteWorkType}`;
    }
    if (options.remoteWorkStatus) {
      endpoint += `&applicationStatus=${options.remoteWorkStatus}`;
    }

    return AuthenticatedAxios.get(endpoint, null, null);
  },

  get: (options, id) => {
    let endpoint = `${BaseEndPoint}/${id}?fromDate=${options.fromDate}&toDate=${options.toDate}`;
    if (options.ApplicationType) {
      endpoint += `&applicationType=${options.ApplicationType}`;
    }
    if (options.applicationStatus) {
      endpoint += `&applicationStatus=${options.applicationStatus}`;
    }
    return AuthenticatedAxios.get(endpoint, null, null);
  },

  getUserApplications: (options, userId) => {
    let endpoint = `${RemoteWorkUserEndPoint}/${userId}/RemoteWorkApplications?fromDate=${options.fromDate}&toDate=${options.toDate}`;

    if (options.remoteWorkType) {
      endpoint += `&ApplicationType=${options.remoteWorkType}`;
    }
    if (options.remoteWorkStatus) {
      endpoint += `&ApplicationStatus=${options.remoteWorkStatus}`;
    }
    return AuthenticatedAxios.get(endpoint, null, null);
  },

  create: (data) => {
    let endpoint = `${BaseEndPoint}`;
    let body = JSON.stringify(data);
    return AuthenticatedAxios.post(endpoint, body, config);
  },

  update: (data, id) => {
    let endpoint = `${BaseEndPoint}/${id}`;
    let body = JSON.stringify(data);
    return AuthenticatedAxios.put(endpoint, body, config);
  },

  delete: (id) => {
    let endpoint = `${BaseEndPoint}/${id}`;
    return AuthenticatedAxios.delete(endpoint, null, null);
  },

  getStatusTypes: () => {
    let endpoint = `${BaseEndPoint}/AllApplicationStatus`;
    return AuthenticatedAxios.get(endpoint, null, null);
  },

  getApplicationTypes: () => {
    let endpoint = `${BaseEndPoint}/Type`;
    return AuthenticatedAxios.get(endpoint, null, null);
  },

  updateStatus: (data) => {
    let endpoint = `${BaseEndPoint}/UpdateStatus`;
    let body = JSON.stringify(data);
    return AuthenticatedAxios.patch(endpoint, body, config);
  },
};
