import React, { Component } from "react";
import OfficeTimingForm from "../forms/OfficeTimingForm";
import PropTypes from "prop-types";
import { CefaloButton } from "../../../../library/button";

export default class CreateOfficeTiming extends Component {
  state = {
    show: false,
  };

  toggle = (e) => () => {
    this.setState({ show: !this.state.show });
  };
  onSubmit = ({
    id,
    startDate,
    endDate,
    isDefaultOfficeTiming,
    officeStartTime,
    officeStartGraceHour,
    officeHour,
    lunchHour,
  }) => {
    let officeTiming = {
      id,
      startDate,
      endDate,
      isDefaultOfficeTiming,
      officeStartTime,
      officeStartGraceHour,
      officeHour,
      lunchHour,
    };

    return this.props.onCreateCallback(officeTiming).then((res) => {
      this.setState({ show: false });
      return res;
    });
  };

  render() {
    return (
      <div className="d-inline-block">
        <CefaloButton size="sm" variant="primary" onClick={this.toggle()}>
          Add Office Timing
        </CefaloButton>
        <OfficeTimingForm
          show={this.state.show}
          onToggle={this.toggle}
          submit={this.onSubmit}
          actionType="CREATE"
        />
      </div>
    );
  }
}

CreateOfficeTiming.propTypes = {
  onCreateCallback: PropTypes.func.isRequired,
};
