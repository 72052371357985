import React from "react";
import RootRoute from "./navigation/route/RootRoute";
import { BrowserRouter } from "react-router-dom";
import AppNavbar from "./navigation/navbar/AppNavbar";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

class App extends React.Component {
  render() {
    return (
      <div>
        <BrowserRouter>
          <AppNavbar />
          <RootRoute />
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
