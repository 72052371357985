import React from "react";
import { Form } from "../../../library/form/Index";
import { Col } from "react-bootstrap";
import styles from "../styles/Profile.module.css";
import { getCefaloFormatDate } from "../../../helpers/DateUtility";

export default class UserProfilePersonalInfoView extends React.Component {
  render() {
    return (
      <Form.Row className="mb-1">
        {this.props.personalInfo &&
          <Col sm="12">
            <Form.Row>
              <Col sm="12" className={styles.profileTitleHeader}>
                Personal Information
                <hr className="mt-0" />
              </Col>
            </Form.Row>
            <Form.Row>
              <Col sm="8">
                <Form.Row className="mb-2">
                  <Col>
                    <span>BLOOD GROUP</span>
                  </Col>
                  <Col>
                    <span>{this.props.personalInfo?.bloodGroup}</span>
                  </Col>
                </Form.Row>
                <Form.Row className="mb-2">
                  <Col>
                    <span>DOB</span>
                  </Col>
                  <Col>
                    <span>
                      {this.props.personalInfo?.dateOfBirth != null ?
                        getCefaloFormatDate(this.props.personalInfo?.dateOfBirth) :
                        'N/A'}
                    </span>
                  </Col>
                </Form.Row>
                <Form.Row className="mb-2">
                  <Col>
                    <span>AGE</span>
                  </Col>
                  <Col>
                    <span>{this.props.personalInfo?.age}</span>
                  </Col>
                </Form.Row>
                <Form.Row className="mb-2">
                  <Col>
                    <span>MARITAL STATUS</span>
                  </Col>
                  <Col>
                    <span>{this.props.personalInfo?.maritalStatus}</span>
                  </Col>
                </Form.Row>
                <Form.Row className="mb-2">
                  <Col>
                    <span>NID NUMBER</span>
                  </Col>
                  <Col>
                    <span>{this.props.personalInfo?.nIdNumber}</span>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col>
                    <span>PASSPORT NUMBER</span>
                  </Col>
                  <Col>
                    <span>{this.props.personalInfo?.passportNumber}</span>
                  </Col>
                </Form.Row>
              </Col>
            </Form.Row>
          </Col>}
      </Form.Row>
    );
  }
}