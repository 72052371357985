import React from "react";
import Row from "react-bootstrap/Row";

const LoginCefaloHeader = () => {
  return (
    <>
      <Row className="justify-content-md-center">
        <h1 className="text-center">
          Cefalo
          <br />
          <small className="text-muted">HR Management Portal</small>
        </h1>
      </Row>
      <br />
    </>
  );
};

export default LoginCefaloHeader;