import React from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import CefaloIcon from "../../library/Icons/Icon";
import { FaCircle } from "react-icons/fa";

export const AttendanceStatusTypeDictionary = Object.freeze({
  Normal: 1,

  LessHour: 2,

  LateEntry: 3,

  NotLoggedOut: 4,

  Absent: 5,

  CasualLeave: 6,

  SickLeave: 7,

  SickLeaveWithoutDoc: 8,

  UnpaidLeave: 9,

  MaternityLeave: 10,

  PaternityLeave: 11,

  NonWorkingDay: 12,

  PublicHoliday: 13,

  ExtraWorking: 14,

  ClientVisit: 15,

  OfficeOuting: 16,

  WorkFromHome: 17,

  ExchangeOfficeDay: 18,
});

export const AttendanceStatusTypeValueDictionary = Object.freeze({
  1: "Normal",
  2: "Less Hour",
  3: "Late Entry",
  4: "Not Logged Out",
  5: "Absent",
  6: "Casual Leave",
  7: "Sick Leave",
  8: "Sick Leave Without Doc",
  9: "Unpaid Leave",
  10: "Maternity Leave",
  11: "Paternity Leave",
  12: "Non Working Day",
  13: "Public Holiday",
  14: "Extra Working",
  15: "Client Visit",
  16: "Office Outing",
  17: "Work From Home",
  18: "Exchange Office Day",
});

const RemoteWork = ({ isDashboard, statusType }) => {
  return (
    <>
      <OverlayTrigger
        key="bottom"
        placement="bottom"
        overlay={
          <Tooltip placement="top">
            {AttendanceStatusTypeValueDictionary[statusType]}
          </Tooltip>
        }
      >
        <div className="float-left" style={{ paddingRight: "5px" }}>
          <CefaloIcon
            Icon={FaCircle}
            color="#FFBF00"
            size={isDashboard ? "15px" : "20px"}
          />
        </div>
      </OverlayTrigger>
    </>
  );
};

const Leave = ({ statusType, isDashboard }) => {
  return (
    <>
      <OverlayTrigger
        key="bottom"
        placement="bottom"
        overlay={
          <Tooltip placement="top">
            {AttendanceStatusTypeValueDictionary[statusType]}
          </Tooltip>
        }
      >
        <div className="float-left" style={{ paddingRight: "5px" }}>
          <CefaloIcon
            Icon={FaCircle}
            color="#BF40BF"
            size={isDashboard ? "15px" : "20px"}
          />
        </div>
      </OverlayTrigger>
    </>
  );
};

const Absent = ({ isDashboard }) => {
  return (
    <>
      <OverlayTrigger
        key="bottom"
        placement="bottom"
        overlay={<Tooltip placement="top">Absent</Tooltip>}
      >
        <div className="float-left" style={{ paddingRight: "5px" }}>
          <CefaloIcon
            Icon={FaCircle}
            color="red"
            size={isDashboard ? "15px" : "20px"}
          />
        </div>
      </OverlayTrigger>
    </>
  );
};

const Present = ({ isDashboard }) => {
  return (
    <>
      <OverlayTrigger
        key="bottom"
        placement="bottom"
        overlay={<Tooltip placement="top">Present</Tooltip>}
      >
        <div className="float-left" style={{ paddingRight: "5px" }}>
          <CefaloIcon
            Icon={FaCircle}
            color="green"
            size={isDashboard ? "15px" : "20px"}
          />
        </div>
      </OverlayTrigger>
    </>
  );
};

export const checkIfUserOnLeave = (statusType) => {
  let userOnLeave = {
    result: false,
  };

  if (
    statusType === AttendanceStatusTypeDictionary.CasualLeave ||
    statusType === AttendanceStatusTypeDictionary.SickLeave ||
    statusType === AttendanceStatusTypeDictionary.SickLeaveWithoutDoc ||
    statusType === AttendanceStatusTypeDictionary.UnpaidLeave ||
    statusType === AttendanceStatusTypeDictionary.PaternityLeave ||
    statusType === AttendanceStatusTypeDictionary.MaternityLeave
  ) {
    userOnLeave.result = true;
  }

  return userOnLeave;
};

export const checkIfUserOnRemoteWork = (statusType) => {
  let userOnRemoteWork = {
    result: false,
  };

  if (
    statusType === AttendanceStatusTypeDictionary.ClientVisit ||
    statusType === AttendanceStatusTypeDictionary.OfficeOuting ||
    statusType === AttendanceStatusTypeDictionary.WorkFromHome ||
    statusType === AttendanceStatusTypeDictionary.ExchangeOfficeDay
  ) {
    userOnRemoteWork.result = true;
  }

  return userOnRemoteWork;
};

export const PresentStatus = ({ entry, isDashboard }) => {
  if (entry.isPresent) {
    return <Present isDashboard={isDashboard} />;
  } else if (checkIfUserOnRemoteWork(entry.statusType).result) {
    return (
      <RemoteWork isDashboard={isDashboard} statusType={entry.statusType} />
    );
  } else if (checkIfUserOnLeave(entry.statusType).result) {
    return <Leave statusType={entry.statusType} isDashboard={isDashboard} />;
  } else return <Absent isDashboard={isDashboard} />;
};
