import React from "react";
import Layout from "../../shared-components/layout/Layout";
import { Helmet } from "react-helmet";
import { Loading } from "../../shared-components/notification/Notification";
import UserManager from "../../navigation/UserManager";
import { defaultErrorResponseParser } from "../../helpers/ErrorParser";
import { Select, Form } from "../../library/form/Index";
import { Col } from "../../library/grid-layout/Index";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/saga-blue/theme.css";
import { Container } from "../../library/grid-layout/Index";
import styles from "./styles/AuditLog.module.css";
import AuditLogApi from "../../apis/endpoints/AuditLogApi";
import AuditLogView from "./views/AuditLogView";
import { AuditLogDto } from "../../models/AuditLogDto";
import { enumsToValueLabelArray } from "../../shared-components/enum-values/EnumValuesComverter";

const ViewContainer = (props) => <div className="m-3">{props.children}</div>;

class AuditLogContainer extends React.Component {
  state = {
    auditLogs: [],
    isLoading: false,
    shouldRender: false,
    auditableEntityOptions: [],
  };

  componentDidMount = () => {
    let user = UserManager.getUser();
    if (!user.isAdminOrHr()) {
      window.location.replace(`/Report/Index/${user.id}`);
    } else {
      this.setState({ shouldRender: true, isLoading: true });
      this.getAllAuditLogs();
      this.getAuditableEnities();
    }
  };

  getAllAuditLogs = () => {
    this.setState({ isLoading: true });

    AuditLogApi.getAll()
      .then((res) => {
        let auditLogs = res.data.map((e) => new AuditLogDto(e));
        this.setState({
          isLoading: false,
          auditLogs: auditLogs,
        });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        defaultErrorResponseParser(err, "Failed to load audit log information");
      });
  };

  getAuditableEnities = () => {
    this.setState({ isLoading: true });
    AuditLogApi.getAllAuditableEntity().then((resp) => {
      let dataArray = resp.data;
      let auditableEntityOptions = [{ value: 0, label: "All" }].concat(
        enumsToValueLabelArray(dataArray)
      );
      this.setState({
        isLoading: false,
        auditableEntityOptions: auditableEntityOptions,
      });
    });
  };

  getFilteredAuditableEnities = (entityType) => {
    this.setState({ isLoading: true });

    AuditLogApi.getAllByEntityType(entityType)
      .then((res) => {
        let auditLogs = res.data.map((e) => new AuditLogDto(e));
        this.setState({
          isLoading: false,
          auditLogs: auditLogs,
        });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        defaultErrorResponseParser(err, "Failed to load audit log information");
      });
  };

  handleEntityType = (e) => {
    let entityType = e.target.value;
    this.getFilteredAuditableEnities(entityType);
  };

  render() {
    return (
      <Layout>
        <Helmet>
          <title>Audit Log - Cefalo</title>
        </Helmet>
        <Loading show={this.state.isLoading} />

        <ViewContainer>
          <Container fluid className="p-0">
            <div>
              <Form.Row>
                <Col className="mt-3" sm>
                    <Select
                      label="Entity Type:"
                      className={styles["filter"]}
                      name="entityType"
                      options={this.state.auditableEntityOptions}
                      onChange={this.handleEntityType.bind(this)}
                      placeholder="All"
                    />
                </Col>
              </Form.Row>
            </div>
          </Container>
          <AuditLogView auditLogs={this.state.auditLogs} />
        </ViewContainer>
      </Layout>
    );
  }
}
export default AuditLogContainer;
