import React from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import ClientDeleteAction from "../actions/ClientDeleteAction";
import ClientUpdateAction from "../actions/ClientUpdateAction";

const ActionsGroupView = (props) => {
    return (
        <div className="d-inline-block action-col">
          <ClientUpdateAction
            key={uuidv4()}
            client={props.client}
            onUpdateCallback={props.updateAction}
            keyAccountManagers={props.keyAccountManagers}
          />
          <ClientDeleteAction
            key={uuidv4()}
            onDeleteCallback={props.deleteAction}
            client={props.client}
          />
        </div>
      );
}
ActionsGroupView.prototype = {
    client: PropTypes.object,
    deleteAction: PropTypes.func.isRequired,
    updateAction: PropTypes.func.isRequired,
  };
  
  export default ActionsGroupView;
  