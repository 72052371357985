import { toast } from "react-toastify";
import { toastConfigurations } from "../notification/Notification";

function excludedField(key) {
  return key !== "Detail" && key !== "RequestPath" && key !== "Status";
}
export function showErrorMessages(errorObject) {
  let keys = Object.keys(errorObject);
  keys
    .filter(excludedField)
    .map((key) => toast.error(`${errorObject[key]}`, toastConfigurations));
}

export function showSuccessMessages(message = "") {
  toast.success(`${message}`, toastConfigurations);
}
