import React, { useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import PublicHolidayApi from "../../apis/endpoints/PublicHolidayApi";
import { Loading, toaster } from "../../shared-components/notification";
import { Card, Table, FormLabel, Row } from "react-bootstrap";
import { defaultErrorResponseParser } from "../../helpers/ErrorParser";
import { CefaloButton } from "../../library/button";
import { FormModal } from "../../library/modal";

const PublicHolidayUploadCsvFormModal = ({
  onSelect,
  onUpload,
  show,
  onToggle,
}) => {
  const { handleSubmit } = useForm({});

  const [file, setFile] = useState(null);
  const onFormSubmit = () => {
    onUpload();
  };
  const handleSelect = (event) => {
    if (event != null) {
      setFile(event.target.files[0].name);
    }
    onSelect(event);
  };

  return (
    <FormModal
      onSubmit={handleSubmit(onFormSubmit)}
      show={show}
      toggle={onToggle()}
      formName="Upload Holiday"
    >
      <div key={FormModal.ControlContainer}>
        <Card.Body>
          <p>Note: csv file should be like this (viewed in excel).</p>
          <Table striped bordered size="sm">
            <thead>
              <tr>
                <th>Date</th>
                <th>Name</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>16-Dec-2017</td>
                <td>Victory Day</td>
                <td>Victory Day</td>
              </tr>
              <tr>
                <td>25-Dec-2017</td>
                <td>Christmas Day</td>
                <td>Christmas Day</td>
              </tr>
            </tbody>
          </Table>
          <p style={{ textDecoration: "underline" }}>
            Same file viewed in notepad
          </p>
          <p>
            Date,Name,Description
            <br />
            16-Dec-2017,Victory Day,Victory Day
            <br />
            25-Dec-2017,Christmas Day,Christmas Day
          </p>
        </Card.Body>
        <Row className="col-sm-6 ">
          <FormLabel
            className="d-block"
            style={{ fontSize: "12px", fontWeight: "600" }}
          >
            Choose a file:
          </FormLabel>
          <div className="custom-file">
            <input
              type="file"
              className="custom-file-input"
              id="file"
              aria-describedby="inputGroupFileAddon01"
              onChange={handleSelect}
              required
            />
            {file ? (
              <label
                className="custom-file-label d-block "
                htmlFor="file"
                style={{ fontSize: "12px" }}
              >
                {file}
              </label>
            ) : (
              <label
                className="custom-file-label d-block "
                htmlFor="file"
                style={{ fontSize: "12px" }}
              >
                choose file
              </label>
            )}
          </div>
        </Row>
      </div>
      <div key={FormModal.ActionContainer}>
        <CefaloButton
          size="sm"
          variant="light"
          onClick={onToggle()}
          type="button"
        >
          Close
        </CefaloButton>
        <CefaloButton size="sm" variant="primary" type="submit">
          Save
        </CefaloButton>
      </div>
    </FormModal>
  );
};
PublicHolidayUploadCsvFormModal.prototype = {
  onUpdate: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
};

class UploadCsvFormModal extends React.Component {
  static propTypes = {
    onUploadCallback: PropTypes.func.isRequired,
  };
  state = {
    file: null,
    show: false,
    isLoading: false,
  };

  toggle = (e) => () => {
    this.setState({ show: !this.state.show });
  };

  fileSelectHandler = (event) => {
    this.setState({
      file: event.target.files[0],
    });
  };

  onUploadAction = () => {
    this.setState({ isLoading: true });
    const formData = new FormData();
    formData.append("FormFile", this.state.file);
    PublicHolidayApi.Upload(formData)
      .then((res) => {
        this.setState({
          isLoading: false,
          show: false,
        });
        toaster.success("PublicHolidays is updated");
        this.props.onUploadCallback(res.data);
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        defaultErrorResponseParser(err, "Failed to upload holidays");
        // showErrorMessages(err.response);
      });
  };
  render() {
    return (
      <div className="d-inline-block">
        <Loading show={this.state.isLoading} />
        <CefaloButton size="sm" variant="primary" onClick={this.toggle()}>
          Upload CSV File
        </CefaloButton>
        <PublicHolidayUploadCsvFormModal
          onUpload={this.onUploadAction}
          onSelect={this.fileSelectHandler}
          show={this.state.show}
          onToggle={this.toggle}
        />
      </div>
    );
  }
}
export default UploadCsvFormModal;
