import { AuthenticatedAxios } from "../settings/Axios";
const BaseEndPoint = "PublicHolidays";
let config = {
  headers: {
    "Content-Type": "application/json",
  },
};

export default {
  getByYear: (year) => {
    let endpoint = `${BaseEndPoint}/?year=${year}`;
    return AuthenticatedAxios.get(endpoint, null, null);
  },

  update: (holiday) => {
    let endpoint = `${BaseEndPoint}/${holiday.id}`;
    let body = JSON.stringify(holiday);

    return AuthenticatedAxios.put(endpoint, body, config);
  },

  create: (holiday) => {
    return AuthenticatedAxios.post(BaseEndPoint, holiday, config);
  },

  delete: (id) => {
    let endpoint = `${BaseEndPoint}/${id}`;
    return AuthenticatedAxios.delete(endpoint, null, {});
  },

  getPublicHolidayById: (id) => {
    let endpoint = `${BaseEndPoint}/${id}`;
    return AuthenticatedAxios.get(endpoint, null, null);
  },
  Upload: (file) => {
    let endpoint = `${BaseEndPoint}/File`;
    let configuration = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    return AuthenticatedAxios.post(endpoint, file, configuration);
  },
};
