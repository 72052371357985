import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { CefaloButton } from "../../../library/button/Index";
import { FormModal } from "../../../library/modal/Index";
import { Form, Select } from "../../../library/form/Index";
import { Col } from "../../../library/grid-layout/Index";

const TrainingHistoryUpsertForm = ({
  trainings,
  show,
  toggle,
  onSubmit,
  formName,
  userId,
}) => {
  const { handleSubmit, register } = useForm({});

  const [selectedTraining, setSelectedTraining] = useState(null);

  const handleTrainingChange = (e) => {
    setSelectedTraining(e.target.value);
  };

  const onTrainingHistorySubmit = () => {
    const trainingHistory = {
      UserId: userId,
      TrainingId:
        selectedTraining !== null ? selectedTraining : trainings[0]?.value,
    };

    onSubmit(trainingHistory);
  };

  return (
    <FormModal
      onSubmit={handleSubmit()}
      toggle={toggle()}
      show={show}
      formName={formName}
    >
      <div key={FormModal.ControlContainer}>
        <Form.Row>
          <Col>
            <Select
              id="trainingId"
              ref={register}
              label="Training"
              name="trainingId"
              options={trainings}
              placeholder="All Trainings"
              onChange={handleTrainingChange}
            />
          </Col>
        </Form.Row>
      </div>
      <div key={FormModal.ActionContainer}>
        <CefaloButton
          size="sm"
          variant="light"
          onClick={toggle()}
          type="button"
        >
          Close
        </CefaloButton>

        <CefaloButton
          size="sm"
          variant="primary"
          onClick={onTrainingHistorySubmit}
          type="button"
        >
          Save
        </CefaloButton>
      </div>
    </FormModal>
  );
};

export default TrainingHistoryUpsertForm;
