import React from "react";
import { ClientDto } from "../../../models/ClientDto";
import { DataTable } from "../../../library/table";
import ActionsGroupView from "./ActionsGroupView";

const ClientTableView = ({
  clients,
  keyAccountManagers,
  onUpdate,
  onDelete,
}) => {
  const buildTableRowsFromClientData = (clients, keyAccountManagers) => {
    let tempRows = [];
    clients.forEach((item) => {
      if (item instanceof ClientDto) {
        let index = keyAccountManagers.findIndex(
          (kamItem) => kamItem.value === item.keyAccountManagerId
        );
        let tempRow = {};
        tempRow["id"] = item.id;
        tempRow["name"] = item.name;
        tempRow["keyAccountManager"] =
          keyAccountManagers[index]?.label == null
            ? "N/A"
            : keyAccountManagers[index]?.label;
        tempRow["actions"] = (
          <ActionsGroupView
            client={item}
            deleteAction={onDelete}
            updateAction={onUpdate}
            keyAccountManagers={keyAccountManagers}
          />
        );
        tempRows.push(tempRow);
      }
    });
    return tempRows;
  };

  const clientTableColumns = [
    {
      text: "Client Id",
      dataField: "id",
      sort: true,
    },
    {
      text: "Client Name",
      dataField: "name",
      sort: true,
    },
    {
      text: "Key Account Manager",
      dataField: "keyAccountManager",
      sort: true,
    },
    {
      text: "Actions",
      dataField: "actions",
      sort: false,
    },
  ];

  let rows = buildTableRowsFromClientData(clients, keyAccountManagers);
  return <DataTable rows={rows} columns={clientTableColumns} />;
};

export default ClientTableView;
