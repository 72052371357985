export const Role =
{
    admin : 1, 
    hr : 2, 
    user : 3, 
    team : 4,
    guest : 5
}

export const UserRoleInString =
{
    admin : "Admin", 
    hr : "HR", 
    user : "User", 
    team : "Team",
    guest : "Guest"
}
export const convertStringToRoleArray = (object = {}) => {
  let keys = Object.keys(object);
  var roles = []
  keys.filter((key) => object[key]).forEach(function(key) {
      if(key === 'team'){
        roles.push(Role.team)
      } else if(key === 'user'){
        roles.push(Role.user)
      }
      });
  return roles;
};
export const convertRoleToStringObject = (roles = []) => {
  let object = {};
  roles.forEach(function(item){
    if(item === 4){
      (object["team"] = true)
    }else if(item === 3){
      (object["user"] = true)
    }
  })
  return object;
};
export const toCommaSeparatedString = (object = {}) => {
  let keys = Object.keys(object);
  let builtString = keys
    .filter((key) => object[key])
    .map((key) => key.charAt(0).toUpperCase() + key.slice(1))
    .join();
  return builtString;
};
export const commaSeparatedStringToObject = (commaString = "") => {
  commaString = commaString.toLowerCase();
  let keys = commaString.split(",").filter((key) => key.length);
  let object = {};
  keys.forEach((key) => (object[key] = true));
  return object;
};
