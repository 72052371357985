import React from "react";
import { Input } from "../../../../library/form";

const PolicySettingsView = ({ settings, onChangeCrudObject }) => {
  return (
    <>
      <Input
        label="HR Document Link"
        name="hrPolicyDocsUrl"
        value={settings["hrPolicyDocsUrl"]}
        onChange={onChangeCrudObject}
        placeholder="https://docs.google.com"
      />
      <Input
        label="IT Document Link"
        name="itPolicyDocsUrl"
        value={settings["itPolicyDocsUrl"]}
        onChange={onChangeCrudObject}
        placeholder="https://docs.google.com"
      />
      <Input
        label="Knowledge Sharing Sessions Document Link"
        name="knowledgeSharingSessionsDocsUrl"
        value={settings["knowledgeSharingSessionsDocsUrl"]}
        onChange={onChangeCrudObject}
        placeholder="https://docs.google.com"
      />
    </>
  );
};

export default PolicySettingsView;
