import { AuthenticatedAxios } from "../settings/Axios";
const SettingsEndpoint = "Settings";

export default {
  create: (data) => {
    let endpoint = `${SettingsEndpoint}`;
    return AuthenticatedAxios.post(endpoint, data);
  },
  update: (id, data) => {
    let endpoint = `${SettingsEndpoint}`;
    return AuthenticatedAxios.post(endpoint, data);
  },
  get: () => {
    let endpoint = `${SettingsEndpoint}`;
    return AuthenticatedAxios.get(endpoint);
  },
  getLeaveSettings: () => {
    let endpoint = `${SettingsEndpoint}/Leave`;
    return AuthenticatedAxios.get(endpoint);
  },

  getPublicSettings: () => {
    let endpoint = `${SettingsEndpoint}/Public`;
    return AuthenticatedAxios.get(endpoint);
  },
};
