import { DateOnlyBuilder } from "../helpers/Builders";

export class SkillDevelopmentRequestDto {

  // Fields
  id;
  creatorId;
  userId;
  name;
  createdAt;
  requestStatus;
  requestStatusName;
  courseName;
  courseLink;
  amount;
  purpose;
  invoiceFileName;
  certificationFileName;
  reason;

  constructor(obj = {}) {
    this.id = isNaN(obj.id) ? null : Number(obj.id);
    this.creatorId = isNaN(obj.creatorId) ? null : Number(obj.creatorId);
    this.userId = isNaN(obj.userId) ? null : Number(obj.userId);
    this.name = obj.name;
    this.createdAt = !!obj.createdAt ? DateOnlyBuilder(obj.createdAt) : null;
    this.requestStatus = obj.applicationStatus;
    this.requestStatusName = obj.applicationStatusName;
    this.courseName = obj.title;
    this.courseLink = obj.link;
    this.amount = isNaN(obj.amount) ? null : Number(obj.amount);
    this.purpose = obj.purpose;
    this.invoiceFileName = obj.invoiceFileName;
    this.certificationFileName = obj.certificationFileName;
    this.reason = obj.reason;
  }
}