export const globalSettingsUpperTableCol = [
  {
    label: "Internal Login Email",
    field: "internalLoginEmail",
  },
  {
    label: "Password",
    field: "internalLoginPassword",
  },
  {
    label: "Deduct Lunch Hour After",
    field: "minimumHoursForDeductingLunchTime",
  },
];

export const globalSettingsLowerTableCol = [
  {
    label: "Minimum Half Day Working Hour",
    field: "minimumHalfDayWorkingHour",
  },
  {
    label: "Additional Home Office Days Annual Limit",
    field: "additionalWorkFromHomeAnnualLimit",
  },
  {
    label: "Exchange Office Day Annual Limit",
    field: "exchangeOfficeDayAnnualLimit",
  },
];

export const EmailSettingsTableCol = [
  {
    label: "Leave Application Request",
    field: "leaveRequestEmailRecipients",
  },
  {
    label: "Leave Application Confirmation",
    field: "leaveConfirmationEmailRecipients",
  },
  {
    label: "EWH Application Request",
    field: "ewhRequestEmailRecipients",
  },
  {
    label: "EWH Application Confirmation",
    field: "ewhConfirmationEmailRecipients",
  },
  {
    label: "Remote Work Application Request",
    field: "remoteWorkRequestEmailRecipients",
  },
  {
    label: "Remote Work Application Confirmation",
    field: "remoteWorkConfirmationEmailRecipients",
  },
];

export const ProfileSettingsTableCol = [
  {
    label: "Education",
    field: "profileEducationInfoViewers",
  },
  {
    label: "Personal Info",
    field: "profilePersonalInfoViewers",
  },
  {
    label: "Contact Info",
    field: "profileContactInfoViewers",
  },
  {
    label: "Attendance",
    field: "profileAttendanceInfoViewers",
  },
  {
    label: "Leave",
    field: "profileLeaveStatisticsInfoViewers",
  },
];
export const LeaveSettingsUpperTableCol = [
  {
    label: "Casual Leave Per Month",
    field: "casualLeavePerMonth",
  },
  {
    label: "Max Forwarded Leave",
    field: "maxForwardedLeave",
  },
  {
    label: "Sick Leave Without Doc Per Year",
    field: "sickLeaveWithoutDocPerYear",
  },
  {
    label: "Total Sick Leave Per Year",
    field: "totalSickLeavePerYear",
  },
];

export const LeaveSettingsLowerTableCol = [
  {
    label: "Leave Cancellation Period",
    field: "allowedCancellationPeriod",
  },
  {
    label: "Maternity Leave Per Year (In Months)",
    field: "maternityLeavePerYearInMonths",
  },
  {
    label: "Paternity Leave Per Year (In Days)",
    field: "paternityLeavePerYearInDays",
  },
];
export const SettingsTable = [
  {
    label: "Settings Type",
    field: "settingsType",
  },
  {
    label: "Settings Data",
    field: "settingsData",
  },
];

export const initialSettings = {
  adminEmail: "",
  applicationRequestEmailRecipients: "",
  applicationConfirmationEmailRecipients: "",
  internalLoginEmail: "",
  internalLoginPassword: "",
  profileEducationInfoViewers: "",
  profilePersonalInfoViewers: "",
  profileContactInfoViewers: "",
  profileAttendanceInfoViewers: "",
  profileLeaveStatisticsInfoViewers: "",
  minimumHoursForDeductingLunchTime: 0,
  minimumHalfDayWorkingHour: 0,
  casualLeavePerMonth: 0,
  maxForwardedLeave: 0,
  sickLeaveWithoutDocPerYear: 0,
  totalSickLeavePerYear: 0,
  allowedCancellationPeriod: 0,
  id: 0,
};

export const WeekDays = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
};
