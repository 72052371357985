import React from "react";
import PropTypes from "prop-types";
import LeaveAppliactionApi from "../../../apis/endpoints/LeaveAppliactionApi";
import { toaster, Loading } from "../../notification";
import { LeaveStatusValueDictionary } from "../Consts";
import { FormModal } from "../../../library/modal";
import { defaultErrorResponseParser } from "../../../helpers/ErrorParser";
import { CefaloButton } from "../../../library/button";

export default class ApplicationCancelAction extends React.Component {
  static propTypes = {
    application: PropTypes.object,
    onCancelCallback: PropTypes.func.isRequired,
  };

  state = {
    isLoading: false,
    show: false,
  };

  toggle = (e) => () => {
    this.setState({ show: !this.state.show });
  };

  onCancel = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    let newStatus =
      this.props.application.status === LeaveStatusValueDictionary.Requested
        ? LeaveStatusValueDictionary.CancelByUser
        : LeaveStatusValueDictionary.CancelRequest;
    let data = {
      id: this.props.application.id,
      status: newStatus,
    };
    LeaveAppliactionApi.updateStatus(data)
      .then((res) => {
        this.setState({ show: false, isLoading: false });
        toaster.success("Leave Application Canceled Successfully");
        this.props.onCancelCallback();
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        defaultErrorResponseParser(err, "Failed To Cancel Request");
      });
  };

  render() {
    return (
      <div className="d-inline-block">
        <Loading show={this.state.isLoading} />
        <FormModal
          onSubmit={this.onCancel}
          show={this.state.show}
          toggle={this.toggle()}
        >
          <div key={FormModal.ControlContainer}>
            Are you sure to cancel this leave application?
          </div>

          <div key={FormModal.ActionContainer}>
            <CefaloButton
              size="sm"
              variant="danger"
              onClick={this.toggle()}
              type="button"
            >
              No
            </CefaloButton>
            <CefaloButton size="sm" variant="success" type="submit">
              Yes
            </CefaloButton>
          </div>
        </FormModal>
        <CefaloButton
          className="btn-font-12"
          size="sm"
          variant="danger"
          onClick={this.toggle()}
        >
          Cancel
        </CefaloButton>
      </div>
    );
  }
}
