import React, { useState } from "react";
import { useForm } from "react-hook-form";

import { CefaloButton } from "../../../library/button/Index";
import { FormModal } from "../../../library/modal/Index";
import { Form, Input } from "../../../library/form/Index";
import { Col } from "../../../library/grid-layout/Index";

const CertificationUpsertForm = ({
  show,
  toggle,
  onSubmit,
  profile,
  defaultValues,
  formName,
}) => {
  const { handleSubmit, register } = useForm({});

  const [title, setTitle] = useState(
    defaultValues?.title !== undefined ? defaultValues?.title : null
  );

  const [link, setLink] = useState(
    defaultValues?.link !== undefined ? defaultValues?.link : null
  );

  const [description, setDescription] = useState(
    defaultValues?.description !== undefined ? defaultValues?.description : null
  );

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleLinkChange = (e) => {
    setLink(e.target.value);
  };

  const onEmployeeCertificationSubmit = () => {
    const employeeCertificationData = {
      userId: profile?.userId,
      profileId: profile?.id,
      title: title,
      description: description,
      link: link,
    };
    if (defaultValues?.id !== undefined) {
      employeeCertificationData["id"] = defaultValues["id"];
    }

    onSubmit(employeeCertificationData);
  };

  return (
    <FormModal
      onSubmit={handleSubmit()}
      toggle={toggle()}
      show={show}
      formName={formName}
    >
      <div key={FormModal.ControlContainer}>
        <Form.Row>
          <Col>
            <Input
              name="title"
              label="Title"
              placeholder="Enter Title"
              type="text"
              value={title}
              ref={register}
              onChange={handleTitleChange}
            />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <Input
              name="description"
              label="Description"
              placeholder="Enter Description"
              type="text"
              value={description}
              ref={register}
              onChange={handleDescriptionChange}
            />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <Input
              name="link"
              label="Link"
              placeholder="Enter Link"
              type="url"
              value={link}
              ref={register}
              onChange={handleLinkChange}
            />
          </Col>
        </Form.Row>
      </div>

      <div key={FormModal.ActionContainer}>
        <CefaloButton
          size="sm"
          variant="light"
          onClick={toggle()}
          type="button"
        >
          Close
        </CefaloButton>

        <CefaloButton
          size="sm"
          variant="primary"
          onClick={onEmployeeCertificationSubmit}
          type="button"
        >
          Save
        </CefaloButton>
      </div>
    </FormModal>
  );
};

export default CertificationUpsertForm;
