import React from "react";
import PropTypes from "prop-types";

import { toaster, Loading, ConfimationPopup } from "../../notification";
import { CefaloButton } from "../../../library/button/Index";
import ApplicationUpdateFormModal from "../forms/UpdateForm";
import CefaloUtility from "../../../helpers/CefaloUtility";
import RemoteWorkApi from "../../../apis/endpoints/RemoteWorkApi";
import { defaultErrorResponseParser } from "../../../helpers/ErrorParser";

export default class ApplicationUpdateAction extends React.Component {
  static propTypes = {
    userId: PropTypes.number.isRequired,
    application: PropTypes.object.isRequired,
    onUpdateCallback: PropTypes.func.isRequired,
  };

  state = {
    showUpdateModal: false,
    showConfirmation: false,
    fromDate: new Date(this.props.application.fromDate),
    toDate: new Date(this.props.application.toDate),
    totalWorkingDays: this.props.application.totalNumberOfWorkingDays,
    isLoading: false,
  };

  valueReset = () => {
    this.setState({
      fromDate: new Date(this.props.application.fromDate),
      toDate: new Date(this.props.application.toDate),
      totalWorkingDays: this.props.application.totalNumberOfWorkingDays,
    });
  };

  toggle = (e) => () => {
    this.setState({ showUpdateModal: !this.state.showUpdateModal });
    this.valueReset();
  };

  onApplicationPeriodSelect = (fromDate, toDate, isWorkFromHome) => {
    this.setState({
      fromDate: fromDate,
      toDate: toDate,
    });
    if (isWorkFromHome) {
      CefaloUtility.getWorkfromHomeApplicationDaysCount(
        fromDate,
        toDate,
        this.props.userId,
        (days) => {
          this.setState({ totalWorkingDays: days });
        }
      );
    } else {
      CefaloUtility.getWorkingDaysCount(fromDate, toDate, false, (days) => {
        this.setState({ totalWorkingDays: days });
      });
    }
  };

  onUpdateConfirmation = (confirm) => {
    if (!confirm) {
      this.setState({ showConfirmation: false });
    } else {
      let applicationData = this.state.updatedApplicationData;
      this.setState({
        isLoading: true,
        updatedApplicationData: null,
        showConfirmation: false,
      });
      RemoteWorkApi.update(applicationData, this.props.application.id)
        .then((res) => {
          this.props.onUpdateCallback(res.data);
          toaster.success("Application Updated Successfully");
          this.setState({
            isLoading: false,
            showUpdateModal: false,
          });
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          defaultErrorResponseParser(
            err,
            "Failed To Update Remote Work Application"
          );
        });
    }
  };

  onUpdateAction = (applicationData) => {
    applicationData["userId"] = this.props.userId;
    applicationData["id"] = this.props.application.id;
    this.setState({
      updatedApplicationData: applicationData,
      showConfirmation: true,
    });
  };

  render() {
    return (
      <div className="d-inline-block">
        <Loading show={this.state.isLoading} />
        <ConfimationPopup
          show={this.state.showConfirmation}
          text="Are you sure to Edit this remote work application?"
          onConfirmCallback={this.onUpdateConfirmation}
        />
        <CefaloButton
          className="btn-font-12"
          size="sm"
          variant="info"
          onClick={this.toggle()}
        >
          Edit
        </CefaloButton>
        <ApplicationUpdateFormModal
          show={this.state.showUpdateModal}
          onUpdate={this.onUpdateAction}
          onToggle={this.toggle}
          toDate={this.state.toDate}
          fromDate={this.state.fromDate}
          totalWorkingDays={this.state.totalWorkingDays}
          periodSelectCallBack={this.onApplicationPeriodSelect}
          application={this.props.application}
          remoteWorkApplicationTypes={this.props.remoteWorkApplicationTypes}
        />
      </div>
    );
  }
}
