export class AuditLogDto {
    constructor(obj) {
      this.timeStamp = obj.timeStamp;
      this.tableName = obj.tableName;
      this.actionType = obj.actionType;
      this.employeeId = obj.employeeId;
      this.employeeName = obj.employeeName;
      this.performerId = obj.performerId;
      this.performerName = obj.performerName;
      this.newValues = obj.newValues;
      this.oldValues = obj.oldValues;
    }
  }

  