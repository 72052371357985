import React from "react";
import Layout from "../../shared-components/layout/Layout";
import { Helmet } from "react-helmet";
import BillingView from "./views/BillingView";
import UserApi from "../../apis/endpoints/UserApi";
import TeamApi from "../../apis/endpoints/TeamApi";
import {
  toaster,
  Loading,
} from "../../shared-components/notification/Notification";
import UserManager from "../../navigation/UserManager";
import ManageExportApi from "../../apis/endpoints/ManageExportApi";
import { defaultErrorResponseParser } from "../../helpers/ErrorParser";
import { Row } from "react-bootstrap";
import { MultiSelect } from "react-multi-select-component";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/saga-blue/theme.css";
import { CefaloButton } from "../../library/button/Index";
import { FaFilter, FaCaretDown } from "react-icons/fa";
import CefaloIcon from "../../library/Icons/Icon";
import Collapse from "@kunukn/react-collapse";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { Container } from "../../library/grid-layout/Index";
import { BillDto } from "../../models/Bill";
import styles from "./styles/Billing.module.css";
import Select from "../../library/form/Select";
import ReactSelect from "react-select";
const ViewContainer = (props) => <div className="p-2">{props.children}</div>;

class BillingContainer extends React.Component {
  state = {
    Bills: [],
    teams: [],
    teamNames: [],
    monthOptions: [],
    userRoles: [],
    profile: null,
    isLoading: false,
    shouldRender: false,
    selectedTeams: [],
    users: [],
    selectedUser: [],
    Year: new Date().getFullYear(),
    Month: new Date().getMonth(),
    isOpen1: false,
  };

  overrideStrings = {
    allItemsAreSelected: "All",
    clearSearch: "Clear Search",
    clearSelected: "Clear Selected",
    noOptions: "No options",
    search: "Search",
    selectAll: "All",
    selectAllFiltered: "Select All (Filtered)",
    selectSomeItems: "Select",
    create: "Create",
  };

  componentDidMount = () => {
    let user = UserManager.getUser();
    if (!user.isAdminOrHr()) {
      window.location.replace(`/Report/Index/${user.id}`);
    } else {
      this.setState({ shouldRender: true, isLoading: true });
      this.loadBillOfMonth();
      this.loadUserData();
      this.loadTeamData();
      this.loadMonthOptions();
    }
  };

  loadMonthOptions = () => {
    let currentMonth = this.state.Month;
    let monthOptions = [];
    var monthName = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    var d = new Date();
    d.setMonth(currentMonth);
    d.setDate(1);
    for (let i = 0; i <= 11; i++) {
      var option = {
        label: monthName[d.getMonth()],
        value: currentMonth - i,
      };
      monthOptions.push(option);
      d.setMonth(d.getMonth() - 1);
    }
    this.setState({
      monthOptions: monthOptions,
    });
  };
  loadTeamData = () => {
    TeamApi.all()
      .then((res) => {
        let teamNames = res.data;
        let names = [];
        teamNames.forEach((element) => {
          var option = { label: element.name, value: element.id };
          names.push(option);
        });
        this.setState({
          teamNames: names,
          selectedTeams: names,
        });
      })
      .catch((err) => {
        toaster.error("Failed to load Team information");
      });
  };

  loadUserData = () => {
    this.setState({ isLoading: true });
    UserApi.allCefaloUsers()
      .then((res) => {
        let usersData = res.data;
        let users = [];
        users.push({ label: "All Employees", value: "ALL" });
        usersData.forEach((element) => {
          var option = { label: element.name, value: element.id };
          users.push(option);
        });
        this.setState({
          users: users,
        });
      })
      .catch((err) => {
        toaster.error("Failed to load User information");
      });
  };

  loadBillOfMonth = () => {
    let month = this.state.Month;
    this.setState({ isLoading: true });
    ManageExportApi.getBillsOfMonth(month + 1, this.state.Year)
      .then((res) => {
        let Bills = res.data.map((u) => new BillDto(u));
        this.setState({ isLoading: false, Bills: Bills });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        defaultErrorResponseParser(err, "Failed to load Billing information");
      });
  };

  componentDidMount() {
    let currentMonth = new Date().getMonth();
    this.setState({ Month: currentMonth });
  }

  buildEndpoint = () => {
    let selectedTeams = [];
    let teamNames = "";
    let user = "";
    if (this.state.selectedTeams.length !== 0) {
      this.state.selectedTeams.forEach((item) => {
        selectedTeams.push(item.label);
      });
      teamNames = selectedTeams.join(",");
    } else teamNames = "";
    this.state.selectedUser.length === 0
      ? (user = "")
      : (user = this.state.selectedUser.label);
    let endpoint = `${this.state.Month + 1}/${
      this.state.Year
    }?teamNames=${teamNames}&name=${user}`;
    this.GetBillsOfTeamAndUser(endpoint);
  };

  onMonthSelect = (e) => {
    let year = new Date().getFullYear();
    if (e.target.value < 0) {
      let month = 12 + parseInt(e.target.value);
      this.setState({ Month: month });
      this.setState({ Year: year - 1 });
    } else {
      let month = parseInt(e.target.value);
      this.setState({ Month: month });
      this.setState({ Year: year });
    }
  };

  GetBillsOfTeamAndUser(endpoint) {
    this.setState({ isLoading: true });
    let selectedTeams = [];
    this.state.selectedTeams.forEach((item) => {
      selectedTeams.push(item.label);
    });
    ManageExportApi.getBillsOfTeamAndUser(endpoint)
      .then((res) => {
        let Bills = res.data.map((u) => new BillDto(u));
        this.setState({ isLoading: false, Bills: Bills });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        defaultErrorResponseParser(err, "Failed to load Billing information");
      });
  }
  TeamhandleOnChange(value) {
    if (value === null) {
      this.setState({
        selectedTeams: [],
      });
    } else {
      this.setState({
        selectedTeams: value,
      });
    }
  }
  UserhandleOnChange(e) {
    if (!e || e.value === "ALL") {
      this.setState({
        selectedUser: [],
      });
    } else {
      this.setState({
        selectedUser: e,
      });
    }
  }

  handleExport = () => {
    this.setState({ isLoading: true });
    let selectedTeams = [];
    let teamNames = "";
    let user = "";
    if (this.state.selectedTeams.length !== 0) {
      this.state.selectedTeams.forEach((item) => {
        selectedTeams.push(item.label);
      });
      teamNames = selectedTeams.join(",");
    } else teamNames = "";
    this.state.selectedUser.length === 0
      ? (user = "")
      : (user = this.state.selectedUser.label);
    let endpoint = `?teamNames=${teamNames}&name=${user}`;
    this.buildEndpoint();
    ManageExportApi.downloadBills(
      endpoint,
      this.state.Month + 1,
      this.state.Year
    )
      .then((res) => {
        this.setState({ isLoading: false });
        let fileName = res.headers["x-attachmentname"];
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("Download", fileName);
        link.click();
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        if (err.response) {
          if (err.response.data.Title) {
          } else {
          }
        } else {
        }
      });
  };

  toggle = (index) => {
    let collapse = "isOpen" + index;

    this.setState((prevState) => ({ [collapse]: !prevState[collapse] }));
  };
  render() {
    if (!this.state.shouldRender) {
      return <></>;
    }

    return (
      <Layout>
        <Helmet>
          <title>Bill Management- Cefalo</title>
        </Helmet>
        <Loading show={this.state.isLoading} />
        <ViewContainer>
          <div className="d-flex justify-content-end">
            <div className="z-index-3 pt-1 pb-2">
              <div className=" d-inline-block pt-2 pr-2 pl-2">
                <CefaloButton
                  block={true}
                  variant="primary"
                  size="sm"
                  type="button"
                  onClick={this.handleExport}
                >
                  Export Report
                </CefaloButton>
              </div>
              <OverlayTrigger
                key="bottom"
                placement="bottom"
                overlay={
                  <Tooltip id="attendance-tooltip-bottom">Filter</Tooltip>
                }
              >
                <CefaloButton
                  className="mb-1"
                  style={{ zIndex: 100 }}
                  onClick={() => this.toggle(1)}
                  variant="light"
                  size="sm"
                  aria-controls="attendance-filter-collpase"
                  aria-expanded={this.state.collapse}
                >
                  <CefaloIcon Icon={FaFilter} />
                  <CefaloIcon Icon={FaCaretDown} />
                </CefaloButton>
              </OverlayTrigger>
            </div>
          </div>
          <Collapse
            isOpen={this.state.isOpen1}
            style={this.state.isOpen1 ? { overflow: "visible" } : {}}
          >
            <Container fluid className="p-0">
              <div>
                <Row>
                  <div className="pl-3 float-left ml-2">
                    <Select
                      style={{height: "38px"}}
                      defaultValue={this.state.monthOptions[0]}
                      label="Bills of Month"
                      options={this.state.monthOptions}
                      onChange={this.onMonthSelect.bind(this)}
                      name="months"
                    />
                  </div>
                  <div className="col-sm-2">
                    <label>Employees</label>
                    <ReactSelect
                      options={this.state.users}
                      onChange={this.UserhandleOnChange.bind(this)}
                      name="users"
                      isSearchable={true}
                      isClearable={true}
                      placeholder="All Employees"
                    />
                  </div>
                  <div className="col">
                    <label htmlFor="teams">Teams</label>
                    <MultiSelect
                      options={this.state.teamNames}
                      value={this.state.selectedTeams}
                      onChange={this.TeamhandleOnChange.bind(this)}
                      className={`${styles.multiSelectFilter} ${styles["team-dropdown"]}`}
                      labelledBy="All Teams"
                      overrideStrings={this.overrideStrings}
                    />
                    <div
                      className="d-inline-block pr-2"
                      style={{ lineHeight: "30px", fontSize: "16px" }}
                    ></div>
                  </div>
                  <div className="col-sm-2 mr-2">
                    <div className="pt-4 pl-3">
                      <CefaloButton
                        block={true}
                        variant="primary"
                        type="button"
                        onClick={this.buildEndpoint}
                      >
                        Filter Report
                      </CefaloButton>
                    </div>
                  </div>
                </Row>
              </div>
            </Container>
          </Collapse>
          <BillingView Bills={this.state.Bills} />
        </ViewContainer>
      </Layout>
    );
  }
}
export default BillingContainer;
