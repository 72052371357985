import React from "react";
import { Form } from "../../library/form";
import { FormModal } from "../../library/modal";
import { Table } from "../../library/table";
import styles from "./styles/LeaveDataUploadView.module.css";
import LeaveAppliactionApi from "../../apis/endpoints/LeaveAppliactionApi";
import PropTypes from "prop-types";
import {
  toaster,
  Loading,
} from "../../shared-components/notification/Notification";
import { defaultErrorResponseParser } from "../../helpers/ErrorParser";
import { CefaloButton } from "../../library/button";

const LeaveDataDemo = (props) => {
  let csvView = (
    <>
      <p>Email,Leave Type,From Date, To Date, Total Days</p>
      <p>someone@cefalo.com,Casual Leave,17-May-2020,19-May-2020,3</p>
      <p>someother@cefalo.com,Sick Leave w/o Doc,13-May-2020,14-May-2020,2</p>
    </>
  );
  return (
    <div color="black">
      <p>Note: csv file should be like this (viewed in excel).</p>
      <Table bordered striped small className={styles.demoTable}>
        <thead>
          <tr>
            <th>Email</th>
            <th>Leave Type</th>
            <th>From Date</th>
            <th>To Date</th>
            <th>Total Days</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>someone@cefalo.com</td>
            <td>Casual Leave</td>
            <td>17-May-2020</td>
            <td>19-May-2020</td>
            <td>3</td>
          </tr>
          <tr>
            <td>someother@cefalo.com</td>
            <td>Sick Leave w/o Doc</td>
            <td>13-May-2020</td>
            <td>15-May-2020</td>
            <td>1</td>
          </tr>
        </tbody>
      </Table>
      <p className="mt-2 mb-1">
        <u>Same file viewed in notepad</u>
      </p>
      <p>{csvView}</p>
    </div>
  );
};

export default class LeaveDataUploadModal extends React.Component {
  static propTypes = {
    previewCallback: PropTypes.func.isRequired,
  };

  state = {
    isOpen: false,
    file: null,
    isLoading: false,
  };

  toggle = () => () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  handleFileInput = (e) => {
    this.setState({ isLoading: true });
    e.preventDefault();
    let file = e.target.files[0];
    this.setState({
      file: file,
      isLoading: false,
    });
  };

  handleFilePreviewUpload = (e) => {
    e.preventDefault();
    if (this.state.file === null) {
      return;
    }
    this.setState({ isLoading: true });
    let formData = new FormData();
    formData.append("dataFile", this.state.file);
    LeaveAppliactionApi.previewLeaveData(formData)
      .then((res) => {
        toaster.success("You can now save safely");
        toaster.success("Leave data is verified successfully");
        this.setState({ isOpen: false, isLoading: false });
        this.props.previewCallback(res.data);
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        defaultErrorResponseParser(err, "Failed To Upload The File");
      });
  };

  render() {
    return (
      <div className="inline-block">
        <Loading show={this.state.isLoading} />
        <CefaloButton size="sm" variant="primary" onClick={this.toggle()}>
          Upload Leave Data
        </CefaloButton>
        <FormModal
          show={this.state.isOpen}
          toggle={this.toggle()}
          encType="multipart/form-data"
          onSubmit={this.handleFilePreviewUpload}
          modalSize="lg"
          formName="Upload Leave Data"
        >
          <div key={FormModal.ControlContainer}>
            <LeaveDataDemo />
            <div className="mt-4">
              <Form.Label>Choose a file:</Form.Label>
              <Form.Control
                type="file"
                accept=".csv"
                size="sm"
                onChange={this.handleFileInput}
              />
            </div>
          </div>
          <div key={FormModal.ActionContainer}>
            <CefaloButton size="sm" variant="light" onClick={this.toggle()}>
              Close
            </CefaloButton>
            <CefaloButton size="sm" variant="primary" type="submit">
              Upload
            </CefaloButton>
          </div>
        </FormModal>
      </div>
    );
  }
}
