/**
 * Represents weather a string is boolean or not.
 * @param {string} - possible boolean value.
 * @returns {boolean} - Returns the boolean value.
 */
export function convertToBoolean(stringVal) {
    if (stringVal === undefined || stringVal === null) return false;
    switch (stringVal.toLowerCase()) {
        case "1":
            return true;
        case "true":
            return true;
        default: return false;
    }
}