import React from "react";
import { Card } from "react-bootstrap";
import GlobalSettingsView from "./views/GlobalSettingsView";
import EmailSettingsView from "./views/EmailSettingsView";
import { HREmailView } from "./views/EmailSettingsView";
import LeaveSettingsView from "./views/LeaveSettingsView";
import ProfileSettingsView from "./views/ProfileSettingsView";
import { SettingsTable } from "./Const";
import CustomTable from "../../../shared-components/table/CustomTable";
import { CefaloButton } from "../../../library/button";
import PolicySettingsView from "./views/PolicySettingView";
import OfficeDaysSettingsView from "./views/OfficeDaysSettingsView";
export default function SettingsViews({
  settings,
  onSave,
  setLeaveRecipentsState,
  setEWHRecipentsState,
  setRemoteWorkRecipentsState,
  setProfileViewerState,
  setOfficeDaysState,
  onChangeCrudObject,
}) {
  const tableData = {
    columns: SettingsTable,
    rows: [
      {
        settingsType: "HR Email",
        settingsData: (
          <HREmailView
            settings={settings}
            onChangeCrudObject={onChangeCrudObject}
          />
        ),
      },
      {
        settingsType: "Email Recipients Settings",
        settingsData: (
          <EmailSettingsView
            settings={settings}
            setEWHRecipentsState={setEWHRecipentsState}
            setLeaveRecipentsState={setLeaveRecipentsState}
            setRemoteWorkRecipentsState={setRemoteWorkRecipentsState}
            onChangeCrudObject={onChangeCrudObject}
          />
        ),
      },
      {
        settingsType: "Global Settings",
        settingsData: (
          <GlobalSettingsView
            settings={settings}
            onChangeCrudObject={onChangeCrudObject}
          />
        ),
      },
      {
        settingsType: "Leave Settings",
        settingsData: (
          <LeaveSettingsView
            settings={settings}
            onChangeCrudObject={onChangeCrudObject}
          />
        )
      },
      {
        settingsType: "Office Days Settings",
        settingsData: (
          <OfficeDaysSettingsView
            settings={settings}
            setOfficeDaysState={setOfficeDaysState}
          />
        ),
      },
      {
        settingsType: "External Documents Settings",
        settingsData: (
          <PolicySettingsView
            settings={settings}
            onChangeCrudObject={onChangeCrudObject}
          />
        ),
      },
      {
        settingsType: "Profile Settings",
        settingsData: (
          <ProfileSettingsView
            settings={settings}
            setProfileViewerState={setProfileViewerState}
            onChangeCrudObject={onChangeCrudObject}
          />
        ),
      },
    ],
  };
  return (
    <Card style={{ marginTop: "5vh", marginBottom: "5vh" }}>
      <Card.Header>
        <CefaloButton size="sm" variant="primary" onClick={onSave}>
          Save Settings
        </CefaloButton>
      </Card.Header>
      <CustomTable tableData={tableData} striped={false} />
    </Card>
  );
}
