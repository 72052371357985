import React from "react";
import PropTypes from "prop-types";
import { Loading, toaster } from "../../../shared-components/notification";
import UserApi from "../../../apis/endpoints/UserApi";
import { defaultErrorResponseParser } from "../../../helpers/ErrorParser";
import { CefaloButton } from "../../../library/button";
import { FormModal } from "../../../library/modal";

export default class UserDeleteAction extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    onDeleteCallback: PropTypes.func.isRequired,
  };

  state = {
    isLoading: false,
    show: false,
  };

  toggle = (e) => () => {
    this.setState({ show: !this.state.show });
  };

  onDelete = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    UserApi.delete(this.props.user.id)
      .then((res) => {
        toaster.success("User is Deleted Successfully");
        this.setState({ isLoading: false, show: false });
        this.props.onDeleteCallback();
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        defaultErrorResponseParser(err, "Failed To Delete User");
      });
  };

  render() {
    return (
      <div className="d-inline-block">
        <Loading show={this.state.isLoading} />
        <FormModal
          onSubmit={this.onDelete}
          show={this.state.show}
          toggle={this.toggle()}
          formName="Delete User"
        >
          <div key={FormModal.ControlContainer}>
            <p>
              Are you sure to delete: <b>{this.props.user.name}</b>
            </p>
          </div>
          <div key={FormModal.ActionContainer}>
            <CefaloButton size="sm" variant="light" onClick={this.toggle()}>
              No
            </CefaloButton>
            <CefaloButton size="sm" variant="primary" type="submit">
              Yes
            </CefaloButton>
          </div>
        </FormModal>
        <CefaloButton
          className="btn-font-12"
          size="sm"
          variant="danger"
          onClick={this.toggle()}
        >
          Delete
        </CefaloButton>
      </div>
    );
  }
}
