import React from "react";
import { Form, Table, Row, Col, Container } from "react-bootstrap";
import LeaveBalanceApi from "../../../apis/endpoints/LeaveBalanceApi";
import { defaultErrorResponseParser } from "../../../helpers/ErrorParser";

const LeaveCountTable = (props) => {
  let tableRows = [];
  props.leaveTypeInfo.forEach((leaveType, index) => {
    if (leaveType.leaveType === "Casual Leave" || leaveType.count !== 0) {
      tableRows.push(
        <tr key={index}>
          <td>{leaveType.leaveType}</td>
          <td>{leaveType.count}</td>
        </tr>
      );
    }
  });
  return (
    <div className="table-striped">
      <Table bordered className="table-sm">
        <thead>
          <tr>
            <th>LeaveType</th>
            <th>Count</th>
          </tr>
        </thead>
        <tbody>
          {tableRows}
          <tr>
            <td>
              <b>Total</b>
            </td>
            <td>
              <b>{props.totalLeave}</b>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};
const LeaveDetailTable = (props) => {
  return (
    <div>
      {props.casulaLeave !== null ? (
        <div className="table-striped">
          <Table bordered className="table-sm">
            <thead>
              <tr>
                <th>Casual Leave</th>
                <th>Count</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Carry Forwarded Leaves from Previous Year</td>
                <td> {props.casulaLeave.forwardedLeave} </td>
              </tr>
              <tr>
                <td>This Year Leaves</td>
                <td>{props.casulaLeave.currentYearLeave}</td>
              </tr>
              <tr>
                <td>Extra Working Days Adjusted to This Year</td>
                <td>{props.casulaLeave.extraWorkingDays}</td>
              </tr>
              <tr>
                <td>
                  <b>Total Available Leaves</b>
                </td>
                <td>
                  <b>{props.casulaLeave.maximumAllowedLeave}</b>
                </td>
              </tr>
              <tr>
                <td>Already Approved</td>
                <td>{props.casulaLeave.approvedLeave}</td>
              </tr>
              <tr>
                <td>
                  <b>Remaining Leaves</b>
                </td>
                <td>{props.casulaLeave.remainingLeave}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      ) : null}
    </div>
  );
};

export default class LeaveBalanceTable extends React.Component {
  state = {
    leaveInfo: [],
  };

  loadLeaveBalanceInformation = (userId, year) => {
    this.setState({ isLoading: true });
    LeaveBalanceApi.get(year, userId)
      .then((res) => {
        this.setState({ leaveInfo: res.data });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        defaultErrorResponseParser(err, "Failed to load Leave information");
      });
  };

  componentDidMount() {
    let currentYear = new Date().getFullYear();
    this.setState({ year: currentYear });
    this.loadLeaveBalanceInformation(this.props.userId, currentYear);
  }

  componentDidUpdate(prevProps, prevStates) {
    if (this.props.userId !== prevProps.userId) {
      this.loadLeaveBalanceInformation(this.props.userId, this.state.year);
    }
  }

  onYearSelect = (e) => {
    let year = Number.parseInt(e.target.value);
    this.setState({ year: year });
    this.loadLeaveBalanceInformation(this.props.userId, year);
  };

  render() {
    var leaveInfo = this.state.leaveInfo;
    var casulaLeave = null;
    var leaveTypeInfo = [];
    var totalLeave = 0;
    leaveInfo.forEach((leave) => {
      if (leave.leaveType === "Casual Leave") casulaLeave = leave;
      var leaveType = {
        leaveType: leave.leaveType,
        count: leave.approvedLeave,
      };
      leaveTypeInfo.push(leaveType);
      totalLeave += leave.approvedLeave;
    });

    let currentYear = new Date().getFullYear();

    return (
      <Container fluid>
        <Row className="mb-2">
          <h6
            className="d-inline-block pl-3 pr-2"
            style={{ lineHeight: "30px", fontSize: "18px" }}
          >
            Leave Status Of Year -
          </h6>
          <div className="inline-block">
            <Form.Control
              as="select"
              size="sm"
              defaultValue={currentYear}
              className="d-inline-block"
              onChange={this.onYearSelect}
            >
              <option value={currentYear}>{currentYear}</option>
              <option value={currentYear - 1}>{currentYear - 1}</option>
            </Form.Control>
          </div>
        </Row>
        <Row>
          <Col sm="6">
            <LeaveCountTable
              leaveTypeInfo={leaveTypeInfo}
              totalLeave={totalLeave}
            />
          </Col>
          <Col sm="6">
            <LeaveDetailTable casulaLeave={casulaLeave} />
          </Col>
        </Row>
      </Container>
    );
  }
}
