import React from "react";
import { Form } from "react-bootstrap";
import { v4 as uuid4 } from "uuid";

const CheckBox = React.forwardRef((props, ref) => {
  const { label, name, ...restProps } = props;
  return (
    <Form.Check
      custom
      type="checkbox"
      id={`${uuid4()}`}
      label={label}
      name={name}
      ref={ref}
      {...restProps}
    />
  );
});

export default CheckBox;
