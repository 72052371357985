import React from "react";
import Select, { components } from "react-select";
import CefaloIcon from "../Icons/Icon";
import { FaCaretDown, FaRegTimesCircle } from "react-icons/fa";

/*
  This component is specially designed for Attendance Report User Selection
*/

export default class SearchableSelect extends React.Component {
  state = { isOpen: false, value: this.props.value };
  toggleOpen = () => {
    this.setState((state) => ({ isOpen: !state.isOpen }));
  };
  onSelectChange = (value) => {
    this.toggleOpen();
    this.setState({ value });
    if (!!this.props.onChange & (typeof this.props.onChange === "function")) {
      this.props.onChange(value);
    }
  };
  render() {
    const { isOpen, value } = this.state;
    let options = this.props.options;
    if (options[0].value === "*") {
      options = this.props.options.slice(1);
    }
    let wrapperComponents = {
      DropdownIndicator,
      IndicatorSeparator: null,
      IndicatorsContainer: IndicatorsContainerWrapper,
      ClearIndicator: IndicatorsContainerWrapper,
    };
    if (this.props.isHrOrAdmin) {
      wrapperComponents.Control = ControlWrapper;
    }
    return (
      <Dropdown
        isOpen={isOpen}
        onClose={this.toggleOpen}
        target={
          <span
            onClick={this.toggleOpen}
            style={{ fontSize: "16px", cursor: "pointer" }}
          >
            {value ? value.label : "Select..."}
            <CefaloIcon Icon={FaCaretDown} />
          </span>
        }
      >
        <Select
          autoFocus
          backspaceRemovesValue={false}
          components={wrapperComponents}
          controlShouldRenderValue={false}
          hideSelectedOptions={false}
          isClearable={false}
          menuIsOpen
          onChange={this.onSelectChange}
          options={options}
          placeholder={
            this.props.placeholder ? this.props.placeholder : "Search..."
          }
          styles={{
            menu: (provided) => ({ ...provided, marginTop: "0px" }),
            menuList: (provided) => ({ ...provided, maxHeight: "400px" }),
            ...this.props.style,
          }}
          tabSelectsValue={false}
          value={value}
        />
      </Dropdown>
    );
  }
}

const IndicatorsContainerWrapper = (props) => {
  return (
    <div className="p-1" style={{ cursor: "pointer" }}>
      <CefaloIcon Icon={FaRegTimesCircle} size="15" />
    </div>
  );
};

const ControlWrapper = (props) => {
  const selectedOption = props.getValue()[0];
  const customStyle = {
    backgroundColor: "#337AB7",
    textAlign: "left",
    padding: "0.375rem 0.75rem",
    display: "block",
    textDecoration: "none",
    color: "white",
    marginTop: "2px",
  };
  return (
    <div>
      {selectedOption.value !== "*" ? (
        <div className="pb-2">
          <a href="/Report/Index" style={customStyle}>
            All Employee
          </a>
        </div>
      ) : (
        <></>
      )}
      <div className="p-2">
        <components.Control {...props} />
      </div>
    </div>
  );
};

const Menu = (props) => {
  const shadow = "hsla(218, 50%, 10%, 0.1)";
  return (
    <div
      style={{
        backgroundColor: "white",
        padding: "8",
        borderRadius: 4,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        marginTop: 28,
        position: "absolute",
        zIndex: 2,
        width: "220px",
      }}
      {...props}
    />
  );
};

const Blanket = (props) => (
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: "fixed",
      zIndex: 1,
    }}
    {...props}
  />
);
const Dropdown = ({ children, isOpen, target, onClose }) => (
  <div style={{ position: "relative" }}>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);
const Svg = (p) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    focusable="false"
    role="presentation"
    {...p}
  />
);
const DropdownIndicator = () => (
  <div>
    <Svg>
      <path
        d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Svg>
  </div>
);
