import { AuthenticatedAxios } from "../settings/Axios";
const FileUploadEndpoint = "File";
let config = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};
export default {
  Upload: (file) => {
    return AuthenticatedAxios.post(FileUploadEndpoint, file, config);
  },
  Get: (id) => {
    return AuthenticatedAxios.get(`${FileUploadEndpoint}/${id}`);
  },
  getValidationCriteria:(fileType)=>AuthenticatedAxios.get(`${FileUploadEndpoint}/validationCriteria/${fileType}`)
};
