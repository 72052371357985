import React from "react";
import PropTypes from "prop-types";
import { default as ReportTable } from "./ReportTableView";
import { default as Nav } from "./NavView";

const AttendanceView = (props) => {
  return (
    <div>
      <div>
        <Nav
          statusTypes={props.statusTypes}
          initialSelectedId={props.initialSelectedId}
          onFilter={props.onFilter}
          onUserSelect={props.onUserSelect}
          exportOptions={props.exportOptions}
          selectedUserId={props.selectedUserId}
          teamNames={props.teamNames}
          filterWithTeam={props.filterWithTeam}
        />
      </div>
      <div>
        <ReportTable
          statusTypes={props.statusTypes}
          attendances={props.reports}
          includeAction={props.tableWithAction}
          includeUserName={props.tableWithUserName}
          updateCallback={props.onUpdate}
          users={props.users}
        />
      </div>
    </div>
  );
};

AttendanceView.prototype = {
  reports: PropTypes.arrayOf(Object).isRequired,
  statusTypes: PropTypes.arrayOf(Object).isRequired,
  tableWithAction: PropTypes.bool.isRequired,
  tableWithUserName: PropTypes.bool.isRequired,
  exportOptions: PropTypes.object,
  onFilter: PropTypes.func,
};

export default AttendanceView;
