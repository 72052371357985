import { AuthenticatedAxios, jsonContentConfig } from "../settings/Axios";
const BaseEndPoint = "Users";

export default {
  get: (year, userId) => {
    let endpoint = `${BaseEndPoint}/${userId}/LeaveBalance/${year}`;
    return AuthenticatedAxios.get(endpoint, null, null);
  },

  getLeavStatistics: () => {
    let endpoint = `${BaseEndPoint}/LeaveBalance/leavestatistics`;
    return AuthenticatedAxios.get(endpoint, null, null);
  },

  getLeaveStatisticsPerTeam: (path) => {
    let endpoint = `LeaveStatistics/Team`;
    if(path != null){
      endpoint += `${path}`;
    }
    return AuthenticatedAxios.get(endpoint, null, null);
  },

  getLeavStatisticsOfYear: (year) => {
    let endpoint = `${BaseEndPoint}/LeaveBalance/leavestatistics/${year}`;
    return AuthenticatedAxios.get(endpoint, null, null);
  },

  getLeavStatisticsOfTeamAndUser: (path) => {
    let endpoint = `${BaseEndPoint}/LeaveBalance/leavestatistics/${path}`;
    return AuthenticatedAxios.get(endpoint, null, null);
  },

  downloadLeaveStatistics: (path) => {
    let endpoint = `${BaseEndPoint}/LeaveBalance/leavestatistics/${path}`;
    return AuthenticatedAxios({
      url: endpoint,
      method: "GET",
      responseType: "arraybuffer",
    });
  },

  downloadLeaveStatisticsPerTeam: (path) => {
    let endpoint = `LeaveStatistics/Team/Download/${path}`;
    return AuthenticatedAxios({
      url: endpoint,
      method: "GET",
      responseType: "arraybuffer",
    });
  },

  previewCarryForwardData: (formData) => {
    let fileUploadConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    let endpoint = `${BaseEndPoint}/LeaveBalance/DataFilePreview`;
    return AuthenticatedAxios.post(endpoint, formData, fileUploadConfig);
  },

  upload: (dataList) => {
    let endpoint = `${BaseEndPoint}/LeaveBalance/Upload`;
    let body = JSON.stringify(dataList);
    return AuthenticatedAxios.post(endpoint, body, jsonContentConfig);
  },

  adjustCarryForwardData: (year) => {
    let endpoint = `${BaseEndPoint}/LeaveBalance/Adjust/${year}`;
    return AuthenticatedAxios.get(endpoint);
  },
};
