import React from "react";
import PropTypes from "prop-types";
import LeaveAppliactionApi from "../../../apis/endpoints/LeaveAppliactionApi";
import ApplicationRespondFormModal from "../forms/RespondForm";
import { LeaveStatusesDictionary } from "../Consts";
import { LeaveApplicationDetailDto } from "../../../models/LeaveApplication";
import { toaster, Loading, ConfimationPopup } from "../../notification";
import { defaultErrorResponseParser } from "../../../helpers/ErrorParser";
import { CefaloButton } from "../../../library/button";

export default class ApplicationRespondAction extends React.Component {
  static propTypes = {
    application: PropTypes.instanceOf(LeaveApplicationDetailDto).isRequired,
    onUpdateStatusCallback: PropTypes.func,
  };

  state = {
    showFormModal: false,
    showConfirmation: false,
    isLoading: false,
  };

  toggle = (e) => () => {
    this.setState({ showFormModal: !this.state.showFormModal });
  };

  onStatusUpdateConfirmation = (confirm) => {
    if (!confirm) {
      this.setState({ showConfirmation: false });
    } else {
      let data = this.state.applicationStatusUpdateData;
      this.setState({
        isLoading: true,
        showConfirmation: false,
        applicationStatusUpdateData: null,
      });
      LeaveAppliactionApi.updateStatus(data)
        .then((res) => {
          this.setState({ isLoading: false });
          toaster.success(
            `Application ${
              LeaveStatusesDictionary[`${data.status}`]
            } Successfully`
          );
          this.props.onUpdateStatusCallback();
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          defaultErrorResponseParser(
            err,
            `Application ${LeaveStatusesDictionary[`${data.status}`]} Failed`
          );
        });
    }
  };

  onRespond = (data) => {
    this.setState({
      showConfirmation: true,
      applicationStatusUpdateData: data,
    });
  };

  render() {
    let alertText = this.state.applicationStatusUpdateData
      ? `Are you sure to ${
          LeaveStatusesDictionary[
            `${this.state.applicationStatusUpdateData.status}`
          ]
        } this application`
      : "Are you sure to to update this application status";
    return (
      <div className="d-inline-block">
        <Loading show={this.state.isLoading} />
        <ConfimationPopup
          show={this.state.showConfirmation}
          text={alertText}
          onConfirmCallback={this.onStatusUpdateConfirmation}
        />
        <CefaloButton
          className="btn-font-12"
          size="sm"
          variant="primary"
          onClick={this.toggle()}
        >
          Respond
        </CefaloButton>
        <ApplicationRespondFormModal
          show={this.state.showFormModal}
          onToggle={this.toggle}
          application={this.props.application}
          onRespond={this.onRespond}
        />
      </div>
    );
  }
}
