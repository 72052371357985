import React from "react";
import { dataTableColumns, supervisionDataTableColumns } from "../Const";
import { getCefaloFormatDate } from "../../../helpers/DateUtility";

const WithTableData = (WrappedComponent) => (props) => {
  let isAnyActions = false;
  const buildTableRow = (application, isSupervision) => {
    const { getActions } = props;
    const actions = getActions(application);

    let tempRow = {};
    if (isSupervision) {
      tempRow["userName"] = application.applicant.name;
      tempRow["teamName"] = application.applicant.teamNames.join(', ');
      tempRow["paymentStatus"] =
        application.adjustmentType === 2
          ? ""
          : application.paid
          ? "Paid"
          : "Not Paid";
    }
    if (actions.length > 0) {
      isAnyActions = true;
      tempRow["actions"] = <>{actions}</>;
    }

    tempRow["created"] = getCefaloFormatDate(application.created);
    tempRow["fromDate"] = getCefaloFormatDate(application.fromDate);
    tempRow["toDate"] = getCefaloFormatDate(application.toDate);
    tempRow["adjustmentType"] = application.adjustmentTypeDisplayName;
    tempRow["workingHours"] = application.totalHours;
    tempRow["notBillableToCustomer"] = application.notBillableToCustomer===true? "Cefalo" : application.notBillableToCustomer===false? "Customer": "N/A";
    tempRow["status"] = application.extraWorkStatusDisplayName;
    tempRow["extraWorkTeam"] = application.extraWorkTeam?.name;
    return tempRow;
  };

  const getTableData = () => {
    let { applications, isSupervision } = props;
    let rows = applications.map((application) =>
      buildTableRow(application, isSupervision)
    );
    let tableColumns = isSupervision
      ? supervisionDataTableColumns
      : dataTableColumns;
    if (isAnyActions === false && isSupervision) {
      tableColumns = tableColumns.slice(0, -1);
    }
    return {
      columns: tableColumns,
      rows: rows,
    };
  };

  return <WrappedComponent {...props} tableData={getTableData()} />;
};

export default WithTableData;
