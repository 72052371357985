import React from "react";
import PropTypes from "prop-types";
import { LeaveStatisticsPerTeamDto } from "../../models/LeaveStatisticsPerTeamDto";
import DataTable from "../../library/table/DataTable";
let dataTableColumns = [
  {
    text: "Name",
    dataField: "employeeName",
    sort: true,
  },
  {
    text: "Team Name",
    dataField: "teamName",
    sort: true,
  },
  {
    text: "From Date",
    dataField: "fromDate",
    sort: false,
  },
  {
    text: "To Date",
    dataField: "toDate",
    sort: false,
  },
  {
    text: "Total Allocated Leaves",
    dataField: "totalAllocatedLeaves",
    sort: false,
  },
  {
    text: "Total Availed Leaves",
    dataField: "totalAvailedLeaves",
    sort: false,
  },
  {
    text: "Leave Difference",
    dataField: "leaveDifference",
    sort: false,
  },
];

const buildTableRows = (rows) => {
  let tempRows = [];
  rows.forEach((item) => {
    if (item instanceof LeaveStatisticsPerTeamDto) {
      let tempRow = {
        employeeName: item.employeeName,
        teamName: item.teamName,
        fromDate: item.fromDate,
        toDate: item.toDate,
        totalAllocatedLeaves: item.totalAllocatedLeaves,
        totalAvailedLeaves: item.totalAvailedLeaves,
        leaveDifference: item.leaveDifference,
      };
      tempRows.push(tempRow);
    }
  });
  return tempRows;
};
const LeaveStatisticsTable = (props) => {
  let rows = buildTableRows(
    props.leaveStatistics,
    props.profile,
    props.teams,
    props.userRoles
  );
  return <DataTable rows={rows} columns={dataTableColumns} />;
};
const LeaveStatisticsPerTeamView = (props) => {
  return (
    <div>
      <LeaveStatisticsTable {...props} />
    </div>
  );
};
LeaveStatisticsPerTeamView.prototype = {
  leaveStatistics: PropTypes.arrayOf(LeaveStatisticsPerTeamDto),
};

export default LeaveStatisticsPerTeamView;
