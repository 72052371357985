import React from "react";
import { Loading, toaster } from "../../../shared-components/notification/Notification";
import { defaultErrorResponseParser } from "../../../helpers/ErrorParser";
import { CefaloButton } from "../../../library/button/Index";
import TeamHistoryApi from "../../../apis/endpoints/TeamHistoryApi";
import UserTeamHistoryUpsertForm from "../forms/UserTeamHistoryUpsertForm";
import { showErrorMessages } from "../../../shared-components/response-message/ResponseMessage";

export default class UserTeamHistoryCreateAction extends React.Component {
  state = {
    show: false,
    isLoading: false,
  };

  toggle = (e) => () => {
    this.setState({ show: !this.state.show });
  };

  onCreateAction = (data) => {
    this.setState({ isLoading: true });

    TeamHistoryApi.create(data)
      .then((res) => {
        this.setState({
          isLoading: false,
          show: false,
        });
        toaster.success("Team History Saved Successfulluy");
        this.props.onCreateCallBack(this.props.userId);
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        if (err.response.data) showErrorMessages(err.response.data);
        else defaultErrorResponseParser(err, "Failed To Create Team History");
      });
  };

  render() {
    const { teamNames, profile } = this.props;
    return (
      <div className="d-inline-block">
        <Loading show={this.state.isLoading} />
        <CefaloButton size="sm" variant="primary" onClick={this.toggle()}>
          Add Team
        </CefaloButton>

        <UserTeamHistoryUpsertForm
          teamNames={teamNames}
          show={this.state.show}
          toggle={this.toggle}
          onSubmit={this.onCreateAction}
          profile={profile}
          formName={"Add Team"}
        />
      </div>
    );
  }
}
