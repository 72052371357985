import { ApplicantDto } from "./Common";
import { DateOnlyBuilder } from "../helpers/Builders";

export class RemoteWorkApplicationDetailDto {
  constructor(obj = {}) {
    this.applicant = new ApplicantDto(obj.applicant ? obj.applicant : {});

    this.id = obj.id ? obj.id : 0;
    this.userId = obj.userId ? obj.userId : 0;
    this.totalNumberOfWorkingDays = obj.totalNumberOfWorkingDays
      ? obj.totalNumberOfWorkingDays
      : 0.0;
    this.alternativeOfficeDate = obj.alternativeOfficeDate ? new Date(obj.alternativeOfficeDate) : undefined;
    this.remoteWorkTypeDisplayName = obj.remoteWorkTypeDisplayName
      ? obj.remoteWorkTypeDisplayName
      : "";
    this.remoteWorkStatusDisplayName = obj.remoteWorkStatusDisplayName
      ? obj.remoteWorkStatusDisplayName
      : "";
    this.type = obj.type ? obj.type : 0;
    this.status = obj.status ? obj.status : 0;
    this.created = obj.created ? DateOnlyBuilder(obj.created) : null;
    this.fromDate = obj.fromDate ? DateOnlyBuilder(obj.fromDate) : null;
    this.toDate = obj.toDate ? DateOnlyBuilder(obj.toDate) : null;
    this.description = obj.description ? obj.description : "";
    this.supervisorComment = obj.supervisorComment ? obj.supervisorComment : "";
  }
}
