import React from "react";
import { Form } from "../../../library/form/Index";
import { Col, Row } from "react-bootstrap";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import styles from "../styles/Profile.module.css";
import { getCefaloFormatDate } from "../../../helpers/DateUtility";
import UserManager from "../../../navigation/UserManager";
import { Role } from "../../settings-officeTiming/settings/Helper";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { PresentStatus } from "../../../shared-components/attendance-status-view/AttendanceStatusView";

export default class UserProfileInfoView extends React.Component {
  state = {
    showEditButton: false,
    showAttendanceStatus: false,
    loggedInUserId: 0,
  };
  mailContent;

  generateChangeRequestMailContent() {
    let recipient = encodeURIComponent(this.props.adminEmail);
    let subject = encodeURIComponent("Profile Update Request");
    let name = encodeURIComponent(`${this.props.profileInfo.name}\n`);
    let designation = encodeURIComponent(
      `${this.props.profileInfo.designation}\n`
    );
    let organization = encodeURIComponent("Cefalo Bangladesh Ltd.\n\n\n\n");
    let body =
      encodeURIComponent(
        "To whom it may concern,\n\nI want the following changes in my profile -\n1.\n\n"
      ) +
      name +
      designation +
      organization;
    return `mailto:${recipient}?subject=${subject}&body=${body}`;
  }

  handleProfileUpdateRequest = () => {
    const mailContent = this.generateChangeRequestMailContent();
    window.location.href = mailContent;
  };

  componentDidMount() {
    let user = UserManager.getUser();
    if (user.isAdminOrHr()) {
      this.setState({ showEditButton: true, showAttendanceStatus: true });
    }
    this.setState({ loggedInUserId: user.id });
    this.generateChangeRequestMailContent();
  }

  render() {
    return (
      <Form.Row className="mb-4">
        <Col sm="9">
          <Form.Row>
            <Col>
              <div className="container">
                <div className={"row " + styles.verticalCenter}>
                  <div>
                    <h3 className={"m-0 " + styles["memberName"]}>
                      {this.props.profileInfo?.name}
                    </h3>
                  </div>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip placement="top">CV</Tooltip>}
                  >
                    <div>
                      {this.props.profileInfo?.cvLink && (
                        <div className="ml-3" aria-hidden="true">
                          <span>
                            <a
                              target="_blank"
                              href={`${this.props.profileInfo.cvLink}`}
                              rel="noopener noreferrer"
                            >
                              <img
                                src={require("../../../static/images/docs.png")}
                                alt="LINK"
                                height="21px"
                                width="21px"
                              />
                            </a>
                          </span>
                        </div>
                      )}
                    </div>
                  </OverlayTrigger>

                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip placement="top">Profile Update Request</Tooltip>
                    }
                  >
                    <div>
                      {this.props.profileInfo.userId ===
                        this.state.loggedInUserId && (
                        <div className="ml-3 pt-1">
                          <button className="btn p-0" onClick={this.handleProfileUpdateRequest}>
                            <img
                              src={require("../../../static/images/change_mail_request_icon.jpeg")}
                              alt="icon-change-mail-request"
                              height="25px"
                              width="22px"
                            />
                          </button>
                        </div>
                      )}
                    </div>
                  </OverlayTrigger>

                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip placement="top">Edit Profile</Tooltip>}
                  >
                    <div className="ml-3">
                      <Link
                        className="btn btn-lg p-0"
                        style={
                          this.state.showEditButton ? {} : { display: "none" }
                        }
                        to={{
                          pathname: "/profile/edit",
                          state: { userId: this.props.profileInfo?.userId },
                        }}
                      >
                        <i className="fa fa-user-edit" aria-hidden="true"></i>
                      </Link>
                    </div>
                  </OverlayTrigger>

                  {this.state.showEditButton && (
                    <div className="ml-3">
                      <PresentStatus entry={this.props.entry} />
                    </div>
                  )}
                </div>
              </div>
              <div className={`text-muted`}>
                <h5>
                  {" "}
                  {this.props.profileInfo?.designation}
                  {this.props.profileInfo.userDto?.role === Role.team ? (
                    <i className="fa ml-2">&#xf005;</i>
                  ) : (
                    ""
                  )}{" "}
                </h5>
              </div>
            </Col>
            <Col>
              <div className="float-right">
                <div className="float-right">
                  <i className="fa fa-users pr-1" aria-hidden="true"></i>TEAM
                </div>
                <br></br>
                {this.props.profileInfo?.teamNames.map((item) => (
                  <div
                    key={item}
                    className="py-1 px-3 mt-1 bg-success text-white float-right ml-1"
                  >
                    {item}
                  </div>
                ))}
              </div>
            </Col>
          </Form.Row>
          <hr className="mt-0" />
          <Form.Row>
            <Col>
              <Form.Row className="mb-2">
                <Col sm="4">
                  <span>SLACK</span>
                </Col>
                <Col sm="8">
                  <span>{this.props.profileInfo?.slackDisplayName}</span>
                </Col>
              </Form.Row>
              <Form.Row className="mb-2">
                <Col sm="4">
                  <span>EMAIL</span>
                </Col>
                <Col sm="8">
                  <span>
                    <a href={`mailto:${this.props.profileInfo?.email}`}>
                      {" "}
                      {this.props.profileInfo?.email}
                    </a>
                  </span>
                </Col>
              </Form.Row>
              <Form.Row className="mb-2">
                <Col sm="4">
                  <span>PHONE</span>
                </Col>
                <Col sm="8">
                  <span>
                    <a href={`tel:${this.props.profileInfo?.mobileNumber}`}>
                      {this.props.profileInfo?.mobileNumber}
                    </a>
                  </span>
                </Col>
              </Form.Row>
              {this.props.profileInfo?.facebookLink && (
                <Form.Row className="mb-2">
                  <Col sm="4">
                    <span>FACEBOOK</span>
                  </Col>
                  <Col sm="8">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.socialMediaIcon}
                      href={this.props.profileInfo.facebookLink}
                    >
                      <FaFacebookF size={20} />
                    </a>
                  </Col>
                </Form.Row>
              )}
            </Col>
            <Col className="offset-1">
              <Form.Row className="mb-2">
                <Col sm="4">
                  <span>JOINING DATE</span>
                </Col>
                <Col sm="8">
                  <span>
                    {this.props.profileInfo?.joiningDate != null
                      ? getCefaloFormatDate(this.props.profileInfo?.joiningDate)
                      : "N/A"}
                  </span>
                </Col>
              </Form.Row>
              <Form.Row className="mb-2">
                <Col sm="4">
                  <span>JOB TENURE</span>
                </Col>
                <Col sm="8">
                  <span>
                    {this.props.profileInfo?.yearsOfExperienceAtCefalo}
                  </span>
                </Col>
              </Form.Row>
              <Form.Row className="mb-2">
                <Col sm="4">
                  <span>EXPERIENCE</span>
                </Col>
                <Col sm="8">
                  <span>{this.props.profileInfo?.totalWorkingExperience}</span>
                </Col>
              </Form.Row>
              {this.props.profileInfo?.linkedInLink && (
                <Form.Row className="mb-2">
                  <Col sm="4">
                    <span>LINKEDIN</span>
                  </Col>
                  <Col sm="8">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.socialMediaIcon}
                      href={this.props.profileInfo.linkedInLink}
                    >
                      <FaLinkedin size={20} />
                    </a>
                  </Col>
                </Form.Row>
              )}
            </Col>
          </Form.Row>
          {Array.isArray(this.props.profileInfo?.skills) &&
          this.props.profileInfo?.skills.length ? (
            <Form.Row>
              <Col>
                <div className="mt-3 float-left">
                  <div className="float-left">
                    <i className="fa fa-bookmark pr-1" aria-hidden="true"></i>
                    PRIMARY SKILLs
                  </div>
                  <br></br>
                  {this.props.profileInfo?.skills.map((item) => {
                    return (
                      <div
                        key={item.id}
                        className={
                          "py-1 px-3 primary text-white float-left mt-1 mr-1 " +
                          styles.skill
                        }
                      >
                        {item.name}
                      </div>
                    );
                  })}
                </div>
              </Col>
            </Form.Row>
          ) : (
            ""
          )}
        </Col>
        <Col sm="3" className="pl-3 pt-1">
          <Row>
            <Card.Img
              alt="Profile Picture"
              src={this.props?.imageSrc}
              className="img-fluid"
            />
          </Row>
        </Col>
      </Form.Row>
    );
  }
}
