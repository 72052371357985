import SortUtility from "../../helpers/SortUtility";

export const SkillDevelopmentTableViewerColumns = Object.freeze([
  {
    text: "Request Date",
    dataField: "createdAt",
    sort: true,
    sortFunc: SortUtility.dateSorter,
  },
  {
    text: "Course Name",
    dataField: "courseName",
    sort: false,
  },
  {
    text: "Course Link",
    dataField: "courseLink",
    sort: false,
  },
  {
    text: "Amount (BDT)",
    dataField: "amount",
    sort: true,
  },
  {
    text: "Purpose",
    dataField: "purpose",
    sort: false,
  },
  {
    text: "Invoice",
    dataField: "invoice",
    sort: false,
  },
  {
    text: "Certificate",
    dataField: "certificate",
    sort: false,
  },
  {
    text: "Status",
    dataField: "requestStatus",
    sort: true,
  },
  {
    text: "Reason",
    dataField: "reason",
    sort: false,
  },
]);

export const SkillDevelopmentTableColumns = Object.freeze([
  ...SkillDevelopmentTableViewerColumns,
  {
    text: "Action",
    dataField: "action",
    sort: false,
  },
]);

export const SkillDevelopmentSupervisionTableColumns = Object.freeze([
  {
    text: "Employee Name",
    dataField: "name",
    sort: false,
  },
  ...SkillDevelopmentTableColumns,
]);

export const SkillDevelopmentTableSortOrder = Object.freeze([
  {
    dataField: "createdAt",
    order: 'desc',
  },
]);

export const SkillDevelopmentRequestStatus = Object.freeze({
  Requested: 1,
  Canceled: 2,
  Approved: 5,
  Rejected: 9,
  ClaimRequested: 11,
  CanceledRequestedAfterApproved: 12,
  CanceledApprovedAfterInitialApproved: 13,
  ClaimApproved: 15,
  ClaimRejected: 19,
  RefundRequested: 21,
  Refunded: 25,
});

export const SkillDevelopmentRequestStatusName = Object.freeze({
  Requested: "Requested",
  Canceled: "Canceled",
  Approved: "Approved",
  Rejected: "Rejected",
  ClaimRequested: "ClaimRequested",
  CanceledRequestedAfterApproved: "CanceledRequestedAfterApproved",
  CanceledApprovedAfterInitialApproved: "CanceledApprovedAfterInitialApproved",
  ClaimApproved: "ClaimApproved",
  ClaimRejected: "ClaimRejected",
  RefundRequested: "RefundRequested",
  Refunded: "Refunded",
});