import React from "react";
import PropTypes from "prop-types";
import TrainingUpsertModalForm from "../forms/TrainingUpsertModalForm";
import TrainingApi from "../../../apis/endpoints/TrainingApi";
import {
  Loading,
  toaster,
} from "../../../shared-components/notification/Notification";
import { defaultErrorResponseParser } from "../../../helpers/ErrorParser";
import { CefaloButton } from "../../../library/button";

export default class TrainingCreateAction extends React.Component {
  static propTypes = {
    onCreateCallback: PropTypes.func.isRequired,
  };

  state = {
    show: false,
    isLoading: false,
  };

  toggle = (e) => () => {
    this.setState({ show: !this.state.show });
  };

  onCreateAction = (data) => {
    this.setState({ isLoading: true });
    TrainingApi.create(data)
      .then((res) => {
        this.setState({
          isLoading: false,
          show: false,
        });
        toaster.success("Training Saved Successfully");
        this.props.onCreateCallback(res.data);
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        defaultErrorResponseParser(err, "Failed To Save Training");
      });
  };

  render() {
    return (
      <div className="d-inline-block">
        <Loading show={this.state.isLoading} />
        <CefaloButton size="sm" variant="primary" onClick={this.toggle()}>
          Add New Training
        </CefaloButton>
        <TrainingUpsertModalForm
          formName="Add New Training"
          show={this.state.show}
          activeUsers={this.props.activeUsers}
          onToggle={this.toggle}
          onSubmit={this.onCreateAction}
        />
      </div>
    );
  }
}
