import React from "react";
import PropTypes from "prop-types";
import { FormModal } from "../../../library/modal/Index";
import { useForm } from "react-hook-form";
import { CefaloButton } from "../../../library/button/Index";
import FileUpload from "./FileUpload";

function ApplicationClaimFormModal({
  request,
  show,
  onToggle,
  onClaim,
}) {
  const { register, handleSubmit, setValue, clearError, errors } = useForm();

  React.useEffect(() => {
    register("invoice", { required: true });
    register("certificate", { required: true });
  }, [register])

  const onFormSubmit = React.useCallback((data) => {
    onToggle();
    onClaim(request.id, data);
  }, [request.id, onClaim, onToggle]);

  return (
    <FormModal
      show={show}
      toggle={onToggle}
      formName="Claim Request"
      onSubmit={handleSubmit(onFormSubmit)}
    >
      <div key={FormModal.ControlContainer}>
        <div className="font-weight-bold mb-0">*Invoice:</div>
        <FileUpload
          uploadType="SkillDevFundCertificationInvoiceDocument"
          onFileNameChange={(fileInfo) => {
            setValue("invoice", fileInfo["skillDevelopmentFundInvoice"]);
            clearError("invoice")
          }}
          fieldName="skillDevelopmentFundInvoice"
          label="Select Invoice..."
        />
        {errors.invoice && errors.invoice.type === "required" && (
          <span className="text-danger small">*Invoice is required</span>
        )}
        <div className="font-weight-bold mb-0">*Certificate:</div>
        <FileUpload
          uploadType="SkillDevFundCertificationDocument"
          onFileNameChange={(fileInfo) => {
            setValue("certificate", fileInfo["skillDevelopmentFundCertificate"]);
            clearError("certificate")
          }}
          fieldName="skillDevelopmentFundCertificate"
          label="Select Certificate..."
        />
        {errors.certificate && errors.certificate.type === "required" && (
          <span className="text-danger small">*Certificate is required</span>
        )}
      </div>
      <div key={FormModal.ActionContainer}>
        <CefaloButton size="sm" variant="light" onClick={onToggle}>
          Close
        </CefaloButton>
        <CefaloButton variant="primary" size="sm" type="submit">
          Claim
        </CefaloButton>
      </div>
    </FormModal>
  );
};

ApplicationClaimFormModal.propTypes = {
  // fromDate: PropTypes.objectOf(Date).isRequired,
  // toDate: PropTypes.objectOf(Date).isRequired,
  // totalWorkingDays: PropTypes.number.isRequired,
  show: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
  onClaim: PropTypes.func.isRequired,
  // periodSelectCallBack: PropTypes.func.isRequired,
  // leaveTypes: PropTypes.arrayOf(Object).isRequired,
};

export default ApplicationClaimFormModal;
