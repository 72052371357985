import React from "react";
import { globalSettingsLowerTableCol,globalSettingsUpperTableCol } from "../Const";
import CustomTable from "../../../../shared-components/table/CustomTable";
import PasswordField from "../../../../shared-components/forms/PasswordField";
import { Form } from "../../../../library/form";
import { GetValueOrDefault } from "../../../../shared-components/controlled-input-value/GetValueOrDefault";

export default function GlobalSettingsView({ settings, onChangeCrudObject }) {
  const {
    internalLoginEmail,
    internalLoginPassword,
    minimumHoursForDeductingLunchTime,
    minimumHalfDayWorkingHour,
    additionalWorkFromHomeAnnualLimit,
    exchangeOfficeDayAnnualLimit
  } = settings;

  const upperTableData = {
    columns: globalSettingsUpperTableCol,
    rows: [
      {
        internalLoginEmail: (
          <Form.Control
            type="text"
            name="internalLoginEmail"
            value={GetValueOrDefault(internalLoginEmail)}
            onChange={onChangeCrudObject}
            required
            size="sm"
          />
        ),
        internalLoginPassword: (
          <PasswordField
            fieldName="internalLoginPassword"
            fieldValue={GetValueOrDefault(internalLoginPassword)}
            required={true}
            onChange={onChangeCrudObject}
            size="sm"
          />
        ),
        minimumHoursForDeductingLunchTime: (
          <Form.Control
            type="number"
            step={0.5}
            name="minimumHoursForDeductingLunchTime"
            value={GetValueOrDefault(minimumHoursForDeductingLunchTime, 0)}
            required
            onChange={onChangeCrudObject}
            size="sm"
          />
        ),
      },
    ],
  };

  const lowerTableData = {
    columns: globalSettingsLowerTableCol,
    rows: [
      {
        minimumHalfDayWorkingHour: (
          <Form.Control
            type="number"
            step={0.5}
            name="minimumHalfDayWorkingHour"
            value={GetValueOrDefault(minimumHalfDayWorkingHour, 0)}
            required
            onChange={onChangeCrudObject}
            size="sm"
          />
        ),
        additionalWorkFromHomeAnnualLimit: (
          <Form.Control
            type="number"
            step={1}
            name="additionalWorkFromHomeAnnualLimit"
            max={100}
            min={0}
            value={GetValueOrDefault(additionalWorkFromHomeAnnualLimit, 0)}
            required
            onChange={onChangeCrudObject}
            size="sm"
          />
        ),
        exchangeOfficeDayAnnualLimit: (
          <Form.Control
            type="number"
            step={1}
            name="exchangeOfficeDayAnnualLimit"
            max={100}
            min={0}
            value={GetValueOrDefault(exchangeOfficeDayAnnualLimit, 0)}
            required
            onChange={onChangeCrudObject}
            size="sm"
          />
        ),
      },
    ],
  };

  return (
    <div>
      <CustomTable tableData={upperTableData} striped={true} />
      <CustomTable tableData={lowerTableData} striped={true} />
    </div>
  );
}
