import React from "react";
import styles from "./styles/Footer.module.css";

const AppFooter = (props) => {
  const year = new Date().getFullYear();
  return (
    <div className={styles.footerContainer}>
      <p>{`© ${year} - Cefalo`}</p>
    </div>
  );
};

export default AppFooter;
