import React from "react";
import { Container } from "react-bootstrap";
import Layout from "../../shared-components/layout/Layout";
import NocController from "./forms/NocController";
import WithPermittedAction from "./hoc/WithPermittedAction";
import WithTableData from "./hoc/WithTableData";
import DataTable from "../../library/table/DataTable";
import { NocStatusOptions, descSortedOrder } from "./Const";

function NocApplicationView({
  tableData,
  actionsCallbacks,
  profile,
  isSupervision,
  onChangeUser,
  loadUserProfile,
  userId
}) {
  let defaultSorted = isSupervision ? {} : { defaultSorted: descSortedOrder };

  return (
    <Layout>
      <Container
        fluid
        style={{ marginTop: "10px" }}
        className="pr-3 pt-3 pl-3 pb-0"
      >
        <NocController
          onCreateCallback={actionsCallbacks.onCreate}         
          onFilterAction={actionsCallbacks.onFilter}
          profile={profile}
          userId={userId}
          isUserFilterEnabled={!isSupervision}
          isSupervision={isSupervision}
          isStatusEnabled={isSupervision}
          statusOptions={NocStatusOptions}
          onChangeUser={onChangeUser}
          loadUserProfile={loadUserProfile}
        />
        <DataTable {...defaultSorted} {...tableData} />
      </Container>
    </Layout>
  );
}

export default WithPermittedAction(WithTableData(NocApplicationView));
