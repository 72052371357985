import React, { useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import { Col } from "react-bootstrap";
import { useForm } from "react-hook-form";

import { Form, Input } from "../../library/form/Index";
import { toaster } from "../../shared-components/notification/Notification";
import { defaultErrorResponseParser } from "../../helpers/ErrorParser";
import { DateOnlyString } from "../../helpers/Builders";
import { Loading } from "../../shared-components/notification/Notification";
import { Select } from "../../library/form/Index";
import { getCefaloFormatDate } from "../../helpers/DateUtility";
import { CefaloButton } from "../../library/button/Index";
import Layout from "../../shared-components/layout/Layout";
import ProfileApi from "../../apis/endpoints/ProfileApi";
import UserManager from "../../navigation/UserManager";
import ProfilePictureView from "../user-manage/views/ProfilePictureView";
import CefaloDatePicker from "../../library/form/DatePicker";
import BloodGroupDropdown from "../../shared-components/dropdowns/blood-group.dropdown";
import SkillTagInput from "../../shared-components/dropdowns/skill-tag-input/skill-tag.input";
import UserProfileTeamHistoryContainer from "../team-history/UserProfileTeamHistoryContainer";
import EmployeeCertificationViewContainer from "../employee-certification/EmployeeCertificationViewContainer";
import TrainingHistoryContainer from "../training-history/TrainingHistoryContainer";

const UserProfileUpsertContainer = (props) => {
  const [profile, setProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [shouldRender, setShouldRender] = useState(false);
  const [profileImageFileName, setProfileImageFilename] = useState(null);
  const [dateOfBirth, setDateOfBirth] = useState(new Date());
  const [joiningDate, setJoiningDate] = useState(null);
  const [careerStartDate, setCareerStartDate] = useState(new Date());
  const [options] = useState([
    { value: "Married", label: "Married" },
    { value: "Single", label: "Single" },
  ]);
  const [maritalStatus, setMaritalStatus] = useState("Single");
  const [selectedBloodGroup, setBloodGroupValue] = useState(null);
  const [selectedSkills, setSelectedSkills] = useState([]);

  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      mobileNumber: profile?.mobileNumber,
      designation: profile?.designation,
      slackDisplayName: profile?.slackDisplayName,
    },
  });

  const bindPageData = useCallback(() => {
    const { userId } = props.location.state;
    let user = UserManager.getUser();

    if (!user.isAdminOrHr()) {
      window.location.replace(`/Report/Index/${user.id}`);
    } else {
      loadUserProfile(userId);
    }
  }, [props.location.state]);

  useEffect(() => {
    bindPageData();
  }, [bindPageData]);

  const loadUserProfile = (userId) => {
    setIsLoading(true);
    ProfileApi.get(userId)
      .then((res) => {
        setProfile(res.data);
        setIsLoading(false);
        setShouldRender(true);

        if (res.data.joiningDate)
          setJoiningDate(getCefaloFormatDate(res.data.joiningDate));

        if (res.data.careerStartDate)
          setCareerStartDate(new Date(res.data.careerStartDate), true);

        if (res.data.personalInfo) {
          if (res.data.personalInfo.bloodGroup)
            setBloodGroupValue(res.data.personalInfo.bloodGroup);

          if (res.data.personalInfo.dateOfBirth)
            setDateOfBirth(new Date(res.data.personalInfo?.dateOfBirth), true);

          if (res.data.personalInfo.maritalStatus)
            setMaritalStatus(res.data.personalInfo?.maritalStatus);
        }
        setSelectedSkills(res.data.skills);
      })
      .catch((err) => {
        setShouldRender(false);
        setIsLoading(false);
        setProfile(null);
      });
  };

  const handleBloodGroupChange = (selectedValue) => {
    setBloodGroupValue(selectedValue);
  };

  const handleSelectedSkillChange = (selectedSkills) => {
    setSelectedSkills(selectedSkills);
  };

  const onFormSubmit = (data) => {
    reset(data);
    let saveData = { ...data, photoFileName: profile?.photoFileName };
    if (profileImageFileName !== null) {
      //Reset profile image change
      setProfileImageFilename(null);
      saveData = { ...data, photoFileName: profileImageFileName };
    }

    onSaveProfile(saveData);
  };

  const onSaveProfile = (data) => {
    data["userId"] = profile.userId;
    data["id"] = profile.id;
    data["skills"] = selectedSkills;
    data["personalInfo"].dateOfBirth = DateOnlyString(dateOfBirth);
    data["personalInfo"].bloodGroup = selectedBloodGroup;
    data["personalInfo"].maritalStatus = maritalStatus;
    data["joiningDate"] = DateOnlyString(joiningDate);
    data["careerStartDate"] = DateOnlyString(careerStartDate);
    data["educationInfos"][0].educationType = 1;
    data["educationInfos"][1].educationType = 2;
    data["educationInfos"][2].educationType = 3;

    setIsLoading(true);

    ProfileApi.save(data)
      .then(() => {
        setIsLoading(false);
        toaster.success("User Profile Saved Successfully");
        window.location.replace(`/profile/view/${profile.userId}`);
      })
      .catch((err) => {
        setIsLoading(false);
        setProfile(null);
        defaultErrorResponseParser(err, "Failed To Save User Profile");
      });
  };

  return (
    <div>
      <Layout>
        <Helmet>
          <title>Edit Profile - Cefalo</title>
        </Helmet>
        <Loading show={isLoading} />
        {shouldRender && (
          <div className="p-4">
            <Form onSubmit={handleSubmit(onFormSubmit)}>
              <Form.Row>
                <Col sm="9" className="pr-5">
                  <Form.Row>
                    <Col>
                      <Input
                        label="Full Name"
                        readOnly
                        name="name"
                        defaultValue={!!profile?.name ? profile.name : ""}
                      />
                      <Input
                        label="Display Name"
                        name="displayName"
                        ref={register}
                        defaultValue={profile?.displayName}
                      />
                      <Input
                        label="Mobile Number"
                        ref={register}
                        defaultValue={profile?.mobileNumber}
                        name="mobileNumber"
                        placeholder="Enter Mobile Number"
                      />
                      <Input
                        label="Designation"
                        ref={register}
                        defaultValue={profile?.designation}
                        name="designation"
                        placeholder="Enter Designation"
                      />
                      <Input
                        label="Joining Date"
                        readOnly
                        ref={register}
                        defaultValue={joiningDate}
                        name="joiningDate"
                      />
                      <Input
                        label="Facebook Link"
                        ref={register}
                        defaultValue={profile?.facebookLink ? profile.facebookLink : ""}
                        name="facebookLink"
                        placeholder="Enter Facebook Link"
                      />
                    </Col>
                    <Col>
                      <Input
                        label="Slack Display Name"
                        ref={register}
                        defaultValue={profile?.slackDisplayName}
                        name="slackDisplayName"
                        placeholder="Enter Slack Display Name"
                      />
                      <Input
                        label="CV Link"
                        ref={register}
                        defaultValue={!!profile?.cvLink ? profile.cvLink : ""}
                        name="cvLink"
                        placeholder="CV Link"
                      />
                      <Input
                        label="Email"
                        ref={register}
                        defaultValue={!!profile?.email ? profile.email : ""}
                        name="email"
                        readOnly
                      />
                      <Input
                        label="Role"
                        ref={register}
                        defaultValue={profile?.role}
                        name="role"
                        placeholder="Enter Designation"
                      />
                      <div className="form-group">
                        <Form.Label className="d-block">
                          Career Start Date:
                        </Form.Label>
                        <CefaloDatePicker
                          date={careerStartDate}
                          onChange={(d) => setCareerStartDate(d, true)}
                        />
                      </div>
                      <Input
                        label="LinkedIn Link"
                        ref={register}
                        defaultValue={profile?.linkedInLink ? profile.linkedInLink : ""}
                        name="linkedInLink"
                        placeholder="Enter LinkedIn Link"
                      />
                    </Col>
                  </Form.Row>
                </Col>
                <Col sm="3" className="pt-3">
                  <div className="text-right" >
                    {profile && (
                      <ProfilePictureView
                        profile={profile}
                        setPhotoFileNameCallback={(fileName) => {
                          setProfileImageFilename(fileName);
                        }}
                      />
                    )}
                  </div>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col className="mb-4 responsive-react-tags">
                  <Form.Label className="d-block">Skills:</Form.Label>
                  <SkillTagInput
                    onTagChange={handleSelectedSkillChange}
                    selectedSkills={profile?.skills}
                  ></SkillTagInput>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col>
                  <Form.Row>
                    <Col>
                      EDUCATION
                      <hr />
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col>
                      Master's
                      <hr />
                      <Input
                        label="Passing Year"
                        ref={register}
                        defaultValue={profile?.educationInfos[0]?.passingYear}
                        name="educationInfos[0].passingYear"
                        placeholder="Enter passing year"
                      />
                      <Input
                        label="Institute"
                        ref={register}
                        defaultValue={profile?.educationInfos[0]?.institute}
                        name="educationInfos[0].institute"
                        placeholder="Enter institute name"
                      />
                      <Input
                        label="Degree name"
                        ref={register}
                        defaultValue={profile?.educationInfos[0]?.degree}
                        name="educationInfos[0].degree"
                        placeholder="Enter degree"
                      />
                    </Col>
                    <Col>
                      Bachelor's
                      <hr />
                      <Input
                        label="Passing Year"
                        ref={register}
                        defaultValue={profile?.educationInfos[1]?.passingYear}
                        name="educationInfos[1].passingYear"
                        placeholder="Enter passing year"
                      />
                      <Input
                        label="Institute"
                        ref={register}
                        defaultValue={profile?.educationInfos[1]?.institute}
                        name="educationInfos[1].institute"
                        placeholder="Enter institute name"
                      />
                      <Input
                        label="Degree name"
                        ref={register}
                        defaultValue={profile?.educationInfos[1]?.degree}
                        name="educationInfos[1].degree"
                        placeholder="Enter degree"
                      />
                    </Col>
                    <Col>
                      HSC
                      <hr />
                      <Input
                        label="Passing Year"
                        ref={register}
                        defaultValue={profile?.educationInfos[2]?.passingYear}
                        name="educationInfos[2].passingYear"
                        placeholder="Enter passing year"
                      />
                      <Input
                        label="Institute"
                        ref={register}
                        defaultValue={profile?.educationInfos[2]?.institute}
                        name="educationInfos[2].institute"
                        placeholder="Enter institute name"
                      />
                      <Input
                        label="Degree name"
                        ref={register}
                        defaultValue={profile?.educationInfos[2]?.degree}
                        name="educationInfos[2].degree"
                        placeholder="Enter degree"
                      />
                    </Col>
                  </Form.Row>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col>
                  <Form.Row>
                    <Col>
                      PERSONAL INFORMATION
                      <hr />
                    </Col>
                  </Form.Row>
                  <Form.Row>
                    <Col>
                      <div className="form-group">
                        <Form.Label className="d-block">
                          Blood Group:
                        </Form.Label>
                        <BloodGroupDropdown
                          onSelectChange={handleBloodGroupChange}
                          selectedValue={profile?.personalInfo?.bloodGroup}
                        ></BloodGroupDropdown>
                      </div>
                      <div className="form-group">
                        <Form.Label className="d-block">
                          Date Of Birth:
                        </Form.Label>
                        <CefaloDatePicker
                          date={dateOfBirth}
                          onChange={(d) => setDateOfBirth(d, true)}
                        />
                      </div>
                      <Select
                        ref={register}
                        label="Marital Status"
                        style={{ width: "300px" }}
                        value={maritalStatus}
                        onChange={(e) =>
                          setMaritalStatus(e.currentTarget.value)
                        }
                        options={options}
                        name="personalInfo.maritalStatus"
                      />
                    </Col>
                    <Col>
                      <Input
                        label="NID Number"
                        ref={register}
                        defaultValue={profile?.personalInfo?.nIdNumber}
                        name="personalInfo.nIdNumber"
                        placeholder="NID Number"
                      />
                      <Input
                        label="Passport Number"
                        ref={register}
                        defaultValue={profile?.personalInfo?.passportNumber}
                        name="personalInfo.passportNumber"
                        placeholder="Passport Number"
                      />
                    </Col>
                  </Form.Row>
                </Col>
              </Form.Row>

              <Form.Row>
                <Col>
                  CONTACT INFORMATION
                  <hr />
                  <Input
                    label="Personal Email"
                    ref={register}
                    defaultValue={profile?.contactInfo?.personalEmail}
                    name="contactInfo.personalEmail"
                    placeholder="Personal Email"
                  />
                  <Input
                    label="Present Address"
                    ref={register}
                    defaultValue={profile?.contactInfo?.presentAddress}
                    name="contactInfo.presentAddress"
                    placeholder="Present address"
                  />
                  <Input
                    label="Permanent Address"
                    ref={register}
                    defaultValue={profile?.contactInfo?.permanentAddress}
                    name="contactInfo.permanentAddress"
                    placeholder="Permanent address"
                  />
                </Col>
                <Col>
                  SECONDARY CONTACT
                  <hr />
                  <Input
                    label="Name"
                    ref={register}
                    defaultValue={profile?.contactInfo?.secondaryContactName}
                    name="contactInfo.secondaryContactName"
                    placeholder="Name"
                  />
                  <Input
                    label="Phone"
                    ref={register}
                    defaultValue={profile?.contactInfo?.secondaryContactPhoneNo}
                    name="contactInfo.secondaryContactPhoneNo"
                    placeholder="Phone"
                  />
                  <Input
                    label="Relation"
                    ref={register}
                    defaultValue={
                      profile?.contactInfo?.secondaryContactRelation
                    }
                    name="contactInfo.secondaryContactRelation"
                    placeholder="Relation"
                  />
                </Col>
              </Form.Row>

              <Form.Row>
                <Col>
                  <EmployeeCertificationViewContainer
                    profile={profile}
                    userId={props.location.state.userId}
                  />
                </Col>
              </Form.Row>

              <Form.Row>
                <Col>
                  <UserProfileTeamHistoryContainer
                    profile={profile}
                    userId={props.location.state.userId}
                  />
                </Col>
              </Form.Row>

              <Form.Row>
                <Col>
                  <TrainingHistoryContainer
                    userId={props.location.state.userId}
                  />
                </Col>
              </Form.Row>

              <CefaloButton size="sm" variant="primary" type="submit">
                Save
              </CefaloButton>
            </Form>
          </div>
        )}
      </Layout>
    </div>
  );
};

export default UserProfileUpsertContainer;
