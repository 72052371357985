import React from "react";
import PropTypes from "prop-types";
import TeamApi from "../../../apis/endpoints/TeamApi";
import TeamUpsertModalForm from "../forms/TeamUpsertModalForm";
import { CefaloButton } from "../../../library/button";
import { Loading, toaster } from "../../../shared-components/notification";
import { defaultErrorResponseParser } from "../../../helpers/ErrorParser";
import { TeamDto } from "../../../models/Team";

class TeamUpdateAction extends React.Component {
  static propTypes = {
    teamData: PropTypes.object.isRequired,
    onUpdateCallback: PropTypes.func.isRequired,
  };

  state = {
    show: false,
    isLoading: false,
    teamData: this.props.teamData,
  };

  toggle = (e) => () => {
    this.setState({ show: !this.state.show });
  };

  onUpdateAction = (data) => {
    this.setState({ isLoading: true });
    TeamApi.update(data)
      .then((res) => {
        this.setState({
          isLoading: false,
          show: false,
          teamData: new TeamDto(res.data),
        });
        toaster.success("Team Updated Successfully");
        this.props.onUpdateCallback(res.data);
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        defaultErrorResponseParser(err, "Failed To Update Team");
      });
  };

  render() {
    return (
      <div className="d-inline-block">
        <Loading show={this.state.isLoading} />
        <CefaloButton
          className="btn-font-12"
          size="sm"
          variant="primary"
          onClick={this.toggle()}
        >
          Edit
        </CefaloButton>
        <TeamUpsertModalForm
          formName="Edit Team"
          clients={this.props.clients}
          defaultValues={this.state.teamData}
          onSubmit={this.onUpdateAction}
          show={this.state.show}
          onToggle={this.toggle}
        />
      </div>
    );
  }
}

export default TeamUpdateAction;
