import { AuthenticatedAxios } from "../settings/Axios";
const BaseEndPoint = "Users/Trainings";
let config = {
  headers: {
    "Content-Type": "application/json",
  },
};

export default {
  getAll: () => {
    return AuthenticatedAxios.get(BaseEndPoint, null, null);
  },
  create: (userTraining) => {
    let body = JSON.stringify(userTraining);
    return AuthenticatedAxios.post(BaseEndPoint, body, config);
  },
  update: (userTraining) => {
    let endpoint = `${BaseEndPoint}/${userTraining.id}`;
    let body = JSON.stringify(userTraining);

    return AuthenticatedAxios.put(endpoint, body, config);
  },
  delete: (id) => {
    let endpoint = `${BaseEndPoint}/${id}`;
    return AuthenticatedAxios.delete(endpoint, null, {});
  },
};
