import ExtraWorkApplicationApi from "../../apis/endpoints/ExtraWorkApplicationApi";
import {
  enumsToValueLabelArray,
  enumsValueToLabelObject,
  enumsLabelToValueObject,
} from "../../shared-components/enum-values/EnumValuesComverter";
import SortUtility from "../../helpers/SortUtility";

export let ExtraWorkTypesOptions = [{ value: null, label: "All" }];

export let ExtraWorkStatusOptions = [{ value: null, label: "All" }];

export let ToExtraWorkStatusLabel = {};

export let ToExtraWorkStatusValue = {};

export let ToExtraWorkTypesLabel = {};

export const dataTableColumns = [
  {
    text: "Application Date",
    dataField: "created",
    sort: true,
    sortFunc: SortUtility.dateSorter,
    col: 1,
  },
  {
    text: "From Date",
    dataField: "fromDate",
    sort: true,
    sortFunc: SortUtility.dateSorter,
    col: 2,
  },
  {
    text: "To Date",
    dataField: "toDate",
    sort: true,
    sortFunc: SortUtility.dateSorter,
    col: 3,
  },
  {
    text: "Adjusted With",
    dataField: "adjustmentType",
    sort: true,
    col: 4,
  },
  {
    text: "Working Hours",
    dataField: "workingHours",
    sort: true,
    col: 5,
  },
  {
    text: "Extra Work Team",
    dataField: "extraWorkTeam",
    sort: true,
    col: 6,
  },
  {
    text: "Billable To",
    dataField: "notBillableToCustomer",
    sort: true,
    col: 7,
  },
  {
    text: "Application Status",
    dataField: "status",
    sort: true,
    col: 8,
  },
];
export const descSortedOrder=[
  {
    dataField: "fromDate",
    order: 'desc',
  },
  {
    dataField: "toDate",
    order: 'desc',
  },
]

export const supervisionDataTableColumns = [
  {
    text: "User Name",
    dataField: "userName",
    sort: true,
    col: 2,
  },
  {
    text: "Team Name",
    dataField: "teamName",
    sort: true,
  },

  ...dataTableColumns,
  {
    text: "Payment Status",
    dataField: "paymentStatus",
    sort: true,
    col: 1,
  },
  {
    text: "Actions",
    dataField: "actions",
    sort: false,
    col: 3,
  },
];

export function setConstants(settedCallback) {
  ExtraWorkApplicationApi.getAllTypes().then((resp) => {
    let dataArray = resp.data;
    ExtraWorkTypesOptions = [{ value: null, label: "All" }].concat(
      enumsToValueLabelArray(dataArray)
    );
    ToExtraWorkTypesLabel = enumsValueToLabelObject(dataArray);
    settedCallback();
  });

  ExtraWorkApplicationApi.getAllStatus().then((resp) => {
    let dataArray = resp.data;
    ExtraWorkStatusOptions = [{ value: null, label: "All" }].concat(
      enumsToValueLabelArray(dataArray)
    );
    ToExtraWorkStatusLabel = enumsValueToLabelObject(dataArray);
    ToExtraWorkStatusValue = enumsLabelToValueObject(dataArray);
    settedCallback();
  });
}
