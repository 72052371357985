import React from "react";
import { ExtraWorkStatusOptions, ExtraWorkTypesOptions,descSortedOrder } from "./Const";
import WithPermittedAction from "./hoc/WithPermittedAction";
import WithTableData from "./hoc/WithTableData";
import { Container } from "react-bootstrap";
import Layout from "../../shared-components/layout/Layout";
import ExtraWorkController from "./forms/ExtraWorkController";
import { Loading } from "../../shared-components/notification";
import DataTable from "../../library/table/DataTable";

function ExtraWorkApplicationView({
  tableData,
  userId,
  isSupervision,
  actionsCallbacks,
  onChangeUser,
  isLoading,
  teamNames
}) {
  let defaultSorted=isSupervision ? {} : {defaultSorted:descSortedOrder}

  return (
    <Layout>
      <Container
        fluid
        style={{ marginTop: "10px" }}
        className="pr-3 pt-3 pl-3 pb-0"
      >
        <Loading show={isLoading} />
        <ExtraWorkController
          isUserFilterEnabled={!isSupervision}
          isStatusEnabled={isSupervision}
          isSupervision={isSupervision}
          onFilterAction={actionsCallbacks.onFilter}
          onCreateCallback={actionsCallbacks.onCreate}
          typesOptions={ExtraWorkTypesOptions}
          statusOptions={ExtraWorkStatusOptions}
          onChangeUser={onChangeUser}
          teamNames={teamNames}
        />
        <DataTable {...defaultSorted} rowMutedDateColumnName="toDate" {...tableData} />
      </Container>
    </Layout>
  );
}
export default WithPermittedAction(WithTableData(ExtraWorkApplicationView));
