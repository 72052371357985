export function enumsToValueLabelArray(enumArray = [],name='name',value='value') {
  enumArray= !!enumArray ? enumArray : []
  return enumArray.map((enumObject) => ({
    value: enumObject[value],
    label: enumObject[name],
  }));
}

export function enumsValueToLabelObject(enumArray = []) {
  let enumObject = {};
  enumArray.forEach((x) => (enumObject[x.value] = x.name));
  return enumObject;
}

export function enumsLabelToValueObject(enumArray = []) {
  let enumObject = {};
  enumArray.forEach((x) => (enumObject[x.name] = x.value));
  return enumObject;
}
