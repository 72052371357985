import React, { Component } from "react";
import PublicHolidayApi from "../../apis/endpoints/PublicHolidayApi";
import Layout from "../../shared-components/layout/Layout";
import { Helmet } from "react-helmet";
import { Loading } from "../../shared-components/notification";
import PublicHolidayView from "./PublicHolidayView";
import UserManager from "../../navigation/UserManager";
import CreateFormModal from "./CreateFormModal";
import UploadCsvFormModal from "./UploadCsvFormModal";
import { Row, Col } from "react-bootstrap";
import { showErrorMessages } from "../../shared-components/response-message/ResponseMessage";
import SortUtility from "../../helpers/SortUtility";
const user = UserManager.getUser();

const yearSelectOptions = () => {
  var years = [];
  const date = new Date();
  const maxYear = date.getFullYear() + 1;
  for (var year = maxYear - 2; year <= maxYear; year++) {
    if (year === new Date().getFullYear()) {
      years.push(
        <option key={year} value={year}>
          {year}
        </option>
      );
      continue;
    }
    years.push(
      <option key={year} value={year} className="font-weight-bold">
        {year}
      </option>
    );
  }
  return years;
};

class PublicHolidayContainer extends Component {
  state = {
    publicHolidays: [],
    isLoading: false,
    year: new Date().getFullYear(),
  };

  componentDidMount() {
    this.loadPublicHolidayData(this.state.year);
  }

  loadPublicHolidayData = (year) => {
    this.setState({ isLoading: true });
    PublicHolidayApi.getByYear(year)
      .then((res) => {
        this.manageLoadedPublicHoliday(res.data);
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        showErrorMessages(err.response);
      });
  };

  manageLoadedPublicHoliday = (holidays) => {
    let sortedHolidays = Array.from(holidays).sort((a, b) =>
      SortUtility.dateSorter(a.date, b.date, "asc")
    );
    this.setState({
      isLoading: false,
      publicHolidays: sortedHolidays,
    });
  };

  onPublicHolidayCreate = (publicHoliday) => {
    let publicHolidays = this.state.publicHolidays;
    publicHolidays.push(publicHoliday);
    this.setState({
      publicHolidays: publicHolidays,
    });
  };

  onPublicHolidayDelete = () => {
    this.loadPublicHolidayData(this.state.year);
  };
  onPublicHolidayUploadFile = () => {
    this.loadPublicHolidayData(this.state.year);
  };

  onPublicHolidayUpdate = (updatedPublicHoliday) => {
    let publicHolidays = this.state.publicHolidays;
    let index = publicHolidays.findIndex(
      (item) => item.id === updatedPublicHoliday.id
    );
    publicHolidays[index] = updatedPublicHoliday;
    this.setState({ publicHolidays: publicHolidays });
  };

  handleSelect = (event) => {
    this.setState({
      year: event.target.value,
    });
    this.loadPublicHolidayData(event.target.value);
  };

  render() {
    const yearOptions = yearSelectOptions();
    return (
      <Layout>
        <Helmet>
          <title>Holiday Management- Cefalo</title>
        </Helmet>
        <div className="pr-3 pl-3 pt-3 pb-0">
          <Loading
            show={this.state.isLoading}
            headerMessage={"PublicHolidays are loading"}
          />
          <Row>
            <Col sm={8} md={8}>
              {(user.role === 1 || user.role === 2) && (
                <>
                  <CreateFormModal
                    onCreateCallback={this.onPublicHolidayCreate}
                  />
                  <UploadCsvFormModal
                    onUploadCallback={this.onPublicHolidayUploadFile}
                  />
                </>
              )}
            </Col>
            <Col
              xs={7}
              sm={4}
              md={3}
              className="d-flex justify-content-end align-items-center ml-auto"
            >
              <span className="font-weight-bold mr-1">Year:</span>
              <select
                defaultValue={new Date().getFullYear()}
                className="form-inline form-control form-control-sm w-auto d-flex justify-content-flex-end font-weight-bold"
                onChange={this.handleSelect}
              >
                {yearOptions}
              </select>
            </Col>
          </Row>
          <PublicHolidayView
            publicHolidays={this.state.publicHolidays}
            onUpdate={this.onPublicHolidayUpdate}
            onDelete={this.onPublicHolidayDelete}
            onCreate={this.onPublicHolidayCreate}
          />
        </div>
      </Layout>
    );
  }
}
export default PublicHolidayContainer;
