import React from "react";
import { Col } from "react-bootstrap";

import { toaster } from "../../shared-components/notification";
import { Form } from "../../library/form/Index";
import UserManager from "../../navigation/UserManager";
import ProfileApi from "../../apis/endpoints/ProfileApi";
import EmployeeCertificationView from "./views/EmployeeCertificationView";
import EmployeeCertificationCreateAction from "./actions/EmployeeCertificationCreateAction"

const ViewContainer = (props) => <div className="p-2">{props.children}</div>;

class EmployeeCertificationViewContainer extends React.Component {
  state = {
    isLoading: false,
    shouldRender: false,
    profile: this.props.profile,
    userId: this.props.userId
  };
  
  componentDidMount = () => {
    let user = UserManager.getUser();
    if (user.isAdminOrHr()) {
      this.setState({
        shouldRender: true,
        isLoading: true,
      });
    }
  };

  loadProfileData = (userId) => {
    ProfileApi.get(userId)
      .then((res) => {
        this.setState({
          profile: res.data,
        });
      })
      .catch((err) => {
        toaster.error("Failed To Load Employee Certification!");
      });
  };

  render() {
    return (
      <Form>

        <Form.Row>
          <Col>
            COURSES AND CERTIFICATIONS
            <hr />
          </Col>
          <Col sm="auto">
            <EmployeeCertificationCreateAction
              profile={this.state.profile}
              userId={this.state.userId}
              onCreateCallBack={this.loadProfileData}
            />
          </Col>
        </Form.Row>

        <Form.Row>
          <Col>
            <ViewContainer>
              <EmployeeCertificationView
                profile={this.state.profile}
                onDelete={this.loadProfileData}
                onUpdate={this.loadProfileData}
                hasActionGroup={true}
              />
            </ViewContainer>
          </Col>
        </Form.Row>
        
      </Form>
    );
  }
}

export default EmployeeCertificationViewContainer;