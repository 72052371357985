import WorkingDaysAndMonthApi from "../apis/endpoints/WorkingDaysAndMonthApi";
import { DateOnlyString } from "./Builders";
import { defaultErrorResponseParser } from "./ErrorParser";

export default {
  getWorkingDaysCount: (fromDate, toDate, includeHalfDay, callback) => {
    WorkingDaysAndMonthApi.getCount(
      DateOnlyString(fromDate),
      DateOnlyString(toDate),
      includeHalfDay
    )
      .then((res) => {
        callback(Number.parseFloat(res.data));
      })
      .catch((err) => {
        defaultErrorResponseParser(
          err,
          "Failed To Calculate Total Working Days"
        );
      });
  },

  getToDate: (fromDate, totalWorkingDays, callback) => {
    WorkingDaysAndMonthApi.getToDate(DateOnlyString(fromDate), totalWorkingDays)
      .then((res) => {
        callback(new Date(res.data));
      })
      .catch((err) => {
        defaultErrorResponseParser(err, "Failed To Calculate ToDate");
      });
  },

  getWorkfromHomeApplicationDaysCount: (fromDate, toDate, userId, callback) => {
    WorkingDaysAndMonthApi.getWorkFromHomeDaysCount(
      DateOnlyString(fromDate),
      DateOnlyString(toDate),
      userId
    )
      .then((res) => {
        callback(Number.parseFloat(res.data));
      })
      .catch((err) => {
        defaultErrorResponseParser(
          err,
          "Failed To Calculate Total Working Days"
        );
      });
  },
};
