import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";

import UserManager from "../../../navigation/UserManager";
import { CefaloButton } from "../../../library/button/Index";
import { Form, DateRange, Select } from "../../../library/form/Index";
import { DateOnlyString } from "../../../helpers/Builders";
import { Col } from "../../../library/grid-layout/Index";

const FilterFormView = (props) => {
  let user = UserManager.getUser();
  const { register, handleSubmit } = useForm();

  const [fromDate, setFromDate] = useState(
    new Date(new Date().getFullYear(), 0, 1)
  );

  const [toDate, setToDate] = useState(
    new Date(new Date().getFullYear(), 11, 31)
  );

  const setDate = ({ fromDate, toDate }) => {
    setFromDate(fromDate);
    setToDate(toDate);
  };

  let remoteWorkTypeOptions = props.remoteWorkApplicationTypes.map((i) => {
    return { label: i.displayName, value: i.value };
  });

  let userOptions = [];

  if (user.isAdminOrHrOrTeamManager() && props.users) {
    props.users.forEach((item) => {
      const userOption = { value: item.id, label: item.name };
      userOptions.push(userOption);
    });
  }

  const onFilter = (data) => {
    var filteredData = { ...data };
    if (!user.isAdminOrHr()) {
      filteredData.userId = user.id;
    }
    filteredData.fromDate = DateOnlyString(fromDate);
    filteredData.toDate = DateOnlyString(toDate);
    filteredData.userId = Number.parseInt(filteredData.userId);
    props.onFilterAction(filteredData);
  };

  return (
    <form onSubmit={handleSubmit(onFilter)}>
      <Form.Row style={{ alignItems: "flex-end" }}>
        {user.isAdminOrHrOrTeamManager() ? (
          <Col sm>
            <Select
              value={props.selectedUserId}
              label="User:"
              options={userOptions}
              ref={register}
              onChange={(e) => {
                let userId = Number.parseInt(e.target.value);
                props.setSelectedUserId(userId);
                props.onUserChange(userId);
              }}
              name="userId"
            />
          </Col>
        ) : (
          <></>
        )}
        <DateRange
          fromDate={fromDate}
          toDate={toDate}
          onChange={setDate}
          classesNames={{
            fromDateColClassName: "pr-lg-3 pl-lg-4",
            toDateColClassName: "pr-lg-3 pl-lg-3",
          }}
        />
        <Col sm>
          <Select
            label="Remote Work Types:"
            options={[{ value: "", label: "All" }, ...remoteWorkTypeOptions]}
            ref={register}
            name="workType"
            containerClassName="pr-lg-3 pl-lg-2"
          />
        </Col>

        <Col lg={4} className="p-0 pl-lg-1">
          <Form.Row className="mb-3">
            <CefaloButton
              type="submit"
              className="mt-auto filter_panel_btn"
              size="sm"
              variant="primary"
            >
              Show Applications
            </CefaloButton>
            {props.children}
          </Form.Row>
        </Col>
      </Form.Row>
    </form>
  );
};

export default FilterFormView;
