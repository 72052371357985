import React, { Component } from "react";
import WithCrudActionsOnObject from "../../../shared-components/hoc/crud/WithCrudActionsOnObject";
import settingsApi from "../../../apis/endpoints/SettingsApi";
import SettingsViews from "./SettingsViews";
import {
  commaSeparatedStringToObject,
  toCommaSeparatedString,
  convertStringToRoleArray,
  convertRoleToStringObject,
} from "./Helper";
import {
  showErrorMessages,
  showSuccessMessages,
} from "../../../shared-components/response-message/ResponseMessage";
import { initialSettings, WeekDays } from "./Const";
import { toaster } from "../../../shared-components/notification/Notification";

class SettingsContainer extends Component {
  state = {
    isLoading: false,
  };

  processSettingsData = (data) => {
    return {
      ...data,
      leaveRequestEmailRecipients: {
        ...commaSeparatedStringToObject(data.leaveRequestEmailRecipients),
        admin: true,
      },
      leaveConfirmationEmailRecipients: {
        ...commaSeparatedStringToObject(
          data.leaveConfirmationEmailRecipients
        ),
        applicant: true,
      },
      ewhRequestEmailRecipients: {
        ...commaSeparatedStringToObject(data.ewhRequestEmailRecipients),
        admin: true,
      },
      ewhConfirmationEmailRecipients: {
        ...commaSeparatedStringToObject(data.ewhConfirmationEmailRecipients),
        applicant: true,
      },
      remoteWorkRequestEmailRecipients: {
        ...commaSeparatedStringToObject(data.remoteWorkRequestEmailRecipients),
        admin: true,
      },
      remoteWorkConfirmationEmailRecipients: {
        ...commaSeparatedStringToObject(data.remoteWorkConfirmationEmailRecipients),
        applicant: true,
      },
      profileEducationInfoViewers: {
        ...convertRoleToStringObject(data.profileEducationInfoViewers),
      },
      profilePersonalInfoViewers: {
        ...convertRoleToStringObject(data.profilePersonalInfoViewers),
      },
      profileContactInfoViewers: {
        ...convertRoleToStringObject(data.profileContactInfoViewers),
      },
      profileAttendanceInfoViewers: {
        ...convertRoleToStringObject(data.profileAttendanceInfoViewers),
      },
      profileLeaveStatisticsInfoViewers: {
        ...convertRoleToStringObject(data.profileLeaveStatisticsInfoViewers),
      },
    };
  };

  componentDidMount = () => {
    const { setObjectStateCallback } = this.props;
    this.setState({
      isLoading: false,
    });
    settingsApi
      .get()
      .then((resp) => {
        setObjectStateCallback(this.processSettingsData(resp.data));
        this.setState({
          isLoading: true,
        });
      })
      .catch((err) => {
        setObjectStateCallback(initialSettings);
      });
  };

  setLeaveRecipentsState = (event, isRequestedEmail = false) => {
    const { crudObject: settings, setObjectStateCallback } = this.props;
    let objectName = "";
    let object = {};
    let fieldName = event.currentTarget.name;
    if (isRequestedEmail) {
      objectName = "leaveRequestEmailRecipients";
      object = settings.leaveRequestEmailRecipients;
    } else {
      objectName = "leaveConfirmationEmailRecipients";
      object = settings.leaveConfirmationEmailRecipients;
    }
    if (object[fieldName] === "undefined") {
      object[fieldName] = false;
    }
    setObjectStateCallback({
      ...settings,
      [objectName]: { ...object, [fieldName]: !object[fieldName] },
    });
  };

  setRemoteWorkRecipentsState = (event, isRequestedEmail = false) => {
    const { crudObject: settings, setObjectStateCallback } = this.props;
    let objectName = "";
    let object = {};
    let fieldName = event.currentTarget.name;
    if (isRequestedEmail) {
      objectName = "remoteWorkRequestEmailRecipients";
      object = settings.remoteWorkRequestEmailRecipients;
    } else {
      objectName = "remoteWorkConfirmationEmailRecipients";
      object = settings.remoteWorkConfirmationEmailRecipients;
    }
    if (object[fieldName] === "undefined") {
      object[fieldName] = false;
    }
    setObjectStateCallback({
      ...settings,
      [objectName]: { ...object, [fieldName]: !object[fieldName] },
    });
  };

  setEWHRecipentsState = (event, isRequestedEmail = false) => {
    const { crudObject: settings, setObjectStateCallback } = this.props;
    let objectName = "";
    let object = {};
    let fieldName = event.currentTarget.name;
    if (isRequestedEmail) {
      objectName = "ewhRequestEmailRecipients";
      object = settings.ewhRequestEmailRecipients;
    } else {
      objectName = "ewhConfirmationEmailRecipients";
      object = settings.ewhConfirmationEmailRecipients;
    }
    if (object[fieldName] === "undefined") {
      object[fieldName] = false;
    }
    setObjectStateCallback({
      ...settings,
      [objectName]: { ...object, [fieldName]: !object[fieldName] },
    });
  };

  setOfficeDaysState = (event) => {
    const { crudObject: settings, setObjectStateCallback } = this.props;

    let object = [...settings.officeWeekDays];
    let objectName = "officeWeekDays";
    let fieldName = event.currentTarget.name;
    let day = fieldName.charAt(0).toUpperCase() + fieldName.slice(1);

    if (object.includes(WeekDays[day])) {
      object = object.filter((item) => {
        return item !== WeekDays[day];
      });
    } else {
      object.push(WeekDays[day]);
      object.sort();
    }

    setObjectStateCallback({
      ...settings,
      [objectName]: object,
    });
  };

  setProfileViewerState = (event, section) => {
    const { crudObject: settings, setObjectStateCallback } = this.props;
    let objectName = "";
    let object = {};
    let fieldName = event.currentTarget.name;
    if (section === "Education section") {
      objectName = "profileEducationInfoViewers";
      object = settings.profileEducationInfoViewers;
    } else if (section === "PersonalInfo section") {
      objectName = "profilePersonalInfoViewers";
      object = settings.profilePersonalInfoViewers;
    } else if (section === "ContactInfo section") {
      objectName = "profileContactInfoViewers";
      object = settings.profileContactInfoViewers;
    } else if (section === "Attendance section") {
      objectName = "profileAttendanceInfoViewers";
      object = settings.profileAttendanceInfoViewers;
    } else if (section === "LeaveStatistics section") {
      objectName = "profileLeaveStatisticsInfoViewers";
      object = settings.profileLeaveStatisticsInfoViewers;
    }

    if (object[fieldName] === "undefined") {
      object[fieldName] = false;
    }
    setObjectStateCallback({
      ...settings,
      [objectName]: { ...object, [fieldName]: !object[fieldName] },
    });
  };

  checkIfPolicyDocumentLinkIsValid = (url) => {
    if (!!url && typeof url === "string") {
      return (
        url.toLowerCase().startsWith("http://") ||
        url.toLowerCase().startsWith("https://")
      );
    }
    return true;
  };

  onSave = () => {
    const { objectCrudCallbacks, crudObject } = this.props;
    if (
      !(
        this.checkIfPolicyDocumentLinkIsValid(crudObject.hrPolicyDocsUrl) &&
        this.checkIfPolicyDocumentLinkIsValid(crudObject.itPolicyDocsUrl)
      )
    ) {
      toaster.error("Policy documents URL should be hyperlink");
      return;
    }
    let settings = {
      ...crudObject,
      leaveRequestEmailRecipients: toCommaSeparatedString(
        crudObject.leaveRequestEmailRecipients
      ),
      leaveConfirmationEmailRecipients: toCommaSeparatedString(
        crudObject.leaveConfirmationEmailRecipients
      ),
      ewhRequestEmailRecipients: toCommaSeparatedString(
        crudObject.ewhRequestEmailRecipients
      ),
      ewhConfirmationEmailRecipients: toCommaSeparatedString(
        crudObject.ewhConfirmationEmailRecipients
      ),
      remoteWorkRequestEmailRecipients: toCommaSeparatedString(
        crudObject.remoteWorkRequestEmailRecipients
      ),
      remoteWorkConfirmationEmailRecipients: toCommaSeparatedString(
        crudObject.remoteWorkConfirmationEmailRecipients
      ),

      profileEducationInfoViewers: convertStringToRoleArray(
        crudObject.profileEducationInfoViewers
      ),
      profilePersonalInfoViewers: convertStringToRoleArray(
        crudObject.profilePersonalInfoViewers
      ),
      profileContactInfoViewers: convertStringToRoleArray(
        crudObject.profileContactInfoViewers
      ),
      profileAttendanceInfoViewers: convertStringToRoleArray(
        crudObject.profileAttendanceInfoViewers
      ),
      profileLeaveStatisticsInfoViewers: convertStringToRoleArray(
        crudObject.profileLeaveStatisticsInfoViewers
      ),
    };
    objectCrudCallbacks
      .onUpdate(settings.id, settings, this.processSettingsData)
      .then((resp) => {
        showSuccessMessages("Setting Saved Successfully");
      })
      .catch((err) => {
        showErrorMessages(err.response.data);
      });
  };

  onChangeCrudObject = (event) => {
    const { setObjectStateCallback, crudObject } = this.props;
    setObjectStateCallback({
      ...crudObject,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  render() {
    const { crudObject: settings } = this.props;
    return (
      <>
        {this.state.isLoading && (
          <SettingsViews
            settings={settings}
            onSave={this.onSave}
            setLeaveRecipentsState={this.setLeaveRecipentsState}
            setEWHRecipentsState={this.setEWHRecipentsState}
            setRemoteWorkRecipentsState={this.setRemoteWorkRecipentsState}
            setProfileViewerState={this.setProfileViewerState}
            setOfficeDaysState={this.setOfficeDaysState}
            onChangeCrudObject={this.onChangeCrudObject}
          />
        )}
      </>
    );
  }
}

export default WithCrudActionsOnObject(settingsApi)(SettingsContainer);
