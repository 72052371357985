export const Pair = Object.freeze({
  Value: "value",
  DisplayName: "displayName",
});

export const TypesBuilder = {
  toObject: (arr, key, value) => {
    if (!Array.isArray(arr)) {
      throw new Error("Not an array instance");
    }
    let obj = {};
    arr.forEach((item) => {
      var obj_key = item[key];
      if (Number.isNaN(Number.parseInt(obj_key))) {
        obj_key = obj_key.split(" ").join("");
      }
      obj[obj_key] = item[value];
    });
    return Object.freeze(obj);
  },

  toOptionsArray: (arr) => {
    if (!Array.isArray(arr)) {
      throw new Error("Not an array instance");
    }
    let options = [];
    arr.forEach((item) => {
      options.push({ label: item[Pair.DisplayName], value: item[Pair.Value] });
    });
    return options;
  },

  toStatusOptionsArray: (arr) => {
    if (!Array.isArray(arr)) {
      throw new Error("Not an array instance");
    }
    let options = [];
    arr.forEach((item) => {
      options.push({ label: item.name, value: item.value });
    });
    return options;
  },
};

export const DateOnlyBuilder = (dateStr) => {
  let date = dateStr ? new Date(dateStr) : new Date();
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

export const DateOnlyString = (date = new Date()) => {
  date = new Date(date);
  return (
    date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
  );
};

export const DateOnlyStringMMDDYY = (date = new Date()) => {
  date = new Date(date);
  return date.getMonth() + 1 + "-" + date.getDate() + "-" + date.getFullYear();
};

export const ConvertDateToTimeString = (date = new Date()) => {
  const addPrefixIfRequired = (value) => {
    if (value < 10) {
      return `0${value}`;
    }
    return `${value}`;
  };

  return `${addPrefixIfRequired(date.getHours())}: ${addPrefixIfRequired(
    date.getMinutes()
  )}`;
};

export const ParseDateTime = (timeString) => {
  let spliitedTime = timeString.split(/[ .:]+/);
  var dateTime = new Date(
    null,
    null,
    null,
    Number.parseInt(spliitedTime[0]),
    Number.parseInt(spliitedTime[1])
  );
  return dateTime;
};
