import React from "react";
import Layout from "../../shared-components/layout/Layout";
import { Helmet } from "react-helmet";
import SkillApi from "../../apis/endpoints/SkillApi";
import SkillManageView from "./views/SkillManageView";
import { toaster } from "../../shared-components/notification/Notification";
import { SkillDto } from "../../models/Skill";
import UserManager from "../../navigation/UserManager";
export default class SkillManageContainer extends React.Component {
  state = {
    skills: [],
    isLoading: false,
    shouldRender: false,
    parentSkillOptions: [],
  };

  componentDidMount() {
    let user = UserManager.getUser();
    if (!user.isAdminOrHr()) {
      window.location.replace(`/Report/Index/${user.id}`);
    } else {
      this.setState({ shouldRender: true });
      this.loadSkilsData();
    }
  }

  loadSkilsData = () => {
    this.setState({ isLoading: true });
    SkillApi.all()
      .then((res) => {
        let skills = res.data.map((t) => new SkillDto(t));
        this.setState({
          isLoading: false,
          skills: skills,
        });

      let dataArray = [];
      res.data.forEach(element => {
        const object = {
          label: element.name,
          value: element.id,
        }
        dataArray.push(object);
      });
      this.setState({
        isLoading: false,
        parentSkillOptions: [{ value: null, label: "None" }].concat(
          dataArray
        )
      });

      })
      .catch((err) => {
        this.setState({ isLoading: false });
        toaster.error("Failed to load skill information");
      });
  };

  onSkillCreate = (createdSkill) => {
    let skills = this.state.skills;
    let skill =  new SkillDto(createdSkill);
    skills.push(skill);
    this.setState({
      skills: skills,
    });
    this.loadSkilsData();
  };

  onSkillDelete = () => {
    this.loadSkilsData();
  };

  onSkillUpdate = (skill) => {
    let updatedSkill = new SkillDto(skill);
    let skills = this.state.skills;
    let index = skills.findIndex((item) => item.id === updatedSkill.id);
    skills[index] = updatedSkill;
    this.setState({ skills: skills });
  };

  render() {
    if (!this.state.shouldRender) {
      return <></>;
    }
    return (
      <Layout>
        <Helmet>
          <title>Skill Management- Cefalo</title>
        </Helmet>
        <SkillManageView
          {...this.state}
          teams={this.state.skills}
          parentSkillOptions={this.state.parentSkillOptions}
          onUpdate={this.onSkillUpdate}
          onDelete={this.onSkillDelete}
          onCreate={this.onSkillCreate}
        />
      </Layout>
    );
  }
}
