import React from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import SkillUpdateAction from "../actions/SkillUpdateAction";
import SkillDeleteAction from "../actions/SkillDeleteAction";

const ActionsGroupView = (props) => {
  return (
    <div className="d-inline-block action-col">
      <SkillUpdateAction
        key={uuidv4()}
        skillData={props.skill}
        onUpdateCallback={props.updateAction}
        parentSkillOptions={props.parentSkillOptions}
      />
      <SkillDeleteAction
        key={uuidv4()}
        onDeleteCallback={props.deleteAction}
        skill={props.skill}
      />
    </div>
  );
};
ActionsGroupView.prototype = {
  skill: PropTypes.object,
  deleteAction: PropTypes.func.isRequired,
  updateAction: PropTypes.func.isRequired,
};

export default ActionsGroupView;
