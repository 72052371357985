import NocApplicationApi from "../../apis/endpoints/NocApplicationApi";
import {
  enumsToValueLabelArray,
  enumsValueToLabelObject,
  enumsLabelToValueObject,
} from "../../shared-components/enum-values/EnumValuesComverter";
import SortUtility from "../../helpers/SortUtility";

export let NocStatusOptions = [{ value: "*", label: "All" }];

export let ToNocStatusLabel = {};

export let ToNocStatusValue = {};

export const dataTableColumns = [
  {
    text: "Application Date",
    dataField: "created",
    sort: true,
    sortFunc: SortUtility.dateSorter,
    col: 1,
  },
  {
    text: "From Date",
    dataField: "fromDate",
    sort: true,
    sortFunc: SortUtility.dateSorter,
    col: 2,
  },
  {
    text: "To Date",
    dataField: "toDate",
    sort: true,
    sortFunc: SortUtility.dateSorter,
    col: 3,
  },
  {
    text: "Designation",
    dataField: "designation",
    sort: true,
    col: 4,
  },
  {
    text: "Joining Date",
    dataField: "joiningDate",
    sort: true,
    col: 5,
  },

  {
    text: "Passport Number",
    dataField: "passportNumber",
    sort: true,
    col: 6,
  },
  {
    text: "Country",
    dataField: "country",
    sort: true,
    col: 7,
  },
  {
    text: "Purpose",
    dataField: "description",
    sort: true,
    col: 8,
  },
  {
    text: "Status",
    dataField: "status",
    sort: true,
    col: 9,
  },
  {
    text: "Actions",
    dataField: "actions",
    sort: false,
    col: 10,
  },
];
export const descSortedOrder = [
  {
    dataField: "fromDate",
    order: "desc",
  },
  {
    dataField: "toDate",
    order: "desc",
  },
];

export const supervisionDataTableColumns = [
  {
    text: "User Name",
    dataField: "name",
    sort: true,
    col: 1,
  },
  {
    text: "Team Name",
    dataField: "team",
    sort: true,
    col: 2,
  },
  ...dataTableColumns,
];

export function setConstants(settedCallback) {
  NocApplicationApi.getAllStatus().then((resp) => {
    let dataArray = resp.data;
    NocStatusOptions = [{ value: null, label: "All" }].concat(
      enumsToValueLabelArray(dataArray)
    );
    ToNocStatusLabel = enumsValueToLabelObject(dataArray);
    ToNocStatusValue = enumsLabelToValueObject(dataArray);
    settedCallback();
  });
}

export const NocStatusesDictionary = Object.freeze({
  "1": "Drafted",
  "2": "Requested",
  "3": "Approved",
  "4": "Rejected",
  "5": "Cancelled",
  "6": "CancelRequested",
  "7": "CancelledByUser",
  "8": "CancelRequestApproved",
  "9": "CancelRequestRejected"  
});

export const NocStatusValueDictionary = Object.freeze({
  Drafted: 1,
  Requested: 2,
  Approved: 3,
  Rejected: 4,
  Cancelled: 5,
  CancelRequested: 6,
  CancelledByUser: 7,
  CancelRequestApproved: 8,
  CancelRequestRejected: 9  
});
