import React, { useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { Form, Col } from "react-bootstrap";
import { FormModal } from "../../../library/modal";
import { CefaloButton } from "../../../library/button";
import { Input, Select } from "../../../library/form";
import CefaloDatePicker from "../../../library/form/DatePicker";
import { DateOnlyString } from "../../../helpers/Builders";

const TrainingUpsertModalForm = ({
  onSubmit,
  show,
  onToggle,
  formName,
  defaultValues,
  activeUsers,
  isEdit,
}) => {
  const { register, handleSubmit, errors } = useForm({
    defaultValues: defaultValues,
  });

  const [startDate, setStartDate] = useState(new Date());
  const [selectedOption, setSelectedOption] = useState("");
  const [organizerFromTextInput, setOrganizerFromTextInput] = useState("");
  const [organizerFromDropDown, setOrganizerFromDropDown] = useState("");
  const [previousOrganizer, setPreviousOrganizer] = useState(
    defaultValues?.organizer
  );

  const handleDropDownChange = (event) => {
    const selectedLabel =
      event.target.options[event.target.selectedIndex].label;
    setOrganizerFromDropDown(selectedLabel);
    setSelectedOption(event.target.value);
  };

  const handleTextInputChange = (event) => {
    setOrganizerFromTextInput(event.target.value);
  };
  const handlePreviousOrganizerChange = (event) => {
    setPreviousOrganizer(event.target.value);
  };
  const onFormSubmit = (data) => {
    if (defaultValues?.id) {
      data["id"] = defaultValues.id;
    }

    data["startDate"] = DateOnlyString(startDate);
    data["organizer"] = isEdit
      ? previousOrganizer
      : organizerFromTextInput !== ""
      ? organizerFromTextInput
      : organizerFromDropDown !== ""
      ? organizerFromDropDown
      : activeUsers[0]?.label;

    onSubmit(data);
  };

  return (
    <FormModal
      onSubmit={handleSubmit(onFormSubmit)}
      show={show}
      toggle={onToggle()}
      formName={formName}
    >
      <div key={FormModal.ControlContainer}>
        <Form.Row>
          <Col>
            <Input
              ref={register({ required: "Training title is required" })}
              placeholder="Enter title "
              name="title"
              label="Title"
              errors={errors}
            />
          </Col>
        </Form.Row>
        <Form.Row>
          <Col>
            <Input
              label="Description"
              as="textarea"
              size="sm"
              name="description"
              placeholder="Enter description"
              ref={register()}
              errors={errors}
            />
          </Col>
        </Form.Row>

        <Form.Row>
          <Col>
            <Form.Label>Start Date</Form.Label>
            <CefaloDatePicker
              date={startDate}
              placeholder="Enter start date"
              name="startDate"
              onChange={(d) => setStartDate(d)}
              label="Start Date"
            />
          </Col>
          <Col>
            <Input
              placeholder="Enter duration"
              ref={register({ required: "Duration is required" })}
              name="duration"
              label="Duration (In Hours)"
              errors={errors}
            />
          </Col>
        </Form.Row>

        {!isEdit && (
          <Form.Row>
            <Col sm={5}>
              <Select
                name="organizer"
                value={selectedOption}
                onChange={handleDropDownChange}
                label="Internal Organizer"
                options={activeUsers !== undefined ? activeUsers : ""}
                errors={errors}
                required
              />
            </Col>

            <Col sm={2}>
              <div className="pl-4 pr-3 mt-3 pt-3">
                <h6>
                  <b>or</b>
                </h6>
              </div>
            </Col>

            <Col sm={5}>
              <Input
                value={organizerFromTextInput}
                onChange={handleTextInputChange}
                label="External Organizer"
                placeholder="Enter organizer name"
              />
            </Col>
          </Form.Row>
        )}
        {isEdit && (
          <Form.Row>
            <Col sm={5}>
              <Input
                value={previousOrganizer}
                onChange={handlePreviousOrganizerChange}
                label="Organizer"
              />
            </Col>
          </Form.Row>
        )}
      </div>

      <div key={FormModal.ActionContainer}>
        <CefaloButton size="sm" variant="light" onClick={onToggle()}>
          Close
        </CefaloButton>
        <CefaloButton size="sm" variant="primary" type="submit">
          Save
        </CefaloButton>
      </div>
    </FormModal>
  );
};
TrainingUpsertModalForm.prototype = {
  onCreate: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default TrainingUpsertModalForm;
