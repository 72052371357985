import React from "react";
import PropTypes from "prop-types";
import RowActions from "../../../shared-components/leave-application/actions/RowActions";
import SortUtility from "../../../helpers/SortUtility";
import { LeaveApplicationDetailDto } from "../../../models/LeaveApplication";
import { getTableFormatDate } from "../../../helpers/DateUtility";
import { DataTable } from "../../../library/table/Index";

let dataTableColumns = [
  {
    text: "User Name",
    dataField: "userName",
    sort: true,
  },
  {
    text: "Team Name",
    dataField: "teamName",
    sort: true,
  },
  {
    text: "Application Date",
    dataField: "created",
    sort: true,
    sortFunc: SortUtility.dateSorter,
  },
  {
    text: "From Date",
    dataField: "fromDate",
    sort: true,
    sortFunc: SortUtility.dateSorter,
  },
  {
    text: "To Date",
    dataField: "toDate",
    sort: true,
    sortFunc: SortUtility.dateSorter,
  },
  {
    text: "Leave Type",
    dataField: "leaveType",
    sort: true,
  },
  {
    text: "Working Days",
    dataField: "totalNumberOfWorkingDays",
    sort: true,
  },
  {
    text: "Leave Status",
    dataField: "status",
    sort: true,
  },
  {
    text: "Actions",
    dataField: "actions",
    sort: false,
  },
];

const ApplicationTable = ({
  applications,
  actionsCallbacks,
  leaveSettings,
  leaveTypes,
  leaveStatuses,
}) => {
  let rows = [];
  applications.forEach((item) => {
    if (item instanceof LeaveApplicationDetailDto) {
      let tempRow = {};
      tempRow["userName"] = item.applicant.name;
      tempRow["teamName"] = item.applicant.teamNames.join(', ');
      tempRow["created"] = getTableFormatDate(item.created);
      tempRow["fromDate"] = getTableFormatDate(item.fromDate);
      tempRow["toDate"] = getTableFormatDate(item.toDate);
      tempRow["totalNumberOfWorkingDays"] = item.totalNumberOfWorkingDays;
      tempRow["status"] = item.leaveStatusDisplayName;
      tempRow["leaveType"] = item.leaveTypeDisplayName;
      tempRow["actions"] = (
        <RowActions
          leaveTypes={leaveTypes}
          leaveStatuses={leaveStatuses}
          leaveSettings={leaveSettings}
          callbacks={actionsCallbacks}
          application={item}
          userId={item.userId}
        />
      );
      rows.push(tempRow);
    }
  });
  return (
    <DataTable
      rowMutedDateColumnName="toDate"
      rows={rows}
      columns={dataTableColumns}
    />
  );
};
ApplicationTable.prototype = {
  applications: PropTypes.arrayOf(LeaveApplicationDetailDto).isRequired,
  actionsCallbacks: PropTypes.arrayOf(Function).isRequired,
  leaveStatuses: PropTypes.arrayOf(Object).isRequired,
  leaveTypes: PropTypes.arrayOf(Object).isRequired,
  leaveSettings: PropTypes.object.isRequired,
};

export default ApplicationTable;
