import React from "react";
import { EmailSettingsTableCol } from "../Const";
import { CheckBox, Form } from "../../../../library/form";
import CustomTable from "../../../../shared-components/table/CustomTable";
import { v4 as uuid4 } from "uuid";
import { GetValueOrDefault } from "../../../../shared-components/controlled-input-value/GetValueOrDefault";

export function HREmailView({ settings, onChangeCrudObject }) {
  return (
    <div>
      <Form.Control
        type="email"
        name="adminEmail"
        value={GetValueOrDefault(settings.adminEmail)}
        onChange={onChangeCrudObject}
        required
        size="sm"
      />
    </div>
  );
}

function EmailRecipientsCheckBoxGroup({
  recipents,
  isRequestedEmail,
  onChangeRecipents,
}) {
  const checkBoxes = [
    { name: "admin", label: "HR", disabled: isRequestedEmail },
    { name: "kam", label: "KAM" },
    { name: "team", label: "Team" },
    { name: "client", label: "Client" },
    { name: "applicant", label: "Applicant", disabled: !isRequestedEmail },
  ];

  return checkBoxes.map(({ name, label, disabled }) => (
    <CheckBox
      key={uuid4()}
      name={name}
      label={label}
      disabled={disabled}
      checked={!!recipents[name]}
      onChange={(event) => onChangeRecipents(event, isRequestedEmail)}
    />
  ));
}

export default function EmailSettingsView({
  settings,
  setLeaveRecipentsState,
  setEWHRecipentsState,
  setRemoteWorkRecipentsState,
}) {
  const getCheckBoxesView = (
    recipents,
    isRequestedEmail,
    onChangeRecipents
  ) => (
    <EmailRecipientsCheckBoxGroup
      recipents={recipents}
      isRequestedEmail={isRequestedEmail}
      onChangeRecipents={onChangeRecipents}
    />
  );

  const tableData = {
    columns: EmailSettingsTableCol,
    rows: [
      {
        leaveRequestEmailRecipients: getCheckBoxesView(
          settings.leaveRequestEmailRecipients,
          true,
          setLeaveRecipentsState
        ),
        leaveConfirmationEmailRecipients: getCheckBoxesView(
          settings.leaveConfirmationEmailRecipients,
          false,
          setLeaveRecipentsState
        ),
        ewhRequestEmailRecipients: getCheckBoxesView(
          settings.ewhRequestEmailRecipients,
          true,
          setEWHRecipentsState
        ),
        ewhConfirmationEmailRecipients: getCheckBoxesView(
          settings.ewhConfirmationEmailRecipients,
          false,
          setEWHRecipentsState
        ),
        remoteWorkRequestEmailRecipients: getCheckBoxesView(
          settings.remoteWorkRequestEmailRecipients,
          true,
          setRemoteWorkRecipentsState
        ),
        remoteWorkConfirmationEmailRecipients: getCheckBoxesView(
          settings.remoteWorkConfirmationEmailRecipients,
          false,
          setRemoteWorkRecipentsState
        ),
      },
    ],
  };

  return <CustomTable tableData={tableData} striped={true} />;
}
