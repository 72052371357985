import React from "react";

import FilterFormView from "../../remote-work-application/views/FilterFormView";
import ApplicationCreateAction from "../../../shared-components/remote-application/actions/CreateAction";
import ApplicationTable from "../../remote-work-application/views/ApplicationTable";
import UserManager from "../../../navigation/UserManager";

const RemoteWorkApplicationView = (props) => {
  let actionsCallbacks = {
    onDelete: props.onDelete,
    onUpdate: props.onUpdate,
    onStatusUpdate: props.onStatusUpdate,
    onCancel: props.onCancel,
  };
  let user = UserManager.getUser();
  return (
    <>
      <div>
        <FilterFormView
          setSelectedUserId={props.setSelectedUserId}
          selectedUserId={props.selectedUserId}
          userId={props.userId}
          onUserChange={props.onUserChange}
          onCreateCallback={props.onCreate}
          onFilterAction={props.onFilter}
          users={props.users}
          onCreate={props.onCreate}
          remoteWorkApplicationTypes={props.remoteWorkApplicationTypes}
          remoteWorkApplicationStatusTypes={
            props.remoteWorkApplicationStatusTypes
          }
        >
          {!user.isTeamManagerVisualizingOtherMembers(props.selectedUserId) && (
            <ApplicationCreateAction
              onCreateCallback={props.onCreate}
              userId={props.userId}
              remoteWorkApplicationTypes={props.remoteWorkApplicationTypes}
            ></ApplicationCreateAction>
          )}
        </FilterFormView>

        <ApplicationTable
          isTeamManagerVisualizingOtherMembers={
            user.isTeamManagerVisualizingOtherMembers
          }
          selectedUserId={props.selectedUserId}
          userId={props.userId}
          actionsCallbacks={actionsCallbacks}
          remoteWorkApplications={props.remoteWorkApplications}
          remoteWorkApplicationStatusTypes={
            props.remoteWorkApplicationStatusTypes
          }
          remoteWorkApplicationTypes={props.remoteWorkApplicationTypes}
        />
      </div>
    </>
  );
};

export default RemoteWorkApplicationView;
