import React from "react";
import PropTypes from "prop-types";
import ClientApi from "../../../apis/endpoints/ClientApi";
import ClientUpsertModalForm from "../forms/ClientUpsertModalForm";
import { CefaloButton } from "../../../library/button";
import { Loading, toaster } from "../../../shared-components/notification";
import { defaultErrorResponseParser } from "../../../helpers/ErrorParser";
import { ClientDto } from "../../../models/ClientDto";

class ClientUpdateAction extends React.Component {
  static propTypes = {
    client: PropTypes.object.isRequired,
    onUpdateCallback: PropTypes.func.isRequired,
  };

  state = {
    show: false,
    isLoading: false,
    client: this.props.client,
  };

  toggle = (e) => () => {
    this.setState({ show: !this.state.show });
  };

  onUpdateAction = (data) => {
    this.setState({ isLoading: true });
    ClientApi.update(data)
      .then((res) => {
        this.setState({
          isLoading: false,
          show: false,
          client: new ClientDto(res.data),
        });
        toaster.success("Client Updated Successfully");
        this.props.onUpdateCallback(res.data);
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        defaultErrorResponseParser(err, "Failed To Update Client");
      });
  };

  render() {
    return (
      <div className="d-inline-block">
        <Loading show={this.state.isLoading} />
        <CefaloButton
          className="btn-font-12"
          size="sm"
          variant="primary"
          onClick={this.toggle()}
        >
          Edit
        </CefaloButton>
        <ClientUpsertModalForm
          formName="Edit Client"
          defaultValues={this.state.client}
          onSubmit={this.onUpdateAction}
          show={this.state.show}
          onToggle={this.toggle}
          keyAccountManagers={this.props.keyAccountManagers}
        />
      </div>
    );
  }
}

export default ClientUpdateAction;
