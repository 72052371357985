import React from "react";
import { checkIfUserOnLeave, checkIfUserOnRemoteWork } from "../../shared-components/attendance-status-view/AttendanceStatusView";
import styles from "./styles/DashboardView.module.css";
import CefaloIcon from "../../library/Icons/Icon";
import { FaCircle } from "react-icons/fa";
import { PresentStatus } from "../../shared-components/attendance-status-view/AttendanceStatusView";


const MemberEntry = ({ member }) => {
  return (
    <div className={`col-sm-2 ${styles.teamMember}`}>
      <PresentStatus entry={member} isDashboard={true} />
      <a className={styles.teamMemberUrl} href={`/profile/view/${member.id}`}>
        {member.name}
      </a>
    </div>
  );
};

const TeamMembersEntry = (props) => {
  let membersStatus = [];
  props.teamEntries.membersAttendanceStatus.forEach((member, index) => {
    membersStatus.push(<MemberEntry key={index} member={member} />);
  });
  let membersContainer = (
    <div className="container-fluid">
      <div className="row">{membersStatus}</div>
    </div>
  );
  return (
    <tbody>
      <tr
        className={styles["table-row"]}
        style={{ backgroundColor: "white !important" }}
      >
        <td className={`${styles.teamName}`}>
          <span>{props.teamEntries.teamName}</span>
        </td>
        <td>{membersContainer}</td>
      </tr>
    </tbody>
  );
};

const attendanceStatusCounter = (entries) => {
  let data = {
    total: 0,
    absent: 0,
    present: 0,
    remoteWork: 0,
    leave: 0,
  };
  let isUserAlreadyDisplayed = {};
  entries.forEach((team) => {
    team.membersAttendanceStatus.forEach((member) => {
      if (!!isUserAlreadyDisplayed[member.id]) {
        return;
      }
      isUserAlreadyDisplayed[member.id] = true;

      if (member.isPresent) {
        data.present = data.present + 1;
      } else if (
        checkIfUserOnRemoteWork(member.statusType).result
      ) {
        data.remoteWork = data.remoteWork + 1;
      } else if (checkIfUserOnLeave(member.statusType).result) {
        data.leave = data.leave + 1;
      } else {
        data.absent = data.absent + 1;
      }
      data.total = data.total + 1;
    });
  });
  return data;
};

const AttendanceCount = ({ entries }) => {
  let statusCount = attendanceStatusCounter(entries);
  return (
    <div className={styles["count-container"]}>
      <span className="mr-2">
        <span>
          {`Total: `}
          <b>{`${statusCount.total} |`}</b>
        </span>
      </span>
      <span className="mr-2">
        <CefaloIcon Icon={FaCircle} color="green" size="15px" />
        <span style={{ paddingLeft: "5px" }}>
          {`Present: `}
          <b>{` ${statusCount.present} |`}</b>
        </span>
      </span>
      <span className="mr-2">
        <CefaloIcon Icon={FaCircle} color="#FFBF00" size="15px" />
        <span style={{ paddingLeft: "5px" }}>
          {`Remote Work: `}
          <b>{` ${statusCount.remoteWork} |`}</b>
        </span>
      </span>
      <span className="mr-2">
        <CefaloIcon Icon={FaCircle} color="#BF40BF" size="15px" />
        <span style={{ paddingLeft: "5px" }}>
          {`Leave: `}
          <b>{` ${statusCount.leave} |`}</b>
        </span>
      </span>
      <span className="mr-2">
        <CefaloIcon Icon={FaCircle} color="red" size="15px" />
        <span style={{ paddingLeft: "5px" }}>
          {`Absent: `}
          <b>{statusCount.absent}</b>
        </span>
      </span>
    </div>
  );
};

const DashboardView = (props) => {
  let body = [];

  props.entries.forEach((item, index) => {
    body.push(<TeamMembersEntry teamEntries={item} key={index} />);
  });

  return (
    <div>
      <div>
        <table className="table table-bordered table-responsive">{body}</table>
      </div>
      <div>
        <AttendanceCount entries={props.entries} />
      </div>
    </div>
  );
};

export default DashboardView;
