import React from "react";
import Layout from "../../shared-components/layout/Layout";
import LeaveApplicationView from "./views/LeaveApplicationView";
import LeaveApplicationApi from "../../apis/endpoints/LeaveAppliactionApi";
import UserApi from "../../apis/endpoints/UserApi";
import SettingsApi from "../../apis/endpoints/SettingsApi";
import UserManager from "../../navigation/UserManager";
import { Helmet } from "react-helmet";
import { toaster, Loading } from "../../shared-components/notification";
import { LeaveApplicationDetailDto } from "../../models/LeaveApplication";
import { defaultErrorResponseParser } from "../../helpers/ErrorParser";
import { UserWithTeamDto } from "../../models/User";
import { DateOnlyString } from "../../helpers/Builders";

class LeaveApplicationContainer extends React.Component {
  constructor(props) {
    super(props);
    let userId = UserManager.getUser().id;
    this.state = {
      applications: [],
      users: [],
      userId: userId,
      fromDate: DateOnlyString(new Date(new Date().getFullYear(), 0, 1)),
      toDate: DateOnlyString(new Date(new Date().getFullYear(), 12, 31)),
      isLoading: false,
      leaveStatusTypes: null,
      leaveApplicationTypes: null,
      leaveSettings: null,
      selectedUserId: userId,
    };
  }

  setSelectedUserId = (selectedUserId) => this.setState({ selectedUserId });

  componentDidMount() {
    let user = UserManager.getUser();
    this.loadApplicationsData(user.id);
    this.loadLeaveApplicationTypes();
    this.loadLeaveSettings();
    this.loadLeaveStatusTypes();
    if (user.isAdminOrHr()) {
      this.loadUserData();
    } else if (user.hasTeamManagerPermission) {
      this.loadTeamMembersData(user.id);
    }
  }

  loadUserData = () => {
    this.setState({ isLoading: true });
    UserApi.all()
      .then((res) => {
        this.setState({
          isLoading: false,
          users: res.data.map((item) => new UserWithTeamDto(item)),
        });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        toaster.error("Failed to load User information");
      });
  };

  loadTeamMembersData = (userId) => {
    this.setState({ isLoading: true });
    UserApi.getTeamMembers(userId)
      .then((resp) => {
        this.setState({
          isLoading: false,
          users: resp.data.map((item) => new UserWithTeamDto(item)),
        });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        toaster.error("Failed to load Team Members information");
      });
  };

  loadApplicationsData = (userId) => {
    let options = {
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
      leaveType: this.state.leaveType ? this.state.leaveType : "",
    };

    this.setState({ isLoading: true });
    LeaveApplicationApi.getLeave(options, userId)
      .then((res) => {
        this.setState({
          isLoading: false,
          applications: res.data.map(
            (item) => new LeaveApplicationDetailDto(item)
          ),
        });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        defaultErrorResponseParser(err, "Failed To Load Leave Applications");
      });
  };

  loadLeaveStatusTypes = () => {
    LeaveApplicationApi.getAllLeaveStatus()
      .then((res) => {
        this.setState({
          leaveStatusTypes: res.data,
        });
      })
      .catch((err) => {
        defaultErrorResponseParser(err, "Failed To Load Leave Status Types");
      });
  };

  loadLeaveApplicationTypes = () => {
    LeaveApplicationApi.getAllLeaveType()
      .then((res) => {
        this.setState({
          leaveApplicationTypes: res.data,
        });
      })
      .catch((err) => {
        defaultErrorResponseParser(err, "Failed To Load Application Types");
      });
  };

  loadLeaveSettings = () => {
    SettingsApi.getLeaveSettings()
      .then((res) => {
        this.setState({ leaveSettings: res.data });
      })
      .catch((err) => {
        defaultErrorResponseParser(err, "Failed To Load Leave Settings");
      });
  };

  onApplicationCreate = (newApplications) => {
    let applications = this.state.applications;
    newApplications.forEach((item) => {
      applications.push(new LeaveApplicationDetailDto(item));
    });
    this.setState({ applications: applications });
  };

  onFilter = (data) => {
    this.setState({
      userId: data.userId,
      fromDate: data.fromDate,
      toDate: data.toDate,
      leaveType: data.leaveType,
    });
    this.loadApplicationsData(data.userId);
  };

  onUserChange = (userId) => {
    this.setState({ userId: userId });
    this.loadApplicationsData(userId);
  };

  onApplicationDelete = () => {
    this.loadApplicationsData(this.state.userId);
  };

  onApplicationStatusUpdate = () => {
    this.loadApplicationsData(this.state.userId);
  };

  onApplicationUpdate = (updatedApplications) => {
    let applications = this.state.applications;
    updatedApplications.forEach((updatedApplication) => {
      let index = applications.findIndex(
        (item) => item.id === updatedApplication.id
      );
      applications[index] = new LeaveApplicationDetailDto(updatedApplication);
    });
    this.setState({
      applications: applications,
    });
  };

  onApplicationCancel = () => {
    this.loadApplicationsData(this.state.userId);
  };

  checkIsComponentReadyToRender = () => {
    return (
      !!this.state.leaveSettings &&
      !!this.state.leaveStatusTypes &&
      !!this.state.leaveApplicationTypes
    );
  };

  render() {
    if (!this.checkIsComponentReadyToRender()) {
      return <></>;
    }
    return (
      <Layout>
        <Helmet>
          <title>Leave Applications- Cefalo</title>
        </Helmet>
        <div className="p-3">
          <Loading show={this.state.isLoading} />
          <LeaveApplicationView
            applications={this.state.applications}
            users={this.state.users}
            userId={this.state.userId}
            setSelectedUserId={this.setSelectedUserId}
            selectedUserId={this.state.selectedUserId}
            onCreate={this.onApplicationCreate}
            onUpdate={this.onApplicationUpdate}
            onDelete={this.onApplicationDelete}
            onCancel={this.onApplicationCancel}
            onStatusUpdate={this.onApplicationStatusUpdate}
            onFilter={this.onFilter}
            onUserChange={this.onUserChange}
            leaveStatusTypes={this.state.leaveStatusTypes}
            leaveApplicationTypes={this.state.leaveApplicationTypes}
            leaveSettings={this.state.leaveSettings}
          />
        </div>
      </Layout>
    );
  }
}

export default LeaveApplicationContainer;
