import React from "react";
import PropTypes from "prop-types";
import { Loading } from "../notification";
import {
  showErrorMessages,
  showSuccessMessages,
} from "../response-message/ResponseMessage";
import ConfirmationModal from "../modals/ConfirmationModal";

export default class DeleteActions extends React.Component {
  state = {
    isLoading: false,
  };

  onDelete = () => {
    const {
      successMessage = "Application is deleted successfully",
    } = this.props;
    this.setState({ isLoading: true });
    this.props
      .onDeleteCallback(this.props.applicationId)
      .then((res) => {
        showSuccessMessages(successMessage);
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        showErrorMessages(err.response.data);
      });
  };

  render() {
    const { message = "Are you sure to delete this application?" } = this.props;
    return (
      <div className="d-inline-block">
        <Loading show={this.state.isLoading} />
        <ConfirmationModal
          btnStyleClass="btn-font-12"
          message={message}
          toggleButtonText="Delete"
          toggleButtonColor="danger"
          doAction={this.onDelete}
        />
      </div>
    );
  }
}

DeleteActions.propTypes = {
  applicationId: PropTypes.number.isRequired,
  onDeleteCallback: PropTypes.func.isRequired,
};
