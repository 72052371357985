import React, { useState } from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { DateOnlyString } from "../../../helpers/Builders";
import { FormModal } from "../../../library/modal";
import { Col } from "../../../library/grid-layout";
import { CefaloButton } from "../../../library/button";
import { Form, DateRange, Select, Input } from "../../../library/form";
import { toaster } from "../../notification/Notification";
import { RemoteWorkTypesValueDictionary } from "../Consts";
import UserManager from "../../../navigation/UserManager";
import CefaloDatePicker from "../../../library/form/DatePicker";

const ApplicationCreateFormModal = ({
  fromDate,
  toDate,
  totalWorkingDays,
  show,
  onToggle,
  onCreate,
  periodSelectCallBack,
  remoteWorkApplicationTypes,
}) => {
  const { register, handleSubmit, errors, watch } = useForm();
  const [remoteWorkDate, setRemoteWorkDate] = useState(new Date());
  const [alternativeOfficeDate, setAlternativeOfficeDate] = useState(
    new Date()
  );

  const handleRemoteWorkDate = (date) => {
    setRemoteWorkDate(date);
  };
  const handleAlternativeOfficeDate = (date) => {
    setAlternativeOfficeDate(date);
  };

  const isWorkFromHome =
    parseInt(watch("remoteWorkApplicationType")) ===
    RemoteWorkTypesValueDictionary.WorkFromHome;

  const isExchangeOfficeDay =
    parseInt(watch("remoteWorkApplicationType")) ===
    RemoteWorkTypesValueDictionary.ExchangeOfficeDay;

  const checkIfValidRemoteWorkRequest = (remoteWorkApplicationData) => {
    let validationResponse = {
      result: true,
      errorMessage: "",
    };

    if (remoteWorkApplicationData.totalWorkingDays <= 0) {
      validationResponse.result = false;
      validationResponse.errorMessage =
        "Remote work application must include atleast one working day";
    }

    return validationResponse;
  };

  const onFormSubmit = (data) => {
    let applicationData = { ...data };

    if (isExchangeOfficeDay) {
      applicationData["fromDate"] = DateOnlyString(remoteWorkDate);
      applicationData["toDate"] = DateOnlyString(remoteWorkDate);
      applicationData["alternativeOfficeDate"] = DateOnlyString(
        alternativeOfficeDate
      );
    } else {
      applicationData["fromDate"] = DateOnlyString(fromDate);
      applicationData["toDate"] = DateOnlyString(toDate);
    }

    applicationData["type"] = Number.parseInt(
      data["remoteWorkApplicationType"]
    );
    applicationData["totalNumberOfWorkingDays"] = Number.parseFloat(
      data["totalNumberOfWorkingDays"]
    );

    const isValidRequest = checkIfValidRemoteWorkRequest(applicationData);

    if (isValidRequest.result) {
      onCreate(applicationData)
        .then((res) => {})
        .catch((err) => {});
    } else {
      toaster.error(isValidRequest.errorMessage);
    }
  };

  let remoteWorkApplicationTypesOptions = remoteWorkApplicationTypes.map(
    (i) => {
      return { label: i.displayName, value: i.value };
    }
  );

  return (
    <FormModal
      show={show}
      toggle={onToggle()}
      formName="Create Remote Work Application"
      onSubmit={handleSubmit(onFormSubmit)}
    >
      <div key={FormModal.ControlContainer}>
        <Form.Row>
          <Col sm>
            <Select
              className="form-inline"
              ref={register}
              name="remoteWorkApplicationType"
              options={remoteWorkApplicationTypesOptions}
              defaultValue={RemoteWorkTypesValueDictionary.ExchangeOfficeDay}
              onChange={(selectedOption) => {
                const selectedValue = selectedOption
                  ? selectedOption.value
                  : null;
                periodSelectCallBack(
                  fromDate,
                  toDate,
                  isWorkFromHome,
                  selectedValue
                );
              }}
              label="Remote Work Type"
            />
          </Col>
          <Col sm>
            <Input
              label="Total Working Days"
              type="number"
              name="totalNumberOfWorkingDays"
              disabled
              value={isExchangeOfficeDay ? 1 : totalWorkingDays}
              ref={register}
            />
          </Col>
        </Form.Row>

        {!isExchangeOfficeDay && (
          <Form.Row>
            <DateRange
              fromDate={fromDate}
              toDate={toDate}
              minDate={!UserManager.getUser().isAdminOrHr() ? new Date() : ""}
              onChange={({ fromDate, toDate }) => {
                periodSelectCallBack(fromDate, toDate, isWorkFromHome);
              }}
            />
          </Form.Row>
        )}
        {isExchangeOfficeDay && (
          <Form.Row>
            <Col>
              <Form.Group>
                <Form.Label>Remote Work Date</Form.Label>
                <CefaloDatePicker
                  date={remoteWorkDate}
                  onChange={(d) => handleRemoteWorkDate(d)}
                  minDate={
                    !UserManager.getUser().isAdminOrHr() ? new Date() : ""
                  }
                />
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group>
                <Form.Label>Alternative Office Date</Form.Label>
                <CefaloDatePicker
                  date={alternativeOfficeDate}
                  onChange={(d) => handleAlternativeOfficeDate(d)}
                  minDate={
                    !UserManager.getUser().isAdminOrHr() ? new Date() : ""
                  }
                />
              </Form.Group>
            </Col>
          </Form.Row>
        )}

        <Input
          containerClassName="mt-2"
          label={
            isExchangeOfficeDay
              ? "Reason For Exchange"
              : "Reason For Remote Work"
          }
          as="textarea"
          name="description"
          placeholder="Reason..."
          ref={register({ required: "Reason is required" })}
          errors={errors}
        />
      </div>
      <div key={FormModal.ActionContainer}>
        <CefaloButton size="sm" variant="light" onClick={onToggle()}>
          Close
        </CefaloButton>
        <CefaloButton variant="primary" size="sm" type="submit">
          Save
        </CefaloButton>
      </div>
    </FormModal>
  );
};

ApplicationCreateFormModal.prototype = {
  fromDate: PropTypes.objectOf(Date).isRequired,
  toDate: PropTypes.objectOf(Date).isRequired,
  totalWorkingDays: PropTypes.number.isRequired,
  show: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  periodSelectCallBack: PropTypes.func.isRequired,
  remoteWorkApplicationTypes: PropTypes.arrayOf(Object).isRequired,
};

export default ApplicationCreateFormModal;
