import UserManager from "../UserManager";
import styles from "./styles/Nav.module.css";

function getGuestNavOptions() {
  let navOptions = [
    {
      navInfo: {
        title: "Members Directory",
        identifier: "Members Directory",
        path: "/UserProfile/Index",
      },
    },
    {
      navInfo: {
        title: "Public Holidays",
        identifier: "Public Holidays",
        path: "/HolidayInfo/Index",
      },
    },
    {
      navInfo: {
        title: "Knowledge Sharing Sessions",
        identifier: "Knowledge Sharing Sessions",
        path: "/KnowledgeSharingSessions",
      },
    },
    {
      navInfo: {
        title: "HR Policy",
        identifier: "HR Policy",
        path: "/Policy/Hr",
      },
    },
    {
      navInfo: {
        title: "IT Policy",
        identifier: "IT Policy",
        path: "/Policy/It",
      },
    },
  ];
  return navOptions;
}

function getSuperUserNavOptions() {
  let navOptions = [];
  navOptions.push({
    isDropdown: true,
    navInfo: {
      title: "Attendance",
      identifier: "Attendance",
      items: [
        { title: "Report", identifier: "Report", path: "/Report/Index" },
        { title: "Data Sync", identifier: "Sync", path: "/Report/Sync" },
      ],
    },
  });
  navOptions.push({
    isDropdown: true,
    navInfo: {
      title: "Leaves",
      identifier: "leaves",
      items: [
        {
          title: "Application",
          identifier: "Leave Application",
          path: "/LeaveApplication/Index",
        },
        {
          title: "Supervision",
          identifier: "Leave Supervision",
          path: "/LeaveApplication/Supervision",
        },
      ],
    },
  });
  navOptions.push({
    isDropdown: true,
    navInfo: {
      title: "Extra Working Hours",
      identifier: "Extra Working Hours",
      items: [
        {
          title: "Application",
          identifier: "Extra Application",
          path: "/ExtraWorkingDayApplication/Index",
        },
        {
          title: "Supervision",
          identifier: "Extra Supervision",
          path: "/ExtraWorkingDayApplication/Supervision",
        },
      ],
    },
  });
  navOptions.push({
    isDropdown: true,
    navInfo: {
      title: "Remote Work",
      identifier: "Remote Work",
      items: [
        {
          title: "Application",
          identifier: "Extra Application",
          path: "/WorkingFromOutsideApplication/Index",
        },
        {
          title: "Supervision",
          identifier: "Extra Supervision",
          path: "/WorkingFromOutsideApplication/Supervision",
        },
      ],
    },
  });
  navOptions.push({
    isDropdown: true,
    navInfo: {
      title: "Fund Requests",
      identifier: "Fund Requests",
      items: [
        {
          isSubMenu: true,
          title: "Skill Development",
          identifier: "Skill Development Fund Request",
          items: [
            {
              title: "Application",
              identifier: "Skill Development Fund Application",
              path: "/FundRequest/SkillDevelopment/Index",
            },
            {
              title: "Supervision",
              identifier: "Skill Development Fund Supervision",
              path: "/FundRequest/SkillDevelopment/Supervision",
            },
          ],
        },
      ],
    },
  });
  navOptions.push({
    isDropdown: true,
    navInfo: {
      title: "Travel NOC",
      identifier: "noc",
      items: [
        {
          title: "Application",
          identifier: "Noc Application",
          path: "/NocApplication/Index",
        },
        {
          title: "Supervision",
          identifier: "Noc Supervision",
          path: "/NocApplication/Supervision",
        },
      ],
    },
  });
  navOptions.push({
    isDropdown: true,
    navInfo: {
      title: "Miscellaneous",
      identifier: "Miscellaneous",
      items: [
        {
          title: "Public Holidays",
          identifier: "Public Holidays",
          path: "/HolidayInfo/Index",
        },
        {
          title: "Members Directory",
          identifier: "Members Directory",
          path: "/UserProfile/Index",
        },
        {
          title: "Knowledge Sharing Sessions",
          identifier: "Knowledge Sharing Sessions",
          path: "/KnowledgeSharingSessions",
        },
        { title: "HR Policy", identifier: "HR Policy", path: "/Policy/Hr" },
        { title: "IT Policy", identifier: "IT Policy", path: "/Policy/It" },
      ],
    },
  });
  navOptions.push({
    isDropdown: true,
    navInfo: {
      title: "Manage",
      identifier: "Manage",
      items: [
        { title: "Teams", identifier: "Teams", path: "/Team/Index" },
        { title: "Users", identifier: "Users", path: "/UserInfo/Index" },
        { title: "Clients", identifier: "Clients", path: "/ClientInfo/Index" },
        {
          title: "Settings",
          identifier: "Settings",
          path: "/Settings/Index",
        },
        {
          title: "Skills",
          identifier: "Skills",
          path: "/Skill/Index",
        },
        {
          title: "Trainings",
          identifier: "Trainings",
          path: "/Training/Index"
        },
        {
          title: "Audit Log",
          identifier: "AuditLog",
          path: "/AuditLog/Index",
        },
        {
          title: "Leave Data Upload",
          identifier: "Leave Data Upload",
          path: "/LeaveApplication/DataUpload",
        },
        {
          title: "Carry Forward Data",
          identifier: "Carry Forward Data",
          path: "/LeaveApplication/EscalateCarryForwardData",
        },
        {
          title: "Leave Days Report",
          identifier: "Leave Days Report",
          path: "/LeaveApplication/LeaveReport",
        },
      ],
    },
  });
  navOptions.push({
    isDropdown: true,
    navInfo: {
      title: "Export Data",
      identifier: "Export Data",
      items: [
        {
          title: "Billing",
          identifier: "Billing",
          path: "/BillingInfo/Index",
        },
        {
          title: "Employee Data",
          identifier: "Available Employee Data",
          path: "/Export/EmpoyeeInfo/Index",
        },
        {
          title: "Available Employee Data",
          identifier: "Employee Data",
          path: "/Export/AvailableEmployeeInfo/Index",
        },
        {
          title: "Leave Statistics",
          identifier: "Leave Statistics",
          path: "/LeaveInfo/Index",
        },
        {
          title: "Leave Statistics Per Client",
          identifier: "Leave Statistics Per Client",
          path: "/LeaveInfo/Client/Index",
        },
      ],
    },
  });
  return navOptions;
}

function getStandardUserNavoptions(user) {
  let navOptions = [];
  navOptions.push({
    navInfo: {
      title: "Attendance",
      identifier: "Report",
      path: `/Report/Index/${user.id}`,
    },
  });
  navOptions.push({
    navInfo: {
      title: "Leaves",
      identifier: "Leaves",
      path: "/LeaveApplication/Index",
    },
  });
  navOptions.push({
    navInfo: {
      title: "Extra Working Hours",
      identifier: "Extra Working Hours",
      path: "/ExtraWorkingDayApplication/Index",
    },
  });
  navOptions.push({
    navInfo: {
      title: "Remote Work",
      identifier: "Extra Application",
      path: "/WorkingFromOutsideApplication/Index",
    },
  });
  navOptions.push({
    isDropdown: true,
    navInfo: {
      title: "Fund Requests",
      identifier: "Fund Requests",
      items: [
        {
          title: "Skill Development",
          identifier: "Skill Development Fund Application",
          path: "/FundRequest/SkillDevelopment/Index",
        },
      ],
    },
  });
  navOptions.push({
    navInfo: {
      title: "Travel NOC",
      identifier: "Noc",
      path: "/NocApplication/Index",
    },
  });
  navOptions.push({
    isDropdown: true,
    navInfo: {
      title: "Miscellaneous",
      identifier: "Miscellaneous",
      items: [
        {
          title: "Public Holidays",
          identifier: "Public Holidays",
          path: "/HolidayInfo/Index",
        },
        {
          title: "Members Directory",
          identifier: "Members Directory",
          path: "/UserProfile/Index",
        },
        {
          title: "Knowledge Sharing Sessions",
          identifier: "Knowledge Sharing Sessions",
          path: "/KnowledgeSharingSessions",
        },
        { title: "HR Policy", identifier: "HR Policy", path: "/Policy/Hr" },
        { title: "IT Policy", identifier: "IT Policy", path: "/Policy/It" },
      ],
    },
  });
  return navOptions;
}

export function getNavOptions() {
  let user = UserManager.getUser();
  if (!!!user) {
    return [];
  }

  let navOptions = [];
  if (user.isGuestUser()) {
    navOptions = getGuestNavOptions();
  } else if (user.isAdminOrHr()) {
    navOptions = getSuperUserNavOptions();
  } else {
    navOptions = getStandardUserNavoptions(user);
  }

  // in case the token is corrupted/malformed, then
  // forcefully logout the user
  if (!user || !user.name) {
    window.localStorage.clear();
    window.sessionStorage.clear();
    window.location.href = "/Login";
  }
  let displayName = `Welcome ${user.name.trim().split(" ")[0]}`;
  navOptions.push({
    isDropdown: true,
    navInfo: {
      title: displayName,
      identifier: user.name,
      items: [
        {
          title: "My Profile",
          identifier: "My Profile",
          path: `/profile/view/${user.id}`,
        },
        { title: "Logout", identifier: "Logout", path: "/logout" },
      ],
    },
  });

  return navOptions;
}

export function getNavItemStyleClass(selected = false) {
  let styleClassName = styles.navItem;
  if (selected) styleClassName += ` ${styles.activeNavItem}`;

  return styleClassName;
}

export function getDropdownItemStyleClass(active = false) {
  let styleClassName = styles.dropDownItem;
  if (active) styleClassName += ` ${styles.activeDropDownItem}`;

  return styleClassName;
}

export function getPathsActiveness(activePath, pathInfos = []) {
  let pathsActivenessMapper = {};
  let isAnyPathActive = false;

  pathInfos.forEach((info) => {
    if (info.isSubMenu && Array.isArray(info.items)) {
      info.items.forEach(subMenu => pathsActivenessMapper[subMenu.path] = isPathActive(activePath, subMenu.path))
    } else {
      pathsActivenessMapper[info.path] = isPathActive(activePath, info.path);
    }
    if (pathsActivenessMapper[info.path]) {
      isAnyPathActive = true;
    }
  });
  return { pathsActivenessMapper, isAnyPathActive };
}

export function isPathActive(activePath, path) {
  if (activePath === path) {
    return true;
  } else {
    return false;
  }
}
