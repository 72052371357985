import React from "react";
import PropTypes from "prop-types";

import { getTableFormatDate } from "../../../helpers/DateUtility";

import SortUtility from "../../../helpers/SortUtility";
import { DataTable } from "../../../library/table/Index";
import { RemoteWorkApplicationDetailDto } from "../../../models/RemoteWorkApplication";
import RowActions from "../../../shared-components/remote-application/actions/RowActions";

let dataTableColumns = [
  {
    text: "Application Date",
    dataField: "created",
    sort: true,
    sortFunc: SortUtility.dateSorter,
  },
  {
    text: "From Date",
    dataField: "fromDate",
    sort: true,
    sortFunc: SortUtility.dateSorter,
  },
  {
    text: "To Date",
    dataField: "toDate",
    sort: true,
    sortFunc: SortUtility.dateSorter,
  },
  {
    text: "Remote Work Type",
    dataField: "remoteWorkType",
    sort: true,
  },
  {
    text: "Working Days",
    dataField: "totalNumberOfWorkingDays",
    sort: true,
  },
  {
    text: "Alternative Office Date",
    dataField: "alternativeOfficeDate",
    sort: true,
  },
  {
    text: "Remote Work Status",
    dataField: "status",
    sort: true,
  },
  {
    text: "Action",
    dataField: "actions",
    sort: false,
  },
];

let sortedOrder = [
  {
    dataField: "fromDate",
    order: "desc",
  },
  {
    dataField: "toDate",
    order: "desc",
  },
];

const ApplicationTable = ({
  remoteWorkApplications,
  actionsCallbacks,
  userId,
  remoteWorkApplicationTypes,
  remoteWorkApplicationStatusTypes,
  selectedUserId,
  isTeamManagerVisualizingOtherMembers,
}) => {
  let rows = [];
  remoteWorkApplications.forEach((item) => {
    if (item instanceof RemoteWorkApplicationDetailDto) {
      let tempRow = {};
      tempRow["created"] = getTableFormatDate(item.created);
      tempRow["fromDate"] = getTableFormatDate(item.fromDate);
      tempRow["toDate"] = getTableFormatDate(item.toDate);
      tempRow["remoteWorkType"] = item.remoteWorkTypeDisplayName;
      tempRow["totalNumberOfWorkingDays"] = item.totalNumberOfWorkingDays;
      tempRow["alternativeOfficeDate"] =
        item.alternativeOfficeDate !== undefined
          ? getTableFormatDate(item.alternativeOfficeDate)
          : "";
      tempRow["status"] = item.remoteWorkStatusDisplayName;
      tempRow["actions"] = (
        <RowActions
          remoteWorkApplicationStatusTypes={remoteWorkApplicationStatusTypes}
          remoteWorkApplicationTypes={remoteWorkApplicationTypes}
          callbacks={actionsCallbacks}
          application={item}
          userId={userId}
          includeCancel={true}
        />
      );
      rows.push(tempRow);
    }
  });

  let permittedDataColumns = dataTableColumns;
  if (isTeamManagerVisualizingOtherMembers(selectedUserId)) {
    permittedDataColumns = dataTableColumns.slice(0, -1);
  }

  return (
    <DataTable
      defaultSorted={sortedOrder}
      rowMutedDateColumnName="toDate"
      initialPageSize={10}
      rows={rows}
      columns={permittedDataColumns}
    />
  );
};
ApplicationTable.prototype = {
  remoteWorkApplications: PropTypes.arrayOf(RemoteWorkApplicationDetailDto)
    .isRequired,
  actionsCallbacks: PropTypes.arrayOf(Function).isRequired,
  remoteWorkApplicationStatusTypes: PropTypes.arrayOf(Object).isRequired,
  remoteWorkApplicationTypes: PropTypes.arrayOf(Object).isRequired,
};

export default ApplicationTable;
