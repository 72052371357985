import React from "react";
import { MultiSelect } from "react-multi-select-component";
import TeamApi from "../../../apis/endpoints/TeamApi";
import { defaultErrorResponseParser } from "../../../helpers/ErrorParser";
import PropTypes from "prop-types";
import styles from "./styles/Teams.module.css";

export default class TeamDropdown extends React.Component {
  overrideStrings = {
    allItemsAreSelected: "All teams are selected.",
    clearSearch: "Clear Search",
    noOptions: "No options",
    search: "Search",
    selectAll: "Select All",
    selectSomeItems: "Select a team...",
  };

  static propTypes = {
    onTeamSelect: PropTypes.func.isRequired,
  };

  state = {
    isLoading: false,
    options: [],
    selected: [],
  };

  componentDidMount() {
    this.props.onRef(this);
    TeamApi.all(1, 100)
      .then((res) => {
        let options = [];
        options = options.concat(
          res.data.flatMap((t) => {
            return { value: t.id, label: t.name };
          })
        );
        this.setState({
          options: options,
        });
      })
      .catch((err) => {
        defaultErrorResponseParser(err, "Failed to generate team dropdown");
      });
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  getSelectedTeamIds = () => {
    var selectedTeamIds = "";
    this.state.selected.forEach(function (element) {
      selectedTeamIds += element.value + ",";
    });
    return selectedTeamIds.replace(/,\s*$/, "");
  };

  setSelected = (selectedList) => {
    this.props.onTeamSelect(selectedList);
    this.setState({ selected: selectedList });
  };

  clearSelected = () => {
    this.setState({ selected: [] });
    this.setState({ options: [] });
  };

  render() {
    return (
      <div className="form-group">
        <label className="form-label">Teams</label>
        <MultiSelect
          className={styles["rmsc"]}
          hasSelectAll={false}
          overrideStrings={this.overrideStrings}
          options={this.state.options}
          value={this.state.selected}
          onChange={this.setSelected}
          labelledBy="Select.."
        />
      </div>
    );
  }
}
