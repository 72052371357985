import React from "react";
import { Form, Table, Row, Col, Container } from "react-bootstrap";
import PropTypes from "prop-types";

const SummaryTable = ({ fundSummary }) => {
  return (
    <div className="table-striped">
      <Table bordered className="table-sm">
        <thead>
          <tr>
            <th>Total Annual Fund</th>
            <th>Allocated Fund</th>
            <th>Availed Fund</th>
            <th>Remaining Fund</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{fundSummary?.totalFundAllocated ?? 0} BDT</td>
            <td>{fundSummary?.fundAllocated ?? 0} BDT</td>
            <td>{fundSummary?.fundAvailed ?? 0} BDT</td>
            <td>{fundSummary?.fundAvailable ?? 0} BDT</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

function SkillDevelopmentFundSummaryComponent({ fundSummary, callbacks }) {
  const [currentYear] = React.useState(new Date().getFullYear());
  const onYearSelection = React.useCallback((e) => {
    const selectedYear = Number.parseInt(e.target.value);
    e.preventDefault();
    e.stopPropagation();
    callbacks.onSummaryYearChange(selectedYear)
  }, [callbacks]);
  return (
    <Container className="p-0" fluid>
      <Row className="mb-2 ml-0">
        <h6
          className="d-inline-block pr-2"
          style={{ lineHeight: "30px", fontSize: "18px" }}
        >
          Fund Summary
        </h6>
        <div className="inline-block">
          <Form.Control
            as="select"
            size="sm"
            value={fundSummary?.year ?? currentYear}
            className="d-inline-block"
            onChange={onYearSelection}
          >
            <option value={currentYear}>{currentYear}</option>
            <option value={currentYear - 1}>{currentYear - 1}</option>
          </Form.Control>
        </div>
      </Row>
      <Row className="pl-3">
        <Col className="p-0" sm="6">
          <SummaryTable fundSummary={fundSummary} />
        </Col>
      </Row>
    </Container>
  );
}

SkillDevelopmentFundSummaryComponent.propTypes = {
  callbacks: PropTypes.object.isRequired,
}

export const SkillDevelopmentFundSummary = SkillDevelopmentFundSummaryComponent;
