import React from "react";
import {
  LeaveSettingsUpperTableCol,
  LeaveSettingsLowerTableCol,
} from "../Const";
import { Form } from "../../../../library/form";
import CustomTable from "../../../../shared-components/table/CustomTable";
import { GetValueOrDefault } from "../../../../shared-components/controlled-input-value/GetValueOrDefault";

export default function LeaveSettingsView({ settings, onChangeCrudObject }) {
  const {
    casualLeavePerMonth,
    sickLeaveWithoutDocPerYear,
    totalSickLeavePerYear,
    maxForwardedLeave,
    allowedCancellationPeriod,
    maternityLeavePerYearInMonths,
    paternityLeavePerYearInDays,
  } = settings;

  const upperTableData = {
    columns: LeaveSettingsUpperTableCol,
    rows: [
      {
        casualLeavePerMonth: (
          <Form.Control
            type="number"
            step={0.5}
            name="casualLeavePerMonth"
            value={GetValueOrDefault(casualLeavePerMonth, 0)}
            required
            onChange={onChangeCrudObject}
            size="sm"
          />
        ),
        sickLeaveWithoutDocPerYear: (
          <Form.Control
            type="number"
            name="sickLeaveWithoutDocPerYear"
            step={0.5}
            value={GetValueOrDefault(sickLeaveWithoutDocPerYear, 0)}
            required
            onChange={onChangeCrudObject}
            size="sm"
          />
        ),
        totalSickLeavePerYear: (
          <Form.Control
            type="number"
            name="totalSickLeavePerYear"
            step={0.5}
            value={GetValueOrDefault(totalSickLeavePerYear, 0)}
            required
            onChange={onChangeCrudObject}
            size="sm"
          />
        ),
        maxForwardedLeave: (
          <Form.Control
            type="number"
            name="maxForwardedLeave"
            step={0.5}
            value={GetValueOrDefault(maxForwardedLeave, 0)}
            required
            onChange={onChangeCrudObject}
            size="sm"
          />
        ),
      },
    ],
  };

  const lowerTableData = {
    columns: LeaveSettingsLowerTableCol,
    rows: [
      {
        allowedCancellationPeriod: (
          <Form.Control
            type="number"
            name="allowedCancellationPeriod"
            step={1}
            value={GetValueOrDefault(allowedCancellationPeriod, 0)}
            required
            onChange={onChangeCrudObject}
            size="sm"
          />
        ),
        maternityLeavePerYearInMonths: (
          <Form.Control
            type="number"
            name="maternityLeavePerYearInMonths"
            step={1}
            value={GetValueOrDefault(maternityLeavePerYearInMonths, 0)}
            required
            onChange={onChangeCrudObject}
            size="sm"
          />
        ),

        paternityLeavePerYearInDays: (
          <Form.Control
            type="number"
            name="paternityLeavePerYearInDays"
            step={1}
            value={GetValueOrDefault(paternityLeavePerYearInDays, 0)}
            required
            onChange={onChangeCrudObject}
            size="sm"
          />
        ),
      },
    ],
  };

  return (
    <>
      <CustomTable tableData={upperTableData} striped={true} />
      <CustomTable tableData={lowerTableData} striped={true} />
    </>
  );
}
