import Resizer from "react-image-file-resizer";

const resizeFile = (file) =>
  new Promise((resolve) => {
  Resizer.imageFileResizer(
    file,
    1024,
    1024,
    "JPEG",
    100,
    0,
    (uri) => {
      resolve(uri);
    },
    "base64"
  );
});

const ResizeIamgeAction = {
    resizeFile
}

export default ResizeIamgeAction;