import React from "react";
import PropTypes from "prop-types";
import { FormModal } from "../../../library/modal/Index";
import { CefaloButton } from "../../../library/button/Index";
import { SkillDevelopmentRequestDto } from "../../../models/SkillDevelopmentFund";
import { Input } from "../../../library/form";
import { useForm } from "react-hook-form";

function RequestCancelActionComponent({ request, onCancel }) {
  const { id: requestId } = request;
  const [show, setShow] = React.useState(false);
  const toggleShow = React.useCallback(() => setShow(state => !state), [setShow]);
  const { register, handleSubmit, errors } = useForm({
    defaultValues: {},
  });
  const onSubmit = React.useCallback((data) => {
    toggleShow();
    onCancel(requestId, data.reason);
  }, [requestId, onCancel, toggleShow]);

  return (
    <div className="d-inline-block">
      <FormModal
        formName="Cancel Request"
        onSubmit={handleSubmit(onSubmit)}
        show={show}
        toggle={toggleShow}
      >
        <div key={FormModal.ControlContainer}>
          <p>Are you sure to cancel this request?</p>
          <Input
            containerClassName="mt-2"
            label="Reason"
            as="textarea"
            name="reason"
            placeholder="Reason to cancel the request..."
            ref={register({ required: "Reason is required" })}
            errors={errors}
          />
        </div>
        <div key={FormModal.ActionContainer}>
          <CefaloButton
            size="sm"
            variant="danger"
            onClick={toggleShow}
            type="button"
          >
            No
          </CefaloButton>
          <CefaloButton size="sm" variant="success" type="submit">
            Yes
          </CefaloButton>
        </div>
      </FormModal>
      <CefaloButton
        className="btn-font-12"
        size="sm"
        variant="danger"
        onClick={toggleShow}
      >
        Cancel
      </CefaloButton>
    </div>
  );
}

RequestCancelActionComponent.propTypes = {
  request: PropTypes.instanceOf(SkillDevelopmentRequestDto).isRequired,
  onCancel: PropTypes.func.isRequired,
};

export const RequestCancelAction = RequestCancelActionComponent;