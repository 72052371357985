import React, { Component } from "react";
import NocApplicationView from "./NocApplicationView";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import UserManager from "../../navigation/UserManager";
import { DateOnlyString } from "../../helpers/Builders";
import { getCurrentYearStartDate } from "../../helpers/DateUtility";
import { getCurrentYearEndDate } from "../../helpers/DateUtility";
import NocApplicationApi from "../../apis/endpoints/NocApplicationApi";
import WithCrudActionsOnArray from "../../shared-components/hoc/crud/WithCrudActionsOnArray";
import ProfileApi from "../../apis/endpoints/ProfileApi";
import { setConstants } from "./Const";

class NocApplicationContainer extends Component {
  state = {
    userId: UserManager.getUser().id,
    constSetted: false,
    isLoading: false,
    userProfile: [],
  };

  toggleOnLoader = () => this.setState({ isLoading: true });
  toggleOffLoader = () => this.setState({ isLoading: false });

  componentDidMount() {
    let { userId } = this.state;
    let fromDate = DateOnlyString(getCurrentYearStartDate());
    let toDate = DateOnlyString(getCurrentYearEndDate());
    let filter = {
      fromDate,
      toDate,
    };

    this.loadUserProfile(userId);
    this.loadNocApplications(filter, userId);
  }

  loadNocApplications = (filter, userId) => {
    this.toggleOnLoader();
    NocApplicationApi.getAllByUser(filter, userId)
      .then((resp) => {
        this.toggleOffLoader();
        this.props.setArrayStateCallback(resp.data);
      })
      .catch(() => this.toggleOffLoader());

    setConstants(() => this.setState({ constSetted: !this.state.constSetted }));
  };

  loadUserProfile = (id) => {
    ProfileApi.get(id)
      .then((res) => {
        this.setState({
          isLoading: false,
          userProfile: res.data,
        });
      })
      .catch((err) => {});
  };

  onFilter = ({ fromDate, toDate }, userId) => {
    let applicationFilter = {
      fromDate,
      toDate,
    };
    return NocApplicationApi.getAllByUser(applicationFilter, userId).then(
      (resp) => this.props.setArrayStateCallback(resp.data)
    );
  };

  onChangeUser = (userId) => this.setState({ userId });

  actionsCallbacks = {
    ...this.props.arrayCrudCallbacks,
    onFilter: this.onFilter,
  };

  onApplicationCancel = () => {
    let fromDate = DateOnlyString(getCurrentYearStartDate());
    let toDate = DateOnlyString(getCurrentYearEndDate());
    let filter = {
      fromDate,
      toDate,
    };
    this.loadNocApplications(filter, this.state.userId);
  }

  render() {
    const { crudArray: applications } = this.props;
    const { userId, isLoading, userProfile } = this.state;
    return (
      <>
        <Helmet>
          <title>Noc Applications- Cefalo</title>
        </Helmet>
        <NocApplicationView
          applications={applications}
          userId={userId}
          isLoading={isLoading}
          isSupervision={false}
          onChangeUser={this.onChangeUser}
          loadUserProfile={this.loadUserProfile}
          actionsCallbacks={this.actionsCallbacks}
          onCancelCallback={this.onApplicationCancel}
          profile={userProfile}
        />
      </>
    );
  }
}

export default WithCrudActionsOnArray(NocApplicationApi)(
  NocApplicationContainer
);

NocApplicationContainer.propTypes = {
  setArrayStateCallback: PropTypes.func.isRequired,
  crudArray: PropTypes.array.isRequired,
  arrayCrudCallbacks: PropTypes.object.isRequired,
};
