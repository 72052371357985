import React from "react";
import ExtraWorkForm from "../forms/ExtraWorkForm";
import { Form, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import { CefaloButton } from "../../../library/button";

export default class UpdateApplication extends React.Component {
  state = {
    show: false,
  };

  toggle = (e) => () => {
    this.setState({ show: !this.state.show });
  };
  onSubmit = ({
    id,
    fromDate,
    toDate,
    totalHours,
    adjustmentType,
    description,
    paid,
    userId,
    notBillableToCustomer,
    extraWorkTeamId,
    extraWorkTeamName,
  }) => {
    let application = {
      id,
      fromDate,
      toDate,
      totalHours,
      adjustmentType,
      description,
      paid,
      userId,
      extraWorkTeamId,
      extraWorkTeamName,
    };
    if (adjustmentType === 1) {
      application = {
        ...application,
        notBillableToCustomer,
      };
    }
    if (extraWorkTeamId === 0) {
      application.extraWorkTeamId = this.props.teamNames[0]?.value;
    }
    return this.props.onUpdateCallback(id, application).then((res) => {
      this.setState({ show: false });
      return res;
    });
  };

  render() {
    const { show } = this.state;
    const { application } = this.props;
    return (
      <div className="d-inline-block">
        <CefaloButton
          className="btn-font-12"
          variant="info"
          size="sm"
          onClick={this.toggle()}
        >
          Edit
        </CefaloButton>
        <ExtraWorkForm
          onToggle={this.toggle}
          show={show}
          action={"UPDATE"}
          submit={this.onSubmit}
          application={application}
          teamNames={this.props.teamNames}
          header={"Edit Application"}
          successMessage="Extra working day application Updated Successfully"
        >
          {this.getPreview()}
        </ExtraWorkForm>
      </div>
    );
  }

  getPreview = () => {
    const { application } = this.props;
    return (
      <Form.Row>
        <Col>
          <Form.Group>
            <Form.Label>Applicant</Form.Label>
            <Form.Control
              size="sm"
              readOnly
              value={application.applicant.name}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Team</Form.Label>
            <Form.Control
              size="sm"
              readOnly
              value={application.applicant.teamName}
            />
          </Form.Group>
        </Col>
      </Form.Row>
    );
  };
}

UpdateApplication.propTypes = {
  onUpdateCallback: PropTypes.func.isRequired,
  application: PropTypes.object.isRequired,
};
