import React from "react";
import { DataTable } from "../../library/table";
import SortUtility from "../../helpers/SortUtility";

export const LeaveDataTable = ({ rows }) => {
  let columns = [
    {
      text: "Email",
      dataField: "email",
      sort: true,
    },
    {
      text: "Leave Type",
      dataField: "leaveTypeDisplayName",
      sort: true,
    },
    {
      text: "From Date",
      dataField: "fromDate",
      sort: true,
      sortFunc: SortUtility.dateSorter,
    },
    {
      text: "To Date",
      dataField: "toDate",
      sort: true,
      sortFunc: SortUtility.dateSorter,
    },
    {
      text: "Total Days",
      dataField: "totalDays",
      sort: true,
    },
    {
      text: "Comments",
      dataField: "comments",
      sort: true,
    },
  ];

  return <DataTable rows={rows} columns={columns} />;
};

export const CarryForwardDataTable = ({ rows }) => {
  let columns = [
    {
      text: "Year",
      dataField: "year",
      sort: true,
    },
    {
      text: "Email",
      dataField: "email",
      sort: true,
    },
    {
      text: "Leave Type",
      dataField: "leaveTypeDisplayName",
      sort: true,
    },
    {
      text: "Total Days",
      dataField: "totalDays",
      sort: true,
    },
    {
      text: "Comments",
      dataField: "comments",
      sort: true,
    },
  ];
  return <DataTable rows={rows} columns={columns} />;
};

export const ExtraWorkingDayDataTable = ({ rows }) => {
  let columns = [
    {
      text: "Email",
      dataField: "email",
      sort: true,
    },
    {
      text: "EWD Type",
      dataField: "extraWorkingDayType",
      sort: true,
    },
    {
      text: "From Date",
      dataField: "fromDate",
      sort: true,
      sortFunc: SortUtility.dateSorter,
    },
    {
      text: "To Date",
      dataField: "toDate",
      sort: true,
      sortFunc: SortUtility.dateSorter,
    },
    {
      text: "Total Working Hours",
      dataField: "totalHours",
      sort: true,
    },
  ];
  return <DataTable rows={rows} columns={columns} />;
};

export const DefaultPreviewTable = () => {
  let columns = [
    {
      text: "Year",
      dataField: "year",
      sort: true,
    },
    {
      text: "Email",
      dataField: "email",
      sort: true,
    },
    {
      text: "Leave Type",
      dataField: "leaveType",
      sort: true,
    },
    {
      text: "Total Days",
      dataField: "totalDays",
      sort: true,
    },
    {
      text: "Comments",
      dataField: "comments",
      sort: true,
    },
  ];

  return <DataTable rows={[]} columns={columns} />;
};
