import React from "react";
import PropTypes from "prop-types";
import UserManager from "../../../navigation/UserManager";
import { RemoteWorkApplicationDetailDto } from "../../../models/RemoteWorkApplication";
import { RemoteWorkStatusValueDictionary } from "../Consts";
import { v4 as uuidv4 } from "uuid";
import { isDateEqualToOrGreaterThanToday } from "../../../helpers/DateUtility";

import ApplicationUpdateAction from "../../remote-application/actions/UpdateAction";
import ApplicationRespondAction from "./RespondAction";
import ApplicationDeleteAction from "./DeleteAction";
import ApplicationCancelAction from "./CancelAction";

const checkIfApplicationIsEditable = (status) => {
  return !(
    status === RemoteWorkStatusValueDictionary.Rejected ||
    status === RemoteWorkStatusValueDictionary.CancelRequestRejected ||
    status === RemoteWorkStatusValueDictionary.Canceled ||
    status === RemoteWorkStatusValueDictionary.CancelByUser
  );
};

const checkIfUserCanCancelApplication = (
  application = new RemoteWorkApplicationDetailDto(),
  remoteWorkApplicationStatuses
) => {
  let remoteWorkApplicationStatusDisplayName =
    remoteWorkApplicationStatuses.find(
      (s) => s.value === application.status
    ).name;

  return (
    (remoteWorkApplicationStatusDisplayName === "Requested" ||
      remoteWorkApplicationStatusDisplayName === "Approved") &&
    isDateEqualToOrGreaterThanToday(application.fromDate)
  );
};

const checkIfApplicationInResponsiveStatus = (status) => {
  return (
    status === RemoteWorkStatusValueDictionary.Requested ||
    status === RemoteWorkStatusValueDictionary.CancelRequest
  );
};

const RowActions = ({
  application = new RemoteWorkApplicationDetailDto(),
  userId,
  callbacks,
  remoteWorkApplicationTypes,
  remoteWorkApplicationStatusTypes,
  includeCancel = false,
}) => {
  let user = UserManager.getUser();
  let actions = [];

  if (user.isAdminOrHr() && checkIfApplicationIsEditable(application.status)) {
    actions.push(
      <ApplicationUpdateAction
        userId={userId}
        application={application}
        onUpdateCallback={callbacks.onUpdate}
        remoteWorkApplicationTypes={remoteWorkApplicationTypes}
        key={uuidv4()}
      />
    );
  }

  if (
    user.isAdminOrHr() &&
    checkIfApplicationInResponsiveStatus(application.status)
  ) {
    actions.push(
      <ApplicationRespondAction
        onUpdateStatusCallback={callbacks.onStatusUpdate}
        key={uuidv4()}
        application={application}
      />
    );
  } else if (
    includeCancel &&
    `${user.id}` === `${userId}` &&
    checkIfUserCanCancelApplication(
      application,
      remoteWorkApplicationStatusTypes
    )
  ) {
    actions.push(
      <ApplicationCancelAction
        key={uuidv4()}
        onCancelCallback={callbacks.onCancel}
        application={application}
      />
    );
  }

  if (user.isAdminOrHr()) {
    actions.push(
      <ApplicationDeleteAction
        onDeleteCallback={callbacks.onDelete}
        applicationId={application.id}
        key={uuidv4()}
      />
    );
  }

  return <div className="d-inline-block action-col">{actions}</div>;
};

RowActions.prototype = {
  application: PropTypes.instanceOf(RemoteWorkApplicationDetailDto).isRequired,
  callbacks: PropTypes.arrayOf(Function).isRequired,
  remoteWorkApplicationStatusTypes: PropTypes.arrayOf(Object).isRequired,
  remoteWorkApplicationTypes: PropTypes.arrayOf(Object).isRequired,
};

export default RowActions;
