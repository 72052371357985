import React from "react";
import Layout from "../../shared-components/layout/Layout";
import AttendanceApi from "../../apis/endpoints/AttendanceApi";
import UserManager from "../../navigation/UserManager";
import AttendanceView from "./views/AttendanceView";
import { Helmet } from "react-helmet";
import { toaster, Loading } from "../../shared-components/notification";
import { defaultErrorResponseParser } from "../../helpers/ErrorParser";
import { DateOnlyString } from "../../helpers/Builders";
import { getDateByAddingDays } from "../../helpers/DateUtility";
import UserApi from "../../apis/endpoints/UserApi";
import TeamApi from "../../apis/endpoints/TeamApi";

export default class AttendanceContainer extends React.Component {
  state = {
    shouldRender: false,
    isLoading: false,
    reports: [],
    statusTypes: [],
    tableWithUserName: false,
    tableWithAction: false,
    users : [],
    teamNames: [],
    filterWithTeam : true
  };

  componentDidMount() {
    /* two route can access this component
       /Report/Index
       /Report/Index/:id

       route /Report/Index only can accesses by the admin/hr
       route /Report/Index/:id can be accessed by user him/herself and team lead
    */
    let requestedUserId = this.getUserIdFromRouteOrDefault();
    let isAdminOrHr = UserManager.getUser().isAdminOrHr();
    this.setState({ selectedUserId: requestedUserId });
    this.loadUserSpecificData(requestedUserId);
    this.loadStatusTypes();
    if(isAdminOrHr){
      this.loadTeams();
      this.getAllUsers();
    }
  }

  loadTeams = () => {
    TeamApi.all()
      .then((res) => {
        let teams = res.data;
        let names = [];
        teams.forEach((element) => {
          var option = { label: element.name, value: element.id };
          names.push(option);
        });
        this.setState({
          teamNames: names
        });
      })
      .catch((err) => {
        toaster.error("Failed to load Team information");
      });
  };

  getAllUsers = () => {
    UserApi.allCefaloUsers().then ( (res) =>{
      this.setState({
        users : res.data
      })
    }).catch(()=>{
      toaster.error("Failed to load users team information");
    })
  }

  getUserIdFromRouteOrDefault = () => {
    let pathname = this.props.location.pathname;
    let splittedPath = pathname.split("/");
    if (splittedPath.length > 3) {
      var id = splittedPath[3] ? splittedPath[3] : NaN;
      return Number.isNaN(id) ? null : id;
    } else {
      return null;
    }
  };

  loadUserSpecificData = (requestedUserId) => {
    let user = UserManager.getUser();
    if (!requestedUserId && user.isAdminOrHr()) {
      this.setState({ tableWithAction: false, tableWithUserName: true });
      this.loadAllReportsByDefaultFilters();
    } else {
      if (user.isAdminOrHrOrTeamManager()) {
        if (user.isAdminOrHr()) {
          this.setState({ tableWithAction: true, tableWithUserName: false });
        }
        this.loadUserReportsByDefaultFilter(requestedUserId);
      } else {
        if (Number(requestedUserId) !== Number(user.id)) {
          window.location.replace(`/Report/Index/${user.id}`);
        } else {
          if (user.isAdminOrHr()) {
            this.setState({ tableWithAction: false, tableWithUserName: false });
          }
          this.loadUserReportsByDefaultFilter(user.id);
        }
      }
    }
  };

  loadAllReportsByDefaultFilters = () => {
    let toDate = DateOnlyString(new Date());
    let fromDate = DateOnlyString(getDateByAddingDays(-1));
    this.setState({
      fromDate: fromDate,
      toDate: toDate,
      filterOptions: null,
      shouldRender: true,
    });
    this.loadAllReports(fromDate, toDate);
  };

  loadUserReportsByDefaultFilter = (userId, hasTeamPermission = false) => {
    let fromDate = DateOnlyString(getDateByAddingDays(-30));
    let toDate = DateOnlyString(new Date());
    this.setState({ isLoading: true });
    AttendanceApi.getAllByUser(userId, fromDate, toDate)
      .then((res) => {
        this.setState({
          reports: res.data,
          fromDate: fromDate,
          toDate: toDate,
          id: userId,
          shouldRender: true,
          isLoading: false,
          filterOptions: null,
        });
        if (hasTeamPermission) {
          this.loadTeamMembers();
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        if (err.response && err.response.status === 403) {
          let user = UserManager.getUser();
          window.location.replace(`/Report/Index/${user.id}`);
        } else {
          defaultErrorResponseParser(err, "Failed To Load Attendance Reports");
        }
      });
  };

  loadAllReports = (fromDate, toDate, options = null) => {
    this.setState({ isLoading: true });
    var api;
    if (options === null) {
      api = AttendanceApi.getAll(fromDate, toDate);
    } else {
      api = AttendanceApi.getAll(fromDate, toDate, options);
    }
    api
      .then((res) => {
        this.setState({ isLoading: false, reports: res.data });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        defaultErrorResponseParser(err, "Unable to fetch reports");
      });
  };

  loadUserReports = (userId, fromDate, toDate, options = null) => {
    this.setState({ isLoading: true });
    var api;
    if (options === null) {
      api = AttendanceApi.getAllByUser(userId, fromDate, toDate);
    } else {
      api = AttendanceApi.getAllByUser(userId, fromDate, toDate, options);
    }
    api
      .then((res) => {
        this.setState({ isLoading: false, reports: res.data });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        defaultErrorResponseParser(err, "Unable to fetch reports");
      });
  };

  loadStatusTypes = () => {
    AttendanceApi.getStatusTypes()
      .then((res) => {
        this.setState({ statusTypes: res.data });
      })
      .catch((err) => {
        toaster.error("Failed to fetch status Types");
      });
  };

  onFilter = (data) => {
    let options = {
      statusType: data.status,
      includeWeekends: data.includeWeekends,
      includeHolidays: data.includeHolidays,
      teamId: data.team
    };
    this.setState({
      filterOptions: options,
      fromDate: data.fromDate,
      toDate: data.toDate,
    });
    if (this.state.selectedUserId) {
      this.loadUserReports(
        this.state.selectedUserId,
        data.fromDate,
        data.toDate,
        options
      );
    } else {
      this.loadAllReports(data.fromDate, data.toDate, options);
    }
  };

  onUserSelect = (userId) => {
    if (userId === "*") {
      this.props.history.push("/Report/Index");
      this.loadUserSpecificData();
      this.setState({ selectedUserId: null });
      this.setState({filterWithTeam : true});
    } else {
      this.props.history.push(`/Report/Index/${userId}`);
      this.loadUserSpecificData(userId);
      this.setState({ selectedUserId: userId });
      this.setState({filterWithTeam : false});
    }
  };

  onUpdate = () => {
    if (this.state.selectedUserId) {
      this.loadUserReports(
        this.state.selectedUserId,
        this.state.fromDate,
        this.state.toDate,
        this.state.options
      );
    } else {
      this.loadAllReports(
        this.state.fromDate,
        this.state.toDate,
        this.state.options
      );
    }
  };

  render() {
    if (!this.state.shouldRender) {
      return <></>;
    }
    let exportOptions = {
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
      options: this.state.filterOptions,
    };
    if (this.state.selectedUserId) {
      exportOptions["id"] = this.state.selectedUserId;
    }

    return (
      <Layout>
        <Helmet>
          <title>Report Page- Cefalo</title>
        </Helmet>
        <Loading show={this.state.isLoading} />
        <AttendanceView
          reports={this.state.reports}
          onFilter={this.onFilter}
          onUpdate={this.onUpdate}
          onUserSelect={this.onUserSelect}
          exportOptions={exportOptions}
          tableWithAction={this.state.tableWithAction}
          tableWithUserName={this.state.tableWithUserName}
          statusTypes={this.state.statusTypes}
          selectedUserId={this.state.selectedUserId}
          users={this.state.users}
          teamNames={this.state.teamNames}
          filterWithTeam={this.state.filterWithTeam}
        />
      </Layout>
    );
  }
}
