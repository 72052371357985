import React from "react";
import { Container, Card } from "react-bootstrap";
import DataSyncForm from "./forms/DataSyncForm";
import LightLayout from "../../shared-components/layout/LightLayout";

export default function AttendanceDataSyncView({ handleSubmit }) {
  return (
    <LightLayout>
      <Container fluid>
        <Card>
          <Card.Header>
            <h4>Attendance Data Sync</h4>
          </Card.Header>
          <Card.Body>
            <DataSyncForm handleSubmit={handleSubmit} />
          </Card.Body>
        </Card>
      </Container>
    </LightLayout>
  );
}
