import React from "react";
import PropTypes from "prop-types";
import { UserWithTeamDto } from "../../../models/User";
import UserCreateAction from "../actions/UserCreateAction";
import UserUpdateAction from "../actions/UserUpdateAction";
import UserDeleteAction from "../actions/UserDeleteAction";
import { DataTable } from "../../../library/table/Index";
import { v4 as uuidv4 } from "uuid";
import { enumsValueToLabelObject } from "../../../shared-components/enum-values/EnumValuesComverter";
import { Link } from "react-router-dom";

const ActionGroup = (props) => {
  return (
    <div className="d-inline-block action-col">
      <UserUpdateAction 
        userRoles={props.userRoles}
        key={uuidv4()}
        userData={props.user}
        teams={props.teams}
        onUpdateCallback={props.callbacks.onUserUpdate} />
      <Link className="btn btn-info btn-sm btn-font-12 mr-1"
        to={{
          pathname: `/profile/view/${props.user.id}`
        }}>Profile
      </Link>
      <UserDeleteAction
        key={uuidv4()}
        onDeleteCallback={props.callbacks.onUserDelete}
        user={props.user}
      />
    </div>
  );
};

ActionGroup.prototype = {
  callbacks: PropTypes.arrayOf(Function),
};

let dataTableColumns = [
  {
    text: "Name",
    dataField: "name",
    sort: true,
  },
  {
    text: "Team Name",
    dataField: "teamName",
    sort: true,
  },
  {
    text: "Email",
    dataField: "email",
    sort: true,
  },
  {
    text: "Client Email",
    dataField: "clientEmail",
    sort: true,
  },
  {
    text: "Role",
    dataField: "role",
    sort: true,
  },
  {
    text: "Status",
    dataField: "status",
    sort: true,
  },
  {
    text: "Actions",
    dataField: "actions",
    sort: false,
  },
];

const buildTableRows = (rows, profile, teams, userRoles, actionsCallbacks) => {
  let tempRows = [];
  let userRolesMapper = enumsValueToLabelObject(userRoles)
  rows.forEach((user) => {
    if (user instanceof UserWithTeamDto) {
      let tempRow = {
        name: user.name,
        teamName: user.hasTeam() ? user.teams.map(t => t.name).join(', ') : "N/A",
        teamEmail: user.hasTeam() ? user.teams.map(t => t.teamEmail).join(', ') : "N/A",
        email: user.email,
        clientEmail: user.clientEmail,
        role: userRolesMapper[user.role],
        status: user.isActive ? "Active" : "Inactive",
      };
      tempRow["actions"] = (
        <ActionGroup
          userRoles={userRoles}
          user={user}
          profile={profile}
          teams={teams}
          callbacks={actionsCallbacks}
        />
      );
      tempRows.push(tempRow);
    }
  });
  return tempRows;
};

const UserTable = (props) => {
  let callbacks = {
    onUserDelete: props.onDelete,
    onUserUpdate: props.onUpdate,
    onProfileLoad: props.onProfileLoad,
    onProfileSave: props.onProfileSave,
  };

  let rows = buildTableRows(props.users, props.profile, props.teams, props.userRoles, callbacks);

  return <DataTable rows={rows} columns={dataTableColumns} />;
};

const AllUserView = (props) => {
  return (
    <div>
      <div className="mt-3 mb-3 d-inline-block">
        <UserCreateAction
          userRoles={props.userRoles}
          teams={props.teams}
          onCreateCallback={props.onCreate}
        />
      </div>
      <UserTable {...props} />
    </div>
  );
};
AllUserView.prototype = {
  users: PropTypes.arrayOf(UserWithTeamDto),
  onCreate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onProfileLoad: PropTypes.func.isRequired,
  onProfileSave: PropTypes.func.isRequired,
};

export default AllUserView;
