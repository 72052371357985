import { AuthenticatedAxios, jsonContentConfig } from "../settings/Axios";
import { QueryBuilder } from "../utils/QueryBuilder";
const ExtraWorkEndpoint = "extraWorkApplications";
const ExtraWorkUserEndpoint = "Users";

export default {
  all: (options) => {
    let endpoint = `${ExtraWorkEndpoint}${QueryBuilder(options)}`;
    return AuthenticatedAxios.get(endpoint);
  },
  getAllByUser: (options, userId) => {
    let endpoint = `${ExtraWorkUserEndpoint}/${userId}/extraWorkApplications${QueryBuilder(
      options
    )}`;
    return AuthenticatedAxios.get(endpoint, null, null);
  },

  create: (data) => {
    let endpoint = `${ExtraWorkEndpoint}`;
    return AuthenticatedAxios.post(endpoint, data);
  },

  updateStatus: (data) => {
    let endpoint = `${ExtraWorkEndpoint}/Status`;
    return AuthenticatedAxios.patch(endpoint, data);
  },

  delete: (id) => {
    let endpoint = `${ExtraWorkEndpoint}/${id}`;
    return AuthenticatedAxios.delete(endpoint);
  },

  update: (id, data) => {
    let endpoint = `${ExtraWorkEndpoint}/${id}`;
    return AuthenticatedAxios.put(endpoint, data);
  },
  getAllTypes: () => {
    let endpoint = `${ExtraWorkEndpoint}/allTypes`;
    return AuthenticatedAxios.get(endpoint);
  },
  getAllBillableTo: () => {
    let endpoint = `${ExtraWorkEndpoint}/BillableTo`;
    return AuthenticatedAxios.get(endpoint);
  },
  getAllStatus: () => {
    let endpoint = `${ExtraWorkEndpoint}/allStatus`;
    return AuthenticatedAxios.get(endpoint);
  },
  previewDataFile: (formData) => {
    let fileUploadConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    let endpoint = `${ExtraWorkEndpoint}/DataFilePreview`;
    return AuthenticatedAxios.post(endpoint, formData, fileUploadConfig);
  },

  upload: (dataList) => {
    let body = JSON.stringify(dataList);
    let endpoint = `${ExtraWorkEndpoint}/Upload`;
    return AuthenticatedAxios.post(endpoint, body, jsonContentConfig);
  },
};
