import React from "react";
import PropTypes from "prop-types";
import UserManager from "../../../navigation/UserManager";
import { useForm } from "react-hook-form";
import { UserWithTeamDto } from "../../../models/User";
import { DateOnlyString } from "../../../helpers/Builders";
import { CefaloButton } from "../../../library/button/Index";
import { DateRange, Select, Form } from "../../../library/form/Index";
import { Col } from "../../../library/grid-layout/Index";
import { GetInitialEndDateForFilter, GetInitialStartDateForFilter } from "../Utils";

function FilterFormView(props) {
  let user = UserManager.getUser();
  const { register, handleSubmit } = useForm();

  const [fromDate, setFromDate] = React.useState(GetInitialStartDateForFilter());
  const [toDate, setToDate] = React.useState(GetInitialEndDateForFilter());

  const setDate = ({ fromDate, toDate }) => {
    setFromDate(fromDate);
    setToDate(toDate);
  };

  let userOptions = [];

  if (user.isAdminOrHrOrTeamManager() && props.users) {
    props.users.forEach((user) => {
      const userOption = { value: user.id, label: user.name };
      userOptions.push(userOption);
    });
  }

  const onFilter = (data) => {
    var filteredData = { ...data };
    if (!user.isAdminOrHr()) filteredData.userId = user.id;
    filteredData.fromDate = DateOnlyString(fromDate);
    filteredData.toDate = DateOnlyString(toDate);
    filteredData.userId = Number.parseInt(filteredData.userId);
    props.callbacks.onFilter(filteredData);
  };

  return (
    <form onSubmit={handleSubmit(onFilter)}>
      <Form.Row
        style={{
          alignItems: "flex-end",
        }}
      >
        {!props.isSupervision && user.isAdminOrHrOrTeamManager() ? (
          <Col sm>
            <Select
              value={props.selectedUserId}
              label="User:"
              options={userOptions}
              ref={register}
              onChange={(e) => {
                let userId = Number.parseInt(e.target.value);
                props.callbacks.setSelectedUserId(userId);
                props.callbacks.onUserChange(userId);
              }}
              name="userId"
            />
          </Col>
        ) : (
          <></>
        )}
        <DateRange
          fromDate={fromDate}
          toDate={toDate}
          onChange={setDate}
          classesNames={{
            fromDateColClassName: "pr-lg-3 pl-lg-4",
            toDateColClassName: "pr-lg-3 pl-lg-3",
          }}
        />
        {!!props.isSupervision ? (
          <Col sm>
            <Select
              label="Request State:"
              options={[
                { value: "*", label: "All" },
                { value: "1", label: "Requested" },
                { value: "2", label: "Canceled" },
                { value: "5", label: "Approved" },
                { value: "9", label: "Rejected" },
                { value: "11", label: "Claim Requested" },
                { value: "12", label: "Canceled Requested After Approved" },
                { value: "13", label: "Canceled Approved After Initial Approved" },
                { value: "15", label: "Claim Approved" },
                { value: "19", label: "Claim Rejected" },
                // { value: "21", label: "RefundRequested" },
                { value: "25", label: "Refunded" },
              ]}
              ref={register}
              name="requestType"
              containerClassName="pr-lg-3 pl-lg-2"
            />
          </Col>
        ) : null}

        <Col lg={4} className="p-0 pl-lg-1">
          <Form.Row className="mb-3">
            <CefaloButton
              type="submit"
              className="mt-auto filter_panel_btn"
              size="sm"
              variant="primary"
            >
              Show Applications
            </CefaloButton>
            {props.children}
          </Form.Row>
        </Col>
      </Form.Row>
    </form>
  );
};

FilterFormView.propTypes = {
  callbacks: PropTypes.object.isRequired,
  users: PropTypes.arrayOf(PropTypes.instanceOf(UserWithTeamDto)),
};

export default FilterFormView;
