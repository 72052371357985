import React from "react";
import {
  Loading,
  toaster,
} from "../../../shared-components/notification/Notification";
import { defaultErrorResponseParser } from "../../../helpers/ErrorParser";
import { CefaloButton } from "../../../library/button/Index";
import UserTrainingApi from "../../../apis/endpoints/UserTrainingApi";
import { showErrorMessages } from "../../../shared-components/response-message/ResponseMessage";
import TrainingHistoryUpsertForm from "../forms/TrainingHistoryUpsertForm";
export default class TrainingHistoryCreateAction extends React.Component {
  state = {
    show: false,
    isLoading: false,
  };

  toggle = (e) => () => {
    this.setState({ show: !this.state.show });
  };

  onCreateAction = (data) => {
    this.setState({ isLoading: true });

    UserTrainingApi.create(data)
      .then((res) => {
        this.setState({
          isLoading: false,
          show: false,
        });
        toaster.success("Training History Saved Successfulluy");
        this.props.onCreateCallBack(this.props.userId);
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        if (err.response.data) showErrorMessages(err.response.data);
        else
          defaultErrorResponseParser(err, "Failed To Create Training History");
      });
  };

  render() {
    const { trainings, userId } = this.props;
    return (
      <div className="d-inline-block">
        <Loading show={this.state.isLoading} />
        <CefaloButton size="sm" variant="primary" onClick={this.toggle()}>
          Add Training
        </CefaloButton>

        <TrainingHistoryUpsertForm
          trainings={trainings}
          userId={userId}
          show={this.state.show}
          toggle={this.toggle}
          onSubmit={this.onCreateAction}
          formName={"Add Training"}
        />
      </div>
    );
  }
}
