import React from "react";
import PropTypes from "prop-types";
import { FormModal } from "../../../library/modal";
import { TimePicker } from "../../../library/form";
import { CefaloButton } from "../../../library/button";
import {
  ConvertDateToTimeString,
  ParseDateTime,
} from "../../../helpers/Builders";

const AttendanceUpdateForm = ({ attendance, onUpdate, show, onToggle }) => {
  // attendance start and endtime format is "HH: mm" to convert this string to js date string we need to parse this
  // Time Picker component required a date object to to display time, we have to convert this startTime and endTime to
  // date object then pass to TimePicker. when ever user submit, the we have to parse the time from date object

  const [startTime, setStartTime] = React.useState(
    ParseDateTime(attendance.startTime)
  );
  const [endTime, setEndTime] = React.useState(
    ParseDateTime(attendance.endTime)
  );

  const onFormSubmit = (e) => {
    e.preventDefault();
    let data = {
      id: attendance.id,
      userId: attendance.userId,
      date: attendance.date,
      startTime: ConvertDateToTimeString(startTime),
      endTime: ConvertDateToTimeString(endTime),
    };
    onUpdate(data);
  };

  return (
    <FormModal
      onSubmit={onFormSubmit}
      formName="Edit Attendance"
      show={show}
      toggle={onToggle()}
    >
      <div key={FormModal.ControlContainer}>
        <TimePicker
          label="Entry Time (24 hour format)"
          time={startTime}
          onChange={(d) => {
            setStartTime(d);
          }}
        />

        <TimePicker
          label="Exit Time(24 hour format)"
          time={endTime}
          onChange={(d) => {
            setEndTime(d);
          }}
        />
      </div>
      <div key={FormModal.ActionContainer}>
        <CefaloButton size="sm" variant="light" onClick={onToggle()}>
          Close
        </CefaloButton>
        <CefaloButton size="sm" variant="primary" type="submit">
          Save
        </CefaloButton>
      </div>
    </FormModal>
  );
};
AttendanceUpdateForm.prototype = {
  teamData: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default AttendanceUpdateForm;
