import React from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";

export default function ApplicationActionModal({
  isOpen,
  header,
  children,
  onToggle,
  footer,
}) {
  return (
    <Modal show={isOpen} centered onHide={onToggle()}>
      <Modal.Header as="h6" closeButton>
        <Modal.Title>{header}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>{footer}</Modal.Footer>
    </Modal>
  );
}

ApplicationActionModal.prototype = {
  header: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};
