import { TeamDto } from "./Team";

export class UserWithTeamDto {
  constructor(obj) {
    this.id = obj.id;
    this.name = obj.name;
    this.email = obj.email;
    this.teamId = obj.teamId;
    this.joiningDate = obj.joiningDate ? new Date(obj.joiningDate) : undefined;
    this.lastWorkingDate = obj.lastWorkingDate
      ? new Date(obj.lastWorkingDate)
      : undefined;
    this.attSysUserId = obj.attSysUserId;
    this.isActive = obj.isActive;
    this.role = obj.role;
    this.clientEmail = obj.clientEmail;
    this.lastModified = obj.lastModified
      ? new Date(obj.lastModified)
      : undefined;
    this.created = obj.created ? new Date(obj.created) : undefined;
    this.creatorId = obj.creatorId;
    this.creatorName = obj.creatorName;
    this.lastModifierName = obj.lastModifierName;
    this.lastModifierId = obj.lastModifierId;
    this.hideEmail = obj.hideEmail;
    this.team = obj.team ? new TeamDto(obj.team) : null;
    this.teams = !!obj.teams ? obj.teams.map(team => new TeamDto(team)) : null;
    this.isSetLeaveBalanceToZero = obj.isSetLeaveBalanceToZero;
  }

  hasTeam() {
    return !!this.teams;
  }
}
