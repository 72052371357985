import React, { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";

export default function PasswordField({
  fieldName,
  fieldValue,
  onChange,
  required = false,
  placeholder,
  size=""
}) {
  let [showPassword, setShowPasswordState] = useState(false);

  return (
    <InputGroup>
      <Form.Control
        type={showPassword ? "text" : "password"}
        name={fieldName}
        value={fieldValue}
        onChange={onChange}
        required={required}
        placeholder={placeholder}
        size={size}
      />
      <InputGroup.Append>
        <InputGroup.Checkbox
          id={9}
          name="showPassword"
          checked={showPassword}
          onChange={() => setShowPasswordState(!showPassword)}
        ></InputGroup.Checkbox>
      </InputGroup.Append>
    </InputGroup>
  );
}
