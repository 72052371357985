import React from "react";
import PropTypes from "prop-types";
import ClientDeleteModalForm from "../forms/ClientDeleteModalForm";
import { CefaloButton } from "../../../library/button";
import { Loading, toaster } from "../../../shared-components/notification";
import ClientApi from "../../../apis/endpoints/ClientApi";
import { defaultErrorResponseParser } from "../../../helpers/ErrorParser";

export default class ClientDeleteAction extends React.Component {
  static propTypes = {
    client: PropTypes.object.isRequired,
    onDeleteCallback: PropTypes.func.isRequired,
  };

  state = {
    isLoading: false,
    show: false,
  };

  toggle = (e) => () => {
    this.setState({ show: !this.state.show });
  };

  onDelete = (e) => {
    this.setState({ isLoading: true });
    ClientApi.delete(this.props.client.id)
      .then((res) => {
        toaster.success("Client Deleted Successfully");
        this.setState({ isLoading: false, show: false });
        this.props.onDeleteCallback();
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        defaultErrorResponseParser(err, "Failed To Delete Client");
      });
  };

  render() {
    return (
      <div className="d-inline-block">
        <Loading show={this.state.isLoading} />
        <ClientDeleteModalForm
          onSubmit={this.onDelete}
          show={this.state.show}
          onToggle={this.toggle}
          client={this.props.client}
        />
        <CefaloButton
          className="btn-font-12"
          variant="danger"
          onClick={this.toggle()}
        >
          Delete
        </CefaloButton>
      </div>
    );
  }
}
