import { AuthenticatedAxios } from "../settings/Axios";
let config = {
  headers: {
    "Content-Type": "application/json",
  },
};

const buildQueryString = (queryOptions) => {
  let anyQuery = false;
  let queryString = "";
  if (queryOptions.includeHolidays) {
    queryString = "?includeHolidays=true";
    anyQuery = true;
  }
  if (queryOptions.includeWeekends) {
    if (anyQuery) {
      queryString = queryString + "&includeWeekends=true";
    } else {
      anyQuery = true;
      queryString = "?includeWeekends=true";
    }
  }
  if (queryOptions.statusType !== "*") {
    if (anyQuery) {
      queryString = queryString + `&statusType=${queryOptions.statusType}`;
    } else {
      queryString = `?statusType=${queryOptions.statusType}`;
    }
  }
  return queryString;
};

export default {
  getAll: (
    fromDate,
    toDate,
    options = {
      statusType: "*",
      teamId: "*"
    }
  ) => {
    let endpoint = `Attendances/${fromDate}/${toDate}`;

    if (options.statusType !== "*") {
      endpoint = `${endpoint}?statusType=${options.statusType}`;
      if (options.teamId !== "*") {
        endpoint = `${endpoint}&teamId=${options.teamId}`;
      }
    }

    if(options.statusType === "*") {
      if (options.teamId !== "*") {
        endpoint = `${endpoint}?teamId=${options.teamId}`;
      }
    }
    return AuthenticatedAxios.get(endpoint, null, null);
  },

  getAllByUser: (
    userId,
    fromDate,
    toDate,
    options = {
      includeHolidays: false,
      includeWeekends: false,
      statusType: "*",
    }
  ) => {
    let endpoint = `Users/${userId}/Attendances/${fromDate}/${toDate}`;
    let queryString = buildQueryString(options);
    endpoint = endpoint + queryString;
    return AuthenticatedAxios.get(endpoint, null, null);
  },

  getTodayEntries: () => {
    let endpoint = `Attendances`;
    return AuthenticatedAxios.get(endpoint, null, null);
  },

  getUserTodaysEntry: (userId) => {
    let endpoint = `Attendances/${userId}`;
    return AuthenticatedAxios.get(endpoint, null, null);
  },

  downloadAll: (
    fromDate,
    toDate,
    options = {
      statusType: "*",
      teamId: "*"
    }
  ) => {
    let endpoint = `Attendances/${fromDate}/${toDate}/Download`;
    if (options.statusType !== "*") {
      endpoint = `${endpoint}?statusType=${options.statusType}`;
      if (options.teamId !== "*") {
        endpoint = `${endpoint}&teamId=${options.teamId}`;
      }
    }

    if(options.statusType==="*") {
      if (options.teamId !== "*") {
        endpoint = `${endpoint}?teamId=${options.teamId}`;
      }
    }
    return AuthenticatedAxios({
      url: endpoint,
      method: "GET",
      responseType: "arraybuffer",
    });
  },

  downloadAllByUser: (
    userId,
    fromDate,
    toDate,
    options = {
      includeHolidays: false,
      includeWeekends: false,
      statusType: "*",
    }
  ) => {
    let endpoint = `Users/${userId}/Attendances/${fromDate}/${toDate}/Download`;
    let queryString = buildQueryString(options);
    endpoint = endpoint + queryString;
    return AuthenticatedAxios({
      url: endpoint,
      method: "GET",
      responseType: "arraybuffer",
    });
  },

  update: (id, data) => {
    let endpoint = `Attendances/${id}`;
    let body = JSON.stringify(data);
    return AuthenticatedAxios.put(endpoint, body, config);
  },

  getStatusTypes: () => {
    let endpoint = `Attendances/StatusTypes`;
    return AuthenticatedAxios.get(endpoint, null, null);
  },
  syncData: ({ fromDate, toDate }) => {
    let endpoint = `Attendances/DataSync/${fromDate}/${toDate}`;
    return AuthenticatedAxios.post(endpoint);
  },
};
