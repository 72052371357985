import React from "react";
import { getNavOptions } from "./Const";
import NavbarDropdown from "./NavbarDropdown";
import NavbarItem from "./NavbarItem";
import styles from "./styles/Nav.module.css";
import CefaloLogo from "../../static/images/CefaloLogo.png";
import { Navbar, Nav } from "react-bootstrap";
import { getDefaultURI } from "../../shared-components/protected-route/DefaultRoute";

export default function AppNavbarView({ handleNavigation, activePath}) {
  const getNavOptionsView = () => {
    let navOptions = getNavOptions();
    let navViews = navOptions.map((navOption, key) => {
      if (!!navOption.isDropdown) {
        return (
          <NavbarDropdown
            key={key}
            activePath={activePath}
            dropdownInfo={navOption.navInfo}
          />
        );
      } else {
        return (
          <NavbarItem
            key={key}
            activePath={activePath}
            navInfo={navOption.navInfo}
          />
        );
      }
    });
    return navViews;
  };

  const handleBrandLogoClick = () => {
    let defaultPath = getDefaultURI() 
    handleNavigation(defaultPath);
  };

  return (
    <div className={styles.appNavbarContainer}>
      <Navbar
        className="custom_container_xl  custom_container_lg custom_container_md custom_container_sm p-0"
        collapseOnSelect
        expand="xl"
        variant="light"
      >
        <Navbar.Brand
          onClick={handleBrandLogoClick}
          className={styles.brandLogo}
        >
          <img width="130px" src={CefaloLogo} alt="Cefalo" />
        </Navbar.Brand>
        {activePath !== "/logout" && (
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        )}
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto" style={{ backgroundColor: "#f5f5f5" }}>
            {getNavOptionsView()}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}
