import React from "react";
import Layout from "../../shared-components/layout";
import { Helmet } from "react-helmet";
import TeamApi from "../../apis/endpoints/TeamApi";
import ClientApi from "../../apis/endpoints/ClientApi";
import TeamManageView from "./views/TeamManageView";
import { toaster } from "../../shared-components/notification";
import { TeamDto } from "../../models/Team";
import UserManager from "../../navigation/UserManager";

export default class TeamManageContainer extends React.Component {
  state = {
    teams: [],
    clients: [],
    isLoading: false,
    shouldRender: false,
  };

  componentDidMount() {
    let user = UserManager.getUser();
    if (!user.isAdminOrHr()) {
      window.location.replace(`/Report/Index/${user.id}`);
    } else {
      this.setState({ shouldRender: true });
      this.loadTeamsData();
      this.loadClientsData();
    }
  }
  loadTeamsData = () => {
    this.setState({ isLoading: true });
    TeamApi.allIncludingInactive()
      .then((res) => {
        let teams = res.data.map((t) => new TeamDto(t));
        this.setState({
          isLoading: false,
          teams: teams,
        });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        toaster.error("Failed to load team information");
      });
  };

  loadClientsData = () => {
    this.setState({ isLoading: true });
    ClientApi.getAll()
      .then((res) => {
        let elements = res.data;
        let clients = [];
        elements.forEach((element) => {
          var option = { label: element.name, value: element.id };
          clients.push(option);
        });
        this.setState({
          isLoading: false,
          clients: clients,
        });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        toaster.error("Failed to load client information");
      });
  };

  onTeamCreate = (createdTeam) => {
    let teams = this.state.teams;
    let team = new TeamDto(createdTeam);
    teams.push(team);
    this.setState({
      teams: teams,
    });
  };

  onTeamDelete = () => {
    this.loadTeamsData();
  };

  onTeamUpdate = (updatedTeam) => {
    let team = new TeamDto(updatedTeam);
    let teams = this.state.teams;
    let index = teams.findIndex((item) => item.id === updatedTeam.id);
    teams[index] = team;
    this.setState({ teams: teams });
  };
  render() {
    if (!this.state.shouldRender) {
      return <></>;
    }
    return (
      <Layout>
        <Helmet>
          <title>Team Management- Cefalo</title>
        </Helmet>
        <TeamManageView
          {...this.state}
          teams={this.state.teams}
          clients={this.state.clients}
          onUpdate={this.onTeamUpdate}
          onDelete={this.onTeamDelete}
          onCreate={this.onTeamCreate}
        />
      </Layout>
    );
  }
}
