import React from "react";
import { CefaloButton } from "../button/Index";
import { v4 as uuid4 } from "uuid";
import { getDateByAddingDays } from "../../helpers/DateUtility";
const NoHyperLinkRef = "#";

export const SizePerPageOptionRenderer = ({
  text,
  page,
  onSizePerPageChange,
}) => (
  <li key={text} role="presentation" className="dropdown-item">
    <a
      href={NoHyperLinkRef}
      tabIndex="-1"
      role="menuitem"
      data-page={page}
      onMouseDown={(e) => {
        e.preventDefault();
        onSizePerPageChange(page);
      }}
    >
      {text}
    </a>
  </li>
);

export const SizePerPageRenderer = ({
  options,
  currSizePerPage,
  onSizePerPageChange,
}) => (
  <div style={{ display: "inline", float: "right" }}>
    <span style={{ fontWeight: "bold", marginRight: "4px" }}>{"Show"}</span>
    <select
      className="form-control form-control-sm input-sm mr-1"
      style={{ width: "63px", display: "inline" }}
      onChange={(e) => {
        onSizePerPageChange(e.target.value);
      }}
      defaultValue={currSizePerPage}
    >
      {options.map((option, index) => (
        <option key={index} value={option.page}>
          {option.text}
        </option>
      ))}
      ));
    </select>
    <span style={{ fontWeight: "bold" }}>{"entries"}</span>
  </div>
);

export const pageButtonRenderer = ({
  page,
  active,
  disabled,
  title,
  onPageChange,
}) => {
  const handleClick = (e) => {
    e.preventDefault();
    onPageChange(page);
  };
  return (
    <li className="page-item" key={uuid4()}>
      <CefaloButton
        variant={active ? "primary" : "light"}
        onClick={handleClick}
        disabled={disabled}
      >
        {page}
      </CefaloButton>
    </li>
  );
};

export const isPastDate = (row, rowIndex, name) => {
  const yesterday = getDateByAddingDays(-1);
  const rowDate = new Date(row[name]);
  if (yesterday >= rowDate) {
    return { color: "gray" };
  }
};

export const EmptyRowMessage = () => {
  return <p className="p-0 m-0">No data available in table</p>;
};
