import React from "react";
import PropTypes from "prop-types";
import { FormModal } from "../../../library/modal/Index";
import { CefaloButton } from "../../../library/button/Index";
import { SkillDevelopmentRequestDto } from "../../../models/SkillDevelopmentFund";
import { FaTrashAlt as FaTrash } from "react-icons/fa";
import CefaloIcon from "../../../library/Icons/Icon";

function RequestDeleteAction({ request, onDelete }) {
  const { id: requestId } = request;
  const [show, setShow] = React.useState(false);
  const toggleShow = React.useCallback(() => setShow(state => !state), [setShow]);
  const onSubmit = React.useCallback((e) => {
    toggleShow();
    e.preventDefault();
    e.stopPropagation();
    onDelete(requestId)
  }, [requestId, onDelete, toggleShow]);

  return (
    <div className="d-inline-block">
      <FormModal
        formName="Delete Request"
        onSubmit={onSubmit}
        show={show}
        toggle={toggleShow}
      >
        <div key={FormModal.ControlContainer}>
          <p>Are you sure to delete this request?</p>
        </div>
        <div key={FormModal.ActionContainer}>
          <CefaloButton
            size="sm"
            variant="danger"
            onClick={toggleShow}
            type="button"
          >
            No
          </CefaloButton>
          <CefaloButton size="sm" variant="success" type="submit">
            Yes
          </CefaloButton>
        </div>
      </FormModal>
      <CefaloButton
        className="btn-font-12"
        size="sm"
        variant="danger"
        onClick={toggleShow}
      >
        <CefaloIcon Icon={FaTrash} />
      </CefaloButton>
    </div>
  );
}

RequestDeleteAction.propTypes = {
  request: PropTypes.instanceOf(SkillDevelopmentRequestDto).isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default RequestDeleteAction;