import React from "react";
import { Helmet } from "react-helmet";

// apis
import UserApi from "../../apis/endpoints/UserApi";
import RemoteWorkApi from "../../apis/endpoints/RemoteWorkApi";
// models
import { RemoteWorkApplicationDetailDto } from "../../models/RemoteWorkApplication";
import { UserWithTeamDto } from "../../models/User";
// helpers
import { DateOnlyString } from "../../helpers/Builders";
import { defaultErrorResponseParser } from "../../helpers/ErrorParser";
// shared
import Layout from "../../shared-components/layout/Layout";
import { toaster, Loading } from "../../shared-components/notification";
// views
import RemoteWorkApplicationView from "./views/RemoteWorkApplicationView";
// managers
import UserManager from "../../navigation/UserManager";

class RemoteWorkApplicationContainer extends React.Component {
  constructor(props) {
    super(props);
    let userId = UserManager.getUser().id;

    this.state = {
      remoteWorkApplications: [],
      users: [],
      userId: userId,
      fromDate: DateOnlyString(new Date(new Date().getFullYear(), 0, 1)),
      toDate: DateOnlyString(new Date(new Date().getFullYear(), 12, 31)),
      isLoading: false,
      remoteWorkApplicationStatusTypes: null,
      remoteWorkApplicationTypes: null,
      selectedUserId: userId,
    };
  }

  setSelectedUserId = (selectedUserId) => this.setState({ selectedUserId });

  componentDidMount() {
    let user = UserManager.getUser();

    this.loadRemoteWorkApplicationsData(user.id);
    this.loadRemoteApplicationTypes();
    this.loadRemoteApplicationStatusTypes();

    if (user.isAdminOrHr()) {
      this.loadUserData();
    } else if (user.hasTeamManagerPermission) {
      this.loadTeamMembersData(user.id);
    }
  }

  loadUserData = () => {
    this.setState({ isLoading: true });

    UserApi.all()
      .then((res) => {
        this.setState({
          isLoading: false,
          users: res.data.map((item) => new UserWithTeamDto(item)),
        });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        toaster.error("Failed to load user information");
      });
  };

  loadTeamMembersData = (userId) => {
    this.setState({ isLoading: true });
    UserApi.getTeamMembers(userId)
      .then((res) => {
        this.setState({
          isLoading: false,
          users: res.data.map((item) => new UserWithTeamDto(item)),
        });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        toaster.error("Failed to load team members information");
      });
  };

  loadRemoteWorkApplicationsData = (userId) => {
    let options = {
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
      remoteWorkType: this.state.remoteWorkApplicationType
        ? this.state.remoteWorkApplicationType
        : "",
    };

    this.setState({ isLoading: true });
    RemoteWorkApi.getUserApplications(options, userId)
      .then((res) => {
        this.setState({
          isLoading: false,
          remoteWorkApplications: res.data.map(
            (item) => new RemoteWorkApplicationDetailDto(item)
          ),
        });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        defaultErrorResponseParser(
          err,
          "Failed To Load Remote Work Applications"
        );
      });
  };

  loadRemoteApplicationStatusTypes = () => {
    RemoteWorkApi.getStatusTypes()
      .then((res) => {
        this.setState({
          remoteWorkApplicationStatusTypes: res.data,
        });
      })
      .catch((err) => {
        defaultErrorResponseParser(
          err,
          "Failed To Load Remote Work Status Types"
        );
      });
  };

  loadRemoteApplicationTypes = () => {
    RemoteWorkApi.getApplicationTypes()
      .then((res) => {
        this.setState({
          remoteWorkApplicationTypes: res.data,
        });
      })
      .catch((err) => {
        defaultErrorResponseParser(err, "Failed To Load Remote Work Types");
      });
  };

  onApplicationCreate = (newApplication) => {
    let remoteWorkApplications = this.state.remoteWorkApplications;
    remoteWorkApplications.push(
      new RemoteWorkApplicationDetailDto(newApplication)
    );
    this.setState({ remoteWorkApplications: remoteWorkApplications });
  };

  onFilter = (data) => {
    this.setState({
      userId: data.userId,
      fromDate: data.fromDate,
      toDate: data.toDate,
      remoteWorkApplicationType: data.workType,
    });
    this.loadRemoteWorkApplicationsData(data.userId);
  };

  onUserChange = (userId) => {
    this.setState({ userId: userId });
    this.loadRemoteWorkApplicationsData(userId);
  };

  onApplicationDelete = () => {
    this.loadRemoteWorkApplicationsData(this.state.userId);
  };

  onApplicationStatusUpdate = () => {
    this.loadRemoteWorkApplicationsData(this.state.userId);
  };

  onApplicationUpdate = (updatedApplications) => {
    this.loadRemoteWorkApplicationsData(this.state.userId);
  };

  onApplicationCancel = () => {
    this.loadRemoteWorkApplicationsData(this.state.userId);
  };

  checkIsComponentReadyToRender = () => {
    return (
      !!this.state.remoteWorkApplicationTypes &&
      !!this.state.remoteWorkApplicationStatusTypes
    );
  };

  render() {
    if (!this.checkIsComponentReadyToRender()) {
      return <></>;
    }
    return (
      <Layout>
        <Helmet>
          <title>Remote Work Applications- Cefalo</title>
        </Helmet>
        <div className="p-3">
          <Loading show={this.state.isLoading}></Loading>
          <RemoteWorkApplicationView
            remoteWorkApplications={this.state.remoteWorkApplications}
            users={this.state.users}
            userId={this.state.userId}
            setSelectedUserId={this.setSelectedUserId}
            selectedUserId={this.state.selectedUserId}
            onCreate={this.onApplicationCreate}
            onUpdate={this.onApplicationUpdate}
            onDelete={this.onApplicationDelete}
            onCancel={this.onApplicationCancel}
            onStatusUpdate={this.onApplicationStatusUpdate}
            onFilter={this.onFilter}
            onUserChange={this.onUserChange}
            remoteWorkApplicationTypes={this.state.remoteWorkApplicationTypes}
            remoteWorkApplicationStatusTypes={
              this.state.remoteWorkApplicationStatusTypes
            }
          />
        </div>
      </Layout>
    );
  }
}

export default RemoteWorkApplicationContainer;
