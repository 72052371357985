import React, { Component } from "react";
import NocCreateApplication from "../actions/NocCreateApplication";
import {
  getCurrentYearStartDate,
  getCurrentYearEndDate,
} from "../../../helpers/DateUtility";
import UserManager from "../../../navigation/UserManager";
import { CefaloButton } from "../../../library/button/Index";
import { DateRange, Form, Select } from "../../../library/form";
import { Col } from "../../../library/grid-layout";
import { Loading } from "../../../shared-components/notification";
import UserApi from "../../../apis/endpoints/UserApi";
import { showErrorMessages } from "../../../shared-components/response-message/ResponseMessage";
import { DateOnlyString } from "../../../helpers/Builders";

export default class NocController extends Component {
  constructor(props) {
    super(props);
    this.user = UserManager.getUser();
    this.state = {
      userId: this.user.id,
      fromDate: getCurrentYearStartDate(),
      toDate: getCurrentYearEndDate(),
      applicationStatus: 2,
      isLoading: false,
      changedUserprofile: [],
    };
  }

  componentDidMount = () => {
    const { isUserFilterEnabled, isSupervision } = this.props;
    if (isUserFilterEnabled && this.user.isHrOrAdmin()) {
      UserApi.all().then((resp) => {
        let userOptions = this.makeUserOptions(resp.data);
        this.setState({ userOptions });
      });
    } else if (!isSupervision && this.user.hasTeamManagerPermission) {
      UserApi.getTeamMembers(this.user.id)
        .then((resp) => {
          let userOptions = this.makeUserOptions(resp.data);
          this.setState({ userOptions });
        })
        .catch((err) => {
          showErrorMessages("Failed to load Team Members information");
        });
    }
  };

  setDate = ({ fromDate, toDate }) => {
    this.setState({ fromDate, toDate });
  };

  userOption = (user) => ({
    label: user.name,
    value: user.id,
  });

  makeUserOptions = (users) => users.map((user) => this.userOption(user));

  onSelectBoxChange = (event) => {
    if (isNaN(event.currentTarget.value)) {
      this.setState({
        [event.currentTarget.name]: "*",
      });
    } else {
      this.setState({
        [event.currentTarget.name]: parseInt(event.currentTarget.value),
      });
    }
  };

  onChangeUser = (event) => {
    let value = parseInt(event.currentTarget.value);
    this.setState({ [event.currentTarget.name]: value });
    this.props.onChangeUser(value);
    this.props.loadUserProfile(value);
    this.triggerFilter(value);
  };

  handleFilter = (e) => {
    e.preventDefault();
    const { userId } = this.state;
    this.triggerFilter(userId);
  };

  triggerFilter = (userId) => {
    let { fromDate, toDate, applicationStatus } = this.state;
    this.setState({ isLoading: true });

    this.props
      .onFilterAction(
        {
          fromDate: DateOnlyString(fromDate),
          toDate: DateOnlyString(toDate),
          applicationStatus,
        },
        userId
      )
      .then(() => this.setState({ isLoading: false }))
      .catch(() => this.setState({ isLoading: false }));
  };

  render() {
    const {
      fromDate,
      toDate,
      userId,
      isLoading,
      userOptions,
      applicationStatus,
    } = this.state;
    const {
      isUserFilterEnabled,
      isStatusEnabled,
      statusOptions,
      isSupervision,
      onCreateCallback,
      profile,
    } = this.props;

    return (
      <Form onSubmit={this.handleFilter}>
        <Loading show={isLoading} />
        <Form.Row
          style={{
            alignItems: "flex-end",
          }}
        >
          {isUserFilterEnabled && this.user.isAdminOrHr() && (
            <Col sm={2}>
              <Select
                label="User:"
                options={userOptions}
                value={userId}
                name="userId"
                onChange={this.onChangeUser}
              />
            </Col>
          )}

          <DateRange
            fromDate={fromDate}
            toDate={toDate}
            onChange={this.setDate}
            classesNames={
              isSupervision
                ? {
                    fromDateColClassName: "pr-lg-3 ",
                    toDateColClassName: "pr-lg-3 pl-lg-3",
                  }
                : {
                    fromDateColClassName: "pr-lg-3 pl-lg-4",
                    toDateColClassName: "pr-lg-3 pl-lg-3",
                  }
            }
          />

          {isStatusEnabled && (
            <Col sm={2}>
              <Select
                label="Application Status"
                name="applicationStatus"
                containerClassName="pr-lg-3 pl-lg-3"
                options={statusOptions}
                value={applicationStatus}
                onChange={this.onSelectBoxChange}
              />
            </Col>
          )}

          <Col
            md={isSupervision ? 2 : 12}
            lg={isSupervision ? 2 : 4}
            className="p-0"
          >
            <Form.Row className="mb-3">
              <CefaloButton
                type="submit"
                className="mt-auto filter_panel_btn"
                size="sm"
                variant="primary"
              >
                Show Applications
              </CefaloButton>
              {!isSupervision && (
                <NocCreateApplication
                  userId={userId}
                  onCreateCallback={onCreateCallback}
                  profile={profile}
                  isSupervision={isSupervision}
                />
              )}
            </Form.Row>
          </Col>
        </Form.Row>
      </Form>
    );
  }
}
