import React from "react";
import { getDisplayName } from "../DisplayName";

const WithCrudActionsOnArray = (api) => (WrappedComponent) => {
  class WithCrudActionsOnArray extends React.Component {
    state = {
      crudArray: [],
    };
    setStateCallback = (crudArray) => {
      if (Array.isArray(crudArray)) {
        this.setState({ crudArray });
      }
    };
    onCreate = (data) => {
      return api
        .create(data)
        .then((res) =>
          this.setStateCallback([res.data].concat(this.state.crudArray))
        );
    };

    onUpdate = (id, data) => {
      return api.update(id, data).then((res) => {
        let crudArray = [...this.state.crudArray];
        crudArray = crudArray.map((x) => (x.id === id ? res.data : x));
        this.setStateCallback(crudArray);
      });
    };

    onDelete = (id) => {
      return api
        .delete(id)
        .then((res) =>
          this.setStateCallback(this.state.crudArray.filter((x) => x.id !== id))
        );
    };

    onResponse = (data) => {
      return api.updateStatus(data).then((res) => {
        let crudArray = [...this.state.crudArray];
        crudArray = crudArray.filter((x) =>x.id !== data.id);
        this.setStateCallback(crudArray);
      });
    };

    crudCallbacks = {
      onCreate: this.onCreate,
      onUpdate: this.onUpdate,
      onDelete: this.onDelete,
      onResponse: this.onResponse,
    };

    render() {
      const { crudArray } = this.state;
      return (
        <WrappedComponent
          setArrayStateCallback={this.setStateCallback}
          crudArray={crudArray}
          arrayCrudCallbacks={this.crudCallbacks}
          {...this.props}
        />
      );
    }
  }
  WithCrudActionsOnArray.displayName = `WithCrudActionsOnArray(${getDisplayName(
    WrappedComponent
  )})`;

  return WithCrudActionsOnArray;
};

export default WithCrudActionsOnArray;
