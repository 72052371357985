import React from "react";
import PropTypes from "prop-types";
import LeaveApplicationApi from "../../../apis/endpoints/LeaveAppliactionApi";
import CefaloUtility from "../../../helpers/CefaloUtility";
import ApplicationCreateFormModal from "../forms/CreateForm";
import { Loading, toaster } from "../../notification";
import { defaultErrorResponseParser } from "../../../helpers/ErrorParser";
import { CefaloButton } from "../../../library/button";
export default class ApplicationCreateAction extends React.Component {
  static propTypes = {
    userId: PropTypes.number.isRequired,
    onCreateCallback: PropTypes.func.isRequired,
  };

  state = {
    show: false,
    fromDate: new Date(),
    toDate: new Date(),
    includeHalfDay: false,
    totalWorkingDays: 0,
    isLoading: false,
    leaveApplicationTypes: [],
    paternityLeaveToDate: new Date()
  };

  toggle = (e) => () => {
    this.setState({ show: !this.state.show });
    this.onApplicationPeriodSelect(new Date(), new Date(), false);
    this.onPaternityLeaveToDateSelect(this.state.fromDate)
    this.loadLeaveApplicationTypesByUserId(this.props.userId);
  };

  componentDidMount() {
    this.onApplicationPeriodSelect(
      this.state.fromDate,
      this.state.toDate,
      this.state.includeHalfDay
    );
    this.onPaternityLeaveToDateSelect(this.state.fromDate);
  }

  loadLeaveApplicationTypesByUserId = (userid) => {
    LeaveApplicationApi.getLeaveTypesByUserId(userid)
      .then((res) => {
        this.setState({
          leaveApplicationTypes: res.data,
        });
      })
      .catch((err) => {
        defaultErrorResponseParser(err, "Failed To Load Application Types");
      });
  };

  onApplicationPeriodSelect = (fromDate, toDate, includeHalfDay) => {
    this.setState({
      fromDate: fromDate,
      toDate: toDate,
      includeHalfDay: includeHalfDay,
    });
    CefaloUtility.getWorkingDaysCount(
      fromDate,
      toDate,
      includeHalfDay,
      (days) => {
        this.setState({ totalWorkingDays: days });
      }
    );
  };

  onPaternityLeaveToDateSelect = (fromDate) => {
    this.setState({
      fromDate: fromDate,
    });

    CefaloUtility.getToDate(fromDate, this.props.leaveSettings.paternityLeavePerYearInDays, (date) => {
      this.setState({
        paternityLeaveToDate: date,
      });
    });
  };

  onCreateAction = (applicationData) => {
    applicationData["userId"] = this.props.userId;
    this.setState({ isLoading: true });
    return LeaveApplicationApi.create(applicationData)
      .then((res) => {
        toaster.success("Application Submitted Successfully");
        this.props.onCreateCallback(res.data);
        this.setState({
          isLoading: false,
          show: false,
        });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        defaultErrorResponseParser(err, "Failed To Submit Application");
        throw err;
      });
  };

  render() {
    return (
      <div className="d-inline-block" style={{ marginLeft: "auto" }}>
        <Loading show={this.state.isLoading} />
        <CefaloButton
          size="sm"
          variant="primary"
          className="mt-auto filter_panel_btn"
          onClick={this.toggle()}
        >
          New Application
        </CefaloButton>
        <ApplicationCreateFormModal
          show={this.state.show}
          onCreate={this.onCreateAction}
          onToggle={this.toggle}
          toDate={this.state.toDate}
          fromDate={this.state.fromDate}
          totalWorkingDays={this.state.totalWorkingDays}
          periodSelectCallBack={this.onApplicationPeriodSelect}
          paternityLeaveToDateSelectCallBack={this.onPaternityLeaveToDateSelect}
          paternityLeaveToDate={this.state.paternityLeaveToDate}
          includeHalfDay={this.state.includeHalfDay}
          leaveTypes={this.state.leaveApplicationTypes}
          leaveSettings={this.props.leaveSettings}
        />
      </div>
    );
  }
}
