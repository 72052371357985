import React, { useState, useEffect } from 'react';
import ReactTags from 'react-tag-autocomplete';
import ProfileApi from "../../../apis/endpoints/ProfileApi";
import { defaultErrorResponseParser } from "../../../helpers/ErrorParser";
import { toaster } from "../../../shared-components/notification";

const SkillTagInput = (props) => {

  const [selectedSkills, setSelectedSkills] = useState([]);
  const [suggestions, setSuggestions] = useState([]);

  const loadSkills = () => {
    ProfileApi.getSkills()
      .then((res) => {
        let options = [];
        options = options.concat(
          Object.keys(res.data).map((key) => {
            return { id: key, name: res.data[key] };
          }));

        setSuggestions(options);
      })
      .catch((err) => {
        defaultErrorResponseParser(err, "Failed to generate skills");
      });
  };

  useEffect(() => {
    loadSkills();
  }, []);

  useEffect(() => {
    if (props.selectedSkills) {
      let options = [];
      options = options.concat(
        props.selectedSkills.map((item) => {
          return { id: item.id, name: item.name };
        }));

      setSelectedSkills(options);
    }
  }, [props.selectedSkills]);

  const onDelete = (i) => {
    const tags = selectedSkills.slice(0);
    tags.splice(i, 1);
    setSelectedSkills(tags);
    props.onTagChange(tags);
  }

  const onAddition = (tag) => {
    const newSkills = selectedSkills.slice(0);
    newSkills.push(tag);

    if (props.numberOfAllowedSkills && newSkills.length > props.numberOfAllowedSkills) {
      toaster.info(`Maximum ${props.numberOfAllowedSkills} skill sets allowed here!`);
      return;
    }

    setSelectedSkills(newSkills)
    props.onTagChange(newSkills);
  }

  return (
    <ReactTags
      allowNew
      placeholderText="Skill(s)"
      tags={selectedSkills}
      suggestions={suggestions}
      onDelete={onDelete}
      onAddition={onAddition} />
  )
}

export default SkillTagInput;