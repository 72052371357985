import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import UserManager from "../../navigation/UserManager";
import DefaultRoute from "./DefaultRoute";

let superRoutesForHrOrAdmin = [
  "/ExtraWorkingDayApplication/Supervision",
  "/LeaveApplication/supervision",
  "/Team/Index",
  "/UserInfo/Index",
  "/LeaveApplication/DataUpload",
  "/LeaveApplication/EscalateCarryForwardData",
  "/LeaveApplication/LeaveReport",
  "/Report/Index",
  "/Report/Sync",
  "/Home/Index",
  "/Settings/Index",
  "/WorkingFromOutsideApplication/Supervision",
  "/NocApplication/Supervision",
  "/AuditLog/Index"
];

let guestUserRoutes = [
  "/HolidayInfo/Index",
  "/UserProfile/Index",
  "/Policy/Hr",
  "/Policy/It",
];
class ProtectedRoute extends Component {
  checkRoutesPermission = () => {
    const { path } = this.props;
    let user = UserManager.getUser();
    if (user.isAdminOrHr()) {
      return true;
    } else if (user.isGuestUser()) {
      return guestUserRoutes.includes(path);
    } else {
      let isAllowedRoute = !superRoutesForHrOrAdmin.includes(path);
      return isAllowedRoute;
    }
  };

  getView = (componentProps) => {
    const { component: Component } = this.props;

    if (!!localStorage.getItem("__C_Token")) {
      if (this.checkRoutesPermission()) {
        return <Component {...componentProps} />;
      } else {
        return <DefaultRoute />;
      }
    } else {
      return <Redirect to="/Login" />;
    }
  };

  render() {
    const { component: Component, ...rest } = this.props;
    return <Route {...rest} render={this.getView} />;
  }
}

export default ProtectedRoute;
