import React from "react";
import { toaster } from "../../shared-components/notification/Notification";
import UserManager from "../../navigation/UserManager";
import UserProfileTeamHistoryView from "./views/UserProfileTeamHistoryView";
import TeamHistoryApi from "../../apis/endpoints/TeamHistoryApi";
import TeamApi from "../../apis/endpoints/TeamApi";
import { Form } from "../../library/form/Index";
import UserTeamHistoryCreateAction from "./actions/UserTeamHistoryCreateAction";
import { Col } from "react-bootstrap";

const ViewContainer = (props) => <div className="p-2">{props.children}</div>;

class UserProfileTeamHistoryContainer extends React.Component {
  state = {
    isLoading: false,
    shouldRender: false,
    userTeamHistory: [],
    teamNames: [],
  };

  componentDidMount = () => {
    let user = UserManager.getUser();
    if (user.isAdminOrHr()) {
      this.setState({
        shouldRender: true,
        isLoading: true,
      });
      this.loadTeamData();
      this.loadTeamHistoryData(this.props.userId);
    }
  };

  loadTeamData = () => {
    TeamApi.allIncludingInactive()
      .then((res) => {
        let teamNames = res.data;
        let names = [];
        teamNames.forEach((element) => {
          var option = { label: element.name, value: element.id };
          names.push(option);
        });

        this.setState({
          teamNames: names,
        });
      })
      .catch((err) => {
        toaster.error("Failed to load Team information");
      });
  };

  loadTeamHistoryData = (userId) => {
    TeamHistoryApi.getByUserId(userId)
      .then((res) => {
        let allTeamHistory = res.data;
        let teamHistory = [];

        allTeamHistory.forEach((element) => {
          teamHistory.push(element);
        });
        this.setState({
          userTeamHistory: teamHistory,
        });
      })
      .catch((err) => {
        toaster.error("Failed to load Team History information");
      });
  };

  render() {
    return (
      <Form>
        <Form.Row>
          <Col>
            TEAM HISTORY
            <hr />
          </Col>
          <Col sm="auto">
            <UserTeamHistoryCreateAction
              teamNames={this.state.teamNames}
              profile={this.props.profile}
              userId={this.props.userId}
              onCreateCallBack={this.loadTeamHistoryData}
            />
          </Col>
        </Form.Row>

        <Form.Row>
          <Col>
            <ViewContainer>
              <UserProfileTeamHistoryView
                teamHistory={this.state.userTeamHistory}
                teamNames={this.state.teamNames}
                profile={this.props.profile}
                onDelete={this.loadTeamHistoryData}
                onUpdate={this.loadTeamHistoryData}
                hasActionGroup={true}
              />
            </ViewContainer>
          </Col>
        </Form.Row>
      </Form>
    );
  }
}

export default UserProfileTeamHistoryContainer;
