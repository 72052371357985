import React from "react";
import styles from "./styles/LeaveDataUploadView.module.css";
import { Form } from "../../library/form";
import { FormModal } from "../../library/modal";
import { Table } from "../../library/table";
import { Loading, toaster } from "../../shared-components/notification";
import LeaveBalanceApi from "../../apis/endpoints/LeaveBalanceApi";
import PropTypes from "prop-types";
import { defaultErrorResponseParser } from "../../helpers/ErrorParser";
import { CefaloButton } from "../../library/button";

const CarryForwardDataDemo = (props) => {
  let csvView = (
    <>
      <p>Year,Email,Leave Type,Carry Forward</p>
      <p>2020,someone@cefalo.com,Casual Leave,5</p>
      <p>2020,someother@cefalo.com,Casual Leave,3</p>
    </>
  );
  return (
    <div color="black">
      <p>Note: csv file should be like this (viewed in excel).</p>
      <Table bordered striped small className={styles.demoTable}>
        <thead>
          <tr>
            <th>Year</th>
            <th>Email</th>
            <th>Leave Type</th>
            <th>CarryForward</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>2020</td>
            <td>someone@cefalo.com</td>
            <td>Casual Leave</td>
            <td>5</td>
          </tr>
          <tr>
            <td>2020</td>
            <td>someother@cefalo.com</td>
            <td>Casual Leave</td>
            <td>5</td>
          </tr>
        </tbody>
      </Table>
      <p className="mt-2 mb-1">
        <u>Same file viewed in notepad</u>
      </p>
      <p>{csvView}</p>
    </div>
  );
};
export default class CarryForwardDataUploadModal extends React.Component {
  static propTypes = {
    previewCallback: PropTypes.func.isRequired,
  };

  state = {
    isOpen: false,
    file: null,
    isLoading: false,
  };

  toggle = () => () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  handleFileInput = (e) => {
    this.setState({ isLoading: true });
    e.preventDefault();
    let file = e.target.files[0];
    this.setState({
      file: file,
      isLoading: false,
    });
  };

  handleFilePreviewUpload = (e) => {
    e.preventDefault();
    if (this.state.file === null) {
      return;
    }
    let formData = new FormData();
    formData.append("dataFile", this.state.file);
    LeaveBalanceApi.previewCarryForwardData(formData)
      .then((res) => {
        toaster.success("You can now save safely");
        toaster.success("Carry forward data is verified successfully");
        this.setState({ isOpen: false, isLoading: false });
        this.props.previewCallback(res.data);
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        defaultErrorResponseParser(err, "Failed To Upload The File");
      });
  };

  render() {
    return (
      <div className="inline-block">
        <Loading show={this.state.isLoading} />
        <CefaloButton size="sm" variant="primary" onClick={this.toggle()}>
          Upload Carry Forward Data
        </CefaloButton>
        <FormModal
          encType="multipart/form-data"
          onSubmit={this.handleFilePreviewUpload}
          modalSize="lg"
          show={this.state.isOpen}
          toggle={this.toggle()}
          formName="Upload Carry Forward Data"
        >
          <div key={FormModal.ControlContainer}>
            <CarryForwardDataDemo />
            <div className="mt-4">
              <Form.Label>Choose a file:</Form.Label>
              <Form.Control
                type="file"
                accept=".csv"
                size="sm"
                onChange={this.handleFileInput}
              />
            </div>
          </div>
          <div key={FormModal.ActionContainer}>
            <CefaloButton size="sm" variant="light" onClick={this.toggle()}>
              Close
            </CefaloButton>
            <CefaloButton size="sm" variant="primary" type={"submit"}>
              Upload
            </CefaloButton>
          </div>
        </FormModal>
      </div>
    );
  }
}
