import React from "react";
import { FormControl } from "react-bootstrap";

const Error = ({ errors, field }) => {
  if (!!errors && !!errors[field]) {
    return (
      <FormControl.Feedback type="invalid" style={{ display: "initial" }}>
        {errors[field].message}
      </FormControl.Feedback>
    );
  }
  return <></>;
};

export default Error;
