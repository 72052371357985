import React from "react";
import { Modal, Button } from "react-bootstrap";
import PropTypes from "prop-types";

/*
  onConfirmation callback:
  const onConfirmation = (yes, no) => {}
*/
const ConfirmationModal = ({ message, show, onConfirmation }) => {
  return (
    <Modal isOpen={show} centered>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button
          color="danger"
          size="sm"
          onClick={(e) => {
            onConfirmation(false, true);
          }}
        >
          No
        </Button>
        <Button
          color="green"
          size="sm"
          onClick={(e) => {
            onConfirmation(true, false);
          }}
        >
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ConfirmationModal.prototype = {
  message: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  onConfirmation: PropTypes.func.isRequired,
};

export default ConfirmationModal;
