import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "../styles/AttendanceFilterForm.module.css";
import { useForm } from "react-hook-form";
import { TypesBuilder, DateOnlyString } from "../../../helpers/Builders";
import { Form, DateRange, CheckBox, Select } from "../../../library/form";
import { Col, Container, Row } from "../../../library/grid-layout";
import { CefaloButton } from "../../../library/button";
import UserManager from "../../../navigation/UserManager";

const defaultFromDate = () => {
  let today = new Date();
  let thisYear = today.getUTCFullYear();
  let thisMonth = today.getMonth();
  return new Date(thisYear, thisMonth, 1, today.getHours(), today.getMinutes());
};

const AttendanceFilterForm = ({
  onFilterAction,
  statusTypes,
  selectedUserId,
  teamNames,
  filterWithTeam
}) => {
  const { register, handleSubmit } = useForm();

  const [fromDate, setFromDate] = useState(defaultFromDate());
  const [toDate, setToDate] = useState(new Date());

  const setDate = ({ fromDate, toDate }) => {
    setFromDate(fromDate);
    setToDate(toDate);
  };

  let statusTypeOptions = TypesBuilder.toOptionsArray(statusTypes);
  statusTypeOptions = [{ value: "*", label: "All" }, ...statusTypeOptions];
  let teamTypeOptions = [{ value: "*", label: "All" }, ...teamNames];
  let isAdminOrHr = UserManager.getUser().isAdminOrHr();

  const onFilter = (data) => {
    var filteredData = { ...data };
    filteredData.fromDate = DateOnlyString(fromDate);
    filteredData.toDate = DateOnlyString(toDate);
    onFilterAction(filteredData);
  };

  if(selectedUserId!==null){
    filterWithTeam = false
  }

  return (
    <form onSubmit={handleSubmit(onFilter)}>
      <Container fluid className="p-0">
        <Row style={{ alignItems: "flex-end" }}>
          <Col md={selectedUserId ? "4" : "5"}>
            <Form.Row>
              <DateRange
                fromDate={fromDate}
                toDate={toDate}
                onChange={setDate}
              />
            </Form.Row>
          </Col>
          { filterWithTeam && isAdminOrHr &&
              <Col md="3">
                <Select
                  label="Team"
                  options={teamTypeOptions}
                  ref={register}
                  name="team"
              />
              </Col>
          }
          <Col md={selectedUserId ? "4" : "2"}>
            <Form.Row>
              <Col
                className={
                  selectedUserId
                    ? "col-xs-8 col-md-8 col-lg-8"
                    : "col-sm-12 col-md-12 col-lg-12"
                }
              >
                <Select
                  label="Status"
                  options={statusTypeOptions}
                  ref={register}
                  name="status"
                />
            </Col>
            {selectedUserId ? (
                <Col className={styles["center-content-container"]}>
                  <div className={styles["center-content"]}>
                    <CheckBox
                      label="Weekends"
                      name="includeWeekends"
                      className="input-plain-label"
                      ref={register}
                    />
                    <CheckBox
                      label="Holidays"
                      name="includeHolidays"
                      className="input-plain-label"
                      ref={register}
                    />
                  </div>
                </Col>
              ) : (
                <></>
            )}
            </Form.Row>
          </Col>
          <Col className="mb-3">
            <CefaloButton block={true} variant="primary" type="submit">
              Filter Report
            </CefaloButton>
          </Col>
        </Row>
      </Container>
    </form>
  );
};
AttendanceFilterForm.prototype = {
  onFilterAction: PropTypes.func.isRequired,
  statusTypes: PropTypes.arrayOf(Object),
};

export default AttendanceFilterForm;
