import React, { Component } from "react";
import ApplicationActionModal from "../../../shared-components/modals/ApplicationActionModal";
import { Form } from "react-bootstrap";
import { ToNocStatusLabel } from "../Const";
import { NocStatusValueDictionary } from "../Const";
import PropTypes from "prop-types";
import { Loading } from "../../../shared-components/notification";
import {
  showErrorMessages,
  showSuccessMessages,
} from "../../../shared-components/response-message/ResponseMessage";
import ConfirmationModal from "../../../shared-components/modals/ConfirmationModal";
import { v4 as uuid4 } from "uuid";
import { CefaloButton } from "../../../library/button";

export default class ResponseForm extends Component {
  state = {
    supervisorComment: "",
    isLoading: false,
  };

  onChange = (event) => {
    this.setState({ [event.currentTarget.name]: event.currentTarget.value });
  };

  onResponseCallback = (status) => {
    const { supervisorComment } = this.state;
    if (
      status === NocStatusValueDictionary.Rejected &&
      supervisorComment.length <= 0
    ) {
      showErrorMessages({
        error: "Supervisor's Comment is mandatory for rejection",
      });
      return;
    }
    this.setState({ isLoading: true });
    this.props
      .onResponseCallback({ status, supervisorComment })
      .then((res) => {
        showSuccessMessages(
          `Noc applications ${ToNocStatusLabel[status]} successfully`
        );
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        showErrorMessages(err.response.data);
      });
  };

  render() {
    const { supervisorComment, isLoading } = this.state;
    return (
      <>
        <Loading show={isLoading} />
        <ApplicationActionModal
          isOpen={this.props.show}
          onToggle={this.props.onToggle}
          header="Application Response"
          footer={this.AvailableActions()}
        >
          <Form>
            <Form.Group className="mt-3">
              <Form.Label>Supervisor's Comments</Form.Label>
              <Form.Control
                as="textarea"
                value={supervisorComment}
                name="supervisorComment"
                placeholder="Supervisor's Comments..."
                onChange={this.onChange}
                size="sm"
              />
            </Form.Group>
            {this.props.children}
          </Form>
        </ApplicationActionModal>
      </>
    );
  }
  AvailableActions = () => {
    let options = [];
    options.push(
      <ConfirmationModal
        key={uuid4()}
        message="Are you sure to approve this application?"
        toggleButtonText="Approve"
        toggleButtonColor="success"
        doAction={() =>
          this.onResponseCallback(NocStatusValueDictionary.Approved)
        }
      />
    );
    options.push(
      <ConfirmationModal
        key={uuid4()}
        message="Are you sure to reject this application?"
        toggleButtonText="Reject"
        toggleButtonColor="danger"
        doAction={() =>
          this.onResponseCallback(NocStatusValueDictionary.Rejected)
        }
      />
    );
    options.push(
      <ConfirmationModal
        key={uuid4()}
        message="Are you sure to cancel this application?"
        toggleButtonText="Cancel"
        toggleButtonColor="primary"
        doAction={() =>
          this.onResponseCallback(NocStatusValueDictionary.Cancelled)
        }
      />
    );
    options.push(
      <CefaloButton
        variant="light"
        size="sm"
        key={uuid4()}
        onClick={this.props.onToggle()}
      >
        Close
      </CefaloButton>
    );
    return <div className="d-inline-block">{options}</div>;
  };
}

ResponseForm.propTypes = {
  onToggle: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  onResponseCallback: PropTypes.func.isRequired,
};
