import React from "react";
import PropTypes from "prop-types";
import UserManager from "../../../navigation/UserManager";
import ApplicationRespondAction from "./RespondAction";
import ApplicationDeleteAction from "./DeleteAction";
import ApplicationUpdateAction from "./UpdateAction";
import ApplicationCancelAction from "./CancelAction";
import { LeaveStatusValueDictionary } from "../Consts";
import { LeaveApplicationDetailDto } from "../../../models/LeaveApplication";
import { DateOnlyBuilder } from "../../../helpers/Builders";
import { LeaveSettingsDto } from "../../../models/Settings";
import { v4 as uuidv4 } from "uuid";

const checkIfApplicationIsEditable = (status) => {
  return !(
    status === LeaveStatusValueDictionary.Rejected ||
    status === LeaveStatusValueDictionary.CancelRequestRejected ||
    status === LeaveStatusValueDictionary.Canceled ||
    status === LeaveStatusValueDictionary.CancelByUser
  );
};

const checkIfUserCanCancelApplication = (
  application = new LeaveApplicationDetailDto(),
  leaveSettings = new LeaveSettingsDto(),
) => {
  let today = DateOnlyBuilder();
  let difference = Math.abs(
    (application.toDate - today) / (1000 * 60 * 60 * 24)
  );

  const isRequestedOrApproved =
    application.status === LeaveStatusValueDictionary.Requested ||
    application.status === LeaveStatusValueDictionary.Approved;

  if (application.toDate >= today) {
    return isRequestedOrApproved;
  } else {
    return (
      isRequestedOrApproved &&
      difference <= leaveSettings.allowedCancellationPeriod
    );
  }
};

const checkIfApplicationInResponsiveStatus = (status) => {
  return (
    status === LeaveStatusValueDictionary.Requested ||
    status === LeaveStatusValueDictionary.CancelRequest
  );
};

const RowActions = ({
  application = new LeaveApplicationDetailDto(),
  callbacks,
  userId,
  leaveStatuses,
  leaveTypes,
  leaveSettings = new LeaveSettingsDto(),
  includeCancel = false,
}) => {
  let user = UserManager.getUser();
  let actions = [];
  if (user.isAdminOrHr() && checkIfApplicationIsEditable(application.status)) {
    actions.push(
      <ApplicationUpdateAction
        userId={userId}
        application={application}
        onUpdateCallback={callbacks.onUpdate}
        leaveTypes={leaveTypes}
        key={uuidv4()}
      />
    );
  }

  if (
    user.isAdminOrHr() &&
    checkIfApplicationInResponsiveStatus(application.status)
  ) {
    actions.push(
      <ApplicationRespondAction
        onUpdateStatusCallback={callbacks.onStatusUpdate}
        key={uuidv4()}
        application={application}
      />
    );
  } else if (
    includeCancel &&
    `${user.id}` === `${userId}` &&
    checkIfUserCanCancelApplication(application, leaveSettings)
  ) {
    actions.push(
      <ApplicationCancelAction
        key={uuidv4()}
        onCancelCallback={callbacks.onCancel}
        application={application}
      />
    );
  }

  if (user.isAdminOrHr()) {
    actions.push(
      <ApplicationDeleteAction
        onDeleteCallback={callbacks.onDelete}
        applicationId={application.id}
        key={uuidv4()}
      />
    );
  }

  return <div className="d-inline-block action-col">{actions}</div>;
};
RowActions.prototype = {
  application: PropTypes.instanceOf(LeaveApplicationDetailDto).isRequired,
  callbacks: PropTypes.arrayOf(Function).isRequired,
  leaveStatuses: PropTypes.arrayOf(Object).isRequired,
  leaveTypes: PropTypes.arrayOf(Object).isRequired,
  leaveSettings: PropTypes.object.isRequired,
};

export default RowActions;
