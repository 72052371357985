import { Axios } from "../settings/Axios";

const AuthEndpoint = "authentication";

export default {
  googleLogin: (body) =>
    Axios.post(AuthEndpoint + "/google", body).then(
      (response) => response.data
    ),
  internalLogin: (body) =>
    Axios.post(AuthEndpoint + "/internal", body).then(
      (response) => response.data
    ),
};
