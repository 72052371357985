import React from "react";
import PropTypes from "prop-types";
import { ClientDto } from "../../../models/ClientDto";
import { Loading } from "../../../shared-components/notification/Notification";
import ClientCreateAction from "../actions/ClientCreateAction";
import ClientTableView from "./ClientTableView"

const ClientManageView = (props) => {
  return (
    <div className="p-2">
      <Loading show={props.isLoading} />
      <div>
        <div className="mt-3 mb-3 d-inline-block">
          <ClientCreateAction
            onCreateCallback={props.onCreate}
            keyAccountManagers={props.keyAccountManagers}
          />
        </div>
        <ClientTableView {...props} />
      </div>
    </div>
  );
};

ClientManageView.prototype = {
  clients: PropTypes.arrayOf(ClientDto),
  isLoading: PropTypes.bool.isRequired,
  onCreate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default ClientManageView;
