import React from "react";
import Layout from "../../shared-components/layout";
import { Helmet } from "react-helmet";
import UserManager from "../../navigation/UserManager";
import { toaster, Loading } from "../../shared-components/notification";
import ClientApi from "../../apis/endpoints/ClientApi";
import { ClientDto } from "../../models/ClientDto";
import ClientManageView from "./views/ClientManageView";

const ViewContainer = (props) => <div className="p-2">{props.children}</div>;

class ClientManageContainer extends React.Component {
  state = {
    clients: [],
    isLoading: false,
    shouldRender: false,
    keyAccountManagers: [],
  };

  componentDidMount = () => {
    let user = UserManager.getUser();
    if (!user.isAdminOrHr()) {
      window.location.replace(`/Report/Index/${user.id}`);
    } else {
      this.setState({ shouldRender: true, isLoading: true });
      this.loadClientData();
      this.loadkeyAccountManagersData();
    }
  };

  loadClientData = () => {
    ClientApi.getAll()
      .then((res) => {
        let clients = res.data.map((t) => new ClientDto(t));
        this.setState({
          isLoading: false,
          clients: clients,
        });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        toaster.error("Failed to load Client information");
      });
  };

  loadkeyAccountManagersData = () => {
    ClientApi.getKeyAccountManagers()
      .then((res) => {
        let elements = res.data;
        let kams = [];
        elements.forEach((element) => {
          var option = { label: element.name, value: element.id };
          kams.push(option);
        });
        this.setState({
          isLoading: false,
          keyAccountManagers: kams,
        });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        toaster.error("Failed to load KeyAccountManagers information");
      });
  };

  onClientCreate = (createdClient) => {
    let clients = this.state.clients;
    let client = new ClientDto(createdClient);
    clients.push(client);
    this.setState({
      clients: clients,
    });
  };

  onClientUpdate = (updatedClient) => {
    let clients = this.state.clients;
    let client = new ClientDto(updatedClient);
    let index = clients.findIndex((item) => item.id === updatedClient.id);
    clients[index] = client;
    this.setState({
      clients: clients,
    });
  };

  onClientDelete = () => {
    this.loadClientData();
  };

  render() {
    if (!this.state.shouldRender) {
      return <></>;
    }
    return (
      <Layout>
        <Helmet>
          <title>Client Management- Cefalo</title>
        </Helmet>
        <Loading show={this.state.isLoading} />
        <ViewContainer>
          <ClientManageView
            {...this.state}
            clients={this.state.clients}
            keyAccountManagers={this.state.keyAccountManagers}
            onCreate={this.onClientCreate}
            onUpdate={this.onClientUpdate}
            onDelete={this.onClientDelete}
          />
        </ViewContainer>
      </Layout>
    );
  }
}

export default ClientManageContainer;
