import React from "react";
import { Dropdown, NavItem, NavLink } from "react-bootstrap";
import { FaCaretUp, FaCaretDown } from "react-icons/fa";
import {
  getPathsActiveness,
  getNavItemStyleClass,
  getDropdownItemStyleClass
} from "./Const";
import CefaloIcon from "../../library/Icons/Icon";

function NavbarSubMenu(props) {
  const { pathsActiveness, title, items = [] } = props;
  const [ isOpen, setIsOpen ] = React.useState(() => items.map(item => item.path)
    .some(path => pathsActiveness.pathsActivenessMapper[path]));
  const toggleMenu = React.useCallback(() => setIsOpen(s => !s), [setIsOpen]);
  return (
    <>
      <button className="btn btn-sm" onClick={toggleMenu}>{title} {isOpen ? <CefaloIcon Icon={FaCaretUp}/> : <CefaloIcon Icon={FaCaretDown}/>}</button>
      {isOpen && items.map((item, key) => (
          <Dropdown.Item
            className={getDropdownItemStyleClass(pathsActiveness.pathsActivenessMapper[item.path])}
            key={`menuitem-${key}`}
            style={{ fontSize: "14px" }}
            href={item.path}
          >
            {item.title}
          </Dropdown.Item>
        ))}
      <hr className="m-0" />
    </>
  )
}

export default function NavbarDropdown({
  dropdownInfo = {},
  activePath,
  ...restProps
}) {
  const { title,items = [] } = dropdownInfo;
  let pathsActiveness=getPathsActiveness(activePath,items)

  return (
    <Dropdown as={NavItem} {...restProps}>
      <Dropdown.Toggle
        className={getNavItemStyleClass(pathsActiveness.isAnyPathActive)}
        as={NavLink}
      >
        {title}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {items.map((item, key) => !!item.isSubMenu ? (
          <NavbarSubMenu {...item} key={key} pathsActiveness={pathsActiveness} />
        )
        : (
          <Dropdown.Item
            className={getDropdownItemStyleClass(pathsActiveness.pathsActivenessMapper[item.path])}
            key={key}
            style={{ fontSize: "14px" }}
            href={item.path}
          >
            {item.title}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
