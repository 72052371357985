import React from "react";
import { TeamDto } from "../../../models/Team";
import { DataTable } from "../../../library/table";
import ActionsGroupView from "./ActionsGroupView";

const TeamTableView = ({ teams, clients, onUpdate, onDelete }) => {
  const buildTableRowsFromTeamData = (teams, deleteAction, updateAction) => {
    let tempRows = [];
    teams.forEach((item) => {
      if (item instanceof TeamDto) {
        let index = clients.findIndex(
          (client) => client.value === item.clientId
        );
        let tempRow = {};
        tempRow["id"] = item.id;
        tempRow["name"] = item.name;
        tempRow["teamEmail"] = item.teamEmail;
        tempRow["client"] =
          clients[index]?.label == null ? "" : clients[index]?.label;
        tempRow["clientEmail"] = item.clientEmail;
        tempRow["isActive"] = item.isActive ? "Active" : "Inactive";
        tempRow["actions"] = (
          <ActionsGroupView
            team={item}
            deleteAction={onDelete}
            updateAction={onUpdate}
            clients={clients}
          />
        );
        tempRows.push(tempRow);
      }
    });
    return tempRows;
  };

  const teamTableColumns = [
    {
      text: "Team Id",
      dataField: "id",
      sort: true,
    },
    {
      text: "Team Name",
      dataField: "name",
      sort: true,
    },
    {
      text: "Team Email",
      dataField: "teamEmail",
      sort: true,
    },
    {
      text: "Client Name",
      dataField: "client",
      sort: true,
    },
    {
      text: "Client Email",
      dataField: "clientEmail",
      sort: true,
    },
    {
      text: "Status",
      dataField: "isActive",
      sort: true,
    },
    {
      text: "Actions",
      dataField: "actions",
      sort: false,
    },
  ];

  let rows = buildTableRowsFromTeamData(teams);
  return <DataTable rows={rows} columns={teamTableColumns} />;
};

export default TeamTableView;
