import React from "react";
import UserApi from "../../../apis/endpoints/UserApi";
import ProfileApi from "../../../apis/endpoints/ProfileApi";
import UserManager from "../../../navigation/UserManager";
import {
  UserSelectOptionsView,
  ReadOnlySelectedUserView,
} from "../views/UserSelectionView";
import { defaultErrorResponseParser } from "../../../helpers/ErrorParser";

class UserSelectAction extends React.Component {
  state = {
    users: [],
    selectedUser: null,
    shouldRender: false,
    dropDownSelectionView: false,
    currentUserIsHrOrAdmin: false,
  };

  componentDidMount() {
    let user = UserManager.getUser();
    if (user.isAdminOrHr()) {
      this.setState({ currentUserIsHrOrAdmin: true });
      this.loadAllActiveUsersAndMapToSelectOption();
    } else if (user.hasTeamManagerPermission) {
      this.loadTeamMembersAndMapToSelection(user);
    } else {
      this.loadUserProfileAndDisplayReadOnlySelection(user.id);
    }
  }

  loadAllActiveUsersAndMapToSelectOption = () => {
    UserApi.allCefaloUsers()
      .then((res) => {
        let users = [{ id: "*", name: "All Employees", teamName: "" }];
        try {
          users = users.concat(
            res.data.map((user) => ({
              id: user.id,
              name: user.name,
              teamName: user.teams
                ? user.teams.map((t) => t.name).join(", ")
                : "",
            }))
          );

          let selectedId = this.tryToGetUserIdFromRoutePath();

          var defaultSelected = users.find(
            (u) => u.id === Number.parseInt(selectedId)
          );
          if (!defaultSelected) {
            defaultSelected = users[0];
          }
          this.setState({
            users: users,
            selectedUser: defaultSelected,
            shouldRender: true,
            dropDownSelectionView: true,
          });
        } catch (err) {}
      })
      .catch((err) => {
        defaultErrorResponseParser(err, "Failed to Load User List");
      });
  };

  loadTeamMembersAndMapToSelection = (teamLeadUser) => {
    UserApi.getTeamMembers(teamLeadUser.id)
      .then((res) => {
        let users = [];
        try {
          users = res.data.map((member) => ({
            id: member.id,
            name: member.name,
            teamName: member.teamNames.join(", "),
          }));

          var defaultSelected = users.find((u) => `${u.id}` === `${teamLeadUser.id}`);
          if (!defaultSelected) {
            defaultSelected = users[0];
          }
          this.setState({
            users: users,
            selectedUser: defaultSelected,
            shouldRender: true,
            dropDownSelectionView: true,
          });
        } catch (err) {}
      })
      .catch((err) => {
        defaultErrorResponseParser(err, "Failed To Load Team Members");
      });
  };

  tryToGetUserIdFromRoutePath = () => {
    let pathname = window.location.pathname;
    let splittedPath = pathname.split("/");
    if (splittedPath.length > 3) {
      return splittedPath[3];
    }
    return "*";
  };

  loadUserProfileAndDisplayReadOnlySelection = (userId) => {
    ProfileApi.getShortProfile(userId)
      .then((res) => {
        let user = {
          id: res.data.userId,
          name: res.data.name,
          teamName: res.data.teamName ? res.data.teamName : "",
        };

        this.setState({ selectedUser: user, shouldRender: true });
      })
      .catch((err) => {
        defaultErrorResponseParser(err, "Failed To Load User Information");
      });
  };

  handleSelect = (option) => {
    let id = option.value;
    if (`${this.state.selectedUser.id}` !== `${id}`) {
      let selectedUser = this.state.users.find((u) => `${u.id}` === `${id}`);
      this.setState({ selectedUser: selectedUser });
      this.props.onUserSelect(id);
    }
  };

  render() {
    if (!this.state.shouldRender) {
      return <></>;
    }

    if (!this.state.dropDownSelectionView) {
      return (
        <ReadOnlySelectedUserView
          userName={this.state.selectedUser.name}
          userTeamName={this.state.selectedUser.teamName}
        />
      );
    }

    let userOptions = [];
    this.state.users.forEach((user) => {
      userOptions.push({ label: user.name, value: user.id });
    });

    return (
      <UserSelectOptionsView
        userOptions={userOptions}
        selectedUser={this.state.selectedUser}
        onChange={this.handleSelect}
        isHrOrAdmin={this.state.currentUserIsHrOrAdmin}
      />
    );
  }
}

export default UserSelectAction;
