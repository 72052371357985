import { AuthenticatedAxios, /*jsonContentConfig*/ } from "../settings/Axios";
import { QueryBuilder } from "../utils/QueryBuilder";
const SkillDevFundEndpoint = "/fund/skilldevelopment";

export default {
  all: (options) => {
    const endpoint = `${SkillDevFundEndpoint}/${QueryBuilder(options)}`;
    return AuthenticatedAxios.get(endpoint);
  },

  getAllByUser: (userId, options) => {
    const endpoint = `${SkillDevFundEndpoint}/users/${userId}${QueryBuilder(options)}`;
    return AuthenticatedAxios.get(endpoint);
  },

  create: (data) => {
    return AuthenticatedAxios.post(SkillDevFundEndpoint, data);
  },

  cancel: (id, reason) => {
    const endpoint = `${SkillDevFundEndpoint}/${id}/cancel`;
    return AuthenticatedAxios.post(endpoint, { reason });
  },

  claim: (id, data) => {
    const payload = {
      "invoiceFileName": data.invoice,
      "certificationFileName": data.certificate,
      "userId": data.userId,
    }
    const endpoint = `${SkillDevFundEndpoint}/${id}/claimrequest`;
    return AuthenticatedAxios.post(endpoint, payload);
  },

  updateStatus: (id, status, reason) => {
    const endpoint = `${SkillDevFundEndpoint}/${id}/status${QueryBuilder({
      "ApplicationStatus": status,
      "Reason": reason
    })}`;
    return AuthenticatedAxios.post(endpoint, {});
  },

  summary: (userId, year) => {
    const endpoint = `${SkillDevFundEndpoint}/users/${userId}/summary?year=${year}`;
    return AuthenticatedAxios.get(endpoint);
  },

  delete: (id) => {
    const endpoint = `${SkillDevFundEndpoint}/${id}`;
    return AuthenticatedAxios.delete(endpoint);
  },

  documentData: (userId, opts) => {
    const endpoint = `${SkillDevFundEndpoint}/users/${userId}/document${QueryBuilder(opts)}`;
    return AuthenticatedAxios.get(endpoint, { responseType: 'blob' });
  },
};
