import React from "react";
import { Redirect } from "react-router-dom";
import UserManager from "../../navigation/UserManager";

export const getDefaultURI = () => {
  let user = UserManager.getUser();
  let redirectedUrl = "/Login";

  if (!!user) {
    if(user.isAdminOrHr()){
      redirectedUrl= "/Home/Index" 
    }
    else if(user.isGuestUser()){
      redirectedUrl=  "/UserProfile/Index"
    }else {
      redirectedUrl = `/Report/Index/${user.id}`;  
    }
  }
  
  return redirectedUrl;
};

export default function DefaultRoute() {
  return <Redirect to={getDefaultURI()} />;
}
