import React from "react";
import Layout from "../../shared-components/layout/Layout";
import { Helmet } from "react-helmet";
import LeaveStatisticsView from "./views/LeaveStatisticsView";
import UserApi from "../../apis/endpoints/UserApi";
import TeamApi from "../../apis/endpoints/TeamApi";
import { toaster, Loading } from "../../shared-components/notification/Notification";
import UserManager from "../../navigation/UserManager";
import LeaveBalanceApi from "../../apis/endpoints/LeaveBalanceApi";
import { LeaveStatisticsDto } from "../../models/LeaveStatistics";
import { defaultErrorResponseParser } from "../../helpers/ErrorParser";
import { Form, Row, } from "react-bootstrap";
import Select from 'react-select'
import "primereact/resources/primereact.css";
import "primereact/resources/themes/saga-blue/theme.css";
import { CefaloButton } from "../../library/button";
import { FaFilter, FaCaretDown } from "react-icons/fa";
import CefaloIcon from "../../library/Icons/Icon";
import Collapse from "@kunukn/react-collapse";
import {Tooltip, OverlayTrigger } from "react-bootstrap";
import {Container} from "../../library/grid-layout";
const ViewContainer = (props) => <div className="p-2">{props.children}</div>;

class AllLeaveStatisticsContainer extends React.Component {
  state = {
    LeaveStatistics: [],
    teams: [],
    teamNames: [],
    userRoles:[],
    profile: null,
    isLoading: false,
    shouldRender: false,
    leaveInfo: [],
    selectedTeam: [], 
    users: [],
    selectedUser: [],
    year: new Date().getFullYear(),
    isOpen1: false,
   
  };

  componentDidMount = () => {
    let user = UserManager.getUser();
    if (!user.isAdminOrHr()) {
      window.location.replace(`/Report/Index/${user.id}`);
    } else {
      this.setState({ shouldRender: true, isLoading: true });
      this.loadLeavStatisticsInformationOfYear();
      this.loadUserData()
      this.loadTeamData()
    }
  };
  loadTeamData = () => {
    TeamApi.all()
      .then((res) => {
        let teamNames = res.data;
        let names = [];
        teamNames.forEach(element => {
          var option = { label: element.name, value: element.id };
          names.push(option);
        });
        this.setState({
          teamNames: names,
        });
      })
      .catch((err) => {
        toaster.error("Failed to load Team information");
      });
  };

  loadUserData = () => {
    this.setState({ isLoading: true });
    UserApi.allCefaloUsers()
      .then((res) => {
        let usersData = res.data;
        let users = [];
        users.push({ label: "All Employees", value: "ALL" })
        usersData.forEach(element => {
          var option = { label: element.name, value: element.id };
          users.push(option);
        });
        this.setState({
          users: users,
        });
      })
      .catch((err) => {
        toaster.error("Failed to load Team information");
      });
  };
  loadLeavStatisticsInformationOfYear = () => {
    let year = this.state.year;
    this.setState({ isLoading: true });
    LeaveBalanceApi.getLeavStatisticsOfYear(year)
      .then((res) => {
        let leavestatistics = res.data.map((u) => new LeaveStatisticsDto(u)); 
        this.setState({ isLoading: false, LeaveStatistics: leavestatistics });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        defaultErrorResponseParser(err, "Failed to load Leave information");
      });
  };

  componentDidMount() {
    let currentYear = new Date().getFullYear();
    this.setState({ year: currentYear });
  }

  buildEndpoint = () => {
    let selectedTeams = [];
    let teamNames = "";
    let user = "";
    if(this.state.selectedTeam.length !== 0){
      this.state.selectedTeam.forEach(item => {
        selectedTeams.push(item.label)
      })
      teamNames = selectedTeams.join(",");
    } else teamNames = "";
    this.state.selectedUser.length === 0? user = "" : user = this.state.selectedUser.label;
    let endpoint = `${this.state.year}?teamNames=${teamNames}&name=${user}`;
    this.GetLeavStatisticsOfTeamAndUser(endpoint);
  }

  onYearSelect = (e) => {
    let year = Number.parseInt(e.target.value);
    this.setState({
      year: year,
    });
  }
  
  GetLeavStatisticsOfTeamAndUser(endpoint){
    this.setState({ isLoading: true });
    let selectedTeams = [];
    this.state.selectedTeam.forEach(item => {
      selectedTeams.push(item.label)
    })
    LeaveBalanceApi.getLeavStatisticsOfTeamAndUser(endpoint)
    .then((res) => {
      let leavestatistics = res.data.map((u) => new LeaveStatisticsDto(u)); 
      this.setState({ isLoading: false, LeaveStatistics: leavestatistics });
    })
    .catch((err) => {
      this.setState({ isLoading: false });
      defaultErrorResponseParser(err, "Failed to load Leave information");
    });
  }
  TeamhandleOnChange(value) {
    if(value == null){
      this.setState({
        selectedTeam: [],
      });
    }else{
      this.setState({
        selectedTeam: value,
      });
    }
  }
  UserhandleOnChange(e) {
    if(e.value === "ALL"){
      this.setState({
        selectedUser: [],
      });
    }else{
      this.setState({
        selectedUser: e,
      });
    }
  }

  handleExport = () => {
    this.setState({ isLoading: true });
      let selectedTeams = [];
      let teamNames = "";
      let user = "";
      // let fileName = "Leave_statistics";
      if(this.state.selectedTeam.length !== 0){
        this.state.selectedTeam.forEach(item => {
          selectedTeams.push(item.label)
        })
        teamNames = selectedTeams.join(",");
        // fileName = fileName + `_${teamNames}`;
      } else teamNames = "";
      this.state.selectedUser.length === 0? user = "" : user = this.state.selectedUser.label;
      let endpoint = `${this.state.year}/download?teamNames=${teamNames}&name=${user}`;
      LeaveBalanceApi.downloadLeaveStatistics(endpoint)
      .then((res) => {
        this.setState({ isLoading: false });
        let fileName = res.headers["x-attachmentname"];
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("Download", fileName);
        link.click();
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        if (err.response) {
          if (err.response.data.Title) {
          } else {
          }
        } else {
        }
      });
    };

  toggle = (index) => {
    let collapse = "isOpen" + index;

    this.setState((prevState) => ({ [collapse]: !prevState[collapse] }));
  };
  render() {
    if (!this.state.shouldRender) {
      return <></>;
    }
    let currentYear = new Date().getFullYear();
    
    return (
      <Layout>
        <Helmet>
          <title>Leave Statistics - Cefalo</title>
        </Helmet>
        <Loading show={this.state.isLoading} />
        <ViewContainer>
          <div className="d-flex justify-content-end">
          <div className="z-index-3 pt-1 pb-2">
          <div className=" d-inline-block pt-2 pr-2 pl-2">
                <CefaloButton block={true} variant="primary" size = "sm" type="button" onClick={this.handleExport}>
                 Export Report
                </CefaloButton>
          </div>
      <OverlayTrigger
            key="bottom"
            placement="bottom"
            overlay={<Tooltip id="attendance-tooltip-bottom">Filter</Tooltip>}
          >
      <CefaloButton
              style={{zIndex: 100, 'margin-bottom': '3px'}}
              onClick={() => this.toggle(1)}
              variant="light"
              size = "sm"
              aria-controls="attendance-filter-collpase"
              aria-expanded={this.state.collapse}
            >
              <CefaloIcon Icon={FaFilter} />
              <CefaloIcon Icon={FaCaretDown} />
        </CefaloButton>
        </OverlayTrigger>
        </div>
        </div>
        <Collapse 
          isOpen={this.state.isOpen1} 
          style={this.state.isOpen1?{overflow: "visible"}:{}}
        >
          <Container fluid className="p-0">
          <div>
          <Row>
          <div className="pl-3 float-left ml-2">
          <h6
              className="d-inline-block pr-2"
              style={{ lineHeight: "30px", fontSize: "16px" }}
            >
              Leave Statistics Of Year -
            </h6>
            <div className="inline-block">
              <Form.Control
                as="select"
                defaultValue={currentYear}
                className="d-inline-block"
                onChange={this.onYearSelect}
              >
                <option value={currentYear}>{currentYear}</option>
                <option value={currentYear - 1}>{currentYear - 1}</option>
              </Form.Control>
            </div>
          </div>
            <div className="col"> 
              <h6  className="d-inline-block pr-2 pl-3"
                  style={{ lineHeight: "30px", fontSize: "16px" }}>Employees: </h6>
                <Select 
                  className = "pl-3"
                  name="users"
                  isSearchable={true}
                  options={this.state.users}
                  onChange={this.UserhandleOnChange.bind(this)}
                  placeholder = "All"
                />           
            </div>
            <div className="col">
              <h6  className="d-inline-block pr-2"
                    style={{ lineHeight: "30px", fontSize: "17px" }}>Teams: </h6>
                  <Select 
                    name="teams"
                    isMulti={true}
                    isSearchable={true}
                    options={this.state.teamNames}
                    onChange={this.TeamhandleOnChange.bind(this)}
                    placeholder = "All Teams"
                  />
                 <div  className="d-inline-block pr-2"
                  style={{ lineHeight: "30px", fontSize: "16px" }}> </div>
            </div>
            <div className="col mr-2" >
              <div style={{ marginTop: '20px'}} className="pt-3 pr-4 pl-3">
                <CefaloButton block={true} variant="primary" size = "" type="button" onClick={this.buildEndpoint}>
                 Filter Report
                </CefaloButton>
              </div>
            </div>
          </Row> 
          </div>
          </Container>
        </Collapse>
          <LeaveStatisticsView
            LeaveStatistics={this.state.LeaveStatistics}
          />
        </ViewContainer>
      </Layout>
    );
  }
}
export default AllLeaveStatisticsContainer;
