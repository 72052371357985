import React from "react";
import PropTypes from "prop-types";
import ApplicationCreateFormModal from "../forms/CreateForm";
import { CefaloButton } from "../../../library/button";

export default class ApplicationCreateAction extends React.Component {

  static propTypes = {
    callbacks: PropTypes.object.isRequired,
    availableFund: PropTypes.number.isRequired,
  };

  state = {
    show: false,
  };

  toggle() {
    this.setState({ show: !this.state.show });
  };

  render() {
    return (
      <div className="d-inline-block" style={{ marginLeft: "auto" }}>
        <CefaloButton
          size="sm"
          variant="primary"
          className="mt-auto filter_panel_btn"
          onClick={this.toggle.bind(this)}
        >
          New Request
        </CefaloButton>
        <ApplicationCreateFormModal
          show={this.state.show}
          userId={this.props.userId}
          callbacks={this.props.callbacks}
          onToggle={this.toggle.bind(this)}
          availableFund={this.props.availableFund}
        />
      </div>
    );
  }
}
