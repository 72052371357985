export class LeaveStatisticsPerTeamDto {
    constructor(obj) {
      this.userId = obj.userId;
      this.employeeName = obj.employeeName;
      this.teamName = obj.teamName;
      this.fromDate = obj.fromDate;
      this.toDate = obj.toDate;
      this.totalAllocatedLeaves = obj.totalAllocatedLeaves;
      this.totalAvailedLeaves = obj.totalAvailedLeaves;
      this.leaveDifference = obj.leaveDifference;     
    }
  }
  