export const LeaveStatusesDictionary = Object.freeze({
  "1": "Requested",
  "2": "Approved",
  "3": "Rejected",
  "4": "Canceled",
  "5": "Cancel Request",
  "6": "Canceled By User",
  "7": "Cancel Request Approved",
  "8": "Cancel Request Rejected",
});

export const LeaveStatusValueDictionary = Object.freeze({
  Requested: 1,
  Approved: 2,
  Rejected: 3,
  Canceled: 4,
  CancelRequest: 5,
  CancelByUser: 6,
  CancelRequestApproved: 7,
  CancelRequestRejected: 8,
});

export const LeaveTypeValueDictionary = Object.freeze({
  CasualLeave: 1,
  SickLeaveWithDoc: 2,
  SickLeaveWithoutDoc: 3,
  UnpaidLeave: 4,
  MaternityLeave: 5,
  PaternityLeave: 6
})
