import React from "react";
import PropTypes from "prop-types";
import TrainingApi from "../../../apis/endpoints/TrainingApi";
import TrainingUpsertModalForm from "../forms/TrainingUpsertModalForm";
import { CefaloButton } from "../../../library/button";
import { Loading, toaster } from "../../../shared-components/notification";
import { defaultErrorResponseParser } from "../../../helpers/ErrorParser";
import { TrainingDto } from "../../../models/TrainingDto";

class TrainingUpdateAction extends React.Component {
  static propTypes = {
    training: PropTypes.object.isRequired,
    onUpdateCallback: PropTypes.func.isRequired,
  };

  state = {
    show: false,
    isLoading: false,
    training: this.props.training,
  };

  toggle = (e) => () => {
    this.setState({ show: !this.state.show });
  };

  onUpdateAction = (data) => {
    this.setState({ isLoading: true });
    TrainingApi.update(data)
      .then((res) => {
        this.setState({
          isLoading: false,
          show: false,
          training: new TrainingDto(res.data),
        });
        toaster.success("Training Updated Successfully");
        this.props.onUpdateCallback(res.data);
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        defaultErrorResponseParser(err, "Failed To Update Training");
      });
  };

  render() {
    return (
      <div className="d-inline-block">
        <Loading show={this.state.isLoading} />
        <CefaloButton
          className="btn-font-12"
          size="sm"
          variant="primary"
          onClick={this.toggle()}
        >
          Edit
        </CefaloButton>
        <TrainingUpsertModalForm
          formName="Edit Training"
          activeUsers={this.props.activeUsers}
          defaultValues={this.state.training}
          onSubmit={this.onUpdateAction}
          show={this.state.show}
          onToggle={this.toggle}
          isEdit
        />
      </div>
    );
  }
}

export default TrainingUpdateAction;
