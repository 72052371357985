import React from "react";
import Layout from "../../shared-components/layout/Layout";
import { Helmet } from "react-helmet";
import { Loading } from "../../shared-components/notification/Notification";
import { defaultErrorResponseParser } from "../../helpers/ErrorParser";
import { DateOnlyString } from "../../helpers/Builders";
import RemoteWorkApi from "../../apis/endpoints/RemoteWorkApi";
import { RemoteWorkApplicationDetailDto } from "../../models/RemoteWorkApplication";
import { RemoteWorkStatusValueDictionary } from "../../shared-components/remote-application/Consts";
import SupervisionView from "./views/SupervisionView";

class SupervisionContainer extends React.Component {
  state = {
    applications: [],
    isLoading: false,
    fromDate: DateOnlyString(
      new Date(new Date(new Date().getFullYear(), 0, 1))
    ),
    toDate: DateOnlyString(new Date(new Date().getFullYear(), 11, 31)),
    selectedRemoteWorkStatus: RemoteWorkStatusValueDictionary.Requested,
    remoteWorkStatusTypes: null,
    remoteWorkApplicationTypes: null,
  };

  componentDidMount() {
    this.setState({ shouldRender: true });
    this.loadRemoteWorkApplicationTypes();
    this.loadRemoteWorkStatusTypes();
    this.loadApplicationsData();
  }

  loadApplicationsData = (
    options = {
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
      remoteWorkStatus: this.state.selectedRemoteWorkStatus,
    }
  ) => {
    this.setState({ isLoading: true });

    RemoteWorkApi.all(options)
      .then((res) => {
        this.setState({
          isLoading: false,
          applications: res.data.map(
            (item) => new RemoteWorkApplicationDetailDto(item)
          ),
        });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        defaultErrorResponseParser(
          err,
          "Failed To Load Remote Work Applications"
        );
      });
  };

  loadRemoteWorkStatusTypes = () => {
    RemoteWorkApi.getStatusTypes()
      .then((res) => {
        this.setState({
          remoteWorkStatusTypes: res.data,
        });
      })
      .catch((err) => {
        defaultErrorResponseParser(
          err,
          "Failed To Load Remote Work Status Types"
        );
      });
  };

  loadRemoteWorkApplicationTypes = () => {
    RemoteWorkApi.getApplicationTypes()
      .then((res) => {
        this.setState({
          remoteWorkApplicationTypes: res.data,
        });
      })
      .catch((err) => {
        defaultErrorResponseParser(err, "Failed To Load Application Types");
      });
  };

  onApplicationStatusUpdate = () => {
    this.loadApplicationsData();
  };

  onFilter = (data) => {
    this.setState({
      fromDate: data.fromDate,
      toDate: data.toDate,
      remoteWorkType: data.remoteWorkType,
      selectedRemoteWorkStatus: data.remoteWorkStatus,
    });

    this.loadApplicationsData(data);
  };

  onApplicationDelete = () => {
    this.loadApplicationsData();
  };

  onApplicationUpdate = (updatedApplications) => {
    this.loadApplicationsData();
  };

  checkIsComponentReadyToRender = () => {
    return (
      !!this.state.remoteWorkStatusTypes &&
      !!this.state.remoteWorkApplicationTypes
    );
  };

  render() {
    if (!this.checkIsComponentReadyToRender()) {
      return <></>;
    }
    return (
      <Layout>
        <Helmet>
          <title>Remote Work Supervision- Cefalo</title>
        </Helmet>
        <div className="pr-3 pl-3 pt-3 pb-0">
          <Loading show={this.state.isLoading} />
          <SupervisionView
            applications={this.state.applications}
            onCreate={this.onApplicationCreate}
            onUpdate={this.onApplicationUpdate}
            onDelete={this.onApplicationDelete}
            onStatusUpdate={this.onApplicationStatusUpdate}
            onFilter={this.onFilter}
            remoteWorkStatusTypes={this.state.remoteWorkStatusTypes}
            remoteWorkApplicationTypes={this.state.remoteWorkApplicationTypes}
          />
        </div>
      </Layout>
    );
  }
}

export default SupervisionContainer;
