import React from "react";
import { Form } from "react-bootstrap";
import Error from "./Error";

const Options = ({ optionsArray = [] }) => (
  <>
    {optionsArray.map((o, i) => (
      <option key={i} value={o.value}>
        {o.label}
        {!!o.description && " "}
        {!!o.description && o.description}
      </option>
    ))}
  </>
);

const Select = React.forwardRef((props, ref) => {
  const {
    label,
    name,
    defaultValue,
    options,
    errors,
    containerClassName = "",
    ...restProps
  } = props;
  return (
    <Form.Group className={containerClassName}>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        as="select"
        size="sm"
        name={name}
        defaultValue={defaultValue}
        ref={ref}
        {...restProps}
      >
        <Options optionsArray={options} />
      </Form.Control>
      <Error errors={errors} field={name} />
    </Form.Group>
  );
});

export default Select;
