import React from "react";
import PropTypes from "prop-types";
import { TeamDto } from "../../../models/Team";
import TeamCreateAction from "../actions/TeamCreateAction";
import TeamTableView from "./TeamTableView";
import { Loading } from "../../../shared-components/notification/Notification";

const TeamManageView = (props) => {
  return (
    <div className="p-2">
      <Loading show={props.isLoading} />
      <div>
        <div className="mt-3 mb-3 d-inline-block">
          <TeamCreateAction
            onCreateCallback={props.onCreate}
            clients={props.clients}
          />
        </div>
        <TeamTableView {...props} />
      </div>
    </div>
  );
};

TeamManageView.prototype = {
  teams: PropTypes.arrayOf(TeamDto),
  isLoading: PropTypes.bool.isRequired,
  onCreate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default TeamManageView;
