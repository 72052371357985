import React from "react";
import { Modal, Form } from "react-bootstrap";
import PropTypes from "prop-types";

const ControlContainer = "form-control-container";
const ActionContainer = "form-action-container";

const FormModal = (props) => {
  const {
    onSubmit,
    toggle,
    formName,
    modalSize = "md",
    show = false,
    showHeader = true,
    ...restProps
  } = props;
  const getComponentFromChildren = (key) => {
    return restProps.children.filter((comp) => {
      return comp.key === key;
    });
  };

  const handleHide = () => {
    toggle();
  };

  return (
    <Modal show={show} onHide={handleHide} centered size={modalSize}>
      <Form onSubmit={onSubmit} {...restProps}>
        {showHeader && (
          <Modal.Header closeButton>
            <Modal.Title>{formName}</Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body>{getComponentFromChildren(ControlContainer)}</Modal.Body>
        <Modal.Footer>{getComponentFromChildren(ActionContainer)}</Modal.Footer>
      </Form>
    </Modal>
  );
};

FormModal.ControlContainer = ControlContainer;
FormModal.ActionContainer = ActionContainer;

FormModal.prototype = {
  show: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default FormModal;
