import React from "react";
import { Table } from "react-bootstrap";
import styles from "../styles/Profile.module.css";

const UserProfileLeaveStatView = (props) => {
  const profileLeaveStat = props.leaveStat;

  return (
    <div className="mt-5">
      <div className="table-striped">
        <Table bordered className="table-sm">
          <thead>
            <tr className={styles.profileTitleHeader}>
              <th>
                <b>Leaves</b>
              </th>
              <th>
                <b>Count</b>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Total Available Leaves</td>
              <td>{profileLeaveStat?.totalAvailableLeaves}</td>
            </tr>
            <tr>
              <td>Casual Leaves</td>
              <td>{profileLeaveStat?.casualLeaves}</td>
            </tr>
            <tr>
              <td>Remaining Leaves</td>
              <td>
                <b>{profileLeaveStat?.remainingLeaves}</b>
              </td>
            </tr>
            {profileLeaveStat?.sickLeaves > 0 && (
              <tr>
                <td>Sick Leaves</td>
                <td>{profileLeaveStat?.sickLeaves}</td>
              </tr>
            )}
            {profileLeaveStat?.parentalLeaves > 0 && (
              <tr>
                <td>Parental Leaves</td>
                <td>{profileLeaveStat?.parentalLeaves}</td>
              </tr>
            )}
            {profileLeaveStat?.unpaidLeaves > 0 && (
              <tr>
                <td>Unpaid Leaves</td>
                <td>{profileLeaveStat?.unpaidLeaves}</td>
              </tr>
            )}
            {profileLeaveStat?.leavesAvailed > 0 && (
              <tr>
                <td>Leaves Availed</td>
                <td>{profileLeaveStat?.leavesAvailed}</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      <div className="table-striped"></div>
    </div>
  );
};

export default UserProfileLeaveStatView;
