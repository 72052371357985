import React, { Component } from "react";
import extraWorkApi from "../../apis/endpoints/ExtraWorkApplicationApi";
import ExtraWorkApplicationView from "./ExtraWorkApplicationView";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import WithCrudActionsOnArray from "../../shared-components/hoc/crud/WithCrudActionsOnArray";
import { setConstants } from "./Const";
import { DateOnlyString } from "../../helpers/Builders";
import TeamApi from "../../apis/endpoints/TeamApi";
import { toaster } from "../../shared-components/notification";

class ExtraWorkSupervisionContainer extends Component {
  state = {
    constSetted: false,
    isLoading: false,
    teamNames: [],
  };
  toggleOnLoader = () => this.setState({ isLoading: true });
  toggleOffLoader = () => this.setState({ isLoading: false });
  componentDidMount() {
    let currentYear = new Date().getFullYear();
    let fromDate = DateOnlyString(new Date(currentYear, 0, 1));
    let toDate = DateOnlyString(new Date(currentYear, 11, 31));
    let filter = {
      fromDate,
      toDate,
      applicationStatus: 1,
      extraWorkTeamId: 0
    };

    this.toggleOnLoader();
    extraWorkApi
      .all(filter)
      .then((resp) => {
        this.toggleOffLoader();
        this.props.setArrayStateCallback(resp.data);
      })
      .catch(() => this.toggleOffLoader());
    setConstants(() => this.setState({ constSetted: !this.state.constSetted }));

    this.loadTeams();
  }

  loadTeams = () => {
    TeamApi.all()
      .then((res) => {
        let teams = res.data;
        let names = [];
        teams.forEach((element) => {
          var option = { label: element.name, value: element.id };
          names.push(option);
        });
        this.setState({
          teamNames: names,
        });
      })
      .catch((err) => {
        toaster.error("Failed to load Team information");
      });
  };

  onFilter = (filter) => {
    return extraWorkApi
      .all(filter)
      .then((resp) => this.props.setArrayStateCallback(resp.data));
  };

  actionsCallbacks = {
    ...this.props.arrayCrudCallbacks,
    onFilter: this.onFilter,
  };

  render() {
    const { crudArray: applications } = this.props;
    const { isLoading } = this.state;
    return (
      <>
        <Helmet>
          <title>EWD Supervision- Cefalo</title>
        </Helmet>
        <ExtraWorkApplicationView
          isLoading={isLoading}
          applications={applications}
          actionsCallbacks={this.actionsCallbacks}
          isSupervision={true}
          teamNames={this.state.teamNames}
        />
      </>
    );
  }
}

export default WithCrudActionsOnArray(extraWorkApi)(
  ExtraWorkSupervisionContainer
);

ExtraWorkSupervisionContainer.propTypes = {
  setArrayStateCallback: PropTypes.func.isRequired,
  crudArray: PropTypes.array.isRequired,
  arrayCrudCallbacks: PropTypes.object.isRequired,
};
