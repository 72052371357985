import React from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import TeamUpdateAction from "../actions/TeamUpdateAction";
import TeamDeleteAction from "../actions/TeamDeleteAction";

const ActionsGroupView = (props) => {
  return (
    <div className="d-inline-block action-col">
      <TeamUpdateAction
        key={uuidv4()}
        teamData={props.team}
        clients={props.clients}
        onUpdateCallback={props.updateAction}
      />
      <TeamDeleteAction
        key={uuidv4()}
        onDeleteCallback={props.deleteAction}
        team={props.team}
      />
    </div>
  );
};
ActionsGroupView.prototype = {
  team: PropTypes.object,
  deleteAction: PropTypes.func.isRequired,
  updateAction: PropTypes.func.isRequired,
};

export default ActionsGroupView;
