export class BillDto {
  constructor(obj) {
    this.name = obj.name;
    this.shortName = obj.shortName;
    this.careerStartDate = obj.careerStartDate;
    this.joiningDate = obj.joiningDate;
    this.experience = obj.experience;
    this.jobTenure = obj.jobTenure;
    this.designation = obj.designation;
    this.teamName = obj.teamName;
    this.allocation = obj.allocation;
    this.fromDate = obj.fromDate;
    this.toDate = obj.toDate;
    this.billingStatus = obj.billingStatus;
    this.extraWorkHour = obj.extraWorkHour;
    this.leavesThisMonth = obj.leavesThisMonth;
    this.vacationLeavesThisMonth = obj.vacationLeavesThisMonth;
    this.otherLeavesThisMonth = obj.otherLeavesThisMonth;
    this.billingDesignation = obj.billingDesignation;
    this.leavesThisYear = obj.leavesThisYear;
    this.vacationLeavesThisYear = obj.vacationLeavesThisYear;
    this.otherLeavesThisYear = obj.otherLeavesThisYear;
    this.kam = obj.kam;
    this.customer = obj.customer;
    this.status = obj.status;
  }
}
