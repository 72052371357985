import React, { Component } from "react";
import InternalLoginView from "./InternalLoginView";
import AuthApi from "../../apis/endpoints/AuthApi";
import LightLayout from "../../shared-components/layout/LightLayout";

export default class InternalLoginContainer extends Component {
  state = {
    email: "",
    password: "",
    loading: false,
    submitError: false,
  };
  onChange = (event) =>
    this.setState({
      [event.target.name]: event.target.value,
      submitError: false,
    });

  onSubmit = (event) => {
    event.preventDefault();
    const { email, password } = this.state;
    this.setState({ loading: true, submitError: false });

    AuthApi.internalLogin({ email, password })
      .then((userInfo) => {
        // let user = { ...userInfo };
        // UserManager.setUser(user);
        localStorage.setItem("__C_Token", userInfo.token);
        window.location.replace("/");
      })
      .catch((error) => this.setState({ submitError: true, loading: false }));
  };

  render() {
    return (
      <LightLayout>
        <div className="loginJumbotron">
          <InternalLoginView
            onChange={this.onChange}
            onSubmit={this.onSubmit}
            {...this.state}
          />
        </div>
      </LightLayout>
    );
  }
}
