import React from "react";
import PropTypes from "prop-types";
import LeaveApplicationApi from "../../../apis/endpoints/LeaveAppliactionApi";
import ApplicationUpdateFormModal from "../forms/UpdateForm";
import CefaloUtility from "../../../helpers/CefaloUtility";
import { toaster, Loading, ConfimationPopup } from "../../notification";
import { defaultErrorResponseParser } from "../../../helpers/ErrorParser";
import { CefaloButton } from "../../../library/button";

export default class ApplicationUpdateAction extends React.Component {
  static propTypes = {
    userId: PropTypes.number.isRequired,
    application: PropTypes.object.isRequired,
    onUpdateCallback: PropTypes.func.isRequired,
  };

  state = {
    showUpdateModel: false,
    showConfirmation: false,
    fromDate: new Date(this.props.application.fromDate),
    toDate: new Date(this.props.application.toDate),
    includeHalfDay: this.props.application.includeSingleHalfDay,
    totalWorkingDays: this.props.application.totalNumberOfWorkingDays,
    isLoading: false,
  };

  valueReset = () => {
    this.setState({
      fromDate: new Date(this.props.application.fromDate),
      toDate: new Date(this.props.application.toDate),
      includeHalfDay: this.props.application.includeSingleHalfDay,
      totalWorkingDays: this.props.application.totalNumberOfWorkingDays,
    });
  };

  toggle = (e) => () => {
    this.setState({ showUpdateModel: !this.state.showUpdateModel });
    this.valueReset();
  };

  onApplicationPeriodSelect = (fromDate, toDate, includeHalfDay) => {
    this.setState({
      fromDate: fromDate,
      toDate: toDate,
      includeHalfDay: includeHalfDay,
    });
    CefaloUtility.getWorkingDaysCount(
      fromDate,
      toDate,
      includeHalfDay,
      (days) => {
        this.setState({ totalWorkingDays: days });
      }
    );
  };

  onUpdateConfirmation = (confirm) => {
    if (!confirm) {
      this.setState({ showConfirmation: false });
    } else {
      let applicationData = this.state.updatedApplicationData;
      this.setState({
        isLoading: true,
        updatedApplicationData: null,
        showConfirmation: false,
      });
      LeaveApplicationApi.update(this.props.application.id, applicationData)
        .then((res) => {
          this.props.onUpdateCallback(res.data);
          toaster.success("Application Updated Successfully");
          this.setState({
            isLoading: false,
            showUpdateModel: false,
          });
        })
        .catch((err) => {
          this.setState({ isLoading: false });
          defaultErrorResponseParser(err, "Failed To Update Leave Application");
        });
    }
  };

  onUpdateAction = (applicationData) => {
    applicationData["userId"] = this.props.userId;
    applicationData["id"] = this.props.application.id;
    this.setState({
      updatedApplicationData: applicationData,
      showConfirmation: true,
    });
  };

  render() {
    return (
      <div className="d-inline-block">
        <Loading show={this.state.isLoading} />
        <ConfimationPopup
          show={this.state.showConfirmation}
          text="Are you sure to Edit this leave application?"
          onConfirmCallback={this.onUpdateConfirmation}
        />
        <CefaloButton
          className="btn-font-12"
          size="sm"
          variant="info"
          onClick={this.toggle()}
        >
          Edit
        </CefaloButton>
        <ApplicationUpdateFormModal
          show={this.state.showUpdateModel}
          onUpdate={this.onUpdateAction}
          onToggle={this.toggle}
          toDate={this.state.toDate}
          fromDate={this.state.fromDate}
          totalWorkingDays={this.state.totalWorkingDays}
          periodSelectCallBack={this.onApplicationPeriodSelect}
          includeHalfDay={this.state.includeHalfDay}
          application={this.props.application}
          leaveTypes={this.props.leaveTypes}
        />
      </div>
    );
  }
}
