import { AuthenticatedAxios, jsonContentConfig } from "../settings/Axios";
const BaseEndPoint = "Trainings";

export default {
  all: () => {
    let endpoint = `${BaseEndPoint}`;
    return AuthenticatedAxios.get(endpoint, null, null);
  },

  update: (training) => {
    let endpoint = `${BaseEndPoint}/${training.id}`;
    let body = JSON.stringify(training);

    return AuthenticatedAxios.put(endpoint, body, jsonContentConfig);
  },

  create: (training) => {
    let body = JSON.stringify(training);
    return AuthenticatedAxios.post(BaseEndPoint, body, jsonContentConfig);
  },

  delete: (id) => {
    let endpoint = `${BaseEndPoint}/${id}`;
    return AuthenticatedAxios.delete(endpoint, null, {});
  },
};
