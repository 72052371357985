import moment from "moment/moment.js";

export function _toLocaleDateString(d) {
  let date = new Date(d).toLocaleDateString();
  return date;
}

export const isDateEqualToOrGreaterThanToday = (date) => {
  return date.setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0);
};

export const getCefaloFormatDate = (d) => {
  let date = new Date(d);
  let formattedDate = `${date.getDate()}-${
    months[date.getMonth()]
  }-${date.getFullYear()}`;
  return formattedDate;
};

export function getCurrentYearStartDate() {
  let currentYear = new Date().getFullYear();
  let date = new Date(currentYear, 0, 1);
  return date;
}

export function getCurrentYearEndDate() {
  let currentYear = new Date().getFullYear();
  let date = new Date(currentYear, 11, 31);
  return date;
}

export function getDaysFromDateRange(to, from) {
  let d1 = new Date(to).getDate();
  let d2 = new Date(from).getDate();
  return d1 - d2 + 1;
}
const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const getTableFormatDate = (d) => {
  return moment(d).format("DD-MMM-YYYY");
};

export const getDateByAddingDays = (days = 0) => {
  return moment().add(days, "days");
};
